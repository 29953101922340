import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {

    getAllInterfaceSettings = () => request({
        url: 'interface-settings',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllInterfaceSettingsBaseStyle = () => request({
        url: 'interface-settings',
        method: 'GET',
        token: API_GLOBAL.token
    });



}

const API_CATEGORIES = new ClassAPI();

export default API_CATEGORIES;
