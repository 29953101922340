// @ts-nocheck
import React, { useState } from 'react';
import GraphForm from 'components/Graphs/Form';
import { getObjectTrim } from 'utils/formatters';
import { authRequest } from '../../../redux/actions';
import { AUTH_FORM } from 'constants/forms';
import normalize from './normalize';
import config from './fields';
import validate from './validate';
import { useResolution } from 'hooks/useResolution';
import { Actions } from './actions';

interface ICategoryForm {
  title?: string;
  type?: string;
}

const LoginForm = ({ title, type }: ICategoryForm) => {
  const { isMobile } = useResolution();
  const [restore, setRestore] = useState(false);

  const toggleRestore = () => {
    setRestore(true);
  };

  return (
    <GraphForm
      form={AUTH_FORM}
      actions={
        <Actions
          isMobile={isMobile}
          restore={restore}
          toggleRestore={toggleRestore}
        />
      }
      create={authRequest}
      title={title}
      normalize={normalize}
      validate={(values: object, type: string) =>
        validate(getObjectTrim(values), type)
      }
      type={type}
      columns={1}
      width="12"
      config={config}
    />
  );
};

export default LoginForm;
