import { createSelector } from 'reselect';

const selectGraphFormState = (state: any) => state.graphNews;

export const selectCurrentForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => ownProps.table,
    ],
    (graphForm, table) => graphForm.get(table),
);

export const makeSelectData = () => createSelector(
    selectCurrentForm,
    state => state && state.get('data'),
);