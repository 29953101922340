import * as ACTION_TYPE from './constants';


export const getEquityResearchSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_EQUITY_RESEARCH_SORT_REQUEST,
    meta,
    payload
});


export const getProfileClient = ({ payload, meta }: any) => ({
    type: ACTION_TYPE.GET_PROFILE_CLIENT,
    payload,
    meta,
})

export const editProfileClient = ({ payload, meta }: any) => ({
    type: ACTION_TYPE.EDIT_CLIENTS_REQUEST,
    payload,
    meta,
})

