import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

const CustomizedMostPopularLabel = (props: any) => {
  const { x, y, value } = props;

  return (
    <g>
      <text
        className={'text-t'}
        x={x - 3}
        y={y}
        fill="#000"
        textAnchor="end"
        dominantBaseline="hanging"
      >
        {value}
      </text>
    </g>
  );
};

const FlowChart2 = ({ normalizeTableData, valuesModal, customClass }: any) => {
  const toPercent = (decimal: number) => {
    return `${decimal}%`;
  };

  const data = normalizeTableData(valuesModal);

  return (
    <>
      {data && (
        <>
          <div
            className={`${customClass} plateq plateq-w plate_theme_alfa-on-white chart`}
          >
            <div
              className={'heading heading_size_s heading_theme_alfa-on-white'}
            >
              {data.title}
            </div>
            <ResponsiveContainer height={370}>
              <ComposedChart
                barCategoryGap="10px"
                layout="vertical"
                data={data.content}
                margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
              >
                <CartesianGrid horizontal={false} stroke="#d5d8dc" />
                <Area dataKey="value" dot={{ stroke: 'red', strokeWidth: 2 }} />
                <XAxis
                  hide={false}
                  tick={{ fontSize: 10 }}
                  tickLine={true}
                  axisLine={false}
                  stroke="#969ea8"
                  type="number"
                  tickFormatter={toPercent}
                />
                <YAxis hide={true} type="category" />

                <Bar
                  isAnimationActive={false}
                  dataKey="minus"
                  barSize={20}
                  fill="#ef3124"
                >
                  <LabelList
                    dataKey="nameminus"
                    position="inside"
                    content={<CustomizedMostPopularLabel dataKey="nameminus" />}
                  />
                </Bar>

                <Bar
                  isAnimationActive={false}
                  dataKey="plus"
                  barSize={20}
                  fill="#2fc26e"
                >
                  <LabelList
                    dataKey="nameplus"
                    position="inside"
                    content={<CustomizedMostPopularLabel dataKey="nameplus" />}
                  />
                </Bar>
                <Legend
                  content={
                    <span
                      style={{
                        color: '#969ea8',
                        fontSize: '12px',
                      }}
                    >
                      {' '}
                      {data.legend}{' '}
                    </span>
                  }
                />
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </>
  );
};

export default compose<any>(withRouter)(FlowChart2);
