import React from 'react';
import Paragraph from 'arui-feather/paragraph';
import { isNil, isEmpty } from 'lodash';
import { pdfValidateTypeForModal } from '../../Icon/Icons';
import './EventInfo.scss';
import Label from '../../Label';

const EventInfo = (data) => {
  const { modal } = data;
  return (
    <div className="event-info-content">
      <div className="event-info-content__top">
        <div className="event-info-content__top_title">
          <h2>{modal.name}</h2>
        </div>
        <div className="event-info-content__top_date">
          {modal.startDate} – {modal.endDate}
        </div>
      </div>
      <div className="event-info-content__info">
        {/* 2021-09-01 п.1.9
                {!isEmpty(modal.country) && (<Paragraph view="normal">
                    <b>Country:</b> {modal.country}
                </Paragraph>)}
*/}
        {!isEmpty(modal.company) && (
          <Paragraph view="normal">
            <b>Company/Country:</b> {modal.company}
          </Paragraph>
        )}
        <Paragraph view="normal">
          <b>Event type:</b> {modal.eventType}
        </Paragraph>
        <Paragraph view="normal">{modal.comment}</Paragraph>
      </div>

      {!isNil(modal.file) && (
        <div>
          <div className="event-info-content__file">
            <div className="event-info-content__file_file-icon">
              {pdfValidateTypeForModal(modal, data.dispatch)}
            </div>
            <div className="event-info-content__file_info">
              <div className="event-info-content__file_info file-delail">
                  {modal.categoriesTitle && (
                      <Label text={modal.categoriesTitle}/>
                  )
                  }
                <span className="file-date">
                  <span className="research-content__title_date">
                    {modal.date}
                  </span>
                </span>
              </div>
              <div className="event-info-content__file_info file-delail">
                <span className="file-date">
                  <span className="research-content__title_date">
                    {modal.productName}
                  </span>
                </span>
              </div>

              {/* akayerov
                        <div className="event-info-content__file_info file-comment">
                            <Paragraph view='normal'>
                                <div dangerouslySetInnerHTML={{__html: modal.annotation}}/>
                            </Paragraph>
                        </div>
*/}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventInfo;
