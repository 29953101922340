import React from 'react';
import cn from 'bem-cn';
import './UserCartItem.scss';

const userCart = cn('user-cart');
const userInfo = cn('user-info');

class UserCartItemManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowing: false,
      type: null,
      items: null,
      currentProfile: null,
    };
  }

  renderManagerProfile(userData) {
    const { handleExpandedChange } = this.props;
    return (
      <div
        onClick={() => handleExpandedChange(userData && userData.modal)}
        className={userInfo({ border: 'hover' })}
      >
        {/* @TODO: extra userInfo wrapper. Refactoring! */}
        <div className={userInfo('photo', { type: 'management' })}>
          <img
            className="img-fluid"
            src={`${userData.photo}`}
            alt={`${userData.name}`}
          />
        </div>
        <div className={userInfo('detail').mix('valign-top')}>
          {userData.name && <span className="fullname">{userData.name}</span>}
          {userData.position && (
            <span className="department">{userData.position}</span>
          )}
          {/* {userData.name && (*/}
          {/*    <span className="post">*/}
          {/*        {userData.name}*/}
          {/*    </span>*/}
          {/* )}*/}
        </div>
      </div>
    );
  }

  render() {
    const { user } = this.props;
    const type = user.type;
    return type === 'Manager' ? (
      <div className={userCart()}>
        <div className={userCart()}>
          {this.renderManagerProfile(user, user.type)}
        </div>
      </div>
    ) : (
      ''
    );
  }
}

export default UserCartItemManager;
