// @ts-nocheck
import {call, put, takeLeading, takeLatest, all, select} from 'redux-saga/effects';
import API from '../api';
import {
    GET_ALL_EQUITYRESEARCH_REQUEST,
    CREATE_NEW_EQUITYRESEARCH_REQUEST,
    EDIT_EQUITYRESEARCH_REQUEST,
    GET_EQUITY_RESEARCH_SORT_REQUEST
} from './constants';
import {CATEGORIES} from 'constants/categories';
import API_CATEGORY from 'modules/Category/api'
import {errorMessageFailure} from "../../Error/redux/actions";

function* getAllInterfaceSettings({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllInterfaceSettings);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* createNewInterfaceSettings({payload: {callback, fields}, meta: {form, onSuccess}}: any) {
    try {
        const response = yield call(API.createNewInterfaceSettings, {data: fields});
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getInterfaceSettingsById({payload: {callback, data: {id}}, meta: {form}}: any) {
    try {
        const response = yield call(API.getByIdInterfaceSettings, {id});
        if (callback) {
            return yield put(callback({payload: {response}, meta: {form}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* editInterfaceSettings({payload: {callback, fields}, meta: {form, onSuccess}}: any) {
    try {
        const response = yield call(API.editInterfaceSettings, {data: fields});
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllCategories({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllCategories);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllSectors({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllSectors);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}


function* getProductType({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getProductType);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllAuthorTypes({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllAuthorTypes);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllProduct({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllProduct);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            //if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllEvents({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllEvents);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllAuthor({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllAuthor);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}


function* getAllEventType({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllEventType);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllCountry({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllCountry);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getProductByLanguageAndCategory({payload, meta: {table, callback}}: any) {
    try {
        const response = yield call(API_CATEGORY.getProductByLanguageAndCategoryId, {}, table);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* getAllCompany({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllCompany);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_ALL_EQUITYRESEARCH_REQUEST, getAllInterfaceSettings),
        takeLeading(CREATE_NEW_EQUITYRESEARCH_REQUEST, createNewInterfaceSettings),
        takeLeading(EDIT_EQUITYRESEARCH_REQUEST, editInterfaceSettings),
    ]);
}

export default Saga;
