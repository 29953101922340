import React from 'react';
import {
    getAllCommoditiesRequest
} from 'modules/Commodities/redux/actions';
import {getActiveAuthorRequest, getAllAnalystRequest, getAllAuthorRequest} from 'modules/Author/redux/actions';
import {getLanguageFromStore} from 'utils/formatters'
import {FIELD_NAME} from 'constants/languagesFields';
export default [

    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "single",
        bigLabel: false,
        id: 'commoditiesOne',
        label: FIELD_NAME['commoditiesOneCustom'][getLanguageFromStore()],
        normalize: 'commoditiesOne',
        actions: {commodities: getAllCommoditiesRequest},
    },
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "many",
        bigLabel: false,
        id: 'authorType',
        label: FIELD_NAME['author'][getLanguageFromStore()],
        normalize: 'authorType',
//        actions: {authorType: getAllAnalystRequest},
// akayerov
//        actions: {authorType: getAllAuthorRequest},
// 2021-10-05
        actions: {authorType: getActiveAuthorRequest},


    },
    {
        fieldWidth: '12',
        title: "Date",
        id: 'date',
        name: "date",
        label: FIELD_NAME['date'][getLanguageFromStore()],
        isExpanded: false,
        type: "calendar",
        bigLabel: false,
        dateFrom: '01.01.2020',
        dateTo: '02.02.2020',
        language: 'rus'
    },

];
