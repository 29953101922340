// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { IBodyTable, IHeadTable } from 'components/Flows/Table/interface';
import { COMPANIES_STOCK_RECOMMENDATION_TABLE } from 'constants/tables';
import { getLanguageFromStore, language } from 'utils/formatters';
import { isArray } from 'lodash';
import { TABLE_HEAD } from 'constants/languagesTable';
import { COMPANIES_STOCK_RECOMMENDATION_FILTER } from 'constants/forms';
import { getAllStockRecommendationByCompanyIdRequest } from '../../../../Company/redux/actions';

class EquityResearchTable extends React.Component<any, any> {
  normalizeTableData = (
    head: IHeadTable[],
    body: IBodyTable[],
    valuesTable: any,
  ): any => {
    const { equityTickers, currency, recommendationHistory } = valuesTable;
    const processedData =
      (isArray(equityTickers) &&
        isArray(currency) &&
        isArray(recommendationHistory) &&
        recommendationHistory?.map((item: any) => {
          const { id }: any = item;
          const equityTickerId = equityTickers.find(
            (iy: any) => iy.id === item.equityTickerId,
          );

          const currencyId = equityTickerId && equityTickerId.currencyId;

          const currencyName = currency.find((iy: any) => iy.id === currencyId);
          const curren =
            currencyName && currencyName.name && item && item.targetPrice
              ? ` ${currencyName.name} ${`${item.targetPrice}`}`
              : '';

          return {
            id,
            modal: null,
            cells: [
              {
                element: item.date,
                id,
                type: 'date',
                width: 'l',
              },
              {
                element: equityTickerId && equityTickerId.name,
                id,
                type: 'equityTickerId',
                width: 'l',
              },
              {
                element: curren,
                id,
                type: 'targetPrice',
                width: 'l',
              },
              {
                element: language(
                  equityTickerId?.stockRecommendation?.recommendation,
                  'en',
                ),
                id,
                type: 'recommendation',
                width: 'l',
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: any) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  getRowLink = (id: any) => `/interface-settings/${id}`;

  render() {
    const { handleRowClick, companyId }: any = this.props;
    const head = [
      {
        id: 'date',
        type: 'modal',
        label: TABLE_HEAD['date'][getLanguageFromStore()],
        style: { width: 's' },
        sort: 'none',
      },
      {
        id: 'equityTickerId',
        label: TABLE_HEAD['equityTickerId'][getLanguageFromStore()],
        style: { width: 'm' },
        sort: 'none',
      },
      {
        id: 'targetPrice',
        label: TABLE_HEAD['targetPrice'][getLanguageFromStore()],
        style: { width: 'm' },
        sort: 'none',
      },
      {
        id: 'recommendation',
        label: TABLE_HEAD['recommendation'][getLanguageFromStore()],
        style: { width: 'm' },
        sort: 'none',
      },
    ];

    const graphId = {
      companyId,
    };
    const actions = {
      //    productType: productTypeRequest,
    };

    return (
      <GraphTable
        customTableClass={''}
        actions={actions}
        graphId={graphId}
        type={'edit'}
        caption={''}
        filterForm={COMPANIES_STOCK_RECOMMENDATION_FILTER}
        table={COMPANIES_STOCK_RECOMMENDATION_TABLE}
        normalizeTableData={this.normalizeTableData}
        init={getAllStockRecommendationByCompanyIdRequest}
        onRowClick={()=>{}}
        head={head}
        link={this.getRowLink}
        width={'7'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        pagination={true}
      />
    );
  }
}

export default EquityResearchTable;
