import React from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import './LawFile.scss';
import { makeSelectLawFile } from '../redux/selectors2';
import { getLanguageFromStore, dowloadFile } from '../../../utils/formatters';
import {
  IconPdf,
  IconJpg,
  IconXls,
  IconDoc,
  IconPng,
} from 'components/Icon/Icons';

const normalize = (item) => {
  const language = getLanguageFromStore();
  let label = '';
  let url = '';
  if (language === 'en') {
    label = item.nameEn;
    url = item.urlEn;
  }
  if (language === 'ru') {
    label = item.nameRu;
    url = item.urlRu;
  }
  return { label, url };
};

const LawItem = ({ label, url }) => {
  const extension = url?.split('.');

  let icon = null;

  if (extension?.length) {
    switch (extension[extension.length - 1]) {
      case 'pdf': {
        icon = <IconPdf />;
        break;
      }
      case 'jpg': {
        icon = <IconJpg />;
        break;
      }
      case 'xls':
      case 'xlsx': {
        icon = <IconXls />;
        break;
      }
      case 'doc':
      case 'docx': {
        icon = <IconDoc />;
        break;
      }
      case 'png': {
        icon = <IconPng />;
        break;
      }
      default:
    }
  }

  return (
    <div className="lowfile-item">
      <div className="link link_size_s link_theme_alfa-on-white lowfile">
        <span className="link__text-container">
          <span className="link__text lowfile" onClick={() => download(url)}>
            <span className="svg-icon">{icon}</span>
            {label}
          </span>
        </span>
      </div>
    </div>
  );
};

const download = (url) => {
  dowloadFile(url, null);
};

export class LawFile extends React.PureComponent {
  render() {
    let data = [];
    if (this.props && this.props.lawFile) {
      // из store возвращается MAP
      data = this.props.lawFile
        .get('data')
        .slice(0, 5)
        .map((item) => normalize(item));
    }
    if (this.props && this.props.apiData) {
      data = this.props.apiData.map((item) => normalize(item));
    }
    return (
      <div className="lowfile-container">
        {data &&
          data.map((lawFile, index) => {
            return (
              <LawItem key={index} label={lawFile.label} url={lawFile.url} />
            );
          })}
      </div>
    );
  }
}

// export default LawFile;
const mapStateToProps = createStructuredSelector({
  lawFile: makeSelectLawFile(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(LawFile);
