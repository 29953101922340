import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
    addDownloadStatistics = (payload: any) => {
        console.log('addDownloadStatistics payload=', payload)

        const data = {
            type: 'DNL',                // тип download
            clientId: payload?.user?.clientId,
            userId: payload?.user?.id,
            userName: payload?.user?.username,
            productId: payload?.item?.id,
            productName: payload?.item?.name,

            productTypeId: payload?.item?.productTypeId ||  payload?.item?.modal?.productTypeId ,
            categoryId: payload?.item?.categoryId ||  payload?.item?.modal?.categoryId,
            languageId: payload?.item?.languageId ||  payload?.item?.modal?.languageId,
            companies: payload?.item?.companies  || payload?.item?.modal?.companies
        }
        console.log('addDownloadStatistics data=', data)

        return request({
            url: `statistic`,
            method: 'POST',
            token: API_GLOBAL.token,
            data: data
        });
    };

    addShowProductStatistics = (payload: any) => {
        console.log('addShowProductStatistics payload=', payload)
        const data = {
            type: 'SPR',                // тип Show ForestCust
            clientId: payload?.user?.clientId,
            userId: payload?.user?.id,
            userName: payload?.user?.username,
            productId: payload?.item?.id,
            productName: payload?.item?.name,

            productTypeId: payload?.item?.productTypeId,
            categoryId: payload?.item?.categoryId,
            languageId: payload?.item?.languageId,
            companies: payload?.item?.companies
        }
        console.log('addShowProductStatistics data=', data)

        return request({
            url: `statistic`,
            method: 'POST',
            token: API_GLOBAL.token,
            data: data
        });
    };

    addAuthorisationStatistics = (payload: any) => {
    //    console.log('addAuthorisationStatistics payload=', payload)
        const data = {
            type: 'AUT',                // тип Show ForestCust
            userId: payload?.userId,
            clientId: payload?.clientId,
            productId: null,
        }
    //    console.log('addAuthorisationStatistics data=', data)

        return request({
            url: `statistic`,
            method: 'POST',
            token: API_GLOBAL.token,
            data: data
        });
    };

}

const API_AUTHOR = new ClassAPI();

export default API_AUTHOR;
