import {MAX_IMPORTANT_REPORTS_FOR_ANALYST_PAGE, MAX_IMPORTANT_REPORTS_FOR_COMMODITIES_PAGE} from "./news";

export const CATEGORIES: any = {
    EQUITYRESEARCH_TABLE: '5f1db81c25b61a3b5488def6',
    EQUITYRESEARCH_TABLE_HOME: '5f1db81c25b61a3b5488def6',
    MAX_IMPORTANT_REPORTS_FOR_EQUITY_RESEARCH_PAGE:'5f1db81c25b61a3b5488def6',

    FIXEDINCOME_TABLE: '5f1db9d625b61a3b5488defe',
    FIXEDINCOME_TABLE_HOME: '5f1db9d625b61a3b5488defe',
    MAX_IMPORTANT_REPORTS_FOR_FIXED_INCOME_PAGE: '5f1db9d625b61a3b5488defe',

    ECONOMICS_TABLE: '5f1db9b125b61a3b5488defb',
    ECONOMICS_TABLE_HOME: '5f1db9b125b61a3b5488defb',
    MAX_IMPORTANT_REPORTS_FOR_ECONOMICS_PAGE: '5f1db9b125b61a3b5488defb',

    COMMODITIES_TABLE: '5f1dbab925b61a3b5488df03',
    COMMODITIES_TABLE_HOME: '5f1dbab925b61a3b5488df03',
    MAX_IMPORTANT_REPORTS_FOR_COMMODITIES_PAGE:'5f1dbab925b61a3b5488df03',

    COMPANIES_TABLE: '5f1dc00325b61a3b5488df12',
    // MAX_IMPORTANT_REPORTS_FOR_COMPANIES_PAGE:'5f1dc00325b61a3b5488df12',

    //MAX_IMPORTANT_REPORTS_FOR_ANALYST_PAGE:'5f1dc15625b61a3b5488df17',


    FUTUREEVENTS_TABLE: '5f1dc1c925b61a3b5488df1d',
    // ANALYST_PROFILE_TABLE: '5eda0a6f396529280c953c0f'
};

export const ID: any = {
    '5f1db81c25b61a3b5488def6': 'EQUITYRESEARCH_TABLE',
    '5f1db9d625b61a3b5488defe': 'FIXEDINCOME_TABLE',
    '5f1db9b125b61a3b5488defb': 'ECONOMICS_TABLE',
    '5f1dbab925b61a3b5488df03': 'COMMODITIES_TABLE',
    '5f1dc00325b61a3b5488df12': 'COMPANIES_TABLE',
    '5f1dc1c925b61a3b5488df1d': 'FUTUREEVENTS_TABLE',
};

