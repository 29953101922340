import FixedIncome from './pages';


export default [
    {
        type: 'private',
        exact: true,
        path: '/fixed-income',
        roles: ['admin'],
        component: FixedIncome,
    },
];
