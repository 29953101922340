import * as ACTION_TYPE from './constants'

export const setUser = (payload: any) => ({
  type: ACTION_TYPE.SET_USER,
  payload,
})

export const setProfileId = (payload: any) => ({
  type: ACTION_TYPE.SET_PROFILEID,
  payload,
})
