export const EQUITYRESEARCH_TABLE = 'EQUITYRESEARCH_TABLE';
export const EQUITYRESEARCH_TABLE_HOME = 'EQUITYRESEARCH_TABLE_HOME';

export const ECONOMICS_TABLE = 'ECONOMICS_TABLE';
export const ECONOMICS_TABLE_HOME = 'ECONOMICS_TABLE_HOME';

export const FIXEDINCOME_TABLE = 'FIXEDINCOME_TABLE';
export const FIXEDINCOME_TABLE_HOME = 'FIXEDINCOME_TABLE_HOME';

export const COMMODITIES_TABLE = 'COMMODITIES_TABLE';
export const COMMODITIES_TABLE_HOME = 'COMMODITIES_TABLE_HOME';

export const COMPANIES_TABLE = 'COMPANIES_TABLE';
export const FUTUREEVENTS_TABLE = 'FUTUREEVENTS_TABLE';
export const PAST_TABLE = 'PAST_TABLE';
export const COMPANIES_REPORT_TABLE = 'COMPANIES_REPORT_TABLE';
export const COMPANIES_STOCK_RECOMMENDATION_TABLE =
  'COMPANIES_STOCK_RECOMMENDATION_TABLE';

export const ANALYST_PROFILE_TABLE = 'ANALYST_PROFILE_TABLE';
export const SEARCH_TABLE = 'SEARCH_TABLE';
export const PROFILE_TABLE = 'PROFILE_TABLE';

export const LAW_FILES_TABLE = 'LAW_FILE_TABLE';
export const STATIC_INFO_TABLE = 'FOOTER_INFO_TABLE';
export const STATIC_INFO_CURRENT_MARKET_PRICE_TABLE = 'STATIC_INFO_CURRENT_MARKET_PRICE_TABLE';
