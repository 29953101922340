// @TODO: remove ts-nocheck!!
// @ts-nocheck

import { fromJS } from 'immutable';
import { GET_SEARCH_DATA_REQUEST, SET_MODAL_DATA_REQUEST } from './constants';

const initialState = fromJS({
  data: null,
});

const equityResearchReducer = (
  state = initialState,
  { type, payload }: any,
) => {
  switch (type) {
    case GET_SEARCH_DATA_REQUEST: {
      return state;
    }
    case SET_MODAL_DATA_REQUEST: {
      return state.set('data', payload);
    }
    default:
      return state;
  }
};

export default equityResearchReducer;
