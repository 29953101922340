// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import ColTree from 'components/ColTree';
import { isNil, isArray } from 'lodash';
import GridCol from 'arui-feather/grid-col';
import FileManager from 'components/FileManager';
import cn from 'bem-cn';
import CompaniesProductNews from '../companies/productNews';
import { dowloadFile, getLanguageFromStore } from 'utils/formatters';
import { connect } from 'react-redux';
import { useResolution } from 'hooks/useResolution';
import { Heading } from 'arui-feather/heading';
import { FIELD_NAME } from 'constants/languagesFields';

const about = cn('tab-content');

const Files = ({
  data,
  id,
  dispatch,
}: {
  data: any;
  id: any;
  dispatch: any;
}) => {
  const dow = (file: any) => (event: any) => {
    event.stopPropagation();
    dowloadFile(file, dispatch);
  };

  const { isMobile } = useResolution();

  return (
    <GridRow justify="right" className="files-custom-component">
      <GridCol width={{ mobile: 12, tablet: 9, desktop: 9 }} align="top">
        <div className={about()}>
          {data &&
            isArray(data.files) &&
            data.files.map((value: any) => {
              const { id, name, size, type, url } = value;
              const re = /(?:\.([^.]+))?$/;
              const current = {
                id,
                name,
                size,
                // @ts-ignore
                type: re.exec(url)[1],
                url,
              };

              return (
                !isNil(url) && (
                  <div onClick={dow(url)}>
                    <FileManager file={current} />
                  </div>
                )
              );
            })}
        </div>
      </GridCol>
      <ColTree width={{ mobile: 12, tablet: 3, desktop: 3 }}>
        <div className={'no-margin-product custom-paddings'}>
          {isMobile && (
            <Heading size="xl">
              {FIELD_NAME['actual'][getLanguageFromStore()]}
            </Heading>
          )}
          <CompaniesProductNews id={id} />
        </div>
      </ColTree>
    </GridRow>
  );
};

export default connect()(Files);
