// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import EquityResearchTable from './table/equityResearchTable';
import EconomicsTable from './table/economicsTable';
import FixedIncomeTable from './table/fixedIncomeTable';
import CommoditiesTable from './table/commoditiesTable';

import CompanyChart from './chart/companyChart';
import SectorsChart from './chart/sectorsChart';

import EquityResearchProductNews from './productNews';
import EquityResearchEventsNews from './eventNews';
import ColTree from 'components/ColTree';
import { useResolution } from 'hooks/useResolution';
import { Heading } from 'arui-feather/heading';
import { FIELD_NAME } from 'constants/languagesFields';
import { getLanguageFromStore } from 'utils/formatters';

const EquityResearch = () => {
  const { isMobile } = useResolution();
  return (
    <div className="home-page">
      {isMobile && <EquityResearchEventsNews />}
      <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
        <ColTree width={{ mobile: 12, tablet: 9, desktop: 9 }}>
          <EquityResearchTable />
          <EconomicsTable />
          <FixedIncomeTable />
          <CommoditiesTable />
        </ColTree>
        <ColTree width={{ mobile: 12, tablet: 3, desktop: 3 }}>
          {isMobile ? (
            <div className="home-page__wrapper">
              <Heading size="xl">
                {FIELD_NAME['actual'][getLanguageFromStore()]}
              </Heading>
              <EquityResearchProductNews />
              <CompanyChart />
              <SectorsChart />
            </div>
          ) : (
            <>
              <CompanyChart />
              <SectorsChart />
              <EquityResearchEventsNews />
              <EquityResearchProductNews />
            </>
          )}
        </ColTree>
      </GridRow>
    </div>
  );
};

export default EquityResearch;
