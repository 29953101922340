import * as ACTION_TYPE from './constants';

export const getProductByLanguageAndCategoryRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_REQUEST,
    meta,
    payload
});

export const getEventsByLanguageRequest = ({ meta, payload }: any) => ({
    type: ACTION_TYPE.GET_EVENTS_BY_LANGUAGE_AND_CATEGORY_REQUEST,
    meta,
    payload
});
