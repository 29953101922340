// @ts-nocheck
import React from 'react';
import {Field} from 'redux-form'
import {connect} from 'react-redux';
import {compose} from 'redux';
import './style.scss';
import {setValueSet} from '../ValueSet/actions';
import {createStructuredSelector} from 'reselect';
import {selectValueSet, selectAllValueSets} from '../ValueSet/selector';
import cn, {setup} from "bem-cn";
import TreeView from "components/TreeView";
import {
    normalizeSectorCompany,
    normalizeProductType,
    normalizeAuthor,
    normalizeSector,
    normalizeCommodities,
    normalizeEventType,
    normalizeCompanyOne,
    normalizePeriodTypes,
    normalizeCountryOne
} from './normalize';

const accordionSetup = setup({
    mod: '--'
});
const accordion = accordionSetup('accordion');
const accordionSection = cn('accordion__section');

const TreeViewComponent = (props: any) => {
    const onChange = (value: any) => {
        const {input} = props;
        input.onChange(value);
    };
    return (
        <TreeView
            mode={props.mode}
            input={props.input}
            data={props.data}
            onChange={onChange}
            value={props.input.value}
        />
    )
};


class List extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            isExpanded: this.props.isExpanded
        };
    }

    handleExpandedChange() {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    }

    componentDidMount() {
        const {actions, dispatch}: any = this.props;
        if (actions) {
            Object.keys(actions).forEach((key) => {
                let cur = actions[key];
                if (cur.voidFunc) {
                    dispatch(actions[key].voidFunc({meta: {form: key, callback: setValueSet}, payload: {id: cur.id}}));
                } else {
                    dispatch(actions[key]({meta: {form: key, callback: setValueSet}}));
                }

            });
        }
    }

    normalizeFormat() {
        const {normalize, valueSetsAll} = this.props;
        switch (normalize) {
            case 'commoditiesOne': {
                return normalizeCommodities(valueSetsAll && valueSetsAll.commodities)
            }
                break;
            case 'sector-company': {
                return normalizeSectorCompany(valueSetsAll && valueSetsAll.sectors,
                    valueSetsAll && valueSetsAll.company)
            }
            case 'companyOne': {
                return normalizeCompanyOne(valueSetsAll && valueSetsAll.company)
            }
                break;

            case 'countryOne': {
                return normalizeCountryOne(valueSetsAll && valueSetsAll.country)
            }
                break;

            case 'periodTypes': {
                return normalizePeriodTypes(valueSetsAll && valueSetsAll.periodTypes)
            }
                break;
            case 'sectorOne': {
                return normalizeSector(valueSetsAll && valueSetsAll.sectors)
            }
                break;
                // case 'sector-categories': {
                //     return normalizeSectorCompany(valueSetsAll && valueSetsAll.sectors,
                //         valueSetsAll && valueSetsAll.categories)
                // }
                break;
            case 'product-type': {
                return normalizeProductType(valueSetsAll && valueSetsAll.productType)
            }
            break;
            case 'product-type-by-category': {
                return normalizeProductType(valueSetsAll && valueSetsAll.productTypeByCategory)
            }
            break;
            // case 'author-types': {
            //     return normalizeAuthorTypes(valueSetsAll && valueSetsAll.authorTypes)
            // }

            case 'author': {
                return normalizeAuthor(valueSetsAll && valueSetsAll.author)
            }
            break;

            case 'authorType': {
                return normalizeAuthor(valueSetsAll && valueSetsAll.authorType)
            }
                break;

            case 'eventType': {
                return normalizeEventType(valueSetsAll && valueSetsAll.eventType)
            }
                break;
            default:
                return [];
        }

    }

    render() {
        const {label, id, mode, data: propsData} = this.props;
        const {isExpanded} = this.state;
        const data = propsData || this.normalizeFormat();
        return (
            <>
                <div className={accordionSection()}>
                    <button className={accordion({mod: isExpanded ? 'is-open' : 'is-close'})}
                            onClick={this.handleExpandedChange.bind(this)}>
                    <span className={accordion('title')}>
                        {label}
                    </span>
                        <span className={isExpanded ? "accordion__icon" : "accordion__icon-close"}/>
                    </button>
                    <div className={accordion('content', {mod: this.state.isExpanded ? 'is-show' : 'is-hide'})}>
                        {data && data.length &&
                        <Field
                            name={id}
                            type="text"
                            data={data}
                            mode={mode}
                            component={TreeViewComponent}
                            view='filled'
                            width='available'
                            size='s'
                            label={''}
                        />
                        }
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    valueSet: selectValueSet(),
    valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(List);
