import * as ACTION_TYPE from './constants';

export const getDataSearchRequest = ({ meta, payload }: any) => ({
  type: ACTION_TYPE.GET_SEARCH_DATA_REQUEST,
  meta,
  payload,
});
export const getDataSearchRequest2 = ({ meta, payload }: any) => ({
  type: ACTION_TYPE.GET_SEARCH_DATA_REQUEST2,
  meta,
  payload,
});

export const getDataSearchSortRequest = ({ meta, payload }: any) => ({
  type: ACTION_TYPE.GET_SEARCH_DATA_SORT_REQUEST,
  meta,
  payload,
});


export const setModalValue = ({ meta, payload }: any) => ({
  type: ACTION_TYPE.SET_MODAL_DATA_REQUEST,
  meta,
  payload,
});
