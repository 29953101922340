// @ts-nocheck
import React from 'react';
import { isArray } from 'lodash';
import GraphNews from 'components/Graphs/News';
import { ICategoryTable } from 'components/Flows/Table/interface';
import { EVENTS_NEWS } from 'constants/news';
import { getAllEventTypesRequest } from 'modules/EventType/redux/actions';
import { getAllCountryRequest } from 'modules/Country/redux/actions';
import { getAllEventsRequest } from 'modules/Event/redux/actions';
import { productTypeRequest } from 'modules/ProductType/redux/actions';
import { getAllcategoriesRequest } from 'modules/Categories/redux/actions';
import { language } from 'utils/formatters';
import moment from 'moment';

class EquityResearchEventNews extends React.Component<ICategoryTable> {
  normalizeTableData = (data: any, valuesModal: any): any => {
    const { eventType, country, productType, categories } = valuesModal;
    // console.log('eventNews normalize valuesModal=', valuesModal)
    if (
      isArray(data) &&
      //            isArray(productsFilter) &&
      isArray(productType) &&
      isArray(categories) &&
      isArray(eventType) &&
      isArray(country)
    ) {
      return data.map((item: any) => {
        const countryId = country
          .filter((o1: any) => {
            return o1.id === item.countryId;
          })
          .map((lu: any) => {
            return language(lu.name, 'en');
          })
          .join('');

        const eventTypeId = eventType
          .filter((o1: any) => {
            return o1.id === item.eventTypeId;
          })
          .map((lu: any) => {
            return language(lu.name, 'en');
          })
          .join('');
        /*
                const fileFromProduct = productsFilter?.find((value) => value.id === item.productId);

                let strategy = productType?.filter((o1: any) => {
                    return o1.id === fileFromProduct?.productTypeId
                }).map((lu: any) => {
                    return language(lu.name, 'en')
                }).join('');

                let categoriesTitle = categories?.filter((o1: any) => {
                    return o1.id === fileFromProduct?.categoryId
                }).map((lu: any) => {
                    return language(lu.name, 'en')
                }).join('');
*/
        return {
          date: item.startDate,
          comment: language(item.comment, 'en'),
          id: item.id,
          name: language(item.name, 'en'),
          modal: {
            file: item.report,
            downloadAvailable: item.downloadAvailable,
            categoriesTitle: language(item.nameTypeProduct, 'en'),
            productName: item.nameProduct,
            date: item.loadDate
              ? moment(item.loadDate).format('DD.MM.YYYY')
              : '',

            country: countryId,
            eventType: eventTypeId,
            comment: language(item.comment, 'en'),
            name: language(item.name, 'en'),
            startDate: item.startDate,
            endDate: item.endDate,
          },
        };
      });
    }
  };

  render() {
    const actions = {
      eventType: getAllEventTypesRequest,
      country: getAllCountryRequest,
      productType: productTypeRequest,
      categories: getAllcategoriesRequest,
      //           productsFilter: getAllProductWithFilterRequest,
    };
    return (
      <GraphNews
        actions={actions}
        type={'events'}
        table={EVENTS_NEWS}
        normalizeTableData={this.normalizeTableData}
        init={getAllEventsRequest}
        width={'3'}
      />
    );
  }
}

export default EquityResearchEventNews;
