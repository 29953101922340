import { any } from 'prop-types';

export const throttle = (func, ms) => {

  let isThrottled = false, savedArgs, savedThis

  function wrapper() {

    if (isThrottled) {
      savedArgs = arguments
      savedThis = this
      return;
    }

    func.apply(this, arguments); // (1)

    isThrottled = true;

    setTimeout(function() {
      isThrottled = false; // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs);
        savedArgs = savedThis = null;
      }
    }, ms);
  }

  return wrapper;
}