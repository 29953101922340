import React from 'react';
import {getLanguageFromStore} from 'utils/formatters';

import Link from 'arui-feather/link';

import {dataMenu} from '../../temp_data';
import './Footer.scss';
import LawFile from '../../modules/LawFile/pages/index';
import {useResolution} from '../../hooks/useResolution';
import {useSelector} from 'react-redux';
import {STATIC_INFO_TABLE} from '../../constants/tables';

const Footer = (props) => {
    const {language} = props;
    const postDate = new Date(2001, 0, 1);
    const currentDate = new Date();
    const {isMobile} = useResolution();

    const graphTable = useSelector((state) => state.graphTable);
    const footerTable = graphTable.get(STATIC_INFO_TABLE);
    const footerDataArray = footerTable ? footerTable.get('data') : null;

    let footerData = ""
    if( footerDataArray && footerDataArray.length > 0 )
        footerData = footerDataArray.find(item => item.type == 'footer');


    const footerEn = footerData ? footerData.infoEn : '';
    const footerRu = footerData ? footerData.infoRu : '';

    const footer = (getLanguageFromStore() === 'en') ? footerEn: footerRu;

    return (
        <div className="footer">
            <div className="footer__content">
                <div className="footer__content-menu">
                    {dataMenu.map((menuItem, index) => {
                        return (
                            <Link
                                key={index}
                                text={language === 'rus' ? menuItem.label_ru : menuItem.label}
                                size="m"
                                url={menuItem.url}
                            />
                        );
                    })}
                </div>
                {!isMobile && <LawFile/>}
                <div className="footer__content-copyright">
                    <div
                        style={{overflowWrap: 'break-word'}}
                        dangerouslySetInnerHTML={{__html: footer}}
                    />
                </div>
            </div>
        </div>
    );
};

export default Footer;
