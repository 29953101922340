import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'

class ClassAPI {

    getPeriodTypes = () => request({
        url: 'catalog/period-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

}

const API_AUTHOR = new ClassAPI();

export default API_AUTHOR;
