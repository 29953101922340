import { fromJS } from 'immutable';
import {
    OPEN_DIALOG,
    CLOSE_DIALOG,
} from './constants';


const initialState = fromJS({});

const reducer = (state = initialState, { type, payload }) => {
    const id = payload && payload.id;

    switch (type) {
    case OPEN_DIALOG: {
        return state
            .setIn([id, 'open'], true);
    }

    case CLOSE_DIALOG: {
        return state
            .setIn([id, 'open'], false);
    }

    default:
        return state;
    }
};

export default reducer;
