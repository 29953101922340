import React from 'react';
import {getAllSectorsRequest} from 'modules/Sector/redux/actions'
import {productTypeRequest} from 'modules/ProductType/redux/actions'
import {getAllAnalystRequest} from 'modules/Author/redux/actions'
import {getAllCompanyRequest} from 'modules/Company/redux/actions'
import {FIELD_NAME} from "constants/languagesFields";
import {getLanguageFromStore} from "utils/formatters";

export default (query: any) => [
    // {
    //     type: "list",
    //     query: query
    // },
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "single",
        bigLabel: false,
        id: 'sectorCompany',
        label: FIELD_NAME['sectorCompany'][getLanguageFromStore()],
        normalize: 'sector-company',
        actions: {company: getAllCompanyRequest, sectors: getAllSectorsRequest},
    },
    {
        fieldWidth: '12',
        title: "Date",
        id: 'date',
        name: "date",
        label: FIELD_NAME['date'][getLanguageFromStore()],
        isExpanded: false,
        type: "calendar",
        bigLabel: false,
        dateFrom: '01.01.2020',
        dateTo: '02.02.2020',
        language: 'rus'
    },
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "many",
        bigLabel: false,
        id: 'productType',
        label: FIELD_NAME['productType'][getLanguageFromStore()],
        normalize: 'product-type',
        actions: {
            //product: productTypeRequest
        },
    },
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "many",
        bigLabel: false,
        id: 'authorType',
        label: FIELD_NAME['author'][getLanguageFromStore()],
        normalize: 'authorType',
        actions: {authorType: getAllAnalystRequest},
    },
];
