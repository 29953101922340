import React from 'react';
import { withRouter, NavLink, Link as LinkRouter } from 'react-router-dom';
import SearchBar from 'modules/Search/components/search';
import AdminHeader from 'components/AdminHeader/AdminHeader';
import './Menu.scss';
import { dataMenu } from '../../temp_data';
import Link from 'arui-feather/link';
import { IconBurger } from '../Icon/Icons';
import { slide as SlideMenu } from 'react-burger-menu';
import { SiteLogo } from '../SiteLogo/SiteLogo';

const LangBar = ({ toggle, lang }) => {
  return (
    <div className="lang-bar custom-link">
      <Link onClick={toggle} text={lang === 'ru' ? 'En' : 'Рус'} />
    </div>
  );
};

const MenuWrapper = ({ menuItems, language, isMobile = false }) => {
  return (
    <ul className="menu-wrapper__menu">
      {menuItems.map((item) => (
        <li key={item.id}>
          <span className="menu__link">
            <NavLink to={item.url} activeClassName="menu__link--active">
              {language === 'ru' ? item.label_ru : item.label}
            </NavLink>
          </span>
        </li>
      ))}
      {isMobile && (
        <li key="information-link-key">
          <span className="menu__link">
            <NavLink to="/information" activeClassName="menu__link--active">
              {language === 'ru' ? 'Информация' : 'Information'}
            </NavLink>
          </span>
        </li>
      )}
    </ul>
  );
};

class Menu extends React.Component {
  static defaultProps = {
    menuItems: dataMenu,
    language: 'en',
  };

  constructor(props) {
    super(props);
    this.state = {
      openSearch: false,
    };
  }

  searchBarHandler() {
    this.setState({
      openSearch: true,
    });
  }

  render() {
    const { language, toggle, menuItems, isMobile, userName } = this.props;
    return (
      <div className="menu-wrapper">
        {isMobile && (
          <SlideMenu width={'90%'} customBurgerIcon={<IconBurger size="s" />}>
            <div className="mobile-menu-header">
              <div className="header__user">
                <LinkRouter to="/profile">
                  <h4>{userName}</h4>
                </LinkRouter>
              </div>
              <SiteLogo />
            </div>
            <MenuWrapper
              menuItems={menuItems}
              language={language}
              isMobile={isMobile}
            />
          </SlideMenu>
        )}
        <MenuWrapper menuItems={menuItems} language={language} />
        <div className="menu-wrapper__search-bar">
          <SearchBar isOpen={this.state.openSearch} />
        </div>
        <LangBar toggle={toggle} lang={language} />
        {isMobile && <AdminHeader />}
      </div>
    );
  }
}

export default withRouter(Menu);
