import {
    NEWS_DATA_REQUEST,
    NEWS_DATA_SUCCESS,
} from './constants';
import {IAction} from 'types/index'

export const getRequest = ({payload,meta}: IAction<void>) => {
    return ({
        type: NEWS_DATA_REQUEST,
        payload,
        meta,
    });
}

export const getSuccess = ({payload, meta}: IAction<void>) => ({
    type: NEWS_DATA_SUCCESS,
    payload,
    meta,
});