import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';

class ClassAPI {
  getAllLawFile = () =>
    request({
      url: 'lawfile',
      method: 'GET',
      token: API_GLOBAL.token,
    });
}

const API_LAWFILE = new ClassAPI();

export default API_LAWFILE;
