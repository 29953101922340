interface lan {
  [key: string]: string;
}

interface tableName {
  [key: string]: lan;
}

export const TABLE_NAME: tableName = {
  EQUITYRESEARCH_TABLE: {
    ru: 'Рынок акций',
    en: 'Equity',
  },
  EQUITYRESEARCH_TABLE_ALL: {
    ru: 'Все рынки акций',
    en: 'All Equity',
  },
  ECONOMICS_TABLE: {
    ru: 'Экономика',
    en: 'Economics',
  },
  ECONOMICS_TABLE_ALL: {
    ru: 'Вся Экономика',
    en: 'All Economics',
  },
  FIXEDINCOME_TABLE: {
    ru: 'Облигации',
    en: 'Fixed Income',
  },
  FIXEDINCOME_TABLE_ALL: {
    ru: 'Все Облигации',
    en: 'All Fixed Income',
  },
  COMMODITIES_TABLE: {
    ru: 'Сырье',
    en: 'Commodities',
  },
  COMMODITIES_TABLE_ALL: {
    ru: 'Все Сырье',
    en: 'All Commodities',
  },
  COMPANIES_TABLE: {
    ru: 'Компании',
    en: 'Companies',
  },
  FUTUREEVENTS_TABLE: {
    ru: 'Будущие события',
    en: 'Future events',
  },
  PAST_TABLE: {
    ru: 'Прошедшие события',
    en: 'Past events',
  },
  ANALYST_PROFILE_TABLE: {
    ru: 'Все отчеты',
    en: 'All Reports',
  },
  SEARCH_TABLE: {
    ru: 'Поиск',
    en: 'Search',
  },
};

export const CLIENT: any = {
  name: {
    ru: 'Имя пользователя',
    en: 'Username',
  },
  password: {
    ru: 'Изменение пароля',
    en: 'Change password',
  },
  passwordPlaceholder: {
    ru: 'Новый пароль',
    en: 'New password',
  },
  confirmPassword: {
    ru: 'Повтор изменения пароля',
    en: 'Confirm change password',
  },
  confirmPasswordPlaceholder: {
    ru: 'Повтор нового пароля',
    en: 'Confirm new password',
  },
};

export const PROFILE: any = {
  step: {
    ru: 'Шаг',
    en: 'Step',
  },
  defaultLanguage: {
    ru: 'Язык сайта по умолчанию:',
    en: 'Default site language:',
  },
  settingMail: {
    ru: 'Настройка подписок для E-mail:',
    en: 'Configuring subscriptions for E-mail:',
  },
  chooseLanguage: {
    ru: 'Выберите язык, на котором хотите получать материалы в рассылке',
    en: 'Select the language in which you want to receive materials in the mailing list',
  },
  chooseCategory: {
    ru: 'Выберите категорию и тип продукта в категории',
    en: 'Select a category and product type in the category',
  },
  selectSector: {
    ru: 'Выберите сектор',
    en: 'Select a sector',
  },
  chooseAttachFile: {
    ru: 'Файл продукта',
    en: 'A product file',
  },
  attachFile: {
    ru: 'Прикреплять к письму',
    en: 'Attach to an E-mail',
  },
};

export const TABLE_HEAD: tableName = {
  recommendation: {
    ru: 'Рекомендации',
    en: 'Recommendation',
  },
  equityTickerId: {
    ru: 'Акция',
    en: 'Equity Ticker',
  },
  targetPrice: {
    ru: 'Целевая цена',
    en: 'Target price',
  },
  company: {
    ru: 'Компания',
    en: 'Company',
  },
  country: {
    ru: 'Страны',
    en: 'Country',
  },
  companyOrCountry: {
    ru: 'Компания/Страна',
    en: 'Company/Country',
  },
  report: {
    ru: 'Отчеты',
    en: 'Report',
  },
  reports: {
    ru: 'Отчеты',
    en: 'Reports',
  },
  name: {
    ru: 'Наименование',
    en: 'Name',
  },
  eventName: {
    ru: 'Имя события',
    en: 'Event Name',
  },
  productType: {
    ru: 'Тип продукта',
    en: 'ForestCust Type',
  },
  eventType: {
    ru: 'Тип события',
    en: 'Event Type',
  },
  analyst: {
    ru: 'Аналитики',
    en: 'Analysts',
  },
  date: {
    ru: 'Дата',
    en: 'Date',
  },
  sector: {
    ru: 'Сектор',
    en: 'Sector',
  },
  FUTUREEVENTS_TABLE: {
    ru: 'Календарь',
    en: 'Future events',
  },
  ANALYST_PROFILE_TABLE: {
    ru: 'Все отчеты',
    en: 'All Reports',
  },
  SEARCH_TABLE: {
    ru: 'Поиск',
    en: 'Search',
  },
};

export const LOGIN_INFO: any = {
  descrption: {
    ru: 'Если у вас возникли сложности со входом или есть вопросы по работе портала, обращайтесь ',
    en: 'If you have any problems with authorization or have questions about working with the portal, reach out for support with ',
  },
  remember: {
    ru: 'Запомнить меня на этом компьютере',
    en: 'Remember me on this device',
  },
  logIn: {
    ru: 'Пожалуйста, авторизуйтесь',
    en: 'Please log in',
  },
  signIn: {
    ru: 'Войти',
    en: 'Sign in',
  },
  restore: {
    ru: 'Забыли пароль?',
    en: 'Restore password?',
  },
  Input: {
    login: {
      ru: 'Логин',
      en: 'Login',
    },
    placeholderLogin: {
      ru: 'Ваш логин',
      en: 'Your login here',
    },
    password: {
      ru: 'Пароль',
      en: 'Password',
    },
    placeholderPassword: {
      ru: 'Ваш пароль',
      en: 'Your password here',
    },
  },
};
