// @ts-nocheck
import React from 'react';
import { isArray, includes } from 'lodash';
import GraphNews from 'components/Graphs/News';
import {
  ICategoryTable,
  IHeadTable,
  IBodyTable,
} from 'components/Flows/Table/interface';
import { MAX_IMPORTANT_REPORTS_FOR_ANALYST_PAGE } from 'constants/news';
import { getAllcategoriesRequest } from 'modules/Categories/redux/actions';
import {
  getAllProductForNewByAuthor,
  getAllProductForNews,
  getAllProductRequest,
} from 'modules/Product/redux/actions';
import { getAllSectorsRequest } from 'modules/Sector/redux/actions';
import { productTypeRequest } from 'modules/ProductType/redux/actions';
import { getAllAuthorRequest } from 'modules/Author/redux/actions';
import { language } from 'utils/formatters';
import { CATEGORIES } from '../../../../constants/categories';
import { EQUITYRESEARCH_TABLE } from '../../../../constants/tables';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getAllCompanyRequest } from '../../../Company/redux/actions';
import { makeToShorter } from '../../../../utils/makeShorterAnnotation';

class EquityResearchProductNews extends React.Component<ICategoryTable> {
  normalizeTableData = (data: any, valuesModal: any): any => {
    const { sectors, author, categories, company, productType, product } =
      valuesModal;
    if (
      isArray(data) &&
      isArray(sectors) &&
      isArray(author) &&
      // isArray(productType) &&
      (isArray(productType) || isArray(product)) &&
      isArray(company) &&
      isArray(categories)
    ) {
      // @ts-ignore
      // let companyList = company.filter((v: any) => {
      //     return includes(v.authors, id)
      // }).map((y: any) => {
      //     let j = y.id;
      //     return j;
      // });
      //
      // let arr = data.filter((vau: any) => {
      //     return isArray(companyList) &&
      //         companyList.some((r: any) => isArray(vau.companies) &&
      //             vau.companies.includes(r));
      // }).sort((a, b): any => {
      //     return a.date - b.date || a.mark - b.mark
      // });

      return data.map((firstLevel: any) => {
        const sector = sectors
          .filter((o1: any) => {
            return (
              isArray(firstLevel.sectors) &&
              firstLevel.sectors.some((o2: any) => {
                return o1.id === o2;
              })
            );
          })
          .map((lu: any) => {
            return {
              name: language(lu.name, 'en'),
              id: lu.id,
            };
          });

        const au = author
          .filter((o1: any) => {
            return (
              isArray(firstLevel.authors) &&
              firstLevel.authors.some((o2: any) => {
                return o1.id === o2;
              })
            );
          })
          .map((y: any) => {
            return {
              name: language(y.name, 'en'),
              id: y.id,
              photo: y.photo,
            };
          });

        /*
                let strategy = productType.filter((o1: any) => {
                    return o1.id === firstLevel.productTypeId
                }).map((lu: any) => {
                    return language(lu.name, 'en')
                }).join('');
*/
        let prodType;
        if (productType) {
          prodType = productType;
        } else {
          prodType = product;
        }
        const strategy = prodType
          .filter((o1: any) => {
            return o1.id === firstLevel.productTypeId;
          })
          .map((lu: any) => {
            return language(lu.name, 'en');
          })
          .join('');

        const categoriesTitle = categories
          .filter((o1: any) => {
            return o1.id === firstLevel.categoryId;
          })
          .map((lu: any) => {
            return language(lu.name, 'en');
          })
          .join('');

        return {
          annotation: makeToShorter(firstLevel.annotation),
          id: firstLevel.id,
          name: firstLevel.name,
          date: firstLevel.date,
          category: firstLevel.categoryId,
          strategy,
          modal: {
            sectors: sector,
            authors: au,
            name: firstLevel.name,
            annotation: firstLevel.annotation,
            file: firstLevel.file,
            downloadAvailable: firstLevel?.downloadAvailable,
            date: firstLevel.date,
            id: firstLevel.id,
            strategy,
            categoriesTitle,
          },
        };
      });
    }
  };

  render() {
    // @ts-ignore
    const { match: { params: { id } } } = this.props;
    const actions = {
      author: getAllAuthorRequest,
      sectors: getAllSectorsRequest,
      productType: productTypeRequest,
      categories: getAllcategoriesRequest,
      company: getAllCompanyRequest,
    };

    return (
      <GraphNews
        graphId={id}
        customClass={'filter-sidebar__item'}
        actions={actions}
        type={'products'}
        table={MAX_IMPORTANT_REPORTS_FOR_ANALYST_PAGE}
        normalizeTableData={this.normalizeTableData}
        init={getAllProductForNewByAuthor}
      />
    );
  }
}

export default compose<any>(connect(), withRouter)(EquityResearchProductNews);
