import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';
import queryString from 'query-string';
import { CATEGORIES } from 'constants/categories';
import { getLanguageFromStore } from 'utils/formatters';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages';
import { getGlobalCompanyId } from '../../../globalVariables';

class ClassAPI {
  getProductByLanguageAndCategoryId = (data: any, table: string) => {
    const {} = this;
    if (table) {
      const req = {
        interfaceSettingName: table,
        page: 1,
        categoryId: CATEGORIES[table],
      };
      const sent = Object.assign(req, data);
      const q = queryString.stringify(sent);
      return request({
        url: `product/read-by-filters?${q}`,
        method: 'GET',
        token: API_GLOBAL.token,
      });
    }
  };

  getCompanyMassFilterId = (data: any, table: string) => {
    const {} = this;
    if (table) {
      const req = {
        interfaceSettingName: table,
        languageId: getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
        page: 1,
        categoryId: CATEGORIES[table],
      };
      const sent = Object.assign(data, req);
      const q = queryString.stringify(sent);
      return request({
        url: `company/read-by-filters?${q}`,
        method: 'GET',
        token: API_GLOBAL.token,
      });
    }
  };

  getProductByLanguageId = (table: string, data: any) => {
//    console.log('getProductByLanguageId', table, data);

    const {} = this;
    const companyId = getGlobalCompanyId();
    const req = companyId
      ? {
          languageId:
            getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
          page: 1,
          interfaceSettingName: table,
          companyId: getGlobalCompanyId(),
        }
      : {
          languageId:
            getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
          page: 1,
          interfaceSettingName: table
        };

    const sent = Object.assign(req, data);
    console.log('API getProductByLanguageId data/req/sent', data, req, sent);
    const q = queryString.stringify(sent);
    console.log('API getProductByLanguageId par=', q);
    return request({
      url: `product/read-by-filters?${q}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  getEventsByLanguage = (data: any, table: string) => {
    const {} = this;
    const req = {
      interfaceSettingName: table,
      languageId: getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
      page: 1,
    };
    const sent = Object.assign(req, data);
    const q = queryString.stringify(sent);
    return request({
      url: `event/read-by-filters?${q}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };
}

const API_CATEGORY = new ClassAPI();

export default API_CATEGORY;
