// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import ColTree from 'components/ColTree';
import CompaniesTable from 'modules/Companies/pages/targetPrices/table/index';
import CompaniesFilter from 'modules/Companies/pages/targetPrices/filter/index';
import CompaniesProductNews from '../companies/productNews';
import CompaniesEventsNews from '../companies/eventNews';
import { useResolution } from 'hooks/useResolution';
import { FIELD_NAME } from 'constants/languagesFields';
import { getLanguageFromStore } from 'utils/formatters';
import { Heading } from 'arui-feather/heading';

const TargetPrices = ({ data, id }: { data: any; id: any }) => {
  const { isMobile } = useResolution();

  if (!data) {
    return null;
  }
  return (
    <GridRow
      className="report-mar target-price-column"
      gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
    >
      <CompaniesFilter companyId={data && data.companyId} />
      <CompaniesTable companyId={data && data.companyId} />
      <ColTree width={{ mobile: 12, tablet: 3, desktop: 3 }}>
        <div className="no-margin-product">
          {isMobile && (
            <Heading size="xl">
              {FIELD_NAME['actual'][getLanguageFromStore()]}
            </Heading>
          )}
          <CompaniesProductNews id={id} />
        </div>
        {!isMobile && <CompaniesEventsNews />}
      </ColTree>
    </GridRow>
  );
};

export default TargetPrices;
