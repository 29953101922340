import React, { Component } from 'react';
import Select from 'arui-feather/select';
import style from 'components/Flows/Form/form.module.scss';

// const FormItem = Form.Item;
// const Option = Select.Option;

class SelectMultiple extends Component {
  onChange = (value: any) => {
    const { input, multiple }: any = this.props;
    input.onChange(multiple ? value.map((itemValue: any) => itemValue) : value);
  };

  render() {
    const {
      input,
      id,
      label,
      data = [],
      required,
      disabled,
      hasFeedback,
      loading,
      itemOptions,
      multiple,
      meta: { touched, invalid, error },
      ...rest
    }: any = this.props;

    const hasError = touched && invalid;
    const value = input.value
      ? typeof input.value.toJS === 'function'
        ? input.value.toJS()
        : input.value
      : [];

    let options = [];
    if (itemOptions) {
        data && data.forEach((item: any) => {
        let option = itemOptions(item)
          if (option) options.push(option)
      });
    } else {
      options = data && data.map((value: any) => (
        { value, text: value }
      ));
    }

    return (
      <Select
        mode={multiple ? 'check' : 'radio'}
        width='available'
        view='filled'
        value={value}
        onChange={this.onChange}
        options={options}
        {...rest}
      />
    );
  }
}

export const InputSelect = SelectMultiple;
