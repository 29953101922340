// @ts-nocheck
import React from 'react';
import {sortBy} from 'lodash'
import GraphChart from 'components/Graphs/Chart';
import {ICategoryTable, IHeadTable, IBodyTable} from 'components/Flows/Table/interface';
import {CHART_SECTORS} from 'constants/news';
import './Chart.scss'
import {
    getAllCompanyWidgetsChartsRequest,
    getAllSectorWidgetsChartsRequest
} from '../../redux/actions';
import {CHART_NAME} from 'constants/languagesFields';
import {getLanguageFromStore} from 'utils/formatters'


class SectorsChart extends React.Component<ICategoryTable> {

    normalizeTableData = (valuesModal: any): any => {
        const sectorChart = valuesModal && valuesModal.sectorChart;
        if (sectorChart && sectorChart.length) {
            sectorChart.push({
                sectorName: "",
                sectorVal: -0.00001
            });
            const arr = sortBy( sectorChart, 'sectorVal').reverse();

            const chartsData = {
                title: CHART_NAME['sectorChart']['title'][getLanguageFromStore()],
                legend: CHART_NAME['sectorChart']['legend'][getLanguageFromStore()],
                    content: arr.map((item: any) => {

                        const position = item.sectorVal >= 0 ? 'plus' : 'minus';
                        // minus: -0.036539335177787
                        // mr: "Telecommunication"
                        // nameminus: "Telecommunication"
                        return {
                            mr: item.sectorName,
                            [position]: item.sectorVal,
                            [`name${position}`]: item.sectorName
                        }
                    })
            };

            // chartsData.content.push({
            //     hide:0,
            //     mr:'',
            //     namehide:'',
            // });

            return chartsData
        }
    };
    render() {
        const actions = {
            sectorChart: getAllSectorWidgetsChartsRequest,
        };
        return (
            <GraphChart
                customClass={'sector-bottom'}
                actions={actions}
                type={'products'}
                table={CHART_SECTORS}
                normalizeTableData={this.normalizeTableData}
            />
        );
    }
}

export default SectorsChart;
