export const AUTH_FORM = 'AUTH_FORM';


export const EQUITYRESEARCH_FILTER = 'EQUITYRESEARCH_FILTER';
export const ECONOMICS_FILTER = 'ECONOMICS_FILTER';
export const FIXEDINCOME_FILTER = 'FIXEDINCOME_FILTER';
export const COMMODITIES_FILTER = 'COMMODITIES_FILTER';
export const COMPANIES_FILTER = 'COMPANIES_FILTER';
export const COMPANIES_REPORT_FILTER = 'COMPANIES_REPORT_FILTER'
export const COMPANIES_STOCK_RECOMMENDATION_FILTER = 'COMPANIES_STOCK_RECOMMENDATION_FILTER'
export const FUTUREEVENTS_FILTER = 'FUTUREEVENTS_FILTER';

export const TEAM = 'TEAM';

export const ANALYST_PROFILE_FILTER = 'ANALYST_PROFILE_FILTER';
export const SEARCH_FILTER = 'SEARCH_FILTER';
export const PROFILE_FILTER = 'PROFILE_FILTER';

