// @ts-nocheck
import React from 'react';
import {withRouter} from 'react-router-dom';
import Spin from 'arui-feather/spin';
import {compose} from 'redux';
import GridCol from 'arui-feather/grid-col';
import Research from 'components/ModalContent/reasearch';
import {IHeadTable} from 'components/Graphs/Table/interface';
import './Table.scss';
import ModalTable from 'components/ModalTable';
import {EventInfo} from '../../ModalContent';
import {dowloadFile, dowloadFileExtended, getLanguageFromStore,} from 'utils/formatters';
import {isArray, isEmpty} from 'lodash';
import {FIELD_NAME, NOT_FIND} from '../../../constants/languagesFields';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {interfaceSettings} from '../../../modules/IntefaceSettings/redux/selector';
import {loginFailure} from '../../../modules/Auth/redux/actions';
import {ShowMoreAction} from './components/showMoreAction';
import {useResolution} from 'hooks/useResolution';
import {makeSelectLoading} from "./selector";

interface ITableBody {
    head: any;
    body: any;
    normalizeTableData: any;
    valuesTable: any;
    clickCellHandler: any;
}

interface ITableHeadProps {
    sortColumn: any;
    currentSort: any;
    directionSort: any;
    head: IHeadTable[];
}

const TableBody = ({
                       head,
                       body,
                       normalizeTableData,
                       valuesTable,
                       clickCellHandler,
                   }: ITableBody) => {
    const data = normalizeTableData(head, body, valuesTable);
    const {isMobile} = useResolution();

    if (!data) {
        return null;
    }

    return data.map((val: any, p: string | number | undefined) => {
        return (
            <div id={val.id} key={p} className="table__body">
                {val.cells.map((it: any, y: number) => {
                    return (
                        <div
                            key={y}
                            onClick={() => clickCellHandler(head[y], val)}
                            className={`table__cell table__cell_size_${it && it.width} ${
                                it.type
                            } ${it.element?.props?.className}`}
                        >
                            <div className="cell-wrapper">
                                <span className="cell-wrapper-title">{head[y].label}</span>
                                <span className="cell-wrapper-body">{it && it.element}</span>
                                {it.type === 'pdf' &&
                                    isMobile &&
                                    FIELD_NAME['download'][getLanguageFromStore()]}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    });
};

const TableHead = ({
                       sortColumn,
                       currentSort,
                       directionSort,
                       head,
                   }: ITableHeadProps) => {
    const {isMobile} = useResolution();
    return (
        <div className="table__head">
            {head.map((item: any, index) => {
                return isMobile && item.id === 'analyst' ? null : (
                    <div
                        key={index}
                        onClick={
                            item.sort && item.sort === 'none'
                                ? () => {
                                }
                                : () => sortColumn(item.id)
                        }
                        className={`table__cell table__cell_size_${
                            item.style && item.style.width
                        } table__cell_header ${currentSort === item.id ? 'active' : ''}`}
                    >
                        {item.label}
                        {item.id !== 'analyst' && item.id !== 'author' && (
                            <span
                                className={`sort-icon sort-icon_sort_${
                                    directionSort || 'desc'
                                } ${currentSort === item.id ? 'active' : ''}`}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

class FlowTable extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            isShowing: false,
            modal: null,
            loading: true,
            buttonMore: true,
        };
    }

    componentDidUpdate() {
        const {tablePage, body, table, tableSettings} = this.props;
        const page = tablePage;
        const size =
            tableSettings && tableSettings[table] ? tableSettings[table] : 10;
        const bodyLength = body?.length;
        const remander = (bodyLength % size) * page;

        if (this.state.buttonMore) {
            if (size * page > bodyLength && remander !== 0) {
                this.setState({
                    buttonMore: false,
                });
            }
        } else if (size * page >= bodyLength && remander === 0) {
            this.setState({
                buttonMore: true,
            });
        }
    }

    modalHandler = (modal: any) => {
        if (modal.type === 'event') {
            if (!isEmpty(modal.comment)) {
                this.setState({
                    isShowing: !this.state.isShowing,
                    modal: modal ? modal : null,
                });
            }
        } else {
            this.setState({
                isShowing: !this.state.isShowing,
                modal: modal ? modal : null,
            });
        }
    };

    modalClose = () => {
        this.setState({isShowing: false, modal: null});
    };

    // 2021-08-04 Добавлен режим откллючения сортировки по колонке при установке в head колонки sort='none'

    handleRowClick = (id: any, row: any) => () => {
        const {exceptionLink} = this.props;

        if (!exceptionLink) {
            this.modalHandler(row && row.modal);
        } else {
            const {history}: any = this.props;
            history.push(`${exceptionLink}${id}`);
        }
    };

    redirectLink = (id: any) => {
        const {exceptionLink} = this.props;
        const {history}: any = this.props;
        history.push(`${exceptionLink}${id}`);
    };

    handleDelete = (id: any) => () => {
        const {onDelete} = this.props;
        onDelete(id);
    };

    handleEditRow = (id: any, val: any) => () => {
        const {onEdit} = this.props;
        onEdit(id, val);
    };

    dowloadFile = (url: any, dispatch: any, downloadAvailable: boolean) => {
        downloadAvailable && dowloadFile(url, dispatch);
    };

    // 2021-10-13 пробрасываем вниз item, на уровне api нужен код продукта
    // для сохранения статистики
    dowloadFileExtended = (
        val: any,
        url: any,
        dispatch: any,
        downloadAvailable: boolean,
    ) => {
        downloadAvailable && dowloadFileExtended(val, url, dispatch);
    };

    classic = () => {
    };

    clickCellHandler = (head: any, val: any) => {
        const {dispatch} = this.props;
        switch (head?.type) {
            case 'link':
                return this.redirectLink(val.id);
            case 'modal':
                return this.modalHandler(val && val.modal);
            case 'file': {
                if (val?.downloadAvailable === false) {
                    // 2021-09-14  add modal message
                    const customMessage = {
                        en: 'Insufficient permissions to download the file',
                        ru: 'Недостаточно прав для загрузки файла',
                    };
                    const error = 'Insufficient permissions';
                    dispatch(loginFailure({error}, customMessage));
                }
                // 2021-10-13
                // return this.dowloadFile(val?.file, dispatch, val?.downloadAvailable);
                console.log('clickCellHandler val=', val)
                return this.dowloadFileExtended(
                    val,
                    val?.file,
                    dispatch,
                    val?.downloadAvailable,
                );
            }
            default:
                return this.classic();
        }
    };

    // через 10 секунд спинер превращается в запись "Нет данных" если данные не будут получены к этому времени
    // тем не менее если данные придут позже они ноомално отобразятся
    // первоначальное значение ожидания было  3 секунды
    // spinRender = () => {
    //     if (this.state.loading) {
    //         setTimeout(() => this.setState({loading: false}), 10000);
    //     }
    // };

    clickPag = () => {
        const {Paginate, tablePage, body, table, tableSettings} = this.props;
        Paginate();
        const page = tablePage;
        const size =
            tableSettings && tableSettings[table] ? tableSettings[table] : 10;
        const bodyLength = body?.length;
        // eslint-disable-next-line no-bitwise
        const remander = (bodyLength % size) * page;

        if (size * page >= bodyLength && remander === 0) {
            this.setState({
                buttonMore: true,
            });
        } else {
            this.setState({
                buttonMore: false,
            });
        }
    };

    render() {
        const {
            head,
            width,
            dispatch,
            caption,
            sizeTitle,
            customTableClass,
            pagination,
            normalizeTableData,
            body,
            valuesTable,
            table,
            tableSettings,
            sortColumn,
            currentSort,
            directionSort,

            // 2022-02-11
            history,
        } = this.props;
//        console.log('FLOW loading=', this.props.loading)

//        const {isShowing, modal, loading, buttonMore} = this.state;
        const {isShowing, modal, buttonMore} = this.state
        const loading = this.props.loading

        const data = normalizeTableData(head, body, valuesTable);
        const header = head === undefined ? [] : head;
        const pageSize =
            tableSettings && tableSettings[table] ? tableSettings[table] : 10;

        // this.spinRender();
        return (
            <GridCol
                width={width}
                className={`grid-col_gutter-desktop-m_24 table ${
                    customTableClass ? customTableClass : ''
                }`}
            >
                {caption && (
                    <div
                        className={
                            sizeTitle === 'small'
                                ? 'table__caption table__caption_size_default'
                                : 'table__caption table__caption_size_large'
                        }
                    >
                        {caption}
                    </div>
                )}
                <TableHead
                    head={header}
                    sortColumn={sortColumn}
                    currentSort={currentSort}
                    directionSort={directionSort}
                />
                <div className="table__wrapper">
                    <TableBody
                        head={head}
                        body={body}
                        normalizeTableData={normalizeTableData}
                        valuesTable={valuesTable}
                        clickCellHandler={this.clickCellHandler}
                    />
                </div>
                {
                    <ModalTable isShowing={isShowing} onClose={this.modalClose}>
                        {modal && modal.type === 'event' ? (
                            <EventInfo dispatch={dispatch} modal={modal}/>
                        ) : (
                            <Research dispatch={dispatch} history={history} modal={modal}/>
                        )}
                    </ModalTable>
                }

                {isEmpty(data) && (
                    <div className={'empty-data2'}>
                        {loading ? (
                            <Spin size={'xl'} visible={true}/>
                        ) : (
                            NOT_FIND[getLanguageFromStore()]
                        )}
                    </div>
                )}
                {pagination &&
                    isArray(data) &&
                    data.length >= pageSize &&
                    !isEmpty(data) &&
                    buttonMore && <ShowMoreAction clickPag={this.clickPag}/>}
            </GridCol>
        );
    }
}

/*
export default compose<any>(
    withRouter,
)(FlowTable);
*/

/*  2021-04-03 Добавлены настройки таблицы c числом строк на страницу */
const mapStateToProps = createStructuredSelector({
    tableSettings: interfaceSettings(),
    loading: makeSelectLoading(),
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withRouter, withConnect)(FlowTable);
