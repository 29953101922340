import React from 'react';
import {isArray, isEmpty, chain, groupBy, flattenDeep} from 'lodash';
import {language} from 'utils/formatters'
import cn from 'bem-cn';
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {createStructuredSelector} from "reselect";
import {selectAllValueSets, selectValueSet} from "components/ValueSet/selector";
import {setValueSet} from "components/ValueSet/actions";
import {getAllCompanyWidgetsChartsRequest, getAllSectorWidgetsChartsRequest} from "modules/Home/redux/actions";
import {
    getAllEquityTickersRequest,
    getCatalogCurrencyIdRequest,
    getCompanyByAuthorIdRequest, getCompanyStockIdRequest,
    getEquityTickerByCompanyIdRequest, getStockRecommendationTickerIdRequest,
    getAllStockRequest, getAllCurrencyRequest,
    getAllStockRecommendationRequest,
    getAllCompanyRequest, getAllCompanyDupRequest
} from 'modules/Company/redux/actions'
import {getAllSectorsRequest} from 'modules/Sector/redux/actions'
import CoverageList from 'components/CoverageList/CoverageList'
import ValueSetAsync from 'components/ValueSetAsync'

const analyst = cn('analyst-profile');

class Coverage extends React.Component<any> {

    stateMarker = (currentState: string) =>
        currentState ? `state-${currentState}` : `state-GREY`;

    normalize = () => {
        const {valueSetsAll, dispatch} = this.props;
        const {
            analystId,
            sectors,
            equityTickers,
            stock,
            currency,
            recommendation,
            company
        } = valueSetsAll;

        if (isArray(analystId) &&
            isArray(recommendation) &&
            isArray(sectors) &&
            isArray(currency) &&
            isArray(equityTickers) &&
            isArray(company) &&
            isArray(stock)) {
            let data = analystId.map((item: any, y: number, arr: any) => {
                let sector = sectors.find((u: any) => {
                    return u.id === item.sectorId
                });

                let companyId = item.id;
                let stockRecommendation = equityTickers.filter((lab: any) => {
                    return lab.companyId === companyId;
                });

                let stockRec = stockRecommendation.map((h: any) => {

                    let tickerName = equityTickers
                        .find((v: any) => v.id === h.id);

                    let stockName = stock.find((y: any) => {
                        return y.id === h.stockId
                    });
                    let currencyId = currency.find((n: any) => {
                        return n.id === h.currencyId
                    });
                    let companyN = company.find((n: any) => {
                        return n.id === h.companyId
                    });
                    let rec = isArray(recommendation) && recommendation.filter((j: any) => {
                        return h.id === j.equityTickerId;
                    }).map((reco) => {
                        return {
                            tickerName: tickerName && tickerName.name,
                            company: companyN && language(companyN.name, 'en'),
                            recommendation: language(reco.recommendation, 'en'),
                            targetPrice: reco.targetPrice,
                            date: reco.date,
                            state: h && h.tickerState
                        }
                    });


                    let recNew = isArray(rec) && rec.sort((a: any, b: any) => {
                        a = a.date && a.date.split('.').reverse().join('');
                        b = b.date && b.date.split('.').reverse().join('');
                        return a < b ? 1 : a > b ? -1 : 0;
                    });
                    return {
                        currency: currencyId && currencyId.name,
                        stock: stockName && language(stockName.name, 'en'),
                        recommendation: recNew
                    }
                });

                return {
                    sector: language(sector.name, 'en'),
                    stockRecommendation: stockRec
                }

            });
            let clean: any = [];

            data.filter((k) => {
                return k && !isEmpty(k.stockRecommendation);
            }).forEach((va: any) => {
                if (clean.some((ite: any) => ite['sector'] === va.sector)) {
                    let index = clean.findIndex((ite: any) => ite['sector'] === va.sector);
                    clean[index].stockRecommendation =
                        [...clean[index].stockRecommendation, ...va.stockRecommendation];
                } else {
                    clean.push(va)
                }
            });
            return clean;
        }
    };

    componentDidMount() {

        const {match: {params: {id}}} = this.props;
        const {dispatch}: any = this.props;
        const actions: any = {
            sectors: getAllSectorsRequest,
            equityTickers: getAllEquityTickersRequest,
            stock: getAllStockRequest,
            currency: getAllCurrencyRequest,
            recommendation: getAllStockRecommendationRequest,
            company: getAllCompanyDupRequest
        };
        dispatch(getCompanyByAuthorIdRequest({payload: {id}, meta: {form: 'analystId', callback: setValueSet}}));

        Object.keys(actions).forEach((key) => {
            dispatch(actions[key]({meta: {form: key, callback: setValueSet}}));
        });
    }


    render() {


        let data = this.normalize();
        const sizeList =  !!data?.length;
        return (
            <>
                { sizeList && <CoverageList data={data}/>}
            </>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    valueSet: selectValueSet(),
    valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);


export default compose<any>(
    withConnect,
    withRouter
)(Coverage)
