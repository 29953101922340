// @ts-nocheck
import { push } from 'connected-react-router/immutable';
import { call, put, takeLeading, takeLatest, all } from 'redux-saga/effects';

import API from '../api';
import { authSuccess, loginFailure, logoutSuccess } from './actions';
import { AUTH_REQUEST, LOGOUT_REQUEST } from './constants';
import { setUser } from 'modules/User/redux/actions';
import { language } from '../../../utils/formatters';
import {getAllLawFileRequest} from "../../LawFile/redux/actions";
import {LAW_FILES_TABLE} from "../../../constants/tables";
import {tableDataSuccess} from "../../../components/Graphs/Table/actions";
import {addAuthorisationAction} from "../../Statistics/redux/actions";
import {LANGUAGE_EN, LANGUAGE_RU} from "../../../constants/languages";

function setAddFieldToUser(client: any, user: any) {
    user.clientType = client.type;
    user.clientId = client.id;
    const names = client.name;
    if (names) {
        names.forEach((item: any) => {
            if (item.language && item.language.name == 'Рус') {
                user.clientRuName = item.translatedValue;
            }
            if (item.language && item.language.name == 'En') {
                user.clientEnName = item.translatedValue;
            }
        });
    }
}

function* authorize({payload: {fields}}: any) {
// 2022-12-07 Создание объекта нового клиента
    function createNewClient(response: any, categories: any, productTypes: any) {
        // console.log('createNewClient', categories )
        let clientCategories: any = []
        categories.forEach((category: any) => {
            // console.log('-----category------------', category)
            if (category.serviceCategory == null) {
                clientCategories.push(category)
                category.productTypes = []
                productTypes.forEach((productType: any) => {
                    // console.log('-------------------')
                    // console.log('category=',category)
                    // console.log('productType=',productType)
                    const idx = productType.categories.indexOf(category.id)
                    // console.log('found=',found)
                    if (idx != -1) {  // found category in ProductType
                        category.productTypes.push(productType.id)
                    }

                })
            }
        })

        let emails: any = []
        emails.push(response.email)

        const client = {
            clientRightSettings: {
                categories: clientCategories
            },
            emails,
            rusLang: true,
            type: (response.email.includes('.cy')) ? "ACC" : "MIFID",
            engLang: true,
            name: [
                {
                    language: {id: LANGUAGE_RU, name: 'Рус'},
                    translatedValue: response.name,
                },
                {
                    language: {id: LANGUAGE_EN, name: 'En'},
                    translatedValue: response.name,
                },
            ],
        };
        console.log('createNewClient client=', client)
        return client
    }

////////////////////////////////////////////////////////////////////////////////
    try {
        if (fields && fields.username)
            fields.username = fields.username.trimLeft().trimRight();

        let response
        try {
            response = yield call(API.login, {...fields});
        } catch (error) {
            console.error('!!!!Auth Wrong2!!!!', error.data.debugMessage);
            const customMessage = {
                en: 'User not found in Keycloak',
                ru: 'Пользователь не найден в Keycloak',
            };
            yield put(loginFailure({error}, customMessage));
            return
        }

        console.log('response=', response);
        const {access_token, ...rest} = response;

        if( response.active == false) {
            console.log('User ' + response.username + " has active=false");
            const error = {
                message: "test"
            }
            const customMessage = {
                en: 'User is not active',
                ru: 'Пользователь не активен',
            };
            yield put(loginFailure({error}, customMessage));
            if (response.id != null && response.roles.includes('USER_AD')) {
                // Удалить клиента
                console.log('Удаление клиента ', response.id, response.username )
                const deleteClient = yield call(API.deleteClient, {
                    data: {},
                    // username: response.username
                    username: fields.username
                });
                console.log('Удаление клиента response=', deleteClient)
            }
            return
        }
        else {
            yield put(authSuccess(access_token));
            console.log("Continue")
        }


//--2022-11-22 Если пользователь только что появился в keycloak не через админку----------------
// имеет валидные логин пароль keycloak, но не зарегистрирован
        if (response.id == null && response.roles.includes('USER_AD')) {
            const error = {
                message: "test"
            }
            const customMessage = {
                en: 'Edit and save your profile for registration',
                ru: 'Отредактируйте и сохраните свой профиль для регистрации',
            };
            yield put(loginFailure({error}, customMessage));

            rest.userFromAD = true
            rest.username = fields.username
            yield put(setUser(rest));

            yield put(getAllLawFileRequest({meta: {table: LAW_FILES_TABLE, callback: tableDataSuccess}}));
            //  2021-11-11 Добавим статистику
            console.log('authorize rest= ', rest);
            yield put(addAuthorisationAction({payload: {userId: null, clientId: null, productId: null}}));


            yield put(push('/'));

            return
        }


//---------------------------------------------------------------------------------------------

        /*  2021-08-03  Если пользователь клиент, то выбрать запись клиента
         *
         */
        if (rest.userType === 'CLIENT') {
            console.log('Текущий пользователь клиент', rest);
            const response = yield call(API.getCurrentClient, {id: rest.profileId});
            // console.log('response client=', response);
            // добавим дополнительные поля в User для пользвателя типа клиент
            // тп клиента MFID и ACC, русское и латинское наименование
            setAddFieldToUser(response, rest);
            rest.clientType = response.type;
        }

        // console.log('setUser rest=', rest);
        yield put(setUser(rest));

        yield put(getAllLawFileRequest({meta: {table: LAW_FILES_TABLE, callback: tableDataSuccess}}));

        //  2021-11-11 Добавим статистику
        console.log('authorize rest= ', rest);
        yield put(addAuthorisationAction({payload: {userId: rest.id, clientId: rest.clientId, productId: null}}));


        yield put(push('/'));
    } catch (error) {
        console.error('Auth Wrong', error);
        const customMessage = {
            en: 'Incorrect login or password',
            ru: 'Неверный логин или пароль',
        };
        yield put(loginFailure({error}, customMessage));
    }
}

function* logout() {
    try {
        yield put(logoutSuccess());
        yield put(push('/'));
    } catch (error) {
        console.error('Auth Wrong');
    }
}

function* Saga() {
    yield all([
        takeLeading(AUTH_REQUEST, authorize),
        takeLeading(LOGOUT_REQUEST, logout),
    ]);
}

export default Saga;
