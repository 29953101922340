import React, { useState } from 'react';

import cn from 'bem-cn';
import Button from 'arui-feather/button';
import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Paragraph from 'arui-feather/paragraph';
import './SearchResult.scss';
import { useResolution } from 'hooks/useResolution';
import { ABOUT_COMPANY } from 'constants/languagesFields';
import { getLanguageFromStore } from 'utils/formatters';

const about = cn('tab-content');
const HeadCompany = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useResolution();

  const handleContentExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <GridRow justify="right">
      <GridCol align="top" style={{ paddingLeft: 0 }}>
        <div className={about()}>
          <div className={about('detail')}>
            <div className={about('detail', 'company-info')}>
              {!isMobile && (
                <img
                  src={data && data.logo}
                  alt="company-logo"
                  className="company-logo"
                />
              )}
              <div className={`${about('detail', 'text')} ${isExpanded}`}>
                <Paragraph view="normal">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data && data.description,
                    }}
                  />
                </Paragraph>
                {isMobile && data?.description && (
                  <div className="more-expand">
                    <Button onClick={handleContentExpand}>
                      {isExpanded
                        ? ABOUT_COMPANY['less'][getLanguageFromStore()]
                        : ABOUT_COMPANY['more'][getLanguageFromStore()]}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </GridCol>
    </GridRow>
  );
};

export default HeadCompany;
