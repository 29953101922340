import {
    CHANGE_TABLE_FILTER,
    RESET_TABLE_FILTER,
    TABLE_DATA_LOADING,
    TABLE_DATA_PAGINATION_REQUEST,
    TABLE_DATA_PAGINATION_SUCCESS,
    TABLE_DATA_REQUEST,
    TABLE_DATA_SUCCESS,
} from './constants';
import {IAction} from 'types/index'

export const tableDataRequest = ({payload, meta}: IAction<void>) => ({
    type: TABLE_DATA_REQUEST,
    payload,
    meta,
});

export const tableDataSuccess = ({payload, meta}: IAction<void>) => ({
    type: TABLE_DATA_SUCCESS,
    payload,
    meta,
});

export const changeTableFilter = ({payload, meta}: IAction<void>) => ({
    type: CHANGE_TABLE_FILTER,
    payload,
    meta,
});

export const resetTableFilter = ({payload, meta}: IAction<void>) => ({
    type: RESET_TABLE_FILTER,
    payload,
    meta,
});


export const tableDataPaginationRequest = ({payload, meta}: IAction<void>) => ({
    type: TABLE_DATA_PAGINATION_REQUEST,
    payload,
    meta,
});

export const tableDataPaginationSuccess = ({payload, meta}: IAction<void>) => ({
    type: TABLE_DATA_PAGINATION_SUCCESS,
    payload,
    meta,
});


/////// 2022-12-19

export const tableDataLoading = ({payload, meta}: IAction<void>) => ({
    type: TABLE_DATA_LOADING,
    payload,
    meta,
});



