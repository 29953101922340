// @TODO: remove ts-nocheck!!
// @ts-nocheck

import { fromJS } from 'immutable';
import { ERROR_MESSAGE_FAILURE } from './constants';

const initialState = fromJS({
  error: null,
  token: null,
  logoutData: null,
});

const errorMessageReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case ERROR_MESSAGE_FAILURE: {
      return state.set('token', payload).set('error', null);
    }

    default:
      return state;
  }
};

export default errorMessageReducer;
