import React from 'react';
import { isString } from 'lodash';
import { withRouter } from 'react-router';
import { capitalizeFirstLetter } from '../../utils/formatters';

class SearchLeftBlock extends React.Component {
  handleClick = (collection, item) => {
    const { history } = this.props;
    switch (collection) {
      case 'companies':
        history.push(`/companies/about/${item.itemId}`);
        break;
      case 'ourTeam':
        history.push(`/analyst-profile/${item.itemId}`);
        break;
      default:
        return null;
    }
  };

  render() {
    const { data = [] } = this.props;
    return (
      <ul className="result-list">
        {data &&
          data?.companies.map((item) => (
            <li onClick={() => this.handleClick('companies', item)}>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
              <span>
                {isString(item.collection) &&
                  capitalizeFirstLetter(item.collection)}
              </span>
            </li>
          ))}
        {data &&
          data?.ourTeam?.map((item) => (
            <li onClick={() => this.handleClick('ourTeam', item)}>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
              <span>
                {isString(item.collection) &&
                  capitalizeFirstLetter(item.collection)}
              </span>
            </li>
          ))}
        {data &&
          data?.events?.map((item) => (
            <li onClick={() => this.handleClick('events', item)}>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
              <span>
                {isString(item.collection) &&
                  capitalizeFirstLetter(item.collection)}
              </span>{' '}
            </li>
          ))}
        {data &&
          data?.categories?.map((item) => (
            <li onClick={() => this.handleClick('categories', item)}>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
              <span>
                {isString(item.collection) &&
                  capitalizeFirstLetter(item.collection)}
              </span>{' '}
            </li>
          ))}
      </ul>
    );
  }
}

export default withRouter(SearchLeftBlock);
