import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'

class ClassAPI {

    getAllAuthor = () => request({
        url: 'author',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllAnalyst = (authorTypeId: string) => request({
        url: `author/by-type/${authorTypeId}`,
        method: 'GET',
        token: API_GLOBAL.token
    });

}

const API_AUTHOR = new ClassAPI();

export default API_AUTHOR;
