import React from 'react';
import addDays from 'date-fns/add_days'
import subtractDays from 'date-fns/sub_days'
import CalendarInput from 'arui-feather/calendar-input';
import moment from 'moment'
import './DateFilter.scss';
import {Field} from "redux-form";
import {getLanguageFromStore} from 'utils/formatters'
import {createStructuredSelector} from "reselect";
import {makeSelectData} from "./selector";
import {connect} from "react-redux";
import {compose} from "redux";

const CalendarComponent = (props) => {
    const onChange = (value) => {
        const {input} = props;
        input.onChange(value);
    };
    const {calendar} = props;
    return (
        <CalendarInput
            calendar={calendar}
            onChange={onChange}
            size="m"
            width="available"
            name={props.name}
            value={props.input.value}
        />
    )
};


const validateDate = (data) => {

    let obj = {
        showToday: true
    };
    if (data?.dateFrom) {
        obj.earlierLimit = subtractDays(moment(data.dateFrom, 'DD-MM-YYYY').format(), 0).valueOf()
    }
    if (data?.dateTo) {
        obj.laterLimit = addDays(moment(data.dateTo, 'DD-MM-YYYY').format(), 0).valueOf()
    }
    return obj

};


class DateFilter extends React.Component {

    render() {
        const {graphData} = this.props;
        const calendar = validateDate(graphData);
        return (
            <div className="date-filter">
                <div className="date-filter__item">
                    <span className="date-filter__item_label">
                        {getLanguageFromStore() === 'ru' ? 'от' : 'from'}
                    </span>
                    <div className="date-filter__item_input">
                        <Field
                            calendar={calendar && {laterLimit:calendar.laterLimit}}
                            name={`dateFrom`}
                            type="text"
                            component={CalendarComponent}
                            view='filled'
                            width='available'
                            size='s'
                            label={''}
                        />
                    </div>
                </div>
                <div className="date-filter__item">
                    <span className="date-filter__item_label">
                        {getLanguageFromStore() === 'ru' ? 'до' : 'to'}
                    </span>
                    <div className="date-filter__item_input">
                        <Field
                            calendar={calendar && {earlierLimit: calendar.earlierLimit}}
                            name={`dateTo`}
                            type="text"
                            component={CalendarComponent}
                            view='filled'
                            width='available'
                            size='s'
                            label={''}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = createStructuredSelector({
    graphData: makeSelectData(),
});

const withConnect = connect(mapStateToProps);

export default compose(
    withConnect
)(DateFilter);