// @TODO: remove ts-nocheck!!
// @ts-nocheck

import { fromJS } from 'immutable';
import { IAction } from 'types/index';
import {
  NEWS_DATA_REQUEST,
  NEWS_DATA_FAILURE,
  NEWS_DATA_SUCCESS,
} from './constants';
const initialState = fromJS({});

const reducer = (
  state = initialState,
  { type, meta, payload }: IAction<void>,
) => {
  const table = meta && meta.table;
  const response = payload && payload.response;

  switch (type) {
    case NEWS_DATA_REQUEST: {
      return state
        .setIn([table, 'data'], null)
        .setIn([table, 'loading'], true)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null);
    }

    case NEWS_DATA_SUCCESS: {
      return state
        .setIn([table, 'data'], response)
        .setIn([table, 'loading'], false)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null);
    }

    case NEWS_DATA_FAILURE: {
      return state
        .setIn([table, 'request'], false)
        .setIn([table, 'loading'], false)
        .setIn([table, 'failureData'], payload)
        .setIn([table, 'error'], payload);
    }

    default:
      return state;
  }
};

export default reducer;
