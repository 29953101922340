import React, { useEffect, useState } from 'react';
import API_LAWFILE from 'modules/LawFile/api';
import { LawFile } from 'modules/LawFile/pages';

const Information = () => {
  const [data, setData] = useState([]);

  // Hardcode.
  // Refactoring!! Update with store!
  useEffect(() => {
    const api = async () => {
      const data = await API_LAWFILE.getAllLawFile();

      setData(data);
    };

    api();
  }, []);

  return (
    <div className="custom-page-wrapper information-page">
      <LawFile apiData={data} />
    </div>
  );
};

export default Information;
