import * as ACTION_TYPE from './constants';


export const getProductByLanguageAndCategoryEconomicsRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_ECONOMICS_REQUEST,
    meta,
    payload
});

export const getEconomicsSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_ECONOMICS_SORT_REQUEST,
    meta,
    payload
});


