// @ts-nocheck
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { InputSelect } from 'components/Inputs';

import { selectValueSet } from './selector';
import { setValueSet } from './actions';

import { Field } from 'redux-form';

class ValueSet extends React.Component {
  componentDidMount() {
    const { id, action, dispatch }: any = this.props;
    if (action) {
      dispatch(action({ meta: { form: id, callback: setValueSet } }));
    }
  }

  render() {
    const {
      id,
      name,
      label,
      multiple,
      data,
      showcode,
      isAutosuggest,
      async,
      valueSet,
      filter,
      sort, // exclude from ...props
      ...props
    }: any = this.props;
    return (
      <Field
        id={id}
        name={name}
        async={async}
        type="text"
        loading={!valueSet}
        multiple={multiple}
        component={InputSelect}
        label={label}
        showcode={showcode}
        {...props}
        data={valueSet}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ValueSet);
