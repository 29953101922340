// @TODO: remove ts-nocheck!!
// @ts-nocheck

import { fromJS } from 'immutable';
import { AUTH_SUCCESS, AUTH_FAILURE, LOGOUT_SUCCESS } from './constants';

const initialState = fromJS({
  error: null,
  token: null,
  logoutData: null,
});

const authReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case AUTH_SUCCESS: {
      return state.set('token', payload).set('error', null);
    }
    case AUTH_FAILURE: {
      return state.set('error', payload);
    }

    case LOGOUT_SUCCESS: {
      return state.set('token', null).set('error', null);
    }

    default:
      return state;
  }
};

export default authReducer;
