import React from 'react';

import cn from 'bem-cn';
import {isNil, isUndefined} from 'lodash'
import TargetPrice from "components/TargetPrice";

import './TickerInfo.scss';
import {ABOUT_COMPANY} from "../../constants/languagesFields";
import {getLanguageFromStore} from "utils/formatters";
import {NOT_FIND} from 'constants/languagesFields'

const ticker = cn('ticker');


class TickerInfo extends React.Component {
    render() {
        const {data} = this.props;
        return (
            <div className={ticker()}>
                <table>
                    <thead className={ ticker('row', {type: 'head'}) }>
                    <tr>
                        <td className={ticker('cell')}>{ABOUT_COMPANY['ticker'][getLanguageFromStore()]}</td>
                        <td className={ticker('cell')}>{ABOUT_COMPANY['targetPrice'][getLanguageFromStore()]}</td>
                        <td className={ticker('cell')}>{ABOUT_COMPANY['recommendation'][getLanguageFromStore()]}</td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((item, index) => {
                        return (
                            <tr key={index} className={ticker('row', {type: 'body'})}>
                                <td className={ticker('cell').mix('company-name')}>
                                    {isNil(item.name) ? <span style={{
                                        color: '#112a41',
                                        fontWeight: '300',
                                        fontSize: 18
                                    }}>{NOT_FIND[getLanguageFromStore()]}</span> : item.name}
                                </td>
                                <td className={ticker('cell').mix('target-price')}>
                                    {!isNil(item.price) && !isNil(item.price.value) ?
                                        <TargetPrice amount={item.price}/> :
                                        <span style={{
                                            color: '#112a41',
                                            fontWeight: '300',
                                            fontSize: 18
                                        }}>{NOT_FIND[getLanguageFromStore()]}</span>}
                                </td>
                                <td className={ticker('cell')}>
                                    {isNil(item.rec) ? <span style={{
                                        color: '#112a41',
                                        fontWeight: '300',
                                        fontSize: 18
                                    }}>{NOT_FIND[getLanguageFromStore()]}</span> : item.rec}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default TickerInfo;