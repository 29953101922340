// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import FlowChart from 'components/Flows/Chart';
import { makeSelectData } from './selector';
import { IGraphTable } from './interface';
import { setValueSet } from '../../ValueSet/actions';
import { selectAllValueSets, selectValueSet } from '../../ValueSet/selector';

class GraphChart extends React.PureComponent<IGraphTable> {
  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  render() {
    const {
      normalizeTableData,
      width,
      gutter,
      graphData,
      caption,
      valuesModal,
      ...rest
    }: IGraphTable = this.props;

    return (
      <FlowChart
        valuesModal={valuesModal}
        caption={caption}
        width={width}
        normalizeTableData={normalizeTableData}
        data={graphData}
        gutter={gutter}
        {...rest}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valuesModal: selectAllValueSets,
  graphData: makeSelectData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(GraphChart);
