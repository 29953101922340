import { MOBILE_RESOLUTION, TABLET_RESOLUTION } from '../constants/resolution';
import { useEffect, useState } from 'react';

export const useResolution = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width < MOBILE_RESOLUTION;
  const isDesktop = width > TABLET_RESOLUTION;

  return { isMobile, isDesktop };
};
