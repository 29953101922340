// @ts-nocheck
import React from 'react';
import {find, isArray} from 'lodash';
import {ICategoryTableExt} from 'components/Flows/Table/interface';
import {MAX_FORESTCUSTS} from 'constants/news';
import GraphForecast from '../../../components/Graphs/Forecast';
import {getLanguageFromStore} from '../../../utils/formatters';

/**
 * Получить нужную категорию из общего массива
 * @param categories
 */
function getCategory(categories: any, selCategory: any) {
  return find(categories, function (o) {
    if (
      o.name[0].translatedValue === selCategory[0] ||
      o.name[0].translatedValue === selCategory[1]
    ) {
      return true;
    }
  });
}

class ForecastFiles extends React.Component<ICategoryTableExt> {
  normalizeTableData = (data: any, valuesModal: any): any => {
    const categories = valuesModal && valuesModal.categories;
    if (isArray(categories)) {
      const categoryCurrent = this.props.category;
      if (!categoryCurrent || categoryCurrent.length !== 2) {
        console.error('ForecastFiles:Unknown parameter category', this.props);
      }

      const category = getCategory(categories, categoryCurrent);

      if (category.files && isArray(category.files[getLanguageFromStore()])) {
        return category.files[getLanguageFromStore()].map((item: any) => {
          return {
            category: item,
            modal: {
              name: `файл загрузки${item?.url}`,
              annotation: '',
              file: item?.url,
              downloadAvailable: true,
              date: '',
              id: 0,
              categoriesTitle: '',
            },
          };
        });
      }
      return {};
    }
  };

  render() {
    const actions = {};
    return (
      <GraphForecast
        actions={actions}
        type={'forestcasts'}
        table={MAX_FORESTCUSTS}
        normalizeTableData={this.normalizeTableData}
      />
    );
  }
}

export default ForecastFiles;
