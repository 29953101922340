import React from 'react';
import PropTypes from 'prop-types';
import './ForestCust.scss';
import ForestCustFiles from './ForestCustFiles';

/**
 * Прогноз
 */

class ForestCust extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { items, isMobile } = this.props;
    // console.log('ForestCust Plate item=', items)

    if (!isMobile && items.length > 0) {
      return (
        <div className={'forestcust'}>
          <ForestCustFiles data={items} isMobile={false}/>
        </div>
      );
    } else if (isMobile && items.length > 0) {
      return (
        <div className={'forestcust-mobile'}>
          <ForestCustFiles data={items} isMobile={true}/>
        </div>
      );
    }
    return <div />;
  }
}

ForestCust.defaultProps = {
  id: null,
  category: null,
  name: null,
  annotation: null,
  date: null,
  isMobile: false,
};

ForestCust.propTypes = {
  id: PropTypes.number,
  category: PropTypes.string,
  name: PropTypes.string,
  annotation: PropTypes.string,
  date: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default ForestCust;
