import * as ACTION_TYPE from './constants';

export const getAllAuthorRequest = ({meta}: any) => ({
    type: ACTION_TYPE.GET_ALL_AUTHOR_REQUEST,
    meta
});

export const getAllAnalystRequest = ({meta}: any) => ({
    type: ACTION_TYPE.GET_ALL_ANALYST_REQUEST,
    meta
});

export const getActiveAuthorRequest = ({meta}: any) => ({
    type: ACTION_TYPE.GET_ACTIVE_AUTHOR_REQUEST,
    meta
});
