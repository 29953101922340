import * as ACTION_TYPE from './constants';



export const getCompanyWithParamsRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_COMPANY_WITH_PARAMS_REQUEST,
    payload,
    meta
});


export const getAllCompanyRequest = ({meta}: any) => ({
    type: ACTION_TYPE.GET_ALL_COMPANY_REQUEST,
    meta
});

export const getAllCompanyDupRequest = ({meta}: any) => ({
    type: ACTION_TYPE.GET_ALL_COMPANY_DUP_REQUEST,
    meta
});

export const getAllStockRequest = ({meta}: any) => ({
    type: ACTION_TYPE.GET_STOCK_REQUEST,
    meta
});

export const getAllStockTypeRequest = ({meta}: any) => ({
    type: ACTION_TYPE.GET_STOCK_TYPE_REQUEST,
    meta
});

export const getCompanyByAuthorIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_COMPANY_BY_AUTHOR_REQUEST,
    payload,
    meta
});

export const getCompanyByIdRequest = ({payload,meta}: any) => ({
    type: ACTION_TYPE.GET_COMPANY_BY_ID_REQUEST,
    meta,
    payload,
});
export const getCompanyFileByIdRequest = ({payload,meta}: any) => ({
    type: ACTION_TYPE.GET_COMPANY_FILE_BY_ID_REQUEST,
    meta,
    payload,
});


export const getStockRecommendationTickerIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_STOCK_RECOMMENDATION_TICKER_ID_REQUEST,
    payload,
    meta
});

export const getRecommendationByCompanyIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_RECOMMENDATION_BY_COMPANY_ID_REQUEST,
    payload,
    meta
});


export const getGraphByCompanyIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_GRAPH_BY_COMPANY_ID_REQUEST,
    payload,
    meta
});

export const getCompanyStockIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_COMPANY_BY_STOCK_ID_REQUEST,
    payload,
    meta
});




export const getDividendsByCompanyIdAndStockIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_DIVIDENDS_BY_COMPANY_REQUEST,
    payload,
    meta
});


export const getEquityTickerByCompanyIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_EQUITY_TICKER_BY_COMPANY_REQUEST,
    payload,
    meta
});

export const getAllEquityTickersRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_ALL_EQUITY_TICKERS_REQUEST,
    payload,
    meta
});

export const getAllCurrencyRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_ALL_CURRENCY_REQUEST,
    payload,
    meta
});

export const
    getAllStockRecommendationRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_ALL_STOCK_RECOMMENDATION_REQUEST,
    payload,
    meta
});

export const getCatalogCurrencyIdRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_CATALOG_CURRENCY_REQUEST,
    payload,
    meta
});

export const  getAllStockRecommendationByCompanyIdRequest = ({payload, meta}: any) => ({
        type: ACTION_TYPE.GET_STOCK_RECOMMENDATION_BY_COMPANY_ID_REQUEST,
        payload,
        meta
    });
