import Home from './pages';


export default [
  {
    type: 'private',
    exact: true,
    path: '/',
    roles: ['admin'],
    component: Home,
  },
];
