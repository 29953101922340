import AnalystProfile from './pages';


export default [
  {
    type: 'private',
    exact: true,
    path: '/analyst-profile/:id',
    roles: ['admin'],
    component: AnalystProfile,
  },
];
