import * as ACTION_TYPE from './constants';
import {IAction} from "../../../types";


export const getProductByLanguageAndCategoryCompaniesRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_COMPANIES_REQUEST,
    meta,
    payload
});

export const getProductByLanguageCompaniesRequest = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_LANGUAGE_COMPANIES_REQUEST,
    meta,
    payload
});

export const getCompaniesSortRequest = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_COMPANIES_SORT_REQUEST,
    meta,
    payload
});


export const getCompaniesSortReportRequest = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_COMPANIES_SORT_REPORT_REQUEST,
    meta,
    payload
});

// 2021-09-14
export const getStorRecommendationRequest = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_STOCK_RECOMMENDATION_REQUEST,
    meta,
    payload
});
