import { combineReducers } from 'redux';
import { History, LocationState } from 'history';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
// @ts-ignore
import immutableTransform from 'redux-persist-transform-immutable';
import storage from 'localforage';

// Components
import graphFormReducer from 'components/Graphs/Form/reducer';
import graphTableReducer from 'components/Graphs/Table/reducer';
import graphNewsReducer from 'components/Graphs/News/reducer';
import valueSetReducer from 'components/ValueSet/reducer';
import dialogReducer from 'components/Flows/Dialog/reducer';
import interfaceSetReducer from 'modules/IntefaceSettings/redux/reducer';

// Modules
import authReducer from 'modules/Auth/redux/reducer';
import userReducer from 'modules/User/redux/reducer';
import equityResearchReducer from 'modules/EquityResearch/redux/reducer';
import economicsReducer from 'modules/Economics/redux/reducer';
import fixedIncomeReducer from 'modules/FixedIncome/redux/reducer';
import commoditiesReducer from 'modules/Commodities/redux/reducer';
import companiesReducer from 'modules/Companies/redux/reducer';
import futureEventsReducer from 'modules/FutureEvents/redux/reducer';
import ourTeamReducer from 'modules/OurTeam/redux/reducer';
import homeReducer from 'modules/Home/redux/reducer';
import analystProfileReducer from 'modules/AnalystProfile/redux/reducer';
import searchReducer from 'modules/Search/redux/reducer';
import profileReducer from 'modules/Profile/redux/reducer';

import errorReducer from 'modules/Error/redux/reducer';
import messageReducer from 'components/Message/reducer';

const rootPersistConfig = {
  transforms: [immutableTransform()],
  key: 'AlfaRoot_1',
  whitelist: ['auth', 'user', 'global', 'languangeController'],
  storage,
};

export const rootReducer = (history: History<LocationState>) =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      router: connectRouter(history),
      // Redux-ourTeam
      form: formReducer,
      // Components
      graphForm: graphFormReducer,
      graphTable: graphTableReducer,
      graphNews: graphNewsReducer,
      valueSets: valueSetReducer,
      dialog: dialogReducer,
      // akayerov 2021-02-04
      interfaceSets:interfaceSetReducer,

      // Modules
      auth: authReducer,
      user: userReducer,
      equityResearch: equityResearchReducer,
      economics: economicsReducer,
      fixedIncome: fixedIncomeReducer,
      commodities: commoditiesReducer,
      companies: companiesReducer,
      futureEvents: futureEventsReducer,
      ourTeam: ourTeamReducer,
      home: homeReducer,
      analystProfile: analystProfileReducer,
      search: searchReducer,
      profile: profileReducer,

      message: messageReducer,
      error: errorReducer,
    }),
  );
