import LoginPage from './pages/Login';

export default [
  {
    type: 'public',
    exact: true,
    path: '/login',
    component: LoginPage,
  },
];
