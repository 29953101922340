// @ts-nocheck
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import {IGraphTable} from './interface';
import {setValueSet} from '../../ValueSet/actions';
import FlowChartOFZ from "components/Flows/ChartOFZ";
import {makeSelectFooterInfoData} from "../Forecast/selector";

class GraphChartOFZ extends React.PureComponent<IGraphTable> {
  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  render() {
    const {
      normalizeTableData,
      width,
      gutter,
      graphData,
      caption,
      valuesModal,
      ...rest
    }: IGraphTable = this.props;

    return (
      <FlowChartOFZ
        valuesModal={valuesModal}
        caption={caption}
        width={width}
        normalizeTableData={normalizeTableData}
        data={graphData}
        gutter={gutter}
        {...rest}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  graphData: makeSelectFooterInfoData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(GraphChartOFZ);
