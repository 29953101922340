import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'

class ClassAPI {

    getAllAuthorTypes = () => request({
        url: 'catalog/author-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

}

const API_AUTHOR_TYPE = new ClassAPI();

export default API_AUTHOR_TYPE;
