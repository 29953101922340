import React from 'react';
import { Helmet } from 'react-helmet';

const MetaData = () => (
  <Helmet titleTemplate="%s - Research Alfa Bank" defaultTitle="Research Alfa Bank">
    <html lang="en-EN" />
    <meta charSet="utf-8" />
    <meta name="description" content="Research Alfa Bank" />
  </Helmet>
);

export default MetaData;
