// @ts-nocheck
import { call, put, takeLeading, takeLatest, all, select } from 'redux-saga/effects';
import API from '../api';
import { GET_ALL_EVENTS_REQUEST } from './constants';
import { errorMessageFailure } from '../../Error/redux/actions';

function* getAllEvents({ meta: { form, table, callback }, payload }: any) {
  try {
    const response = yield call(API.getAllEvents, table, payload);
    if (callback) {
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
    }
  } catch (error) {
    yield put(errorMessageFailure({ payload: { error } }));
  }
}

function* Saga() {
  yield all([takeLeading(GET_ALL_EVENTS_REQUEST, getAllEvents)]);
}

export default Saga;
