import React from 'react';
import { connect } from 'react-redux';
import IconButton from 'arui-feather/icon-button';
import IconLogout from 'arui-feather/icon/action/logout';
import { logoutRequest } from 'modules/Auth/redux/actions';
// import Link from 'arui-feather/link';

const AdminHeader = ({ dispatch }) => {
  return (
    <div className="admin-header">
      {/* <Link text="Super Admin" to="/profile" /> */}
      <IconButton size="xl" onClick={() => dispatch(logoutRequest())}>
        <IconLogout size="s" />
      </IconButton>
    </div>
  );
};

export default connect()(AdminHeader);
