// @ts-nocheck
import {all, call, put, select, takeLeading} from 'redux-saga/effects';
import API from '../api';
import {GET_PRODUCT_TYPE_BY_CATEGORY_REQUEST, GET_PRODUCT_TYPE_REQUEST} from './constants';
import {errorMessageFailure} from "../../Error/redux/actions";


// 2022-12-21 Попытка уменьшить число запросов, берем из хранилища, если страны там уже есть
function* getProductType({meta: {form, table, callback}}: any) {
    try {
        const state = yield select();
        const productType = (state.valueSets && state.valueSets.get('productType')) ? state.valueSets.get('productType') : []
        if(  productType.length > 0) {
            console.log('ProductType was to get from redux store, no request');
            return;
        }
        const response = yield call(API.getProductType);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getProductTypeByCategory({meta: {form, table, callback}, payload: {id}}: any) {
    try {
        const response = yield call(API.getProductTypeByCategory, id);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_PRODUCT_TYPE_REQUEST, getProductType),
        takeLeading(GET_PRODUCT_TYPE_BY_CATEGORY_REQUEST, getProductTypeByCategory),
    ]);
}

export default Saga;
