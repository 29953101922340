import React from 'react';
import {getAllSectorsRequest} from 'modules/Sector/redux/actions'
import {getActiveAuthorRequest} from 'modules/Author/redux/actions'
import {getLanguageFromStore} from 'utils/formatters'
import {FIELD_NAME} from 'constants/languagesFields';

export default [
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "single",
        bigLabel: false,
        id: 'sectorOne',
        label: FIELD_NAME['sectorOne'][getLanguageFromStore()],
        normalize: 'sectorOne',
        actions: {sectors: getAllSectorsRequest},
// 2021-10-05
//        actions: {authorType: getActiveAuthorRequest},


    },
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "many",
        bigLabel: false,
        id: 'authorType',
        label: FIELD_NAME['author'][getLanguageFromStore()],
        normalize: 'authorType',
        // actions: {authorType: getAllAnalystRequest},
 // 2023-01-17
        actions: {authorType: getActiveAuthorRequest},
    },
];
