// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import AnalystProfileFilter from './filter';
import AnalystProfileTable from './table';
import AnalystProfileProductNews from './productNews';
import AnalystProfileCoverage from './coverage';
import AnalystProfilePage from './profile';
import ColTree from 'components/ColTree';
import { useResolution } from 'hooks/useResolution';
import { Heading } from 'arui-feather/heading';
import { FIELD_NAME } from 'constants/languagesFields';
import { getLanguageFromStore } from 'utils/formatters';

const AnalystProfile = () => {
  const { isMobile } = useResolution();
  return (
    <div className="team-member-inner-page">
      <GridRow
        className="team-member-row"
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
      >
        <ColTree width={{ mobile: 12, tablet: 9, desktop: 9 }}>
          <AnalystProfilePage />
          <AnalystProfileCoverage />
          <GridRow
              className="team-member-row"
              gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
          >
          <ColTree
              className="filters-custom"
              width={{ mobile: 12, tablet: 3, desktop: 3 }}
          >
            <AnalystProfileFilter />
          </ColTree>
          <ColTree
              className="table-custom"
              width={{ mobile: 12, tablet: 9, desktop: 9 }}
          >
            <AnalystProfileTable />
          </ColTree>
          </GridRow>
        </ColTree>
        <ColTree
          className="actual-custom"
          width={{ mobile: 12, tablet: 3, desktop: 3 }}
        >
          {isMobile && (
            <Heading size="xl">
              {FIELD_NAME['actual'][getLanguageFromStore()]}
            </Heading>
          )}
          <AnalystProfileProductNews />
        </ColTree>
{/*
        <ColTree
          className="filters-custom"
          width={{ mobile: 12, tablet: 3, desktop: 3 }}
        >
          <AnalystProfileFilter />
        </ColTree>
        <ColTree
          className="table-custom"
          width={{ mobile: 12, tablet: 9, desktop: 9 }}
        >
          <AnalystProfileTable />
        </ColTree>
*/}
      </GridRow>
    </div>
  );
};

export default AnalystProfile;
