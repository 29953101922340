// @ts-nocheck
import {call, put, takeLeading, takeLatest, all, select} from 'redux-saga/effects';
import API from '../api';
import {
    GET_SECTOR_BY_ID_REQUEST,
} from './constants';
import {errorMessageFailure} from "../../Error/redux/actions";


function* getSectorByIdRequest({payload: {callback, data: {id}}, meta: {form}}: any) {
    try {
        const response = yield call(API.getSectorById, {id});

        if (callback) {
            return yield put(callback({payload: {response}, meta: {form}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

function* Saga() {
    yield all([
        //takeLeading(GET_SECTOR_BY_ID_REQUEST, getSectorByIdRequest),
    ]);
}

export default Saga;
