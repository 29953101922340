// @ts-nocheck
import React from 'react';
import qs from 'querystring';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import GridRow from 'arui-feather/grid-row';
import SearchBar from './searchBar';
import SearchFilter from './filter';
import SearchTable from './table';
import ColTree from 'components/ColTree';
import { createStructuredSelector } from 'reselect';
import { selectSearchData } from '../redux/selector';
import { compose } from 'redux';
import { getDataSearchRequest, getDataSearchRequest2 } from '../redux/actions';
import { Heading } from 'arui-feather/heading';
import { TABLE_HEAD } from 'constants/languagesTable';
import { getLanguageFromStore } from 'utils/formatters';

class Search extends React.Component<any, any> {
  componentDidMount() {
    const { location, dispatch } = this.props;
/*
    const { query } = qs.parse(location.search.replace('?', ''));
    if (query) {
      dispatch(getDataSearchRequest({ payload: { value: query } }));
    }
*/

    const { query, productTypeId } = qs.parse(location.search.replace('?', ''));
    console.log('location.search =', location.search)
    console.log('query/productTypeId', query, productTypeId)
/* 2022-02-15 было
    if (query) {
      dispatch(getDataSearchRequest({ payload: { value: query } }));
    }

 */
    if(productTypeId) {
      dispatch(getDataSearchRequest2({ payload: {  query, productTypeId } }));
    }
    else {
      dispatch(getDataSearchRequest({ payload: { value: query } }));
    }
  }

  render() {
    const { dataSearch } = this.props;
    return (
      <div className="search-result-page">
        <div className="mobile-search-results">
          <SearchBar
            dataSearch={dataSearch}
            shouldRenderResults={false}
            shouldRenderHeader={true}
          />
        </div>
        <GridRow gutter={{ mobile: 0, tablet: 16, desktop: 12 }}>
          <ColTree
            className="custom-filter-column"
            width={{ mobile: 12, tablet: 3, desktop: 3 }}
          >
            <SearchFilter />
          </ColTree>
          <ColTree
            className="search-adds"
            width={{ mobile: 12, tablet: 9, desktop: 9 }}
          >
            <SearchBar dataSearch={dataSearch} />
            <Heading size="xl">
              {TABLE_HEAD['reports'][getLanguageFromStore()]}
            </Heading>
            <SearchTable />
          </ColTree>
        </GridRow>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  dataSearch: selectSearchData,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(Search);
