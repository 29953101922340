import React from 'react';
import { getAllEventTypesRequest } from 'modules/EventType/redux/actions';
import { getAllCompanyRequest } from 'modules/Company/redux/actions';
import { getAllCountryRequest } from 'modules/Country/redux/actions';
import { getLanguageFromStore } from 'utils/formatters';
import { FIELD_NAME } from 'constants/languagesFields';
export default [
  {
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 10,
    },
    isExpanded: false,
    type: 'list',
    name: 'sector',
    mode: 'single',
    bigLabel: false,
    id: 'periodFeature',
    label: FIELD_NAME['periodTypesCustom'][getLanguageFromStore()],
    normalize: 'periodFeature',
    data: [
      {
        name: getLanguageFromStore() === 'en' ? 'Future' : 'Будущее',
        id: true,
      },
      {
        name: getLanguageFromStore() === 'en' ? 'Past' : 'Прошедшее',
        id: 'false',
      },
    ],
    // actions: {periodTypes: getAllPeriodTypesRequest},
  },
  {
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 10,
    },
    isExpanded: false,
    type: 'list',
    name: 'sector',
    mode: 'many',
    bigLabel: false,
    id: 'companyOne',
    label: FIELD_NAME['companyOne'][getLanguageFromStore()],
    normalize: 'companyOne',
    actions: { company: getAllCompanyRequest },
  },
  {
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 10,
    },
    isExpanded: false,
    type: 'list',
    name: 'sector',
    mode: 'many',
    bigLabel: false,
    id: 'countryOne',
    label: FIELD_NAME['countryOne'][getLanguageFromStore()],
    normalize: 'countryOne',
    actions: { country: getAllCountryRequest },
  },
  {
    fieldWidth: '12',
    customPosition: {
      label: 1,
      field: 10,
    },
    isExpanded: false,
    type: 'list',
    name: 'sector',
    mode: 'single',
    bigLabel: false,
    id: 'eventType',
    label: FIELD_NAME['eventType'][getLanguageFromStore()],
    normalize: 'eventType',
    actions: { eventType: getAllEventTypesRequest },
  },
  {
    fieldWidth: '12',
    title: 'Date',
    id: 'date',
    name: 'date',
    label: FIELD_NAME['date'][getLanguageFromStore()],
    isExpanded: false,
    type: 'calendar',
    bigLabel: false,
    dateFrom: '01.01.2020',
    dateTo: '02.02.2020',
    language: 'rus',
  },
];
