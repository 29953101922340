import React from 'react';
import {dowloadFile} from 'utils/formatters';
import {connect} from 'react-redux';
import {isArray, isNil} from 'lodash';
import FileManager2 from '../FileManager2';

const ForestCustFiles = ({
  data,
  id,
  dispatch,
  isMobile,
}: {
  data: any;
  id: any;
  dispatch: any;
  isMobile: any;
}) => {
  const dow = (file: any) => (event: any) => {
    event.stopPropagation();
    dowloadFile(file, dispatch);
  };

  // console.log('ForestCustFiles data=', data);
  const flexClassName = (isMobile) ? 'forestcust-flex-container': ''
  return (
    <div className={flexClassName}>
      {data &&
        isArray(data) &&
        data.map((value: any, index: any, data: any) => {
          const { title, size, url } = value.category;
          const re = /(?:\.([^.]+))?$/;
          const current = {
            name: title,
            size,
            // @ts-ignore
            type: re.exec(url)[1],
            url,
          };
          if (isMobile) {
            return (
              !isNil(url) && (
                <div
                  key={index}
                  onClick={dow(url)}
                  className={'forestcust-flex-content'}
                >
                  <FileManager2 key={index} file={current} />
                </div>
              )
            );
          }
          return (
            !isNil(url) && (
              <div key={index}>
                <div onClick={dow(url)}>
                  <FileManager2 key={index} file={current} />
                </div>
                {index < data.length - 1 && (
                  <div
                    className={'forestcust__inner_border forestcust__between'}
                  />
                )}
              </div>
            )
          );
        })}
    </div>
  );
};

export default connect()(ForestCustFiles);
