export const GET_ALL_EQUITYRESEARCH_REQUEST = 'GET_ALL_EQUITYRESEARCH_REQUEST'
export const GET_ALL_EQUITYRESEARCH_SUCCESS = 'GET_ALL_EQUITYRESEARCH_SUCCESS'
export const GET_ALL_EQUITYRESEARCH_FAILURE = 'GET_ALL_EQUITYRESEARCH_FAILURE'

export const CREATE_NEW_EQUITYRESEARCH_REQUEST = 'CREATE_NEW_EQUITYRESEARCH_REQUEST'

export const GET_FIXEDINCOME_FILTER_BY_ID_REQUEST = 'GET_FIXEDINCOME_FILTER_BY_ID_REQUEST'

export const EDIT_FIXEDINCOME_REQUEST = 'EDIT_FIXEDINCOME_REQUEST'


export const GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_FIXEDINCOME_REQUEST = 'GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_FIXEDINCOME_REQUEST';


export const GET_FIXEDINCOME_SORT_REQUEST = 'GET_FIXEDINCOME_SORT_REQUEST';

