// @ts-nocheck
import React, { FC } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PrivateLayout from 'app/Layouts/private';

import { IRouterProps } from 'types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { makeSelectToken } from '../selectors';

const PrivateRoute: FC<IRouterProps> = ({
  path,
  component: Component,
  token,
}) => (
  <PrivateLayout>
    <Route
      path={path}
      render={() => (token ? <Component /> : <Redirect to="/login" />)}
    />
  </PrivateLayout>
);

const mapStateToProps = createStructuredSelector({
  token: makeSelectToken(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(PrivateRoute);
