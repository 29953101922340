import React from 'react';
import cn from 'bem-cn';
import Heading from 'arui-feather/heading';
import Label from 'arui-feather/label';
import Link from 'arui-feather/link';
import Modal from 'components/ModalThree';
import { EventInfo } from '../ModalContent';
import './Events.scss';

/**
 * Анонсы событий
 */

const eventBlock = cn('events');

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowing: false,
    };
  }

  modalHandler = (event) => {
    event.preventDefault();
    this.setState((prevState) => ({
      ...prevState,
      isShowing: !prevState.isShowing,
    }));
  };

  render() {
    const { event } = this.props;
    const { modal } = event;
    return (
      <>
        <div
          onClick={this.modalHandler.bind(this)}
          className={eventBlock('item')}
          key={event.id}
        >
          <Label size="m" isNoWrap={false}>
            {event.date}
          </Label>
          <Heading size="xs" className="events__item_title">
            {event.name}
          </Heading>
          <Link text={event.comment} size="l" className="events__item_link" />
        </div>
        {this.state.isShowing && (
          <Modal
            isShowing={this.state.isShowing}
            onClose={this.modalHandler.bind(this)}
          >
            <EventInfo modal={modal} />
          </Modal>
        )}
      </>
    );
  }
}

export default Events;
