import {language} from 'utils/formatters'

export const normalize = (data: any) => {
    if (!data) return null;
    console.log('Equality filter: normalize data=', data)
    let cormData = {
        nameEN: language(data.name, 'en'),
        nameRU: language(data.name, 'ru'),
        shortNameEN: language(data.shortName, 'en'),
        shortNameRU: language(data.shortName, 'ru'),
    };

    return cormData;
};

export const normalizeFilter = (data: any) => {
    if (!data) return null;
    console.log('Equality filter: normalizeFilter data=', data)
    const sectors = data && data.sectors;
    const categories = data && data.categories;
    if (categories && categories.length && sectors && sectors.length) {
        return categories.map((value: any) => {
            const children = sectors.filter((element: any) => {
                const currentItem = value && value.sectors.includes(element.id);
                if (currentItem) {
                    return element
                }
            }).map((lu: any) => {
                return ({
                    name: language(lu.name, 'en'),
                    id: lu.id
                })
            });
            return {
                name: language(value.name, 'en'),
                id: value.id,
                children: children
            }
        })
    }


};
