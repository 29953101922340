import FutureEvents from './pages';


export default [
  {
    type: 'private',
    exact: true,
    path: '/calendar',
    roles: ['admin'],
    component: FutureEvents,
  },
];
