// @ts-nocheck
import {all, call, put, select, takeLeading} from 'redux-saga/effects';
import API from '../api';
import {GET_ACTIVE_AUTHOR_REQUEST, GET_ALL_ANALYST_REQUEST, GET_ALL_AUTHOR_REQUEST} from './constants';
import {ANALYST} from 'constants/authorType'
import {errorMessageFailure} from "../../Error/redux/actions";

// 2022-12-21 Попытка уменьшить число запросов, берем из хранилища, если авторы там уже есть
function* getAllAuthor({meta: {form, table, callback}}: any) {
    try {
        const state = yield select();
        const authors = (state.valueSets && state.valueSets.get('author')) ? state.valueSets.get('author') : []
        if(  authors.length > 0) {
            console.log('Authors was to get from redux store, no request');
            return;
        }
        const response = yield call(API.getAllAuthor);

        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}

// 2022-12-21 Попытка уменьшить число запросов, берем из хранилища, если авторы там уже есть
// 2022-01-17 Отмена кэширования по непонятной причине иногда отображаются все записи в том числе блокированные,
// разбираться нет времени. возможно есть какая то путаница где все авторы, где только активные

function* getActiveAuthor({meta: {form, table, callback}}: any) {
// 2023-01-23 Эксперементальная функция - динамическое преобразование url на текущий сайт ( имеем ввиду использование reverse proxy на входе)
// на этот момент я скорее предугадываю возможную потребность когда заказчик сделает 2 url внутренний и внешний для своего сайта
    function convertPhotoUrl(response: any) {
        console.log("convertPhotoUrl response=", response, window.location)
        let responseMod: any = []
        response.forEach( (item: any) => {
            const idx = item.photo.indexOf("/",8)
            item.photo = window.location.origin +  item.photo.slice(idx)
            responseMod.push(item)
        })
        return responseMod;
    }

    try {
        // с отключением кэш вылезают блокированные авторы на странице команда, может там проще их фильтровать?

        // const state = yield select();
        // const authors = (state.valueSets && state.valueSets.get('authorType')) ? state.valueSets.get('authorType') : []
        // if(  authors.length > 0) {
        //     console.log('Active Authors was to get from redux store, no request');
        //     return;
        // }

        const responseAll = yield call(API.getAllAuthor);
        // console.log('getActiveAuthor responseAll=',responseAll,table, form)
        let response = responseAll.filter((item : any) => (item.blocked !== true)  )
// эксперементальная функция для транслиции URL
// 2023-10-26 похоже не работает, вернуться позднее!!!
//        response = convertPhotoUrl( response )
        if (callback) {
            console.log("AFTER LOAD AUTHORS response=", response)
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}


function* getAllAnalystRequest({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllAnalyst, ANALYST);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_ALL_AUTHOR_REQUEST, getAllAuthor),
        takeLeading(GET_ALL_ANALYST_REQUEST, getAllAnalystRequest),
        takeLeading(GET_ACTIVE_AUTHOR_REQUEST, getActiveAuthor),
    ]);
}

export default Saga;
