// @ts-nocheck
import React, { ReactNode, ComponentType } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEqual, findKey } from 'lodash';
import { createStructuredSelector } from 'reselect';
import Input from 'arui-feather/input';
import CheckBox from 'arui-feather/checkbox';
import { Field, reduxForm, FieldArray } from 'redux-form';
import { makeSelectLoading } from './selector';
import ValueSet from 'components/ValueSet';
import UploadFile from 'components/InputFile';
import CheckboxesThree from 'components/CheckboxesThree';
import Сommand from 'components/Command';
import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Label from 'arui-feather/label';
import Date from 'arui-feather/calendar-input';
import Accordion from 'components/Accordion';

interface IFlowTeam {
  title?: string;
  config: Array<any>;
  actions: ReactNode;
  normalize: any;
  formData: any;
  loading: boolean;
  reInitialize: any;
  initialize: any;
  hideActions: boolean;
  form: string;
  width?: string;
  filters?: any;
  customClass?: string;
}

class FlowTeam extends React.Component<IFlowTeam> {
  private unblock: any;

  componentDidMount() {
    this.initialize(this.props);
  }

  componentDidUpdate(prevProps: IFlowTeam) {
    this.initialize(prevProps);
  }

  componentWillUnmount() {
    this.initialized = false;
    // @ts-disable
    if (typeof this.unblock === 'function') {
      this.unblock();
    }
  }

  initialized = false;

  fileInit = false;

  initialize(prevProps: IFlowTeam) {
    const { formData, reInitialize, initialize }: IFlowTeam = this.props;
    if (!formData) {
      return null;
    }
    const formDataFormatted = Object.keys(formData).reduce((memo, key) => {
      return {
        ...memo,
        [key]: formData[key],
      };
    }, {});

    if (!this.initialized) {
      this.initialized = true;
      initialize(formDataFormatted);
      this.fileInit = true;
    } else if (reInitialize) {
      if (!isEqual(formData, prevProps.formData)) {
        initialize(formDataFormatted);
        this.fileInit = true;
      }
    }
  }

  makeField =
    (Component: ComponentType) =>
    ({
      input,
      meta,
      children,
      required,
      hasFeedback,
      id,
      style,
      ...rest
    }: any) => {
      return (
        <div style={style}>
          <Component {...input} {...rest}>
            {children}
          </Component>
        </div>
      );
    };

  getField(
    {
      showIf,
      fixedCell,
      name,
      disabled,
      required,
      preview,
      component,
      ...item
    }: any,
    index: number,
  ) {
    const { formValues }: any = this.props;
    const calculated = {
      index,
      name: name || item.id,
      required:
        typeof required === 'function' ? required(formValues) : required,
      disabled,
    };
    // const AImage = this.makeField(Image)
    const AInput = this.makeField(Input);
    const ADate = this.makeField(Date);
    const ACheckbox = this.makeField(CheckBox);

    switch (item.type) {
      case 'whiteSpace':
        return null;
      case 'gridCol':
        return <GridCol width={item.fieldWidth} />;
      case 'command':
        return (
          <FieldArray
            normalizeData
            class={''}
            hasFeedback
            key={index}
            component={Сommand}
            {...item}
            {...calculated}
          />
        );
      case 'calendar':
        return (
          <FieldArray
            hasFeedback
            class={''}
            key={index}
            component={Accordion}
            {...item}
            {...calculated}
          />
        );
      case 'text':
      case 'number':
      case 'email':
      case 'password':
        return (
          <Field
            class={''}
            hasFeedback
            key={index}
            component={AInput}
            {...item}
            {...calculated}
          />
        );
      case 'ValueSet':
        return <ValueSet key={index} {...item} {...calculated} />;
      case 'file':
        return (
          <Field
            class={''}
            hasFeedback
            key={index}
            component={UploadFile}
            {...item}
            {...calculated}
          />
        );
      case 'checkbox':
        return (
          <Field
            hasFeedback
            key={index}
            component={ACheckbox}
            {...item}
            {...calculated}
          />
        );
      case 'date':
        return (
          <Field
            hasFeedback
            key={index}
            component={ADate}
            {...item}
            {...calculated}
          />
        );
      case 'fieldArray':
        return (
          <FieldArray
            key={index}
            component={component}
            {...item}
            {...calculated}
          />
        );
      case 'checkboxesTree':
        return (
          <FieldArray
            hasFeedback
            key={index}
            component={CheckboxesThree}
            {...item}
            {...calculated}
          />
        );
      default:
        return null;
    }
  }

  getCell({ columns, bigLabel = true, ...item }: any, index: number) {
    const { label } = item;
    const customPosition = item.customPosition
      ? item.customPosition
      : { label: 3, field: 8, className: '' };
    return (
      <div key={index} style={{ marginBottom: '30px' }}>
        {bigLabel ? (
          <GridRow gutter={{ desktop: 12 }}>
            {customPosition.label >= 1 ? (
              <GridCol width={customPosition.label} align="top">
                <Label size="m" isNoWrap={true}>
                  {label}
                </Label>
              </GridCol>
            ) : (
              ''
            )}
            <GridCol
              className={customPosition.className}
              width={customPosition.field}
            >
              {this.getField(item, index)}
            </GridCol>
          </GridRow>
        ) : (
          <GridRow gutter={{ desktop: 12 }}>
            <GridCol width={12}>{this.getField(item, index)}</GridCol>
          </GridRow>
        )}
      </div>
    );
  }

  getCellGroup({ columns, items, label, ...item }: any, index: number) {
    const customPosition = item.customPosition
      ? item.customPosition
      : { label: 3, field: 8, className: '' };
    return (
      <div key={index} style={{ marginBottom: '30px' }}>
        <GridRow gutter={{ desktop: 12 }}>
          {customPosition.label >= 1 ? (
            <GridCol width={customPosition.label} align="top">
              <Label size="m" isNoWrap={true}>
                {label}
              </Label>
            </GridCol>
          ) : (
            ''
          )}
          <GridCol
            className={customPosition.className}
            width={customPosition.field}
          >
            {items.map((item: any, index: number) =>
              this.getField(item, index),
            )}
          </GridCol>
        </GridRow>
      </div>
    );
  }

  handleSubmit = (e: any) => {
    e.stopPropagation();
    // @ts-ignore
    this.props.handleSubmit(e);
  };

  render() {
    const { config, actions, hideActions, width, customClass } = this.props;

    const isFieldWidth =
      config && config.length && config.some((item) => item.fieldWidth);

    return (
      <form onSubmit={(e) => this.handleSubmit(e)}>
        {isFieldWidth ? (
          <GridRow>
            {config.map((item: any, index: any) => {
              const isGroup = item && item.isGroup;
              return isGroup ? (
                <GridCol key={index} width={item.fieldWidth}>
                  {this.getCellGroup(item, index)}
                </GridCol>
              ) : (
                <GridCol key={index} width={item.fieldWidth}>
                  {this.getCell(item, index)}
                </GridCol>
              );
            })}
          </GridRow>
        ) : (
          config.map((item: any, index: any) => {
            const isGroup = item && item.isGroup;
            return isGroup
              ? this.getCellGroup(item, index)
              : this.getCell(item, index);
          })
        )}
        {!hideActions && actions}
      </form>
    );
  }
}

const scrollToFirstError = (errors: any) => {
  const res = findKey(
    errors,
    (key, item) => key && document.querySelector(`[name=${item}]`),
  );
  if (res) {
    // @ts-ignore
    document.querySelector(`[name=${res}]`).scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
  }
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
});

const withConnect = connect(mapStateToProps, (state: any, props: any) => ({
  form: props.form,
  validate: (values: any) => props.validate(values.toJS(), props.type),
  onSubmit: (values: any) => props.onSubmit(values.toJS()),
}));

export default compose<any>(
  reduxForm({
    onSubmitFail: scrollToFirstError,
    // destroyOnUnmount: false,
    // keepDirtyOnReinitialize: true,
    // enableReinitialize: true
  }),
  withRouter,
  withConnect,
)(FlowTeam);
