import React from 'react';
import { connect } from 'react-redux';
import UserCartItem from '../UserCartItem';
import { analysts } from '../../temp_data';
import Plate from 'arui-feather/plate';

import cn from 'bem-cn';
import { withRouter } from 'react-router';
const searchDetail = cn('search-detail');

class AuthorResultSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (collection, item) => {
    const { history } = this.props;
    switch (collection) {
      case 'companies':
        history.push(`/companies/about/${item.id}`);
        break;
      case 'ourTeam':
        history.push(`/analyst-profile/${item.id}`);
        break;
      default:
        return null;
    }
  };

  render() {
    const { user } = this.props;
    return (
      (user && (
        <Plate onClick={() => this.handleClick('ourTeam', user)} isFlat={true} view="filled" className={searchDetail()}>
          <div className={searchDetail('content')}>
            <div className={searchDetail('content', 'analysts')}>
              <UserCartItem user={user} type="analyst-group" isGroup={true} />
            </div>
          </div>
        </Plate>
      )) ||
      null
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(connect(mapStateToProps, {})(AuthorResultSearch));
