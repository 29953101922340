import React from 'react';
import PropTypes from 'prop-types';

import cn from 'bem-cn';

import './UserCartItem.scss';
import Link from 'arui-feather/link/link';

const userCart = cn('user-cart');
const userInfo = cn('user-info');

class UserCartItem extends React.Component {
  static propTypes = {
    type: PropTypes.oneOf(['management', 'analyst', 'analyst-group']),
    hoverBorder: PropTypes.bool,
    user: PropTypes.shape({
      // 2921-09-14
      //          id: PropTypes.number,
      fullName: PropTypes.string,
      post: PropTypes.string,
      department: PropTypes.string,
      photo: PropTypes.string,
      urlPage: PropTypes.string,
    }),
  };

  static defaultProps = {
    type: 'management',
    user: {},
    urlPage: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isShowing: false,
      type: null,
      items: null,
      currentProfile: null,
    };
  }

  render() {
    const { user } = this.props;
    const { type } = this.props;

    let content;
    switch (type) {
      case 'management':
        content = this.renderManagerProfile(user, type);
        break;
      case 'analyst':
        content = (
          <Link url={`${user.urlPage}`}>
            {this.renderAnalystProfile(user, type)}
          </Link>
        );
        break;
      case 'analyst-group':
        content = (
          <Link url={`${user.urlPage}`}>
            {this.renderAnalystGroutProfile(user, type)}
          </Link>
        );
        break;
      default:
        content = '';
        break;
    }

    return <div className={userCart()}>{content}</div>;
  }

  renderAnalystProfile(userData, type = 'analyst') {
    return (
      <div className={userInfo()}>
        <div className={userInfo('photo', { type })}>
          <img
            className="img-fluid"
            src={`${process.env.PUBLIC_URL}${userData.photo}`}
            alt={`${userData.fullName}`}
          />
        </div>

        <div className={userInfo('detail')}>
          {userData.department && (
            <span className="department">{userData.department}</span>
          )}
          {userData.fullName && (
            <span className="fullname">{userData.fullName}</span>
          )}
          {userData.fullName && <span className="post">{userData.post}</span>}
        </div>
      </div>
    );
  }

  renderAnalystGroutProfile(userData, type = 'analyst-group') {
    return (
      <div className={userInfo()}>
        <div className={userInfo('photo', { type })}>
          <img
            className="img-fluid"
            src={`${process.env.PUBLIC_URL}${userData.photo}`}
            alt={`${userData.fullName}`}
          />
        </div>
        <div className={userInfo('detail', { data: 'group' })}>
          {userData.fullName && (
            <span className="fullname">{userData.fullName}</span>
          )}
          <div className={userInfo('detail-group')}>
            {userData.department && (
              <span className="department">{userData.department}</span>
            )}
            {userData.fullName && <span className="post">{userData.post}</span>}
          </div>
        </div>
      </div>
    );
  }

  renderManagerProfile(userData, type = 'management') {
    return (
      <div
        className={userInfo({ border: 'hover' })}
        onClick={this.props.onClick}
      >
        <div className={userInfo('photo', { type })}>
          <img
            className="img-fluid"
            src={`${process.env.PUBLIC_URL}${userData.photo}`}
            alt={`${userData.fullName}`}
          />
        </div>
        <div className={userInfo('detail').mix('valign-top')}>
          {userData.fullName && (
            <span className="fullname">{userData.fullName}</span>
          )}
          {userData.department && (
            <span className="department">{userData.department}</span>
          )}
          {userData.fullName && <span className="post">{userData.post}</span>}
        </div>
      </div>
    );
  }
}

export default UserCartItem;
