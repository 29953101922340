export const errorMessage = error => {
    if (!error || typeof error !== 'object') return {};
    let { response, message = 'Something went wrong' } = error;
    let status;
    let code;
    let fieldName;

    if (response) {
        if (response.status) {
            // eslint-disable-next-line
            status = response.status;
            switch (response.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = 'Something went wrong';
            }
        }

        if (response.data) {
            if (response.data.debugMessage) {
                // eslint-disable-next-line
                message = response.data.debugMessage;
            }
            if (response.status) {
                // eslint-disable-next-line
                code = response.status;
            }
            if (response.data.field_name) {
                fieldName = response.data.field_name;
            }
        }
    }

    return { message, status, code, fieldName };
};
