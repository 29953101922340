import React from 'react';
import {isArray, isEmpty} from 'lodash';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import Announcements from 'components/Announcements';

class FlowForestCast extends React.Component<any, any> {
  render() {
    const {
      normalizeTableData,
      data,
      type,
      actions,
      valuesModal,
      customClass,
    } = this.props;
    const array = normalizeTableData(data, valuesModal);
    return (
      <>
        {isArray(array) && !isEmpty(array) && (
          <Announcements
            customClass={customClass ? customClass : ''}
            actions={actions}
            type={type}
            items={array}
            modalView="product"
            withModal={true}
            language={'en'}
          />
        )}
      </>
    );
  }
}

export default compose<any>(withRouter)(FlowForestCast);
