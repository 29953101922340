import { put, takeLeading, takeLatest, select } from 'redux-saga/effects';

import { LOGOUT_FAILURE } from 'modules/Auth/redux/constants';

// actions
import { logoutSuccess } from 'modules/Auth/redux/actions';
import { openErrorMessage } from 'components/Message/actions';
import { errorMessage } from 'components/Errors/handleErrors';
import { makeSelectToken } from 'app/selectors';


function* errorHandler({ type, payload, customMessage }) {

//    console.log('customMessage=', customMessage, payload);
    const error = payload && payload.error;

    if (!error) return;
    const { message, code, debugMessage } = errorMessage(error);
//    console.log('customMessage2 message=', message);

    if (message === 'Invalid credentials') {
        yield put(logoutSuccess());
    }

    switch (code) {
    case 'SessionExpired':
    case 'Unauthorized':
    case 'InvalidSession':
    case 'TokenNotFound': {
        // case 'ObjectDeleted':
        // case 'AccessForbidden':
        const token = yield select(makeSelectToken());
        if (!token) break; // reset token only once
        yield put(logoutSuccess());
        break;
    }
    case 'FailedToParseQuery': {
        yield put(openErrorMessage('Wrong search request'));
        return null;
    }
    default:
        yield put(openErrorMessage(customMessage || debugMessage || message));
        break;
    }
}

function* Saga() {
    yield takeLeading(action => /_FAILURE$/.test(action.type), errorHandler);
}

export default Saga;
