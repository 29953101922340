// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import {
  ICategoryTable,
  IHeadTable,
  IBodyTable,
  ItemNormalize,
} from 'components/Flows/Table/interface';
import { COMMODITIES_TABLE, COMMODITIES_TABLE_HOME } from 'constants/tables';
import {
  getCommoditiesSortRequest,
  getProductByCommoditiesRequest,
} from '../../redux/actions';
import { isArray } from 'lodash';
import { productTypeRequest } from 'modules/ProductType/redux/actions';
import { pdfValidateTypeForHome } from 'components/Icon/Icons';
import { language, getLanguageFromStore } from 'utils/formatters';
import { TABLE_HEAD, TABLE_NAME } from 'constants/languagesTable';
import { getAuthorsForTable } from '../../../../utils/authorsList';
import { LinkMore } from './components/linkMore';
import { AuthorsColumn } from 'components/AuthorsColumn/AuthorsColumn';
import {connect} from "react-redux";

class CommoditiesTable extends React.Component<ICategoryTable> {
  normalizeTableData = (
    head: IHeadTable[],
    body: IBodyTable[],
    valuesTable: any,
  ): any => {
    const { dispatch } = this.props;

    const productType = valuesTable && valuesTable.productType;
    const author = valuesTable && valuesTable.author;
    const sectors = valuesTable && valuesTable.sectors;
    const categories = valuesTable && valuesTable.categories;

    const processedData =
      (productType &&
        productType.length &&
        sectors &&
        sectors.length &&
        author &&
        author.length &&
        productType &&
        productType.length &&
        categories &&
        categories.length &&
        body &&
        body.map((item: any) => {
          const { id }: any = item;

          const sector = sectors
            .filter((o1: any) => {
              return (
                isArray(item.sectors) &&
                item.sectors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((lu: any) => {
              return {
                name: language(lu.name, 'en'),
                id: lu.id,
              };
            });
          const categoriesTitle = categories
            .filter((o1: any) => {
              return o1.id === item.categoryId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join('');

          const strategy = productType
            .filter((o1: any) => {
              return o1.id === item.productTypeId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join('');

          const au = author
            .filter((o1: any) => {
              return (
                isArray(item.authors) &&
                item.authors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((y: any) => {
              return {
                name: language(y.name, 'en'),
                id: y.id,
                photo: y.photo,
                blocked: y.blocked,
              };
            });
          // 2021-08-31 отфильтровать спискок в соотвествии еще  типом документа
          const authorsForTable = getAuthorsForTable(
            item.productTypeNameSort,
            au,
          );
          // 2021-10-05 в модальных окнах только неблокированные авторы
          const auModal = au.filter((o1: ItemNormalize) => o1.blocked !== true);

          return {
            id,
            file: item?.file,
            downloadAvailable: item?.downloadAvailable,
            modal: {
              sectors: sector,
              authors: auModal,
              name: item.name,
              annotation: item.annotation,
              file: item?.file,
              downloadAvailable: item?.downloadAvailable,
              date: item.date,
              id: item.id,
              strategy,
              categoriesTitle,
              // add 2021-11-12 for statistics
              categoryId: item.categoryId,
              productTypeId: item.productTypeId,
              languageId: item.languageId,
              // add 2021-11-17 for statistics
              companies: item.companies,

            },
            cells: [
              {
                element: pdfValidateTypeForHome(item, dispatch),
                id,
                type: 'pdf',
                width: 'xs',
                downloadAvailable: item?.downloadAvailable,
              },
              {
                element: item.name,
                id,
                type: 'name',
                width: 'l',
              },
              {
                element: <AuthorsColumn authorsForTable={authorsForTable} />,
                id,
                type: 'analyst',
                width: 'm',
              },
              {
                element: item.date,
                id,
                type: 'date',
                width: 's',
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: any) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  getRowLink = (id: any) => `/interface-settings/${id}`;

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'pdf',
        label: TABLE_HEAD['report'][getLanguageFromStore()],
        type: 'file',
        style: { width: 'xs' },
      },
      {
        id: 'name',
        label: TABLE_HEAD['name'][getLanguageFromStore()],
        style: { width: 'l' },
        type: 'modal',
        sort: 'default',
      },
      {
        id: 'analyst',
        label: TABLE_HEAD['analyst'][getLanguageFromStore()],
        style: { width: 'm' },
        type: 'modal',
        // 2021-08-04 Новое значение none отключает щелчок по колонке для сортировки
        sort: 'none',
      },
      {
        id: 'date',
        label: TABLE_HEAD['date'][getLanguageFromStore()],
        style: { width: 's' },
        type: 'modal',
      },
    ];

    const graphId = {};
    const actions = {
      productType: productTypeRequest,
    };

    return (
      <div className="graph-table-wrapper">
        <GraphTable
          actions={actions}
          graphId={graphId}
          type="edit"
          caption={TABLE_NAME[COMMODITIES_TABLE][getLanguageFromStore()]}
          sizeTitle="small"
          table={COMMODITIES_TABLE_HOME}
          normalizeTableData={this.normalizeTableData}
          sortRequest={getCommoditiesSortRequest}
          init={getProductByCommoditiesRequest}
          onRowClick={handleRowClick}
          head={head}
          link={this.getRowLink}
          width="12"
          gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        />
        <LinkMore
          title={TABLE_NAME[`${COMMODITIES_TABLE}_ALL`][getLanguageFromStore()]}
          url="/commodities"
        />
      </div>
    );
  }
}

//export default CommoditiesTable;
export default connect()(CommoditiesTable);
