import { createSelector } from 'reselect';

const selectGraphFormState = (state: any) => state.graphForm;

export const selectCurrentForm = createSelector(
  [
      selectGraphFormState,
      (state: any, ownProps: any) => ownProps.form,
  ],
  (graphForm, form) => graphForm.get(form),
);

export const makeSelectLoading = () => createSelector(
  selectCurrentForm,
  state => state && state.get('loading'),
);