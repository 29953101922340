import OurTeam from './pages';


export default [
  {
    type: 'private',
    exact: true,
    path: '/our-team',
    roles: ['admin'],
    component: OurTeam,
  },
];
