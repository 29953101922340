// @TODO: remove ts-nocheck!!
// @ts-nocheck

import { fromJS } from 'immutable';
import {
  FORM_DATA_SUCCESS,
  FORM_INITIALIZE,
  FORM_RESET,
  FORM_DATA_FAILURE,
  FORM_DATA_REQUEST,
  FORM_EDIT_REQUEST,
  FORM_EDIT_SUCCESS,
  FORM_EDIT_FAILURE,
} from './constants';
import { IAction } from 'types/index';
const initialState: any = fromJS({});

const reducer = (
  state = initialState,
  { type, meta, payload }: IAction<void>,
) => {
  const form = meta && meta.form;
  const response = payload && payload.response;

  switch (type) {
    case FORM_EDIT_REQUEST:
    case FORM_DATA_REQUEST: {
      return state
        .setIn([form, 'data'], null)
        .setIn([form, 'loading'], true)
        .setIn([form, 'initialized'], false)
        .setIn([form, 'error'], null);
    }

    case FORM_EDIT_SUCCESS:
    case FORM_DATA_SUCCESS: {
      return state
        .setIn([form, 'data'], response)
        .setIn([form, 'loading'], false)
        .setIn([form, 'initialized'], false)
        .setIn([form, 'error'], null);
    }

    case FORM_EDIT_FAILURE:
    case FORM_DATA_FAILURE: {
      return state
        .setIn([form, 'request'], false)
        .setIn([form, 'loading'], false)
        .setIn([form, 'failureData'], payload)
        .setIn([form, 'error'], payload);
    }

    case FORM_INITIALIZE: {
      return state.mergeIn([form, 'initialData'], payload.data);
    }

    case '@@redux-form/INITIALIZE': {
      // todo change to FORM_INITIALIZE event
      return state.setIn([form, 'initialized'], true);
    }

    case FORM_RESET: {
      return state.set(form, initialState);
    }

    default:
      return state;
  }
};

export default reducer;
