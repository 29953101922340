import React from 'react';

class SearchRightBlock extends React.Component {
  state = {
    productId: null,
    isShowing: false,
  }

  render() {
    const { data = [], modalHandler } = this.props;
    return (
      <>
        <ul className="result-list for-right-side">
          {data &&
          data?.products?.map((item) => (
            <li onClick={() => modalHandler(item)}>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
              <span>{item?.date ? item?.date.split(' ')?.[0] : item?.date}</span>
            </li>
          ))}
        </ul>
      </>
    );
  }
}

export default SearchRightBlock;
