// @ts-nocheck
import { all, takeEvery,takeLeading, call, put } from 'redux-saga/effects';
import { change } from 'redux-form/immutable';
import API_FILES from './api';
import { IMAGE_UPLOAD_REQUEST} from './constants';

function* uploadImage({ payload: { file, id, form } }: any) {
  try {
    const data = yield call(API_FILES.UploadImage, file);
    yield put(change(form, id, data));
  } catch (e) {
    console.log('upload error', e);
  }
}


function* Saga() {
  yield all([takeLeading(IMAGE_UPLOAD_REQUEST, uploadImage)]);
}

export default Saga;
