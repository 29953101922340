// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import EconomicsFilter from './filter';
import EconomicsTable from './table';
import EconomicsProductNews from './productNews';
import EconomicsEventsNews from './eventNews';
import ColTree from 'components/ColTree';
import {Heading} from 'arui-feather/heading';
import {useResolution} from 'hooks/useResolution';
import {ECONOMICS_TABLE} from 'constants/tables';
import {getLanguageFromStore} from 'utils/formatters';
import GridCol from 'arui-feather/grid-col';
import {TABLE_NAME} from 'constants/languagesTable';
import {FIELD_NAME, GRAPH_OFZ} from 'constants/languagesFields';
import ForecastFiles from "../../_Common/forecastFiles";
import ChartOFZ from "../../_Common/chartOFZ";
//import EconomicChart from "../chart/economicChart";

const page = ["Economic", "Экономика"];

const Economics = () => {
    const {isMobile} = useResolution();

    return (
        <div className="custom-page-wrapper economics-page">
            {isMobile && <EconomicsEventsNews/>}
            <GridRow
                gutter={{mobile: 0, tablet: 16, desktop: {m: 12}}}
                className="custom-margin-bottom"
            >
                <GridCol>
                    <Heading size="xl" className="custom-page-title">
                        {TABLE_NAME[ECONOMICS_TABLE][getLanguageFromStore()]}
                    </Heading>
                </GridCol>
            </GridRow>
            <GridRow
                gutter={{mobile: 0, tablet: 16, desktop: {m: 12}}}
                className="flex_no-wrap"
            >
                <ColTree className="custom-column-section custom-filter-section">
                    {isMobile && <ForecastFiles category={page}/>}
                    <EconomicsFilter/>
                    {!isMobile && <ForecastFiles category={page}/>}
                </ColTree>
                <EconomicsTable showTitle={false}/>
                <ColTree className="custom-column-section">
                    {isMobile && (
                        <Heading size="l">
                            {FIELD_NAME['actual'][getLanguageFromStore()]}
                        </Heading>
                    )}
                    <ChartOFZ dataTag={'ofz2'} title={{en: 'OFZ #2', ru: 'ОФЗ #1'}}
                              titleX={GRAPH_OFZ['titleX'][getLanguageFromStore()]}
                              titleY={GRAPH_OFZ['titleY'][getLanguageFromStore()]}
                    />
                    <EconomicsProductNews/>
                    {!isMobile && <EconomicsEventsNews/>}
                </ColTree>
            </GridRow>
        </div>
    );
};

export default Economics;
