import { all, takeEvery, takeLeading, takeLatest, put } from 'redux-saga/effects';
import { formCreateSuccess, formDataSuccess, formEditSuccess } from './actions';
import {
  FORM_CREATE_REQUEST,
  FORM_DATA_REQUEST,
  FORM_EDIT_REQUEST,
  FORM_CREATE_SUCCESS,
  FORM_EDIT_SUCCESS,
} from './constants';
import {IAction} from 'types/index'

function* get({ payload: { init, data }, meta: { form } }: IAction<void> ) {
  yield put(init({ payload: { callback: formDataSuccess, data }, meta: { form } }))
}

function* edit({ payload: { edit, fields }, meta: { form, onSuccess } }: IAction<void>) {
  yield put(edit({ payload: { callback: formEditSuccess, fields }, meta: { form, onSuccess } }))
}

function* create({ payload: { create, fields }, meta: { form, onSuccess } }: IAction<void>) {
  yield put(create({ payload: { callback: formCreateSuccess, fields }, meta: { form, onSuccess } }))
}

function* createSuccess({ payload: { response }, meta: { form, onSuccess } }: any) {
  onSuccess({ payload: { response }, meta: { form, onSuccess } })
}


function* editSuccess({ payload: { response }, meta: { form, onSuccess } }: any) {
  onSuccess({ payload: { response }, meta: { form, onSuccess } })
}

function* Saga() {
  yield all([
    takeLeading(FORM_DATA_REQUEST, get),
    takeLeading(FORM_EDIT_REQUEST, edit),
    takeLeading(FORM_CREATE_REQUEST, create),
    takeLeading(FORM_CREATE_SUCCESS, createSuccess),
    takeLeading(FORM_EDIT_SUCCESS, editSuccess)
  ]);
}

export default Saga;
