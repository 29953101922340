// @ts-nocheck
import {call, put, takeEvery,takeLeading, all, select} from 'redux-saga/effects';
import API from '../api';
import {
    DOWNLOAD_LOGGING,
    SHOW_PRODUCT_LOGGING,
    AUTH_LOGGING
} from './constants';
import {errorMessageFailure} from '../../Error/redux/actions';

function* addDownloadAction({payload}: any) {
    try {
        console.log('addDownloadActiont payload=', payload)
        const response = yield call(API.addDownloadStatistics, payload);
        console.log('addDownloadActiont response=', response)
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

function* addShowProductAction({payload}: any) {
    try {
        console.log('addShowProductAction payload=', payload)
        const response = yield call(API.addShowProductStatistics, payload);
        console.log('addShowProductAction response=', response)
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

function* addAuthorisationAction({payload}: any) {
    try {
        console.log('addAuthorisationAction payload=', payload)
        const response = yield call(API.addAuthorisationStatistics, payload);
        console.log('addAuthorisationAction response=', response)
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}


function* Saga() {
    yield all([
        takeLeading(DOWNLOAD_LOGGING, addDownloadAction),
        takeLeading(SHOW_PRODUCT_LOGGING, addShowProductAction),
        takeLeading(AUTH_LOGGING, addAuthorisationAction),
    ]);
}

export default Saga;
