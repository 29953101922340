// 2021-09-04 добавлены заказные сообщения на 2-х языках
const normalize = (text, language = 'en') => {
    if (!text) return null;
//    if (typeof text !== 'string') return 'Something went wrong';
    if (typeof text === 'object') {
        return text[language];
    }
    else {
        if (typeof text !== 'string') return 'Something went wrong';
        return text.includes('UniqueConstraintViolatedError')
            ? text.replace('UniqueConstraintViolatedError: ', '')
            : text;
    }
};

export default normalize;
