interface IValuesProps {
  login: string,
  password: string,
}

const validate = (values: IValuesProps, type: string) => {
  const errors = {
    email: '', lastName: '',
  };

  if (!values.login) {
    errors.email = 'Введите почту';
  }

  if (!values.password) {
    errors.lastName = 'Введите фамилию'
  }

  return errors
};

export default validate;