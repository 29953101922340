import * as ACTION_TYPE from './constants';



export const getCommoditiesSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_COMMODITIES_SORT_REQUEST,
    meta,
    payload
});



export const getAllCommoditiesRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_ALL_COMMODITIES_REQUEST,
    meta,
    payload
});
