// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { imageUploadRequest, } from './actions';
import GridCol from 'arui-feather/grid-col';
import Attach from 'arui-feather/attach';
import GridRow from 'arui-feather/grid-row';

class FileInput extends React.Component {

  uploadFile = (file: any) => {
    const { dispatch, id, typeFile, meta: { form } }: any = this.props;
    switch (typeFile) {
      case 'img':
        dispatch(imageUploadRequest({ file, id, form }));
        break;
      default:
        return null;
    }

  };

  renderInput = (accept: string, value: string, typeFile: string) => {
    switch (typeFile) {
      case 'img':
        return (<GridRow>
          <GridCol width='8'>
            <Attach
              accept={accept}
              onChange={this.uploadFile}
              noFileText='File is empty'
              buttonContent='Upload file'
              size='s'
            />
          </GridCol>
          <GridCol width='4' className='admin-image'>
            <img src={value}/>
          </GridCol>
        </GridRow>);
        break;
      default:
        return null;
    }
  };


  render() {
    const { accept, input, typeFile }: any = this.props;
    const value = input?.value;
    return this.renderInput(accept, value, typeFile);
  }
}

export default connect()(FileInput);