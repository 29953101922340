import React from 'react';
import Link from 'arui-feather/link/link';
import logoDesktop from '../../assets/logo/logo_site_desktop.svg';
import logoMobile from '../../assets/logo/logo_site_mobile.svg';
import { useResolution } from '../../hooks/useResolution';

export const SiteLogo = () => {
  const { isMobile } = useResolution();
  return (
    <div className="header__logo">
      <Link url="/">
        <img src={isMobile ? logoMobile : logoDesktop} alt="logo" />
      </Link>
    </div>
  );
};
