import React from 'react';
import {
    getAllAuthorTypesRequest
} from 'modules/AuthorType/redux/actions';
import {getAllAuthorRequest, getActiveAuthorRequest} from 'modules/Author/redux/actions';

export default [
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "command",
        name: 'sector',
        mode: "many",
        bigLabel: false,
        id: 'author',
        label: 'Author',
        normalize: 'author',
//        actions: {author: getAllAuthorRequest, authorTypes: getAllAuthorTypesRequest},
        actions: {author: getActiveAuthorRequest, authorTypes: getAllAuthorTypesRequest},
    },
];
