// @ts-nocheck
import React  from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { setValueSet } from '../ValueSet/actions';
import { createStructuredSelector } from 'reselect';
import { selectValueSet, selectAllValueSets } from '../ValueSet/selector';





class Hidden extends React.Component<any, any> {

  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }


  render() {
    return (
     <></>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Hidden);
