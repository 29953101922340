export const dataMenu = [
  {
    id: 0,
    label: 'Equity',
    label_ru: 'Рынок акций',
    url: '/equity'
  },
  {
    id: 1,
    label: 'Economics',
    label_ru: 'Экономика',
    url: '/economics'
  },
  {
    id: 2,
    label: 'Fixed Income',
    label_ru: 'Облигации',
    url: '/fixed-income'
  },
  {
    id: 3,
    label: 'Commodities',
    label_ru: 'Сырье',
    url: '/commodities'
  },
  {
    id: 4,
    label: 'Companies',
    label_ru: 'Компании',
    url: '/companies'
  },
  {
    id: 5,
    label: 'Our Team',
    label_ru: 'Команда',
    url: '/our-team'
  },
  {
    id: 6,
    label: 'Calendar',
    label_ru: 'Календарь',
    url: '/calendar'
  }
];

export const productsData = [
  {
    id: 1,
    category: 'Strategy',
    name: 'ALFA Weekly Radar',
    annotation: 'Waiting for fresh catalysts',
    date: '28.10.2019'
  },
  {
    id: 0,
    category: 'Strategy',
    name: 'ALFA Weekly Radar',
    annotation: 'Cruising to new highs',
    date: '28.10.2019'
  },
  {
    id: 3,
    category: 'Strategy',
    name: 'ALFA Weekly Radar',
    annotation: 'Markets expected to assume holding pattern with no major near-term catalysts',
    date: '25.10.2019'
  }
];

export const chartsData = [
  {
    id: 0,
    title: 'Leaders&Laggards 1D*',
    image: '/assets/images/chart-1.png',
  },
  {
    id: 1,
    title: 'Sector 1D perfomance*',
    image: '/assets/images/chart-2.png',
  }
];

export const eventsData = [
  {
    id: 1,
    name: 'Norilsk Nickel',
    comment: 'Capital Markets Day',
    date: '18.11.2019'
  },
  {
    id: 2,
    name: 'Rusagro',
    comment: '3Q19 IFRS',
    date: '18.11.2019'
  },
  {
    id: 3,
    name: 'Russia',
    comment: 'PPI MoM, %',
    date: '19.11.2019'
  }
];

export const equityResearchItems = [
  {
    id: 1,
    name: 'ALFA Weekly Radar: Waiting for fresh catalysts',
    type: 'Strategy',
    analyst: 'Petroschenkov Konstantin',
    date: '12.11.2019',
    company: 'Ferrexpo'
  },
  {
    id: 2,
    name: 'ALFA Morning Bulletin, 11 November, 2019',
    type: 'Daily',
    analyst: 'Petroschenkov Konstantin',
    date: '11.11.2019',
    company: 'LUKOIL'
  },
  {
    id: 3,
    name: 'Alfa-Bank Daily_November_11',
    type: 'Daily',
    analyst: 'Petroschenkov Konstantin',
    date: '07.11.2019',
    company: 'Severstal'
  },
  {
    id: 4,
    name: 'ALFA Morning Bulletin, 7 November, 2019',
    type: 'Daily',
    analyst: 'Petroschenkov Konstantin',
    date: '05.11.2019',
    company: 'NLMK'
  },
  {
    id: 5,
    name: 'ALFA – NOVATEK (E/W); Quarterly Model Update',
    type: 'Company report',
    analyst: 'Petroschenkov Konstantin',
    date: '04.11.2019',
    company: 'TATNEFT'
  },
  {
    id: 6,
    name: 'ALFA – Macro Insights: Russia’s growth outlook – Staying with below consensus forecast for 2020',
    type: 'Strategy',
    analyst: 'Petroschenkov Konstantin',
    date: '28.10.2019',
    company: 'Severstal'
  },
  {
    id: 7,
    name: 'ALFA Weekly Radar: Fed up – Powell expected to cuts rates this week, but what next?',
    type: 'Strategy',
    analyst: 'Petroschenkov Konstantin',
    date: '26.10.2019',
    company: 'NLMK'
  },
  {
    id: 8,
    name: 'Alfa-Bank Daily_October_25',
    type: 'Daily',
    analyst: 'Dorofeev Denis',
    date: '25.10.2019',
    company: 'Severstal'
  },
  {
    id: 9,
    name: 'ALFA Weekly Radar: Waiting for fresh catalysts',
    type: 'Strategy',
    analyst: 'Dorofeev Denis',
    date: '25.10.2019',
    company: 'Severstal'
  }
];

export const economics = [
  {
    id: 1,
    name: 'ALFA Weekly Radar: Waiting for fresh catalysts',
    analyst: 'Petroschenkov Konstantin',
    date: '12.11.2019'
  },
  {
    id: 2,
    name: 'ALFA Morning Bulletin, 11 November, 2019',
    analyst: 'Petroschenkov Konstantin',
    date: '11.11.2019'
  },
  {
    id: 3,
    name: 'Alfa-Bank Daily_November_11',
    analyst: 'Petroschenkov Konstantin',
    date: '07.11.2019'
  },
  {
    id: 4,
    name: 'ALFA Morning Bulletin, 7 November, 2019',
    analyst: 'Petroschenkov Konstantin',
    date: '05.11.2019'
  },
  {
    id: 5,
    name: 'ALFA – NOVATEK (E/W); Quarterly Model Update',
    analyst: 'Petroschenkov Konstantin',
    date: '04.11.2019'
  },
  {
    id: 6,
    name: 'ALFA – Macro Insights: Russia’s growth outlook – Staying with below consensus forecast for 2020	',
    analyst: 'Petroschenkov Konstantin',
    date: '31.10.2019'
  },
  {
    id: 7,
    name: 'ALFA Weekly Radar: Cruising to new highs',
    analyst: 'Petroschenkov Konstantin',
    date: '28.10.2019'
  },
];

export const fixedIncome = [
  {
    id: 1,
    name: 'ALFA Weekly Radar: Waiting for fresh catalysts',
    type: 'Strategy',
    analyst: 'Petroschenkov Konstantin',
    date: '12.11.2019'
  },
  {
    id: 2,
    name: 'ALFA Morning Bulletin, 11 November, 2019',
    type: 'Daily',
    analyst: 'Petroschenkov Konstantin',
    date: '11.11.2019'
  },
  {
    id: 3,
    name: 'Alfa-Bank Daily_November_11',
    type: 'Daily',
    analyst: 'Petroschenkov Konstantin',
    date: '07.11.2019'
  },
  {
    id: 4,
    name: 'ALFA Morning Bulletin, 7 November, 2019',
    type: 'Daily',
    analyst: 'Petroschenkov Konstantin',
    date: '05.11.2019'
  },
  {
    id: 5,
    name: 'ALFA – NOVATEK (E/W); Quarterly Model Update',
    type: 'Company report',
    analyst: 'Petroschenkov Konstantin',
    date: '04.11.2019'
  },
  {
    id: 6,
    name: 'ALFA – Macro Insights: Russia’s growth outlook – Staying with below consensus forecast for 2020',
    type: 'Sector Report',
    analyst: 'Petroschenkov Konstantin',
    date: '31.10.2019'
  },
  {
    id: 7,
    name: 'ALFA Weekly Radar: Cruising to new highs',
    type: 'Fixed Income',
    analyst: 'Petroschenkov Konstantin',
    date: '28.10.2019'
  },
  {
    id: 8,
    name: 'ALFA Weekly Radar: Fed up – Powell expected to cuts rates this week, but what next?',
    type: 'Blast Note',
    analyst: 'Petroschenkov Konstantin',
    date: '28.10.2019'
  },
  {
    id: 9,
    name: 'Alfa-Bank Daily_October_25',
    type: 'Daily',
    analyst: 'Petroschenkov Konstantin',
    date: '28.10.2019'
  },
  {
    id: 10,
    name: 'ALFA Weekly Radar: Markets expected to assume holding pattern with no major near-term catalysts',
    type: 'Strategy',
    analyst: 'Petroschenkov Konstantin',
    date: '28.10.2019'
  },
];

export const commodities = [
  {
    id: 1,
    name: 'ALFA Weekly Radar: Waiting for fresh catalysts',
    analyst: 'Petroschenkov Konstantin',
    date: '12.11.2019'
  },
  {
    id: 2,
    name: 'ALFA Morning Bulletin, 11 November, 2019',
    analyst: 'Petroschenkov Konstantin',
    date: '11.11.2019'
  },
  {
    id: 3,
    name: 'Alfa-Bank Daily_November_11',
    analyst: 'Petroschenkov Konstantin',
    date: '07.11.2019'
  },
  {
    id: 4,
    name: 'ALFA Morning Bulletin, 7 November, 2019',
    analyst: 'Petroschenkov Konstantin',
    date: '05.11.2019'
  },
  {
    id: 5,
    name: 'ALFA – NOVATEK (E/W); Quarterly Model Update',
    analyst: 'Petroschenkov Konstantin',
    date: '04.11.2019'
  },
  {
    id: 6,
    name: 'ALFA – Macro Insights: Russia’s growth outlook – Staying with below consensus forecast for 2020	',
    analyst: 'Petroschenkov Konstantin',
    date: '31.10.2019'
  },
  {
    id: 7,
    name: 'ALFA Weekly Radar: Cruising to new highs',
    analyst: 'Petroschenkov Konstantin',
    date: '28.10.2019'
  }
];

export const futureEvents = [
  {
    id: 1,
    date: '13.11.2019',
    event_type: 'Macro',
    event_name: 'GDP YoY, %',
    company: '',
    country: 'Russia'
  },
  {
    id: 2,
    date: '14.11.2019',
    event_type: 'Corporate',
    event_name: '3Q19 IFRS',
    company: 'Megafon',
    country: 'Russia'
  },
  {
    id: 3,
    date: '20.11.2019',
    event_type: 'Macro',
    event_name: 'Construction YoY, %',
    company: '',
    country: 'Russia'
  },
  {
    id: 4,
    date: '30.11.2019 - 01.12.2019',
    event_type: 'Macro',
    event_name: 'Manufacturing PMI  ',
    company: '',
    country: 'Russia'
  },
];

export const companies = [
  {
    id: 1,
    company: 'Gazprom neft',
    equity_ticker : 'GAZP RX',
    target_price: 'RUB 228,54',
    recommendation: ' E/W',
    sector: 'Oil&Gas',
    analyst: 'Petroschenkov Konstantin'
  },
  {
    id: 2,
    company: 'LUKOIL',
    equity_ticker : 'LKOH RX',
    target_price: 'RUB 5768',
    recommendation: ' E/W',
    sector: 'Oil&Gas',
    analyst: 'Petroschenkov Konstantin'
  }
];

/**
 * Filters
 */

export const filterSectorCompany = [
  {
    parent: 'Oil & Gas',
    collapsed: true,
    select: false,
    children: [
      { value: 'Gazprom' },
      { value: 'Gazprom neft' },
      { value: 'NOVATEK' },
      { value: 'Rosneft' },
      { value: 'Surgutneftegas' },
      { value: 'TATNEFT' },
    ],
  },
  {
    parent: 'Consumer Goods & Retail',
    collapsed: false,
    select: false,
    children: [
      { value: 'Item 1' },
      { value: 'Item 2' },
      { value: 'Item 3' },
      { value: 'Item 4' },
      { value: 'Item 5' },
      { value: 'Item 6' }
    ],
  },
  {
    parent: 'Agriculture',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Agriculture ',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'IT/Media',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Metals&Mining',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Financials',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Real estate',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Transport',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Fertilizers',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Telecommunication',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Utilities',
    collapsed: false,
    select: false,
    children: [],
  },
];

export const filterProductType = [
  {
    parent: 'Daily',
  },
  {
    parent: 'Strategy',
  },
  {
    parent: 'Company Report',
  },
  {
    parent: 'Sector report',
  },
  {
    parent: 'Economy',
  },
  {
    parent: 'Comodities',
  },
  {
    parent: 'Blast Note',
  }
];

export const filterAnalyst = [
  {
    parent: 'Petroschenkov Konstantin',
  },
  {
    parent: 'Dorofeev Denis',
  },
  {
    parent: 'Anna Kurbatova',
  }
];

export const filterAnalystSelect = [
  {
    parent: 'Petroschenkov Konstantin',
    collapsed: true,
  },
  {
    parent: 'Dorofeev Denis',
    collapsed: true,
  },
  {
    parent: 'Anna Kurbatova',
    collapsed: false,
  }
];

export const filterCommodity = [
  {
    parent: 'Oil',
    collapsed: true,
  },
  {
    parent: 'Gas',
  }
];

export const filterCommoditySearch = [
  {
    parent: 'Oil',
  },
  {
    parent: 'Gas',
  }
];

export const filterSector = [
  {
    parent: 'Oil & Gas',
    collapsed: true,
    select: false,
    parentMode: 'one',
  },
  {
    parent: 'Consumer Goods & Retail',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Agriculture',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Agriculture ',
    collapsed: false,
    select: false,
  },
  {
    parent: 'IT/Media',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Metals&Mining',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Financials',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Real estate',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Transport',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Fertilizers',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Telecommunication',
    collapsed: false,
    select: false,
  },
  {
    parent: 'Utilities',
    collapsed: false,
    select: false,
  },
];

export const filterPeriod = [
  {
    parent: 'Future events',
    collapsed: true,
  },
  {
    parent: 'Last events'
  }
];

export const filterDefault = [
  {
    parent: 'Default item 1'
  },
  {
    parent: 'Default item 2'
  }
];

export const filterEventType = [
  {
    parent: 'Corporate',
    collapsed: true,
  },
  {
    parent: 'Dividends',
  },
  {
    parent: 'Macro'
  }
];

export const filterCategory = [
  {
    parent: 'Equity'
  },
  {
    parent: 'Economics'
  },
  {
    parent: 'Fixed Income'
  },
  {
    parent: 'Commodities'
  },
  {
    parent: 'Companies'
  },
  {
    parent: 'Our Team'
  },
  {
    parent: 'Calendar'
  }
];

/**
 * Employees
 */
export const managements = [
  {
    id: 1,
    fullName: 'Mikhail Grachev',
    department: 'Head of Capital Markets',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/Grachev.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 2,
    fullName: 'Mikhail Grachev',
    department: 'Head of Capital Markets',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/Grachev.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 3,
    fullName: 'Mikhail Grachev',
    department: 'Head of Capital Markets',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/Grachev.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 4,
    fullName: 'Boris Krasnozhenov',
    department: 'Head of Equities',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/Boris_Krasnozhenov.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 5,
    fullName: 'Boris Krasnozhenov',
    department: 'Head of Research',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/Boris_Krasnozhenov.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 6,
    fullName: 'Boris Krasnozhenov',
    department: 'Head of Research',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/Boris_Krasnozhenov.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 7,
    fullName: 'Konstantin Shapsharov',
    department: 'Head of Equities',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/shapsharov.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 8,
    fullName: 'Konstantin Shapsharov',
    department: 'Head of Equities',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/shapsharov.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
  {
    id: 9,
    fullName: 'Konstantin Shapsharov',
    department: 'Head of Equities',
    email: 'MGrachev@alfabank',
    photo: '/assets/images/shapsharov.png',
    phones: ['+7 (495) 785-7404', '+7 (495) 783-5104'],
    addres: ['12. Akad Sakharova prospect', 'Moscow Russia 107078'],
    comment: 'Biography. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus aliquet mi a quam rhoncus, et posuere nisl hendrerit. Cras tempor id justo a rutrum. Vivamus tincidunt, tortor ut efficitur rutrum, sapien ligula vehicula enim, at facilisis quam metus quis eros. Maecenas tempor hendrerit odio ac auctor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitur tincidunt, tortor in tempor fermentum, massa lacus porta odio, ut laoreet enim magna sit amet dui. Nulla diam erat, blandit eget risus tristique, elementum malesuada elit.'
  },
];


export const analysts = [
  {
    id: 1,
    fullName: 'Anna Kurbatova',
    department: 'Senior Analyst, TMT & Agro',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Anna_Kurbatova.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 2,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 3,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 4,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 5,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 6,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 7,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 8,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },
  {
    id: 9,
    fullName: 'Denis Dorofeev',
    department: 'Data Specialist / Analyst',
    email: 'akurbatova@alfabank.ru',
    post: 'Charts and reports to analyse worldwide Derivatives',
    photo: '/assets/images/Denis_Dorofeev.png',
    phones: ['+7 (495) 795-3740'],
    comment: 'Anna joined the Alfa-Bank Research team in 2019. Prior to Alfa-Bank, she has been working for 5 years in Ernst&Young, in the assessment and business modeling area where she participated in the assessment projects and financial modeling for a range of telecoms, internet, and media and consumer companies, including the creation of pre-IPO financial models. She worked with clients from Russia, UK, USA, Europe. Earlier she worked as a senior telecom analyst in BCS, Gazprombank, UniCredit Securities, as well as IR manager in Rostelecom. Anna covered AFK Sistema till 2013. Being a member of several research teams she got a number of professional rewards',
    urlPage: "/analyst-profile"
  },

];

export const files = [
  {
    id: 1,
    name: 'Gazprom Neft” PJSC Sustainability Report 2018',
    type: 'png',
    size: '202,6 Кб '
  },
  {
    id: 2,
    name: 'Gazprom Neft” PJSC Annual Report 2018',
    type: 'xls',
    size: '202,6 Кб '
  },
  {
    id: 3,
    name: 'Financial Report 9M 2019',
    type: 'doc',
    size: '202,6 Кб'
  },
  {
    id: 4,
    name: 'MD&A',
    type: 'png',
    size: '202,6 Кб'
  }
];

export const analystsModal = [
  {
    id: 1,
    fullName: 'Anna Kurbatova',
    photo: '/assets/images/Anna_Kurbatova.png',
    urlPage: "/analyst-profile"
  },
  {
    id: 2,
    fullName: 'Denis Dorofeev',
    photo: '/assets/images/Denis_Dorofeev.png',
    urlPage: "/analyst-profile"
  },
];

export const analystsSeacrch = [
  {
    id: 1,
    fullName: 'Anna Kurbatova',
    photo: '/assets/images/Anna_Kurbatova.png',
    urlPage: "/analyst-profile"
  },
  {
    fullName: 'Denis Dorofeev',
    photo: '/assets/images/Denis_Dorofeev.png',
    urlPage: "/analyst-profile"
  },
];

export const tickerInfo = [
  {
    name: 'GAZP RX',
    // price: ['RUB', '228,54', 'up'],
    price: {
      value: 22854,
      currency: {
        minority: 100
      },
      unit: 'RUB',
      status: 'up'
    },
    rec: 'E/W'
  },
  {
    name: 'GAZP OTC',
    price: {
      value: 850,
      currency: {
        minority: 100
      },
      unit: 'GBR',
      status: 'default'
    },
    rec: 'E/W'
  }
];

export const tickerInfoOnce = [
  {
    name: 'GAZP RX',
    price: {
      value: 22854,
      currency: {
        minority: 100
      },
      unit: 'GBR',
      status: 'up'
    },
    rec: 'E/W'
  }
];

export const coverageList = [
  {
    name: 'Oil&Gas',
    content: [
      {
        company: 'LUKOIL',
        ticker: 'LKOH RX',
        target_price: {
          value: 5768,
          currency: {
            minority: 100
          },
          unit: 'RUB',
          status: 'up'
        },
        rec: 'E/W'
      },
      {
        company: 'Rosneft',
        ticker: 'ROSN RX',
        target_price: {
          value: 41375,
          currency: {
            minority: 100
          },
          unit: 'RUB',
          status: 'default'
        },
        rec: 'E/W'
      },
      {
        company: 'TATNEFT',
        ticker: 'TATN RX',
        target_price: {
          value: 7219,
          currency: {
            minority: 100
          },
          unit: 'RUB',
          status: 'down'
        },
        rec: 'O/W'
      },
    ]
  },
  {
    name: 'Metals&Mining',
    content: [
      {
        company: 'ALROSA',
        ticker: 'ALRS RX',
        target_price: {
          value: 733,
          currency: {
            minority: 100
          },
          unit: 'RUB',
          status: 'up'
        },
        rec: 'E/W'
      },
      {
        company: 'Nornickel',
        ticker: 'MNOD LI',
        target_price: {
          value: 2345,
          currency: {
            minority: 10
          },
          unit: 'USD',
          status: 'default'
        },
        rec: 'E/W'
      },
      {
        company: 'Ferrexpo',
        ticker: 'FXPO LI',
        target_price: {
          value: 13485,
          currency: {
            minority: 100
          },
          unit: 'GBp',
          status: 'default'
        },
        rec: 'E/W'
      },
      {
        company: 'NLMK',
        ticker: 'NLMK LI',
        target_price: {
          value: 1978,
          currency: {
            minority: 10
          },
          unit: 'USD',
          status: 'down'
        },
        rec: 'E/W'
      },
      {
        company: 'Severstal',
        ticker: 'SVST LI',
        target_price: {
          value: 136,
          currency: {
            minority: 10
          },
          unit: 'USD',
          status: 'down'
        },
        rec: 'E/W'
      },
    ]
  }
];

export const phones = [
  {
    par0: 'Research Department',
    par1: [
      ['+7 (495) 795-3676']
    ]
  },
  {
    par0: 'Head of Research',
    par1: [
      ['Boris Krasnozhenov', 'bkrasnozhenov@alfabank.ru', '+7 (495) 795-3677']
    ]
  },
  {
    par0: 'Macroeconomics',
    par1: [
      ['Natalia Orlova, Ph.D.', 'norlova@alfabank.ru', '+7 (495) 795-3677' ]
    ]
  },
  {
    par0: 'Equity Startegy',
    par1: [
      ['Jhon Walsh', 'jwalsh@alfabank.ru', '+7 (495) 795-3677' ]
    ]
  },
  {
    par0: 'Oil&Gas',
    par1: [
      ['Anton Korytsko', 'akorytsko@alfabank.ru', '+7 (495) 795-3677' ]
    ]
  },
  {
    par0: 'Banking',
    par1: [
      ['Evgeniy Kipnis', 'ekipnis@alfabank.ru', '+7 (495) 795-3677' ]
    ]
  },
  {
    par0: 'Translation',
    par1: [
      ['Anna Martynova', 'amartynova@alfabank.ru', '+7 (495) 795-3677' ]
    ]
  },
  {
    par0: 'Data Specialist / Analyst',
    par1: [
      ['Denis Dorifeev', 'didorifeev@alfabank.ru', '+7 (495) 795-3677' ]
    ]
  },
  {
    par0: 'Metals & Mining',
    par1: [
      ['Boris Krasnozhenov', 'bkrasnozhenov@alfabank.ru', '+7 (495) 795-3677'],
      ['Yulia Tolsykh', 'ytolsykh@alfabank.ru', '+7 (495) 795-3677'],
    ]
  },
  {
    par0: 'TMT, Agriculture',
    par1: [
      ['Anna Kurbatov', 'akurbatov@alfabank.ru', '+7 (495) 795-3677'],
      ['Olesya Vorobyeva', 'avorobyeva@alfabank.ru', '+7 (495) 795-3677'],
    ]
  },
  {
    par0: 'Consumer',
    par1: [
      ['Evgeniy Kipnis', 'ekipnis@alfabank.ru', '+7 (495) 795-3677'],
      ['Olesya Vorobyeva', 'avorobyeva@alfabank.ru', '+7 (495) 795-3677'],
    ]
  },

  {
    par0: 'Equity Sales & Trading (Moscow)',
    par1: [
      ['+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Head of Equities',
    par1: [
      ['Konstantin Sharpov', 'ksharpov@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Equity Sales',
    par1: [
      ['Alexasander Zorov', 'azorov@alfabank.ru', '+7 (495) 223-5500'],
      ['Svetlana Golodinkina', 'agolodinkina@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Equity Trading',
    par1: [
      ['Atrem Belobrov', 'abelobrov@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Alfa Direct (Domestic retail brokerage)',
    par1: [
      ['+7 (495) 795-3680'],
    ]
  },
  {
    par0: 'Head of Alfa-Direct',
    par1: [
      ['Sergey Rybakov', 'srybakov@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Alfa-Direct Research',
    par1: [
      ['Alan Kaziev', 'akaziev@alfabank.ru', '+7 (495) 223-5500'],
      ['Elizaveta Naumova', 'enaumova@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Alfa-Direct Sales',
    par1: [
      ['Valeriy Kremnev', 'vkremnev@alfabank.ru', '+7 (495) 223-5500'],
      ['Olga Babina', 'ababina@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Equity Financing',
    par1: [
      ['Oleg Morozow', 'omorozow@alfabank.ru', '+7 (495) 223-5500'],
      ['Viacheslav Savitskiy', 'vsavitskiy@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Alfa Capital Holdings (Cyprus) Limited',
    par1: [
      ['+357 22470900'],
    ]
  },
  {
    par0: 'Equity Sales & Trading',
    par1: [
      ['Alexander Chepurko', 'achepurko@alfabank.ru', '+7 (495) 223-5500'],
      ['Yevgen Buzun', 'ybuzun@alfabank.ru', '+7 (495) 223-5500'],
      ['Artashes Matevosyan', 'amatevosyan@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Fixed Income Sales & Trading',
    par1: [
      ['Aleksei Yudin', 'ayudin@alfabank.ru', '+7 (495) 223-5500'],
      ['Alexandros Fotiou', 'afotiou@alfabank.ru', '+7 (495) 223-5500'],
      ['Ildar Khudayberdin', 'ikhudayberdin@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
  {
    par0: 'Trading & Financing',
    par1: [
      ['Dmitry Maksimov', 'amaksimov@alfabank.ru', '+7 (495) 223-5500'],
    ]
  },
];

/**
 * Rus
 */
export const equityResearchItemsRus = [
  {
    id: 1,
    name: 'ALFA-BANK Ежедневный обзор, 07 ноября, 2019',
    type: 'Еженедельный обзор',
    analyst: 'Петрощенков Константин',
    date: '12.11.2019',
    company: 'Ferrexpo'
  },
  {
    id: 2,
    name: 'ALFA-BANK Weekly: Причин для разочарований много, спасти положение может ФРС',
    type: 'Стратегия',
    analyst: 'Петрощенков Константин',
    date: '11.11.2019',
    company: 'LUKOIL'
  },
  {
    id: 3,
    name: 'ALFA-Bank:Московская биржа (ПО РЫНКУ); Все позитивные факторы учтены',
    type: 'Отчет по компании',
    analyst: 'Петрощенков Константин',
    date: '07.11.2019',
    company: 'Severstal'
  },
  {
    id: 4,
    name: 'ALFA-BANK Weekly: В 4К19 изменений не предвидится',
    type: 'Стратегия',
    analyst: 'Петрощенков Константин',
    date: '05.11.2019',
    company: 'NLMK'
  },
  {
    id: 5,
    name: 'ALFA-Bank:Yandex (ВЫШЕ РЫНКА), Mail.ru (ВЫШЕ РЫНКА): Возобновление анализа: Игра на цифровизации обещает высокую доходность',
    type: 'Отчет по сектору',
    analyst: 'Петрощенков Константин',
    date: '04.11.2019',
    company: 'TATNEFT'
  },
  {
    id: 6,
    name: 'ALFA-BANK Weekly: Неделя меняет многое!',
    type: 'Стратегия',
    analyst: 'Петрощенков Константин',
    date: '28.10.2019',
    company: 'Severstal'
  },
  {
    id: 7,
    name: 'ALFA-Bank:TCS (ВЫШЕ РЫНКА); Избирательный подход к кредитованию защитит от перегрева',
    type: 'Ежедневный обзор',
    analyst: 'Петрощенков Константин',
    date: '26.10.2019',
    company: 'NLMK'
  },
  {
    id: 8,
    name: 'ALFA-Bank: Металлы и горная добыча: Стальной сектор по-прежнему в форме',
    type: 'Экономика',
    analyst: 'Dorofeev Denis',
    date: '25.10.2019',
    company: 'Severstal'
  },
  {
    id: 9,
    name: 'ALFA-BANK Weekly: Обстановка в Саудовской Аравии снизила аппетит к риску',
    type: 'Стратегия',
    analyst: 'Dorofeev Denis',
    date: '25.10.2019',
    company: 'Severstal'
  }
];

export const filterSectorCompanyRus = [
  {
    parent: 'Нефть и газ',
    collapsed: true,
    select: false,
    children: [
      { value: 'Газмпром' },
      { value: 'Газпром нефть' },
      { value: 'ЛУКОЙЛ' },
      { value: 'НОВАТЭК' },
      { value: 'Роснефть' },
      { value: 'Сургутнефтегаз' },
      { value: 'Татнефть' },
    ],
  },
  {
    parent: 'Металлы и горная добыча',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Финансы',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Недвижимость ',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Транспорт',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Сельское хозяйство',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Телекоммуникации',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'ИТ',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Минеральные удобрения',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Энергетика',
    collapsed: false,
    select: false,
    children: [],
  },
  {
    parent: 'Потребительский сектор',
    collapsed: false,
    select: false,
    children: [],
  }
];

export const productsDataRus = [
  {
    id: 1,
    category: 'Отчет по компании',
    name: 'ALFA-Bank: Yandex (ВЫШЕ РЫНКА),Mail.ru (ВЫШЕ РЫНКА)',
    annotation: 'Возобновление анализа: Игра на цифровизации обещает высокую  доходность',
    date: '28.10.2019'
  },
  {
    id: 0,
    category: 'Стратегия',
    name: 'ALFA-BANK Weekly',
    annotation: 'Причин для разочарований много, спасти положение может ФРС',
    date: '28.10.2019'
  },
  {
    id: 3,
    category: 'Ежедневный обзор',
    name: 'ALFA Weekly Radar',
    annotation: 'Обстановка в Саудовской Аравии снизила аппетит к риску',
    date: '25.10.2019'
  }
];

export const eventsDataRus = [
  {
    id: 1,
    name: 'Норильский Никель',
    comment: 'День рынков',
    date: '18.11.2019'
  },
  {
    id: 2,
    name: 'Русагро',
    comment: '3К19 МСФО',
    date: '18.11.2019'
  },
  {
    id: 3,
    name: 'Россия',
    comment: 'Золотовалютный резерв, млрд долл.',
    date: '19.11.2019'
  }
];

export const analystsModalRus = [
  {
    id: 1,
    fullName: 'Анна Курбатова',
    photo: '/assets/images/Anna_Kurbatova.png',
    urlPage: "/analyst-profile"
  },
  {
    id: 2,
    fullName: 'Денис Дорофеев',
    photo: '/assets/images/Denis_Dorofeev.png',
    urlPage: "/analyst-profile"
  },
];


