// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import {
  ICategoryTable,
  IHeadTable,
  IBodyTable,
  ItemNormalize,
} from 'components/Flows/Table/interface';
import { COMPANIES_TABLE } from 'constants/tables';
import { getCompaniesSortRequest } from '../../../redux/actions';
import {
  getCompanyWithParamsRequest,
  getAllCurrencyRequest,
  getAllEquityTickersRequest,
  getAllStockRecommendationRequest,
} from 'modules/Company/redux/actions';
import { isArray } from 'lodash';
import { productTypeRequest } from 'modules/ProductType/redux/actions';
import { language, getLanguageFromStore } from 'utils/formatters';
import { TABLE_NAME, TABLE_HEAD } from 'constants/languagesTable';
import { COMPANIES_FILTER } from 'constants/forms';
import { getAuthorsForTable } from '../../../../../utils/authorsList';
import { AuthorsColumn } from 'components/AuthorsColumn/AuthorsColumn';

class EquityResearchTable extends React.Component<ICategoryTable> {
  normalizeTableData = (
    head: IHeadTable[],
    body: IBodyTable[],
    valuesTable: any,
  ): any => {
    const {
      productType,
      //           author,
      sectors,
      categories,
      equityTickers,
      currency,
      recommendation,
    } = valuesTable;
    // console.log('normalize data valuesTable=', valuesTable)

    // akayerov чтобы работало по F5
    const author = isArray(valuesTable.author)
      ? valuesTable.author
      : valuesTable.authorType;

    const processedData =
      (isArray(productType) &&
        isArray(sectors) &&
        isArray(author) &&
        isArray(categories) &&
        isArray(equityTickers) &&
        isArray(currency) &&
        isArray(recommendation) &&
        body &&
        body.map((item: any) => {
          const { id }: any = item;

          const sector = sectors.find((u: any) => {
            return u.id === item.sectorId;
          });

          const equityTickerId = equityTickers.find(
            (iy: any) => iy.id === item.equityTickerId,
          );

          const currencyId = equityTickerId && equityTickerId.currencyId;

          const currencyName = currency.find((iy: any) => iy.id === currencyId);

          const au = author
            .filter((o1: any) => {
              return (
                isArray(item.authors) &&
                item.authors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((y: any) => {
              return {
                name: language(y.name, 'en'),
                id: y.id,
                photo: y.photo,
                blocked: y.blocked,
              };
            });
          // 2021-08-31 отфильтровать спискок в соотвествии еще  типом документа
          let authorsForTable = getAuthorsForTable(
            item.productTypeNameSort,
            au,
          );
          // 2021-10-05 в модальных окнах только неблокированные авторы
          // в отличие от страниц здесь незачем отображать заблокированных авторов
          authorsForTable = authorsForTable.filter(
            (o1: ItemNormalize) => o1.blocked !== true,
          );

          const curren =
            currencyName && currencyName.name && item && item.targetPrice
              ? ` ${currencyName.name} ${`${item.targetPrice}`}`
              : '';
          return {
            id,
            cells: [
              {
                element: (
                  <div className="company-custom-name">
                    {item?.logo && (
                      <div className="image-wrapper">
                        <img alt="" src={item?.logo} />
                      </div>
                    )}
                    <span>{language(item?.name, 'en')}</span>
                  </div>
                ),
                id,
                type: 'name',
                width: 'l',
              },
              {
                element: equityTickerId && equityTickerId.name,
                id,
                type: 'equityTickerId',
                width: 'l',
              },
              {
                element: curren,
                id,
                type: 'targetPrice',
                width: 'l',
              },
              {
                element: language(
                  equityTickerId?.stockRecommendation?.recommendation,
                  'en',
                ),
                id,
                type: 'recommendation',
                width: 'l',
              },
              {
                element: sector && language(sector.name, 'en'),
                id,
                type: 'sector',
                width: 'l',
              },
              {
                element: <AuthorsColumn authorsForTable={authorsForTable} />,
                id,
                type: 'analyst',
                width: 'l',
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: any) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  getRowLink = (id: any) => `/interface-settings/${id}`;

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'name',
        label: TABLE_HEAD['company'][getLanguageFromStore()],
        style: { width: 'm' },
        sort: 'default',
        type: 'link',
      },
      {
        id: 'equityTickerId',
        label: TABLE_HEAD['equityTickerId'][getLanguageFromStore()],
        style: { width: 'm' },
        type: 'link',
      },
      {
        id: 'targetPrice',
        label: TABLE_HEAD['targetPrice'][getLanguageFromStore()],
        style: { width: 'm' },
        type: 'link',
      },
      {
        id: 'recommendation',
        label: TABLE_HEAD['recommendation'][getLanguageFromStore()],
        style: { width: 'm' },
        type: 'link',
      },
      {
        id: 'sector',
        label: TABLE_HEAD['sector'][getLanguageFromStore()],
        style: { width: 'm' },
        type: 'link',
      },
      {
        id: 'analyst',
        label: TABLE_HEAD['analyst'][getLanguageFromStore()],
        style: { width: 'm' },
        type: 'link',
        // 2021-08-04 Новое значение none отключает щелчок по колонке для сортировки
        sort: 'none',
      },
    ];

    const graphId = {};
    const actions = {
      productType: productTypeRequest,
      equityTickers: getAllEquityTickersRequest,
      currency: getAllCurrencyRequest,
      recommendation: getAllStockRecommendationRequest,
    };
    const { showTitle } = this.props;

    return (
      <GraphTable
        customTableClass={'standart-table-m'}
        actions={actions}
        graphId={graphId}
        type={'edit'}
        caption={
          showTitle ? TABLE_NAME[COMPANIES_TABLE][getLanguageFromStore()] : ''
        }
        table={COMPANIES_TABLE}
        filterForm={COMPANIES_FILTER}
        normalizeTableData={this.normalizeTableData}
        sortRequest={getCompaniesSortRequest}
        init={getCompanyWithParamsRequest}
        onRowClick={handleRowClick}
        head={head}
        link={this.getRowLink}
        width={'7'}
        exceptionLink={'companies/about/'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        pagination={true}
      />
    );
  }
}

export default EquityResearchTable;
