// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import { IHeadTable, IBodyTable } from 'components/Flows/Table/interface';
import { COMPANIES_REPORT_TABLE } from 'constants/tables';
import {
  getCompaniesSortReportRequest,
  getProductByLanguageCompaniesRequest,
} from '../../../redux/actions';
import { productTypeRequest } from 'modules/ProductType/redux/actions';
import { language, getLanguageFromStore } from 'utils/formatters';
import { isArray } from 'lodash';
import { TABLE_HEAD } from 'constants/languagesTable';
import { COMPANIES_REPORT_FILTER } from 'constants/forms';
import { AuthorsColumn } from 'components/AuthorsColumn/AuthorsColumn';

class EquityResearchTable extends React.Component<any, any> {
  normalizeTableData = (
    head: IHeadTable[],
    body: IBodyTable[],
    valuesTable: any,
  ): any => {
    const { productType, author, sectors, categories } = valuesTable;

    const processedData =
      (isArray(productType) &&
        isArray(author) &&
        isArray(sectors) &&
        isArray(categories) &&
        body?.map((item: any) => {
          const { id, date }: any = item;

          const sector = sectors
            ?.filter((o1: any) => {
              return (
                isArray(item.sectors) &&
                item.sectors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            ?.map((lu: any) => {
              return {
                name: language(lu.name, 'en'),
                id: lu.id,
              };
            });
          const categoriesTitle = categories
            ?.filter((o1: any) => {
              return o1.id === item.categoryId;
            })
            ?.map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join(', ');

          const strategy = productType
            ?.filter((o1: any) => {
              return o1.id === item.productTypeId;
            })
            ?.map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join(', ');

          const au = author
            ?.filter((o1: any) => {
              return (
                isArray(item.authors) &&
                item.authors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            ?.map((y: any) => {
              return {
                name: language(y.name, 'en'),
                id: y.id,
                photo: y.photo,
              };
            });
          return {
            id,
            modal: {
              sectors: sector,
              authors: au,
              name: item.name,
              annotation: item.annotation,
              file: item?.file,
              downloadAvailable: item?.downloadAvailable,
              date: item.date,
              id: item.id,
              strategy,
              categoriesTitle,
            },
            cells: [
              {
                element: item.name,
                id,
                type: 'name',
                width: 'l',
              },
              {
                element: strategy,
                id,
                type: 'productType',
                width: 'l',
              },
              {
                element: <AuthorsColumn authorsForTable={au} />,
                id,
                type: 'analyst',
                width: 'l',
              },
              {
                element: date,
                id,
                type: 'date',
                width: 'l',
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: any) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  getRowLink = (id: any) => `/interface-settings/${id}`;

  render() {
    const { handleRowClick, companyId }: any = this.props;
    const head = [
      {
        id: 'name',
        label: TABLE_HEAD['name'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'l' },
        sort: 'default',
      },
      {
        id: 'productType',
        label: TABLE_HEAD['productType'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
      },
      {
        id: 'analyst',
        label: TABLE_HEAD['analyst'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
      },
      {
        id: 'date',
        label: TABLE_HEAD['date'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 's' },
      },
    ];

    const graphId = {
      companyId,
    };
    const actions = {
      productType: productTypeRequest,
    };

    return (
      <GraphTable
        customTableClass={''}
        actions={actions}
        graphId={graphId}
        type={'edit'}
        caption={''}
        filterForm={COMPANIES_REPORT_FILTER}
        table={COMPANIES_REPORT_TABLE}
        normalizeTableData={this.normalizeTableData}
        sortRequest={getCompaniesSortReportRequest}
        init={getProductByLanguageCompaniesRequest}
        onRowClick={handleRowClick}
        head={head}
        link={this.getRowLink}
        width={'7'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        pagination={true}
      />
    );
  }
}

export default EquityResearchTable;
