// @ts-nocheck
import React from 'react';
import {IGraphOFZ} from 'components/Flows/Table/interface';
import {CHART_OFZ} from 'constants/news';
import GraphChartOFZ from "../../../components/Graphs/GraphChartOFZ";

// Расчет параметров логарифмической функции a и b по 2-м крайним точкам
// y = a * ln(x) + b
function calculateLnParametersByPoints(data: any, minX: number, maxX: number, minY: number, maxY: number): any {
    let lnParam = {a: 0, b: 0}
    if( data.length < 2) return lnParam
    const y1 = data[0].y
    const x1 = data[0].x
    const y2 = data[data.length-1].y
    const x2 = data[data.length-1].x
//    console.log("calculateLnParametersByPoints", x1, y1, x2, y2)
    lnParam.a = (y1- y2) /( Math.log1p(x1) - Math.log1p(x2))
    lnParam.b = (y1 + y2 - lnParam.a * (Math.log1p(x2) + Math.log1p(x1))) / 2
    return lnParam
}


class EconomicChart extends React.Component<IGraphOFZ> {
    normalizeTableData = (data: any): any => {
        const {dataTag} = this.props

        let staticInfo: any = null
        staticInfo = (data) ? data.find((item: any) => item.type == dataTag) : null
        // console.log("EconomicChart 1 staticInfo=", staticInfo, dataTag )
        let graphData: any = []
        graphData = (staticInfo && staticInfo.ofz) ? staticInfo.ofz : null

        const titleEn = ( staticInfo && staticInfo.nameEn ) ? staticInfo.nameEn: null
        const titleRu = ( staticInfo && staticInfo.nameRu) ? staticInfo.nameRu: null

//        console.log("EconomicChart 2 ofz=", graphData)
        // выделим мин и макс по осям x и y
        let minX: number | null = null
        let minY: number | null = null
        let maxX: number | null = null
        let maxY: number | null = null
        if (graphData) {
            graphData.sort((a: any, b: any) =>
                a.y - b.y);
            graphData.forEach((item: any) => {
                if (minX == null || item.x <= minX) minX = item.x
                if (minY == null || item.y <= minY) minY = item.y
                if (maxX == null || item.x > maxX) maxX = item.x
                if (maxY == null || item.y > maxY) maxY = item.y
            })

            // const minX2 = (minX != null) ? Math.floor(minX) : 0
            const minX2 =  0
            let maxX2 = (maxX != null) ? Math.ceil(maxX) : 0
            if( maxX2 === maxX)
                ++maxX2
            if( maxX2 % 2) ++maxX2

            const minY2 = (minY != null) ? Math.floor(minY) : 0
            let maxY2 = (maxY != null) ? Math.ceil(maxY) : 0

// расчитать параметры логарифмического графика
// усовершенствованный алгоритм рассчета по 2-м точкам
// \первая точка стартовая,остальные по целым значениям оси X
            const lnParameter = calculateLnParametersByPoints(graphData, minX2, maxX2, minY2, maxY2)
            let lnArray: any = []
            if( graphData[0].x < 1 ) {
                const z = lnParameter.a * Math.log1p(graphData[0].x) + lnParameter.b
                lnArray.push({ x: graphData[0].x, z: Math.round(z * 100) / 100 })
            }
            for(let i = 1; i<= maxX2; i++) {
                const z = lnParameter.a * Math.log1p(i) + lnParameter.b
                lnArray.push({ x: i, z: Math.round(z * 100) / 100 })
            }


            // console.log(graphData)

            return {
                titleEn,
                titleRu,
                axis: {
                   minX: minX2,
                   maxX: maxX2,
                   minY: minY2,
                   maxY: maxY2,
                },
                graphData: [...graphData, ...lnArray]
            }

        }

        // добавим расчетные данные логарифма
        return null

    };

    render() {
        const actions = {
//      companyChart: getAllCompanyWidgetsChartsRequest,
        };
        return (
            <GraphChartOFZ
                customClass={'chart-padding-bottom-economic'}
                actions={actions}
                type={'products'}
                table={CHART_OFZ}
                normalizeTableData={this.normalizeTableData}
                {...this.props}
            />
        );
    }
}

export default EconomicChart;
