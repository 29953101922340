import * as ACTION_TYPE from './constants';



export const getProductByEquityRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_EQUITY_REQUEST,
    meta,
    payload
});

export const getProductByEconomicsRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_ECONOMICS_REQUEST,
    meta,
    payload
});

export const getProductByFixedIncomeRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_FIXEDINCOME_REQUEST,
    meta,
    payload
});

export const getProductByCommoditiesRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_COMMODITIES_REQUEST,
    meta,
    payload
});






export const getFixedIncomeSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_FIXEDINCOME_SORT_REQUEST,
    meta,
    payload
});


export const getEquityResearchSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_EQUITY_RESEARCH_SORT_REQUEST,
    meta,
    payload
});

export const getEconomicsSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_ECONOMICS_SORT_REQUEST,
    meta,
    payload
});

export const getCommoditiesSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_COMMODITIES_SORT_REQUEST,
    meta,
    payload
});


export const getAllCompanyWidgetsChartsRequest = ({ meta }: any) => ({
    type: ACTION_TYPE.GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST,
    meta
});


export const getAllSectorWidgetsChartsRequest = ({ meta }: any) => ({
    type: ACTION_TYPE.GET_ALL_SECTORS_WIDGETSCHARTS_REQUEST,
    meta
});