import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty, isString} from 'lodash'
import Plate from 'arui-feather/plate';
import Heading from 'arui-feather/heading';
import Paragraph from 'arui-feather/paragraph';

import Label from 'components/Label';
import Modal from 'components/ModalThree';
import Research from 'components/ModalContent/reasearch';
import './Product.scss';

/**
 * Анонс продукта
 */

class Product extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowing: false,
        }
    }

    modalHandler = () => {
        this.setState(prevState => ({
            ...prevState,
            isShowing: !prevState.isShowing
        }));
    };

// 2021-11-30 Показ аннотаций в плашках по решению заказчика
// до следующей итерации
    render() {
        const {product} = this.props;
        const {modal} = product;

        return (
            <div>
                <Plate hasCloser={false} onClick={this.modalHandler.bind(this)} className="product">
                    {!isEmpty(product.strategy) && <Label text={product.strategy}/>}
                    <Heading size="xs" className="product__title">
                        {/*{!isEmpty(product.name) && `${product.name}: `}*/}
                        {!isEmpty(product.name) && `${product.name} `}
                        <span>
{/*
                            { !isEmpty(product.annotation) &&
                            <div dangerouslySetInnerHTML={{__html: product.annotation}}/>}
*/}
                        </span>
                    </Heading>
                    {!isEmpty(product.date) && <Paragraph className="product__date">
                        {product.date}
                    </Paragraph>}
                </Plate>
                {(this.state.isShowing) && (
                    <Modal
                        isShowing={this.state.isShowing}
                        onClose={this.modalHandler}
                    >
                        <Research modal={modal}/>
                    </Modal>
                )}
            </div>
        )
    }
}

Product.defaultProps = {
    id: null,
    category: null,
    name: null,
    annotation: null,
    date: null
};

Product.propTypes = {
    id: PropTypes.number,
    category: PropTypes.string,
    name: PropTypes.string,
    annotation: PropTypes.string,
    date: PropTypes.string
};

export default Product;