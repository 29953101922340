// @ts-nocheck
import {all, call, put, select, takeLeading} from 'redux-saga/effects';
import API from '../api';
import {
    CREATE_NEW_EQUITYRESEARCH_REQUEST,
    EDIT_CLIENTS_REQUEST,
    EDIT_EQUITYRESEARCH_REQUEST,
    GET_ALL_EQUITYRESEARCH_REQUEST,
    GET_PROFILE_CLIENT,
} from './constants';
import {EQUITYRESEARCH_TABLE} from 'constants/tables';
import {EQUITYRESEARCH_FILTER} from 'constants/forms';

import {parseFilters} from 'utils/normalizeFilters';
import {tableDataSuccess} from 'components/Graphs/Table/actions';
import API_CATEGORY from '../../Category/api';
import {errorMessageFailure} from '../../Error/redux/actions';
import {getLanguageFromStore} from '../../../utils/formatters';
import {loginFailure} from '../../Auth/redux/actions';
import API_CATEGORIES from "../../Categories/api";
import API_PRODUCT_TYPE from "../../ProductType/api";
import {LANGUAGE_EN, LANGUAGE_RU} from "../../../constants/languages";
import {setProfileId} from "../../User/redux/actions";
import {push} from "connected-react-router/immutable";

function* getAllInterfaceSettings({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllInterfaceSettings);
        if (callback) {
            if (table) {
                yield put(callback({payload: {response}, meta: {table}}));
            }
            if (form) {
                yield put(callback({payload: {response}, meta: {form}}));
            }
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

function* createNewInterfaceSettings({
                                         payload: {callback, fields},
                                         meta: {form, onSuccess},
                                     }: any) {
    try {
        const response = yield call(API.createNewInterfaceSettings, {
            data: fields,
        });
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

function* getInterfaceSettingsById({
                                       payload: {
                                           callback,
                                           data: {id},
                                       },
                                       meta: {form},
                                   }: any) {
    try {
        const response = yield call(API.getByIdInterfaceSettings, {id});
        if (callback) {
            return yield put(callback({payload: {response}, meta: {form}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

function* editInterfaceSettings({
                                    payload: {callback, fields},
                                    meta: {form, onSuccess},
                                }: any) {
    try {
        const response = yield call(API.editInterfaceSettings, {data: fields});
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

/* Обновление имени клиента в объекте
 * */
function changeNameClient(clientName: any, response: any) {
    const names = response.name;
    const language = getLanguageFromStore();

    names.forEach((name: any) => {
        if (language === 'en' && name.language.name === 'En') {
            name.translatedValue = clientName;
        }
        if (language === 'ru' && name.language.name === 'Рус') {
            name.translatedValue = clientName;
        }
    });
}

const isClientNameChanged = (state: any): any => {
    const data = state.form && state.form.CLIENTS_CONFIG_FORM;
    if (data.initial.clientName === data.values.clientName) {
        return false;
    } else {
        return true;
    }


};

function createClient(state: any, fields: any, categories: any, productTypes: any) {
    let clientCategories: any = []
    categories.forEach((category: any) => {
        if (category.serviceCategory == null) {
            clientCategories.push(category)
            category.productTypes = []
            productTypes.forEach((productType: any) => {
                const idx = productType.categories.indexOf(category.id)
                if (idx != -1) {  // found category in ProductType
                    category.productTypes.push(productType.id)
                }

            })
        }
    })
    const email = fields.deliveryData[0].email
    let emails: any = []
    emails.push(email)

    const client = {
        clientRightSettings: {
            categories: clientCategories
        },
        emails,
        rusLang: true,
        type: (email.includes('.cy')) ? "ACC" : "MIFID",
        engLang: true,
        name: [
            {
                language: {id: LANGUAGE_RU, name: 'Рус'},
                translatedValue: state.user.name,
            },
            {
                language: {id: LANGUAGE_EN, name: 'En'},
                translatedValue: state.user.name,
            },
        ],
        ...fields
    };
    console.log('createNewClient client=', client)
    return client
}


function canCreateNewClientAndProfileAndUser(fields: any) {
    const f = fields.deliveryData[0]
    if( f.categories.length > 0 && ( f.engLang || f.rusLang ))
      return true
    else
      return false
}

function* editProfileClient({
                                payload: {callback, fields},
                                meta: {form, onSuccess},
                            }: any) {
    if (fields.clientId != null) {  // обычный клиент или из AD уже имеющий сохраненный профиль
        try {
            console.log('editProfileClient = ', fields)
            // модификация метода обновления профиля
            // перед обновлением профиля обновим поле name клиента
            const state = yield select();

            const clientChanged = isClientNameChanged(state);
            console.log('editProfileClient start...', fields);
            const profileId = fields.id
            console.log('PROFILEID = ', profileId)
            const clientName = fields.clientName
            const clientId = fields.clientId
            const password = fields.password
            const confirmPassword = fields.confirmPassword
            // далее без изменений
            console.log('API.editProfileClient start...', fields);
            const response = yield call(API.editProfileClient, {data: fields});
            console.log('API.editProfileClient result=', response);

            if(!isUserFromAD(state)) {   // для пользователей из AD не пытаемся здесь что то менять
                console.log('user is not from AD')
                if (clientChanged) {
                    // считаем объект клиент
                    console.log('API.getCurrentClient s save to store...');
                    let response2 = yield call(API.getCurrentClient, clientId);
                    console.log('result=', clientId, response2);
                    // Заменим поле с именем
                    console.log('changeNameClient clientName= ', clientName);
                    changeNameClient(clientName, response2);
                    console.log('changeNameClient changed');
                    // Сохраним в базу изменения
                    console.log('API.storeCurrentClientName start...');
                    response2 = yield call(API.storeCurrentClientName, {data: response2});
                    console.log('API.storeCurrentClientName response=', response2);
                }
                // здесь при необходимости поменять пароль
                if (password != '') {
                    console.log('need change password', password, confirmPassword);
                    if (password == confirmPassword) {
                        console.log('change password');
                        if (state.user && state.user.keycloakId) {
                            console.log('API.changePasswordClient keycloakId/password start...', state.user.keycloakId, password);
                            const response3 = yield call(
                                API.changePasswordClient,
                                state.user.keycloakId,
                                password,
                            );
                            console.log('API.changePasswordClient result=', response3);
                        } else {
                            console.error('not defined state.user OR state.user.keycloakId', state.user);
                        }
                    } else {
                        console.log('change password fail');
                        const customMessage = {
                            en: "Passwords don't match",
                            ru: 'Пароли не совпадают',
                        };
                        yield put(loginFailure({error: "Error"}, customMessage));
                    }

                }
            }
            console.log('before callback')

            if (callback) {
                console.log('API.editProfileClient save to store...', form);
                yield put(
                    callback({payload: {response}, meta: {form, onSuccess}}),
                );
            }


        } catch
            (error) {
            // yield put(editClientsFailure({ error }));
            console.log('Ошибка сохранения клиента или профиля', error);
            const customMessage = {
                en: 'Network error. Please try to repeat save',
                ru: 'Сетевая ошибка. Повторите сохранение',
            };
            yield put(loginFailure({error}, customMessage));
        }
    } else {
        if( canCreateNewClientAndProfileAndUser(fields)) {
            let state = yield select();
            const productTypes = yield call(API_PRODUCT_TYPE.getProductType);
            console.log('productTypes = ', productTypes);
            const categories = yield call(API_CATEGORIES.getAllCategories);
            console.log('categories = ', categories);

            const data = createClient(state, fields, categories, productTypes)
            const client = yield call(API.createNewClientsWithoutKeyCloak, {
                data: data,
                username: state.user.username
            });
            console.log('client = ', client)
            fields.clientId = client.id

            let data2 = {
                id: client.id
            }
            const profile = yield call(API.getProfileClient, data2)
            console.log('get profile response  = ', profile)
            fields.id = profile.id

            yield put(setProfileId(profile.clientId))

            const response = yield call(API.editProfileClient, {data: fields});

            if (callback) {
                yield put(
                    callback({payload: {response}, meta: {form, onSuccess}}),
                );
            }
        }
        else {  // нет сохранения
            yield put(push('/'));
        }

    }
}

function* getEquityResearchSortRequest({
                                           payload,
                                           meta: {table, callback},
                                       }: any) {
    const state = yield select();
    const tableSort =
        state.graphTable &&
        state.graphTable.get(EQUITYRESEARCH_TABLE) &&
        state.graphTable.get(EQUITYRESEARCH_TABLE).get('filterMeta');
    const filterForm =
        state.form &&
        state.form[EQUITYRESEARCH_FILTER] &&
        state.form[EQUITYRESEARCH_FILTER].values;
    const filterActive = filterForm ? filterForm : [];
    const graphId = parseFilters(filterActive, tableSort);
    const response = yield call(
        API_CATEGORY.getProductByLanguageAndCategoryId,
        graphId,
        EQUITYRESEARCH_TABLE,
    );
    try {
        yield put(
            tableDataSuccess({
                type: '',
                payload: {response},
                meta: {table: EQUITYRESEARCH_TABLE, callback: tableDataSuccess},
            }),
        );
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

/* Получить актуальное имя клиента с учетом языка
 * */
function getCurrenClientName(client: any): any {
    const names = client.name;
    const language = getLanguageFromStore();
    let res = '';
    names.forEach((name: any) => {
        //        console.log('name',name )
        if (language == 'en' && name.language.name == 'En') {
            // console.log('English', name.translatedValue);
            res = name.translatedValue;
        }
        if (language == 'ru' && name.language.name == 'Рус') {
            // console.log('Русский', name.translatedValue);
            res = name.translatedValue;
        }
    });
    return res;
}

const getUserNameFromState = (state: any): any => {
    if (state && state.user) {
        return state.user.username
    }
    return '';
};


const isUserFromAD = (state: any): any => {
    if (state && state.user && state.user.roles) {
        for (let i = 0; i < state.user.roles.length; i++) {
            if (state.user.roles[i] == 'USER_AD')
                return true
        }
    }
    return false;
};


function setFullAccessByCategories(productTypes: any, categories: any) {
    let clientRigths: any = []
    categories.forEach((category: any) => {
        if (category.serviceCategory == null) {
            category.categoryId = category.id
            clientRigths.push(category)
            category.productTypes = []
            productTypes.forEach((productType: any) => {
                const idx = productType.categories.indexOf(category.id)
                if (idx != -1) {  // found category in ProductType
                    category.productTypes.push(productType.id)
                }

            })
        }
    })
    return clientRigths
}

function setEmptyProfile(state: any, productTypes: any, categories: any) {
    let profile = {
        name: "new user",
        userFromAD: true,
        clientName: "",
        password: "",
        clientId: null,
        deliveryData: [
            {
                "email": state.user.email,
                "rusLang": false,
                "engLang": false,
                "categories": [],
                "sectors": [],
                "attachProduct": false
            },

        ],
        permittRusLang: true,
        permittEngLang: true,
        portalDefaultLanguageId: LANGUAGE_RU,
        accessItems: setFullAccessByCategories(productTypes, categories)
    }
    return profile
}


function* getProfileClient_gen({
                                   payload: {callback, data},
                                   meta: {form, onSuccess},
                               }: any) {
    if (data.id != null) {
        try {
            const response = yield call(API.getProfileClient, data);
            console.log('response =', response )
            // 2022-12-09 Добавляем флаг userFromAD - разрешение на редактирование полей username и password
            // Пользователям из AD это делать запрещено - нельзя сменить системные логин/пароль
            const state = yield select();
            response.userFromAD = isUserFromAD(state)

            // 2021-08-05 Добавляем к данным формы профиля имя пользователя из хранилища user на соответствующем языке
            // считаем объект клиент
            const currentClient = yield call(API.getCurrentClient, response.clientId);

            if (response.userFromAD) {
                response.clientName = getUserNameFromState(state);
            } else {
                response.clientName = getCurrenClientName(currentClient);
            }
            response.password = '';
            // console.log('getProfileClient response=', response, form )
            if (callback) {
                yield put(callback({payload: {response}, meta: {form, onSuccess}}));
            }
        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}));
        }
    } else {    // новый профиль для еще несуществующего пользователя из AD
        try {
            console.log('New profile for AD user')

            const state = yield select();
            const productTypes = yield call(API_PRODUCT_TYPE.getProductType);
            // console.log('productTypes = ', productTypes);
            const categories = yield call(API_CATEGORIES.getAllCategories)
            // console.log('categories=', categories)

            let newProfile = setEmptyProfile(state, productTypes, categories)

            if (callback) {
                yield put(callback({payload: {response: newProfile}, meta: {form, onSuccess}}));
            }

        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}));
        }


    }
}

function* Saga() {
    yield all([
        takeLeading(GET_ALL_EQUITYRESEARCH_REQUEST, getAllInterfaceSettings),
        takeLeading(CREATE_NEW_EQUITYRESEARCH_REQUEST, createNewInterfaceSettings),
        takeLeading(EDIT_EQUITYRESEARCH_REQUEST, editInterfaceSettings),
        // takeLeading(`@@redux-form/CHANGE`, onChangeFilter),
        takeLeading(EDIT_CLIENTS_REQUEST, editProfileClient),
        takeLeading(GET_PROFILE_CLIENT, getProfileClient_gen),
        // takeLeading(GET_EQUITY_RESEARCH_SORT_REQUEST, getEquityResearchSortRequest),
    ]);
}

export default Saga;
