// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { isArray } from 'lodash';
import { createStructuredSelector } from 'reselect';
import FlowTable from 'components/Flows/Table';
import {
  selectAllValueSets,
  selectValueSet,
} from 'components/ValueSet/selector';
import { code2display } from 'utils/code2display';
import {
  tableDataRequest,
  changeTableFilter,
  tableDataPaginationRequest,
} from './actions';
import {
  makeSelectData,
  tablePage,
  filterPage,
  makeSelectSortData,
} from './selector';
import { IGraphTable } from './interface';
import { setValueSet } from 'components/ValueSet/actions';
import { parseFilters } from 'utils/normalizeFilters';
import { ANALYST_PROFILE_TABLE } from '../../../constants/tables';

interface State {
  current?: null | string;
  sort?: string;
  tableData: any;
}

class GraphTable extends React.PureComponent<IGraphTable, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      current: null,
      sort: 'desc',
      tableData: null,
    };
  }

  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
    this.getTableData(this.props);
  }

  componentDidUpdate(prevProps: any) {
    const { head, graphData, normalizeTableData, valuesTable }: any =
      this.props;
    if (this.props.graphData !== prevProps.graphData) {
      const tableData = graphData
        ? this.cellNormalize(
            normalizeTableData(head, graphData, valuesTable),
            this.props,
          )
        : [];
      this.setState({ tableData });
    }
  }

  Paginate = () => {
    const {
      dispatch,
      table,
      init,
      // graphId,
      tablePage,
      filterForm,
      selectCurrentFilter,
      makeSelectSortData,
      authorId,
    } = this.props;
    const currentPage = tablePage + 1;
    const filterData =
      selectCurrentFilter && selectCurrentFilter[filterForm]?.values;
    const filterActive = filterData ? filterData : [];
    const graphId = parseFilters(filterActive, makeSelectSortData);
    if (table === ANALYST_PROFILE_TABLE) {
      graphId.authorId = authorId;
    }
    dispatch(
      tableDataPaginationRequest({
        type: '',
        meta: { table, init, currentPage },
        payload: graphId,
      }),
    );
  };

  sortColumn = (fromTableCurrent: any) => {
    const { dispatch, table, sortRequest } = this.props;
    const { current, sort } = this.state;
    if (fromTableCurrent === current && sort === 'desc') {
      this.setState({
        sort: 'asc',
      });

      const t = {
        sort: current,
        direction: 'asc',
      };
      dispatch(
        changeTableFilter({ payload: { ...t }, meta: { table }, type: '' }),
      );
      dispatch(sortRequest({ payload: {}, meta: { table }, type: '' }));
    }
    if (fromTableCurrent === current && sort === 'asc') {
      this.setState({
        sort: 'desc',
      });
      const t = {
        sort: current,
        direction: 'desc',
      };
      dispatch(
        changeTableFilter({ payload: { ...t }, meta: { table }, type: '' }),
      );
      dispatch(sortRequest({ payload: {}, meta: { table }, type: '' }));
    }
    if (fromTableCurrent !== current) {
      this.setState({
        sort: 'asc',
        current: fromTableCurrent,
      });
      const t = {
        sort: fromTableCurrent,
        direction: 'asc',
      };
      dispatch(
        changeTableFilter({ payload: { ...t }, meta: { table }, type: '' }),
      );
      dispatch(sortRequest({ payload: {}, meta: { table }, type: '' }));
    }
  };

  cellNormalize(data: any, props: any) {
    if (!data || !props) {
      return null;
    }
    const { head } = props;

    return data.map((row: any) => {
      if (row.cells) {
        head.forEach((column: any, index: any) => {
          if (column.type === 'valueSet') {
            row.cells[index] = isArray(row.cells[index])
              ? row.cells[index]
                  .map((item: any) =>
                    code2display(props[`@ValueSet:${column.id}`], item),
                  )
                  .join(', ')
              : code2display(props[`@ValueSet:${column.id}`], row.cells[index]);
          }
        });
      }

      return row;
    });
  }

  getTableData({ dispatch, table, init, graphId, type }: IGraphTable) {
    if (type === 'edit' && init) {
      dispatch(
        tableDataRequest({ type, meta: { table, init }, payload: graphId }),
      );
    }
  }

  render() {
    const {
      head,
      normalizeTableData,
      width,
      graphData,
      caption,
      valuesTable,
      makeSelectSortData,
      ...rest
    }: IGraphTable = this.props;
    const { tableData = [] }: any = this.state;
    return (
      <FlowTable
        currentSort={makeSelectSortData?.sort}
        directionSort={makeSelectSortData?.direction}
        Paginate={this.Paginate}
        sortColumn={this.sortColumn}
        valuesTable={valuesTable}
        caption={caption}
        width={width}
        normalizeTableData={normalizeTableData}
        data={tableData}
        head={head}
        body={graphData}
        {...rest}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valuesTable: selectAllValueSets,
  graphData: makeSelectData(),
  tablePage: tablePage(),
  selectCurrentFilter: filterPage(),
  makeSelectSortData: makeSelectSortData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(GraphTable);
