import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {
  login = (data: any) => request({
    url: 'keycloak/token',
    method: 'POST',
    data,
  });
  getCurrentClient = (client: any) => request({
    url: 'client/' + client.id ,
    method: 'GET',
    token: API_GLOBAL.token
  });

// 2022-12-06 create new client без создания записи в Keycloak
  createNewClientsWithoutKeyCloak = ({ data, username }: any) => request({
    url: 'client/create-client/' + username ,
    data,
    method: 'POST',
    token: API_GLOBAL.token
  });

// 2022-12-12 delete client having active = false (= AD Enabled=FALSE )
  deleteClient = ({ data, username }: any) => request({
    url: 'client/self-client-name/' + username ,
    data,
    method: 'DELETE',
    token: API_GLOBAL.token
  });

}

const API = new ClassAPI();

export default API;
