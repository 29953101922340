import React from 'react';
import Link from 'arui-feather/link';
import ArrowRight from 'arui-feather/icon/ui/arrow-right/arrow-right';

interface ILinkMore {
  title: string;
  url: string;
}

export const LinkMore = ({ title, url }: ILinkMore) => {
  return (
    <Link
      className="events__bottom-link btn-home-20 table-more-action"
      text={title}
      size="m"
      icon={<ArrowRight size="m" />}
      iconPosition="right"
      url={url}
    />
  );
};
