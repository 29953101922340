import {find, isArray, isEmpty} from 'lodash';
import {LANGUAGE_EN, LANGUAGE_RU} from 'constants/languages'
import ls from 'local-storage';
import axios from "axios";
import API_GLOBAL from "../api/restClient/api";
import {getCurrentUser} from "./currentUser";
import {addDownloadAction} from "../modules/Statistics/redux/actions";

export const hashCode = (s: string) => {
    var h = 0, l = s.length, i = 0;
    if (l > 0)
        while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
    return h;
};
export const unqID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substr(2, 9);
};

export const capitalizeFirstLetter = (string: any) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

export const getObjectTrim = (object: any) => {
    if (typeof object !== 'object' || object === null) {
        return object;
    }
    return Object.keys(object).reduce(
        (memo, key) => {
            const typeKey = typeof object[key];
            let value = object[key];
            switch (typeKey) {
                case 'string':
                    value = object[key].trim();
                    break;
                case 'object':
                    value = getObjectTrim(object[key]);
                    break;
                default:
                    value = object[key];
            }

            if (Array.isArray(memo)) {
                return [...memo, value];
            }

            return {...memo, [key]: value};
        },
        Array.isArray(object) ? [] : {},
    );
};


export const checkTouch = (payload: any, meta: any): any => {
    return meta.field === "dateTo" || meta.field === "dateFrom" || isArray(payload?.defaultSelect) && !isEmpty(payload?.defaultSelect);
};

// 2022-12-19  Пустой список выборки не повод не делать загрузку
export const checkTouchFilter = (payload: any, meta: any): any => {
    return meta.field === "dateTo" || meta.field === "dateFrom" || isArray(payload?.defaultSelect);
};


export const getLanguageFromStore = (): string => {
    // @ts-ignore
    const current = ls.get('language');
    if (current) {
        return current
    } else {
        // @ts-ignore
        ls.set('language', 'en');
        return current;
    }
};


export const getButtonMoreFromStore = (): string => {
    // @ts-ignore
    const buttonMore = ls.get('buttonMore')

    if (buttonMore) {
        return buttonMore
    } else {
        // @ts-ignore
        ls.set('buttonMore', 'yes');
        return buttonMore;
    }
};
export const language = (data: object, language: string) => {
    switch (getLanguageFromStore()) {
        case 'en':
            // @ts-ignore
            return find(data, {language: {id: LANGUAGE_EN}}) && find(data, {language: {id: LANGUAGE_EN}}).translatedValue;
            break;
        case 'ru':
            // @ts-ignore
            return find(data, {language: {id: LANGUAGE_RU}}) && find(data, {language: {id: LANGUAGE_RU}}).translatedValue;
            break;
    }
};

export const getFormattedDate = (date: Date) => {
    let year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return day + '.' + month + '.' + year;
};

export const exten = (fileName: string) => {
    return fileName.substr(fileName.lastIndexOf('.') + 1);
}


export const getFilename = (url: any) => {
    if (url) {
        var m = url.toString().match(/.*\/(.+?)\./);
        if (m && m.length > 1) {
            return m[1];
        }
    }
    return "";
}

export const isValidUrl = (string: any) => {
    // try {
    //     new URL(string);
    // } catch (error) {
    //     return false;
    // }

    return true;
};

export const getExtension = (filename: any) => {
    return /[^.]+$/.exec(filename);
};


export const dowloadFile = (url: any, dispatch: any, callback?: any) => {
    if (url && isValidUrl(url) ) {
        const name = getFilename(url);
        const extension = url.split('.').pop();
        axios({
            url: url,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${API_GLOBAL.token}`,
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute("target", "_blank");
            link.setAttribute('download', `${name}.${extension}`);
            document.body.appendChild(link);
            link.click();
            if (callback) {
                window.location = callback
            }
        }).catch((error) => {
            console.log('Error loading pdf = ' ,error);
//            dispatch(errorMessageFailure({payload: {error}}))
        })
    }
};

// Расширенная функия загрузки файлов, добавляется регистрация события
export const dowloadFileExtended = (item: any,  url: any, dispatch: any, callback?: any) => {
    if (url && isValidUrl(url) ) {
        const user = getCurrentUser();
        console.log('downloadFileExtended item/userId=', item, user);

        const name = getFilename(url);
        const extension = url.split('.').pop();
        axios({
            url: url,
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${API_GLOBAL.token}`,
            }
        }).then((response) => {
            // здесь сохраним  статистику - произошла загрузка
            console.log('will action addDownloadAction');
            dispatch( addDownloadAction({payload: {user, item}}))

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute("target", "_blank");
            link.setAttribute('download', `${name}.${extension}`);
            document.body.appendChild(link);
            link.click();
            if (callback) {
                window.location = callback
            }
        }).catch((error) => {
            console.log('Error loading pdf = ' ,error);
//            dispatch(errorMessageFailure({payload: {error}}))
        })
    }
};



export const RU_LANGUAGE = '5ecff43a3f183f1039a68edb';
export const EN_LANGUAGE = '5ecff43f3f183f1039a68edc';


// 2021-01-24 Преобразование url загрузки файлов
export function convertFileUrl(response: any) {
    console.log("convertFileUrl response=", response, window.location)
    let responseMod: any = []
    response.forEach( (item: any) => {
        if( item && item.file && item.file.length > 6) {
            const idx = item.file.indexOf("/", 8)
            item.file = window.location.origin + item.file.slice(idx)
            responseMod.push(item)
        }
    })
    return responseMod;
}