import React from 'react';
import {getLanguageFromStore} from 'utils/formatters'
import {FIELD_NAME} from 'constants/languagesFields';


export default [
    {
        fieldWidth: '12',
        title: "Date",
        id: 'date',
        name: "date",
        label: FIELD_NAME['date'][getLanguageFromStore()],
        isExpanded: false,
        type: "calendar",
        bigLabel: false,
        dateFrom: '01.01.2020',
        dateTo: '02.02.2020',
        language: 'rus'
    },
];
