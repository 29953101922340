// @ts-nocheck
import React from 'react';
import GraphNews from 'components/Graphs/News';
import {
  ICategoryTable,
  IHeadTable,
  IBodyTable,
  ItemNormalize,
} from 'components/Flows/Table/interface';
import { MAX_IMPORTANT_REPORTS_FOR_HOME_PAGE } from 'constants/news';
import { isArray } from 'lodash';
import { getAllcategoriesRequest } from 'modules/Categories/redux/actions';
import {
  getAllProductForNews,
  getAllProductRequest,
} from 'modules/Product/redux/actions';
import { getAllSectorsRequest } from 'modules/Sector/redux/actions';
import { productTypeRequest } from 'modules/ProductType/redux/actions';
import { getAllAuthorRequest } from 'modules/Author/redux/actions';
import { language } from 'utils/formatters';
import { makeToShorter } from '../../../../utils/makeShorterAnnotation';

class EquityResearchProductNews extends React.Component<ICategoryTable> {
  normalizeTableData = (data: any, valuesModal: any): any => {
    const sectors = valuesModal && valuesModal.sectors;
    const author = valuesModal && valuesModal.author;
    const productType = valuesModal && valuesModal.productType;
    const categories = valuesModal && valuesModal.categories;
    // console.log('productNews data ', data)
    if (
      isArray(data) &&
      isArray(sectors) &&
      isArray(author) &&
      isArray(productType) &&
      isArray(categories)
    ) {
      return (
        data &&
        data.length &&
        data.map((firstLevel: any) => {
          const sector = sectors
            .filter((o1: any) => {
              return (
                isArray(firstLevel.sectors) &&
                firstLevel.sectors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((lu: any) => {
              return {
                name: language(lu.name, 'en'),
                id: lu.id,
              };
            });

          let au = author
            .filter((o1: any) => {
              return (
                isArray(firstLevel.authors) &&
                firstLevel.authors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((y: any) => {
              return {
                name: language(y.name, 'en'),
                id: y.id,
                photo: y.photo,
                blocked: y.blocked,
              };
            });
          // 2021-10-05 в модальных окнах только неблокированные авторы
          au = au.filter((o1: ItemNormalize) => o1.blocked !== true);

          const strategy = productType
            .filter((o1: any) => {
              return o1.id === firstLevel.productTypeId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join('');

          const categoriesTitle = categories
            .filter((o1: any) => {
              return o1.id === firstLevel.categoryId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join('');

          return {
            annotation: makeToShorter(firstLevel.annotation),
            id: firstLevel.id,
            name: firstLevel.name,
            date: firstLevel.date,
            category: firstLevel.categoryId,
            strategy,
            modal: {
              sectors: sector,
              authors: au,
              name: firstLevel.name,
              annotation: firstLevel.annotation,
              file: firstLevel.file,
              downloadAvailable: firstLevel.downloadAvailable,
              date: firstLevel.date,
              id: firstLevel.id,
              strategy,
              categoriesTitle,
            },
          };
        })
      );
    }
  };

  render() {
    const actions = {
      author: getAllAuthorRequest,
      sectors: getAllSectorsRequest,
      productType: productTypeRequest,
      categories: getAllcategoriesRequest,
    };
    return (
      <GraphNews
        customClass={''}
        actions={actions}
        type={'products'}
        table={MAX_IMPORTANT_REPORTS_FOR_HOME_PAGE}
        normalizeTableData={this.normalizeTableData}
        init={getAllProductForNews}
      />
    );
  }
}

export default EquityResearchProductNews;
