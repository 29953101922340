/**
 * @TODO: Refactoring!! Update types!
 *
 * @TODO: Are announcements are same for all pages? If yes then
 * super refactoring needed!!
 */

import React from 'react';
import Product from 'components/Product';
import Events from 'components/Events';
import Chart from 'components/Chart';
import './Announcements.scss';
import Plate from 'arui-feather/plate';
import Link from 'arui-feather/link';
import ArrowRight from 'arui-feather/icon/ui/arrow-right/arrow-right';
import {EVENTS} from 'constants/languagesFields';
import {getLanguageFromStore} from '../../utils/formatters';
import {useResolution} from 'hooks/useResolution';
import ForestCust from '../ForestCust';

interface IAnnouncmentsProps {
  type: any;
  items: any;
  customClass: any;
  actions?: any;
  modalView?: any;
  withModal?: any;
  language?: any;
}

/**
 * Компонент для отображения плашек с анонсами
 * в зависивости от типа плашки
 * Анонсы продуктов, анонсы событий, графики, (2022) прогнозы
 *
 */

const PRODUCTS_ANNONCEMENTS = 'products';
const EVENTS_ANNONCEMENTS = 'events';
const CHARTS = 'charts';
const FORECAST_ANNONCEMENTS = 'forestcasts';

const Announcements = ({ type, items, customClass }: IAnnouncmentsProps) => {
  const { isMobile } = useResolution();
  let announcements;
  switch (type) {
    case PRODUCTS_ANNONCEMENTS:
      announcements = items.map((item: any, index: any) => {
        return <Product key={index} product={item} />;
      });
      break;
    case EVENTS_ANNONCEMENTS:
      announcements = (
        // @ts-ignore
        <Plate hasCloser={false} className="events events-mobile">
          {items.map((item: any, index: any) => {
            return <Events key={index} event={item} />;
          })}
          {!isMobile && (
            <Link
              className="events__bottom-link"
              text={EVENTS[getLanguageFromStore()]}
              size="m"
              icon={<ArrowRight size="m" />}
              iconPosition="right"
              url="/calendar"
            />
          )}
        </Plate>
      );
      break;
    case CHARTS:
      announcements = items.map((item: any, index: any) => (
        <Chart key={index} data={item} />
      ));
      break;
    case FORECAST_ANNONCEMENTS:
      // announcements = items.map((item: any, index: any) => {
      //   return <ForestCust key={index} item={item} />;
      // });
      announcements = <ForestCust items={items} isMobile={isMobile} />;
      break;

    default:
      break;
  }

  return <div className={`${customClass} announcements`}>{announcements}</div>;
};

export default Announcements;
