import * as ACTION_TYPE from './constants';

export const getAllLawFileRequest = ({ payload, meta }: any) => ({
    type: ACTION_TYPE.GET_ALL_LAW_FILE_REQUEST,
    meta,
    payload
});







