import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from 'modules/User/redux/selector';
import Menu from '../Menu';
import User from '../User';
import { SiteLogo } from '../SiteLogo/SiteLogo';
import './Header.scss';
import useToggleLanguage from 'utils/language';
import { useResolution } from '../../hooks/useResolution';
import HotNews from 'components/HotNews';

/**
 * Компонент Хедер
 * Содержит в себе конпоненты Меню, Поиск, Переключение языка
 */
const Header = ({ user: { name, clientRuName, clientEnName } }) => {
  const [language, handleToggleLanguage] = useToggleLanguage();
  const { isMobile } = useResolution();
  const changeLanguage = () => {
    handleToggleLanguage();
    window.location.reload();
  };

  if (language === 'ru' && clientRuName) {
    name = clientRuName;
  }
  if (language === 'en' && clientEnName) {
    name = clientEnName;
  }

  return (
    <>
      <div className="header">
        {!isMobile && <SiteLogo />}
        <div className="header__content">
          <Menu
            isMobile={isMobile}
            toggle={changeLanguage}
            language={language}
            userName={name}
          />
          {!isMobile && <User username={name} />}
        </div>
      </div>
      {isMobile && <HotNews isMobile={isMobile} />}
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
});

export default connect(mapStateToProps)(Header);
