import Auth from 'modules/Auth/routes';
import EquityResearch from 'modules/EquityResearch/routes';
import Information from 'modules/Information/routes';
import Economics from 'modules/Economics/routes';
import FixedIncome from 'modules/FixedIncome/routes';
import Commodities from 'modules/Commodities/routes';
import Companies from 'modules/Companies/routes';
import FutureEvents from 'modules/FutureEvents/routes';
import OurTeam from 'modules/OurTeam/routes';
import Home from 'modules/Home/routes';
import AnalystProfile from 'modules/AnalystProfile/routes';
import Search from 'modules/Search/routes';
import Profile from 'modules/Profile/routes';
import DownloadFile from 'modules/DownloadFile/routes';

export default [
  ...Auth,
  ...EquityResearch,
  ...Information,
  ...Economics,
  ...FixedIncome,
  ...Commodities,
  ...Companies,
  ...FutureEvents,
  ...OurTeam,
  ...Home,
  ...AnalystProfile,
  ...Search,
  ...Profile,
  ...DownloadFile,
];
