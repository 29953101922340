import Companies from './pages/companies/companies';
import CompanyView from 'modules/Companies/pages/company'

export default [
    {
        type: 'private',
        exact: true,
        path: '/companies',
        roles: ['admin'],
        component: Companies,
    },
    {
        type: 'private',
        exact: true,
        path: '/companies/about/:id',
        roles: ['admin'],
        component: CompanyView,
    },
    {
        type: 'private',
        exact: true,
        path: '/companies/reports/:id',
        roles: ['admin'],
        component: CompanyView,
    },
    {
        type: 'private',
        exact: true,
        path: '/companies/files/:id',
        roles: ['admin'],
        component: CompanyView,
    },
    {
        type: 'private',
        exact: true,
        path: '/companies/targetprice/:id',
        roles: ['admin'],
        component: CompanyView,
    },
];
