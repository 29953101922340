// @ts-nocheck
import {all, call, put, takeLeading} from 'redux-saga/effects';
import API from '../api';
import {GET_EVENTS_BY_LANGUAGE_AND_CATEGORY_REQUEST, GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_REQUEST,} from './constants';
import {errorMessageFailure} from "../../Error/redux/actions";
import {convertFileUrl} from "../../../utils/formatters";


function* getProductByLanguageAndCategory({payload, meta: {table, callback, currentPage}}: any) {
    payload.page = currentPage ? currentPage : 1;
    try {
        // const response = yield call(API.getProductByLanguageAndCategoryId, payload, table);
////// 2023-01-25
        let response = yield call(API.getProductByLanguageAndCategoryId, payload, table);
/// 2023-10-27        response = convertFileUrl( response )
        console.log('getProductByLanguageAndCategory response=', response)
//////

        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getEventsByLanguage({payload, meta: {table, currentPage, callback}}: any) {
    payload.page = currentPage ? currentPage : 1;
    try {
        const response = yield call(API.getEventsByLanguage, payload, table);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_REQUEST, getProductByLanguageAndCategory),
        takeLeading(GET_EVENTS_BY_LANGUAGE_AND_CATEGORY_REQUEST, getEventsByLanguage)
    ]);
}

export default Saga;
