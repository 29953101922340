import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import {
  selectAllValueSets,
  selectValueSet,
} from 'components/ValueSet/selector';
import SearchResult from 'components/SearchResult';

class SearchBar extends React.Component<any> {
  render() {
    const { dataSearch, shouldRenderResults, shouldRenderHeader } = this.props;
    return (
      <SearchResult
        dataSearch={dataSearch}
        shouldRenderResults={shouldRenderResults}
        shouldRenderHeader={shouldRenderHeader}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(SearchBar);
