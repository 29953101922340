import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';

class ClassAPI {
  getAllStaticInfo = () =>
    request({
      url: 'staticinfo',
      method: 'GET',
      token: API_GLOBAL.token,
    });

    getStaticInfoByType = (data:any) =>
        request({
            url: `staticinfo/type/${data?.type}`,
            method: 'GET',
            token: API_GLOBAL.token,
        });



}

const API_STATIC_INFO = new ClassAPI();

export default API_STATIC_INFO;
