import { createSelector } from 'reselect';

export const selectValueSetFromState = (state: any) => state.valueSets;

export const selectValueSet = (id?: any) =>
  createSelector(
    (state: any, props: any) => {
      return state.valueSets.get(id || props.id);
    },
    (values) => values,
  );

export const selectValueSetMap = (file: any) =>
  createSelector(
    selectValueSet(file),
    (valueSet) =>
      valueSet &&
      valueSet.reduce(
        (map: any, item: any) => ({ ...map, [item.code]: item }),
        {},
      ),
  );

export const selectAllValueSets = createSelector(
  selectValueSetFromState,
  (valueSet) => typeof valueSet.toJS === 'function' && valueSet.toJS(),
);
