// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import {formValueSelector} from 'redux-form';
import {IBodyTable, ICategoryTable, IHeadTable,} from 'components/Flows/Table/interface';
import {isArray} from 'lodash';
import {FUTUREEVENTS_TABLE, PAST_TABLE} from 'constants/tables';
import {getFutureEventsSortRequest} from '../../redux/actions';
import {getEventsByLanguageRequest} from 'modules/Category/redux/actions';
import {getAllSectorsRequest} from 'modules/Sector/redux/actions';
import {getAllAuthorRequest} from 'modules/Author/redux/actions';
import {pdfValidateTypeForHome} from 'components/Icon/Icons';
import Label from 'components/Label';
import {getLanguageFromStore, language} from 'utils/formatters';
import {TABLE_HEAD, TABLE_NAME} from 'constants/languagesTable';
import {getAllEventTypesRequest} from '../../../EventType/redux/actions';
import {getAllCompanyDupRequest} from '../../../Company/redux/actions';
import {getAllCountryRequest} from '../../../Country/redux/actions';
import {FUTUREEVENTS_FILTER} from '../../../../constants/forms';
import {connect} from 'react-redux';
import moment from 'moment';

class EquityResearchTable extends React.Component<ICategoryTable> {
  normalizeTableData = (
    head: IHeadTable[],
    body: IBodyTable[],
    valuesTable: any,
  ): any => {
    const {
      productType,
      //            author,
      sectors,
      categories,
      eventType,
      company,
      country,
      //            productsFilter
    } = valuesTable;
    // !!! Идея собирать все тысячи продуктов. чтобы здесь из подцепить к событиям не годится
    //    Если продукты необходимы для модальной формы события, то надо загружать ForestCust непосредственно
    //    для одного конкретного события

    // akayerov чтобы работало по F5
    const author = isArray(valuesTable.author)
      ? valuesTable.author
      : valuesTable.authorType;

    const processedData =
      (isArray(productType) &&
        isArray(sectors) &&
        //                isArray(productsFilter) &&
        isArray(author) &&
        isArray(productType) &&
        isArray(categories) &&
        isArray(eventType) &&
        isArray(company) &&
        isArray(country) &&
        isArray(body) &&
        body.map((item: any) => {
          const { id, endDate, eventTypeId, name, countryId }: any = item;

          const companyDATA = company
            .filter((o1: any) => {
              return (
                isArray(item.companies) &&
                item.companies.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((lu: any) => language(lu.name, 'en'))
            .join(', ');

          const event = eventType
            .filter((o1: any) => {
              return o1.id === eventTypeId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join(', ');

          const countryData = country
            .filter((o1: any) => {
              return o1.id === countryId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join(', ');

          // 2021-09-01 п.1.9
          const companyOrCountry = companyDATA ? companyDATA : countryData;
          // добавлено для списка
          //            console.log('item=', item);

          // 2021-09-13 Не знаю почему идет ориентация здесь на поле report, дочерний код требует поля file
          item.file =
            item.report == 'Download unavailable' && item.productId == undefined
              ? null
              : item.report;

          return {
            id,
            file: item.file,
            downloadAvailable: item.downloadAvailable,
            modal: {
              type: 'event',

              file: item.file,
              downloadAvailable: item.downloadAvailable,
              categoriesTitle: language(item.nameTypeProduct, 'en'),
              productName: item.nameProduct,
              date: item.loadDate
                ? moment(item.loadDate).format('DD.MM.YYYY')
                : '',

              company: companyOrCountry,
              eventType: event,
              comment: language(item.comment, 'en'),
              name: language(name, 'en'),
              startDate: item.startDate,
              endDate: item.endDate,
            },
            cells: [
              {
                element: endDate,
                id,
                type: 'endDate',
                width: 'm',
              },
              {
                element: <Label text={event} />,
                id,
                type: 'eventTypeId',
                width: 'm',
              },
              {
                element: companyOrCountry,
                id,
                type: 'companies',
                width: 'm',
              },
              {
                element: language(name, 'en'),
                id,
                type: 'name',
                width: 'm',
              },
              /*
                            {
                                element: countryData,
                                id,
                                type: 'countryId',
                                width: 'm',
                            },
*/
              {
                element: pdfValidateTypeForHome(item),
                id,
                type: 'pdf',
                width: 'xs',
                downloadAvailable: item?.downloadAvailable,
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: any) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  getRowLink = (id: any) => `/interface-settings/${id}`;

  render() {
    const { handleRowClick }: any = this.props;
    const head = [
      {
        id: 'endDate',
        label: TABLE_HEAD['date'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
      },
      {
        id: 'eventTypeId',
        label: TABLE_HEAD['eventType'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
        sort: 'default',
      },
      {
        id: 'companies',
        label: TABLE_HEAD['companyOrCountry'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
        sort: 'default',
      },
      {
        id: 'name',
        label: TABLE_HEAD['eventName'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
        sort: 'default',
      },
      /* 2021-09-01 п.1.9
            {
                id: 'countryId',
                label: TABLE_HEAD['country'][getLanguageFromStore()],
                type: 'modal',
                style: {width: 'm'},
                sort: 'default',
            },
*/
      {
        id: 'pdf',
        label: TABLE_HEAD['report'][getLanguageFromStore()],
        type: 'file',
        style: { width: 's' },
        sort: 'default',
      },
    ];

    const graphId = {
      future: true,
    };

    const actions = {
      author: getAllAuthorRequest,
      sectors: getAllSectorsRequest,
      eventType: getAllEventTypesRequest,
      company: getAllCompanyDupRequest,
      country: getAllCountryRequest,
    };
    const { value, showTitle }: any = this.props;
    const tableName =
      value?.defaultSelect?.[0] == 1 ? PAST_TABLE : FUTUREEVENTS_TABLE;

    return (
      <GraphTable
        customTableClass={'standart-table-m'}
        actions={actions}
        graphId={graphId}
        type={'edit'}
        filterForm={FUTUREEVENTS_FILTER}
        caption={showTitle ? TABLE_NAME[tableName][getLanguageFromStore()] : ''}
        table={FUTUREEVENTS_TABLE}
        normalizeTableData={this.normalizeTableData}
        sortRequest={getFutureEventsSortRequest}
        init={getEventsByLanguageRequest}
        onRowClick={handleRowClick}
        head={head}
        link={this.getRowLink}
        width={'7'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        pagination={true}
      />
    );
  }
}

const selector = formValueSelector(FUTUREEVENTS_FILTER);
const mapStateToProps = (state: any) => ({
  value: selector(state, 'periodFeature'),
});

export default connect(mapStateToProps)(EquityResearchTable);
