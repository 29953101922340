import React from 'react';
import PropTypes from 'prop-types';

import cn, {setup} from 'bem-cn';
import TreeView from '../TreeView';
import DateFilter from '../DateFilter';

import './Accordion.scss';

const accordionSetup = setup({
    mod: '--'
});
const accordion = accordionSetup('accordion');
const accordionSection = cn('accordion__section');

/**
 * Комнонент аккордион
 * Скрывает показывает скрытый контент, переданный в данный компонент
 */
export default class Accordion extends React.Component {
    static propTypes = {
        /** Заголовок фильтра */
        title: PropTypes.string,
        /** Статус фильтра (открыт или закрыт при загрузке страницы) */
        isExpanded: PropTypes.bool,
        /** Тип фильтра */
        type: PropTypes.oneOf(['list', 'calendar']),
        /** Количество одновременно выбранных элементов */
        mode: PropTypes.oneOf(['single', 'many']),
        /** Список содержимого для фильтра */
        items: PropTypes.array
    };
   
    static defaultProps = {
        title: '',
        type: '',
        mode: 'single',
        items: [],
        
    };

    constructor(props) {
        super(props);
        this.state = {
            isExpanded: this.props.isExpanded
        };
    }

    handleExpandedChange() {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    }

    render() {
        const {label, type, mode, data, language, input} = this.props;
        const props = this.props;
        const {isExpanded} = this.state;

        let filterContent;

        switch (type) {
            case 'list':
                filterContent = <TreeView  input={input} mode={mode} data={data}/>
                break;
            case 'calendar':
                filterContent = <DateFilter  input={input} language={language} {...props}/>;
                break;
            default:
                filterContent = 'Ошибка получения данных';
                break;
        }

        return (
            <div className={accordionSection()}>
                <button className={accordion({mod: isExpanded ? 'is-open' : 'is-close'})}
                        onClick={this.handleExpandedChange.bind(this)}>
                    <span className={accordion('title')}>
                        {label}
                    </span>
                    <span className={isExpanded ? "accordion__icon" : "accordion__icon-close"}></span>
                </button>
                <div className={accordion('content', {mod: this.state.isExpanded ? 'is-show' : 'is-hide'})}>
                    {filterContent}
                </div>
            </div>
        )
    }
}

