import React from 'react';
import PropTypes from 'prop-types';

import cn from 'bem-cn';

import './Label.scss';

const labelTag = cn('label-tag');

const Label = (props) => {
    return (
        <span className={ labelTag() }>
            { props.text }
        </span>
    )
}

Label.defaultProps = {
    text: ''
};

Label.propTypes = {
    text: PropTypes.string.isRequired
};

export default Label;