// @ts-nocheck
import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import FlowForm from 'components/Flows/Form';
import { makeSelectData } from './selector';
import { formDataRequest, formEditRequest, formCreateRequest } from './actions';
import { FieldFormElement } from 'components/Flows/Form/Interfaces';

interface Fields {
  username: string;
}

interface Groups {
  [name: string]: Fields;
}

interface ICreateForm {
  callback: (type: string, payload: any) => void;
  fields: Groups;
}

interface IGraphForm {
  title?: string;
  type?: string;
  columns?: number;
  config: FieldFormElement[];
  form: string;
  actions: ReactNode;
  init?: any;
  edit?: any;
  submit?: any;
  create?: any;
  normalize: (graphData: object, config: FieldFormElement[]) => void;
  validate: (values: object, type: string) => void;
  graphData?: object;
  dispatch: Dispatch;
  width?: string;
  graphId?: any;
  reInitialize?: boolean;
  onSuccess?: any;
}

class GraphForm extends React.PureComponent<IGraphForm> {
  componentDidMount() {
    this.getFormData(this.props);
  }

  componentDidUpdate(prevProps: IGraphForm) {
    const { graphId }: any = this.props;
    if (graphId !== prevProps.graphId) {
      this.getFormData(this.props);
    }
  }

  getFormData({ type, dispatch, form, init, graphId }: IGraphForm) {
    if (type === 'edit' || type === 'view') {
      dispatch(
        formDataRequest({
          type,
          meta: { form },
          payload: { init, data: { id: graphId } },
        }),
      );
    }
  }

  submit = (fields: Array<object>) => {
    const {
      type,
      edit,
      submit,
      create,
      dispatch,
      onSuccess,
      form,
    }: IGraphForm = this.props;
    let data = {};
    if (submit) {
      data = submit(fields);
    } else {
      data = fields;
    }

    if (type === 'create') {
      dispatch(
        formCreateRequest({
          type,
          meta: { form, onSuccess },
          payload: { create, fields: data },
        }),
      );
    } else if (type === 'edit') {
      dispatch(
        formEditRequest({
          type,
          meta: { form, onSuccess },
          payload: { edit, fields: data },
        }),
      );
    }
  };

  render() {
    const {
      graphData,
      normalize,
      config,
      form,
      validate,
      width,
      ...rest
    }: IGraphForm = this.props;
    const formData = graphData
      ? normalize(graphData, config)
      : config.reduce((formData: object | null, item: any) => {
          if (typeof item.defaultValue === 'undefined') {
            return formData;
          }
          return {
            ...formData,
            [item.id]: item.defaultValue,
          };
        }, null);

    return (
      <FlowForm
        form={form}
        config={config}
        normalize={normalize}
        validate={validate}
        onSubmit={this.submit}
        formData={formData}
        width={width}
        {...rest}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  graphData: makeSelectData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(GraphForm);
