import EquityResearch from './pages';

export default [
  {
    type: 'private',
    exact: true,
    path: '/equity',
    roles: ['admin'],
    component: EquityResearch,
  },
];
