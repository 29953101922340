import {all, takeEvery,takeLeading, put} from 'redux-saga/effects';
import {getSuccess} from './actions';
import {NEWS_DATA_REQUEST} from './constants';
import {IAction} from 'types/index'

function* get({payload, meta: {table, init}}: IAction<void>) {
    yield put(init({payload, meta: {table, callback: getSuccess, payload}}))
}

function* Saga() {
    yield all([
        takeLeading(NEWS_DATA_REQUEST, get),
    ]);
}

export default Saga;
