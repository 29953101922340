import React from 'react';
import cn from 'bem-cn';
import Heading from 'arui-feather/heading';
import Plate from 'arui-feather/plate';
import './Chart.scss';

const chart = cn('chart');

class Chart extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <Plate hasCloser={false} className={chart()}>
        <Heading size="s">{data.title}</Heading>
        <div className={chart('body')}>
          <img
            src={`${process.env.PUBLIC_URL}${data.image}`}
            alt="temp-chart"
          />
        </div>
      </Plate>
    );
  }
}

export default Chart;
