import React from 'react';

import {getAllSectorsRequest} from 'modules/Sector/redux/actions'
import {getAllCompanyDupRequest, getAllCompanyRequest} from 'modules/Company/redux/actions'
import {productTypeByCategoryRequest} from 'modules/ProductType/redux/actions'
import {getActiveAuthorRequest, getAllAnalystRequest, getAllAuthorRequest} from 'modules/Author/redux/actions';
import {getLanguageFromStore} from 'utils/formatters'
import {FIELD_NAME} from 'constants/languagesFields';
import {CATEGORIES} from "constants/categories";
import {EQUITYRESEARCH_TABLE} from 'constants/tables';


export default [
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "single",
        bigLabel: false,
        id: 'sectorCompany',
        label: FIELD_NAME['sectorCompany'][getLanguageFromStore()],
        normalize: 'sector-company',
        actions: {company: getAllCompanyDupRequest, sectors: getAllSectorsRequest},
    },
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "many",
        bigLabel: false,
        id: 'productTypeByCategory',
        normalize: 'product-type-by-category',
        label: FIELD_NAME['productType'][getLanguageFromStore()],
        actions: {
            productTypeByCategory: {
                voidFunc: productTypeByCategoryRequest,
                id: CATEGORIES[EQUITYRESEARCH_TABLE]
            }
        },
    },
    {
        fieldWidth: '12',
        customPosition: {
            label: 1,
            field: 10,
        },
        isExpanded: false,
        type: "list",
        name: 'sector',
        mode: "many",
        bigLabel: false,
        id: 'authorType',
        label: FIELD_NAME['author'][getLanguageFromStore()],
        normalize: 'authorType',
// akayerov
//        actions: {authorType: getAllAnalystRequest},
//        actions: {authorType: getAllAuthorRequest},
// 2021-10-04
        actions: {authorType: getActiveAuthorRequest},
    },
    {
        fieldWidth: '12',
        title: "Date",
        id: 'date',
        name: "date",
        label: FIELD_NAME['date'][getLanguageFromStore()],
        isExpanded: false,
        type: "calendar",
        bigLabel: false,
        dateFrom: '01.01.2020',
        dateTo: '02.02.2020',
        language: 'rus'
    },


];
