import React from 'react';
import Link from 'arui-feather/link/link';
import useToggleLanguage from 'utils/language';
import { SiteLogo } from '../SiteLogo/SiteLogo';

import './Header.scss';

/**
 * Компонент Хедер
 * Содержит в себе конпоненты Меню, Поиск, Переключение языка
 */
const Header = () => {
  const [language, handleToggleLanguage] = useToggleLanguage();
  const changeLanguage = () => {
    handleToggleLanguage();
    window.location.reload();
  };

  return (
    <div className="header">
      <SiteLogo />
      <div className="header__lang">
        <div className="lang-bar">
          <Link
            onClick={changeLanguage}
            text={language === 'ru' ? 'en' : 'ru'}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
