// @ts-nocheck
import React, {Component} from 'react';
import CheckboxGroup from 'arui-feather/checkbox-group';
import InputGroup from 'arui-feather/input-group';
import Heading from 'arui-feather/heading';
import {compose} from 'redux';
import {find, uniqueId} from 'lodash';
import {withRouter} from 'react-router';
import {createStructuredSelector} from 'reselect';
import {selectAllValueSets} from 'components/ValueSet/selector';
import {connect} from 'react-redux';
import {Field} from 'redux-form';
import {CheckBoxComponent, RadioComponent} from 'components/CheckboxesThree';
import {makeSelectData} from 'components/Graphs/Form/selector';
import {getLanguageFromStore, hashCode, language} from 'utils/formatters';
import {LANGUAGE_EN, LANGUAGE_RU} from 'constants/languages';
import {setValueSet} from '../../../../components/ValueSet/actions';
import {CLIENT, PROFILE} from 'constants/languagesTable';
import Collapsible from 'react-collapsible';

import '../ProfileForm.scss';

import InputText from 'components/Inputs/Text';
import {useResolution} from 'hooks/useResolution';
import {FIELD_NAME} from 'constants/languagesFields';

const LabelCHeckGroup = ({
  itemsToCheck,
  step,
  title,
}: {
  itemsToCheck: any;
  step: string;
  title: string;
}) => {
  return (
    <div className="label-chek-group">
      <span
        className={`icon icon_size_m ${
          itemsToCheck.includes(true)
            ? 'icon_name_ok'
            : 'icon_name_attention-mark'
        } icon_colored icon_theme_alfa-on-white`}
      />
      <div className="label-chek-group__content">
        <span>
          {PROFILE['step'][getLanguageFromStore()]} {step}
        </span>
        <span>{title}</span>
      </div>
    </div>
  );
};

const CustomChackboxGroup = ({
  item,
  email,
  accessItems,
  valueSetsAll,
  access,
}: {
  item: any;
  email: any;
  accessItems: any;
  valueSetsAll: any;
  access: any;
}) => {
  const { isMobile } = useResolution();
  const groupLength = valueSetsAll.productType
    .map((itemChild: any) => {
      return itemChild.categories.includes(item.id);
    })
    .filter((item: any) => item);
  return (
    <div key={uniqueId()} className="input__group-checkbox">
      <h4 className="group-checkbox-title">{language(item.name, 'en')}</h4>
      <CheckboxGroup
        label={
          isMobile && groupLength.length < 2 ? null : (
            <Field
              name={`deliveryData.${hashCode(email)}.checkboxes.${item.id}`}
              type="text"
              component={CheckBoxComponent}
              view="filled"
              width="available"
              size="s"
              disabled={
                !find(accessItems, {
                  categoryId: item.id,
                })
              }
              label={
                isMobile
                  ? FIELD_NAME['uncheckall'][getLanguageFromStore()]
                  : language(item.name, 'en')
              }
            />
          )
        }
      >
        <div className="input__group-checkbox-items">
          {valueSetsAll &&
            valueSetsAll.productType &&
            valueSetsAll.productType.map((itemChild: any) => {
              if (itemChild.categories.includes(item.id)) {
                return (
                  <Field
                    key={uniqueId()}
                    name={`deliveryData.${hashCode(email)}.checkboxes.${
                      item.id
                    }.${itemChild.id}`}
                    type="text"
                    component={CheckBoxComponent}
                    view="filled"
                    width="available"
                    disabled={!access?.productTypes.includes(itemChild.id)}
                    size="s"
                    label={language(itemChild.name, 'en')}
                  />
                );
              }
            })}
        </div>
      </CheckboxGroup>
    </div>
  );
};

class ClientPageConfig extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      fakeRender: true,
    };
  }

  static defaultProps = {
    form: 'CLIENTS_CONFIG_FORM',
  };

  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  render() {
    const { graphData, valueSetsAll, customProps } = this.props;

    let clientName = this.props?.clientName;
    let password = this.props?.password;
    let confirmPassword = this.props?.confirmPassword;
    let userFromAD = this.props?.userFromAD;
    let deliveryData = [];
    let accessItems = [] as any;

    if (graphData) {
      deliveryData = graphData.deliveryData;
      accessItems = graphData.accessItems;
      clientName = graphData.clientName;
      password = graphData.password;
      confirmPassword = graphData.password;
      userFromAD = graphData.userFromAD;
    }
//    console.log('RENDER =', this.props, userFromAD)

    const languageLook: boolean[] = [];
    const checkBoxStateArr: boolean[] = [];
    const sectorsStateArr: boolean[] = [];

    deliveryData.map((val: any) => {
      const has = hashCode(val?.email);
      const uni = customProps?.[has];
      const language = uni?.engLang || uni?.rusLang;
      const checkBoxState = [];
      const sectors = [];

      for (const prop in uni?.checkboxes) {
        const stateProps = uni?.checkboxes?.[prop];
        for (const propsTwo in stateProps) {
          const statePropsTwo = stateProps?.[propsTwo];
          checkBoxState.push(statePropsTwo);
        }
      }
      const checkBoxStateValue = checkBoxState.includes(true);

      for (const sec in uni?.sectors) {
        const statePropsSectors = uni?.sectors?.[sec];
        sectors.push(statePropsSectors);
      }

      const sectorsStateValue = sectors.includes(true);

      languageLook.push(language);
      checkBoxStateArr.push(checkBoxStateValue);
      sectorsStateArr.push(sectorsStateValue);
    });

    return (
      <div className="files-tab__form">
        <div className="basic-information-tab">
          <div className="basic-information-tab__item">
            <Heading size="s" />
          </div>
        </div>

        <div className="basic-information-tab">
          <div className="basic-information-tab__item">
            <div
              className="form-row user-form__setting"
              style={{ paddingTop: '0px' }}
            >
              <div className="user-form__setting__label">
                {CLIENT['name'][getLanguageFromStore()]}
              </div>
              <div className="form-row__cell input prof-radio">
                <div className="input--item">
                  <div className="input--item__col">
                    <Field
                      type="text"
                      name="clientName"
                      value={clientName}
                      component={InputText}
                      placeholder={CLIENT['name'][getLanguageFromStore()]}
                      props={{
                        disabled: userFromAD,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="form-row user-form__setting"
              style={{ paddingTop: '0px' }}
            >
              <div className="user-form__setting__label">
                {CLIENT['password'][getLanguageFromStore()]}
              </div>
              <div className="form-row__cell input prof-radio">
                <div className="input--item">
                  <div className="input--item__col">
                    <Field
                      type="password"
                      name="password"
                      value={password}
                      component={InputText}
                      autocomplete="off"
                      placeholder={
                        CLIENT['passwordPlaceholder'][getLanguageFromStore()]
                      }
                      props={{
                        disabled: userFromAD,
                      }}

                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="form-row user-form__setting"
              style={{ paddingTop: '0px' }}
            >
              <div className="user-form__setting__label">
                {CLIENT['confirmPassword'][getLanguageFromStore()]}
              </div>
              <div className="form-row__cell input prof-radio">
                <div className="input--item">
                  <div className="input--item__col">
                    <Field
                      type="password"
                      name="confirmPassword"
                      value={confirmPassword}
                      component={InputText}
                      autocomplete="off"
                      placeholder={
                        CLIENT['confirmPasswordPlaceholder'][
                          getLanguageFromStore()
                        ]
                      }
                      props={{
                        disabled: userFromAD,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row prof-border user-form__setting padding-top-0">
              <div className="user-form__setting__label">
                {PROFILE['defaultLanguage'][getLanguageFromStore()]}
              </div>
              <div className="form-row__cell input prof-radio">
                <div className="input--item">
                  <div className="input--item__col">
                    <Field
                      type="radio"
                      label="EN"
                      name="portalDefaultLanguageId"
                      value={LANGUAGE_EN}
                      component={RadioComponent}
                    />
                    <Field
                      type="radio"
                      label="RU"
                      name="portalDefaultLanguageId"
                      value={LANGUAGE_RU}
                      component={RadioComponent}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-row user-form__setting form-trigger-info">
              <div className="user-form__setting__label">
                {PROFILE['settingMail'][getLanguageFromStore()]}
              </div>
              <div className="form-row__cell input">
                <div className="input--item">
                  {deliveryData.map(({ email }: any) => (
                    <div key={uniqueId()} className="input--item__col">
                      <span>{email}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="delivery-data-wrapper">
              {deliveryData.map(({ email }: any) => (
                <DeliveryDataByEmail
                  key={email}
                  email={email}
                  languageLook={languageLook}
                  graphData={graphData}
                  valueSetsAll={valueSetsAll}
                  checkBoxStateArr={checkBoxStateArr}
                  sectorsStateArr={sectorsStateArr}
                  accessItems={accessItems}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const CollapsibleTrigger = ({ email }: { email: string }) => {
  return (
    <div className="form-row user-form__setting form-trigger-info-mobile">
      <div className="user-form__setting__label">
        {PROFILE['settingMail'][getLanguageFromStore()]}
      </div>
      <div className="form-row__cell input">
        <div className="input--item">
          <div key={uniqueId()} className="input--item__col">
            <span>{email}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeliveryDataByEmail = ({
  email,
  languageLook,
  graphData,
  valueSetsAll,
  checkBoxStateArr,
  sectorsStateArr,
  accessItems,
}: {
  email: string;
  languageLook: any;
  graphData: any;
  valueSetsAll: any;
  checkBoxStateArr: any;
  sectorsStateArr: any;
  accessItems: any;
}) => {
  const { isMobile } = useResolution();
  return (
    <Collapsible
      triggerDisabled={!isMobile}
      open={!isMobile}
      trigger={<CollapsibleTrigger email={email} />}
    >
      <div className="form-row user-form__setting">
        <div className="form-row__cell input">
          <div className="input--item" style={{paddingLeft:0, paddingRight:0}}>
            <div className="user-form__setting__label input--item--column">
              <LabelCHeckGroup
                itemsToCheck={languageLook}
                step="1"
                title={PROFILE['chooseLanguage'][getLanguageFromStore()]}
              />
            </div>
            <div
              key={uniqueId()}
              className="input--item__col input--item--column"
            >
              <InputGroup>
                <Field
                  name={`deliveryData.${hashCode(email)}.engLang`}
                  type="text"
                  component={CheckBoxComponent}
                  view="filled"
                  width="available"
                  size="s"
                  label="EN"
                  disabled={!graphData?.permittEngLang}
                />
                <Field
                  name={`deliveryData.${hashCode(email)}.rusLang`}
                  type="text"
                  component={CheckBoxComponent}
                  view="filled"
                  width="available"
                  size="s"
                  label="RU"
                  disabled={!graphData?.permittRusLang}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>

      <div
        className="form-row user-form__setting"
        style={{ alignItems: 'start' }}
      >
        <div
          style={{ flex: '1.6 1' }}
          className="user-form__setting__label input--item--column"
        >
          <LabelCHeckGroup
            itemsToCheck={checkBoxStateArr}
            step="2"
            title={PROFILE['chooseCategory'][getLanguageFromStore()]}
          />
        </div>
        <div className="form-row__cell input input--item--column">
          <div className="input--item" style={{paddingLeft:0, paddingRight:0}}>
            <div className="input--item__col">
              {valueSetsAll &&
                valueSetsAll.categories &&
                valueSetsAll.categories.map((item: any) => {
                  if (!item.serviceCategory && item.active) {
                    const access = find(accessItems, {
                      categoryId: item.id,
                    });
                    return (
                      <CustomChackboxGroup
                        key={uniqueId()}
                        item={item}
                        email={email}
                        accessItems={accessItems}
                        valueSetsAll={valueSetsAll}
                        access={access}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
      </div>

      <div
        className="form-row user-form__setting"
        style={{ alignItems: 'start' }}
      >
        <div
          style={{ flex: '1.6 1' }}
          className="user-form__setting__label input--item--column"
        >
          <LabelCHeckGroup
            itemsToCheck={sectorsStateArr}
            step="3"
            title={PROFILE['selectSector'][getLanguageFromStore()]}
          />
        </div>
        <div
          key={uniqueId()}
          className="form-row__cell input input--item--column"
        >
          <div className="input--item" style={{paddingLeft:0, paddingRight:0}}>
            <div className="input--item__col">
              <div className="input__group-checkbox">
                {valueSetsAll &&
                  valueSetsAll.sectors &&
                  valueSetsAll.sectors.map((sector: any) => (
                    <Field
                      key={uniqueId()}
                      className="display_block margin-bottom-15"
                      name={`deliveryData.${hashCode(email)}.sectors.${
                        sector.id
                      }`}
                      type="text"
                      component={CheckBoxComponent}
                      view="filled"
                      width="available"
                      size="s"
                      label={language(sector.name, 'en')}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="form-row user-form__setting">
        <div className="form-row__cell input">
          <div className="input--item" style={{paddingLeft:0, paddingRight:0}}>
            <div className="user-form__setting__label input--item--column">
              <LabelCHeckGroup
                itemsToCheck={languageLook}
                step="4"
                title={PROFILE['chooseAttachFile'][getLanguageFromStore()]}
              />
            </div>
            <div
              key={uniqueId()}
              className="input--item__col input--item--column"
            >
              <InputGroup>
                <Field
                  name={`deliveryData.${hashCode(email)}.attachProduct`}
                  type="text"
                  component={CheckBoxComponent}
                  view="filled"
                  width="available"
                  size="s"
                  label={PROFILE['attachFile'][getLanguageFromStore()]}
                  disabled={!graphData?.permittEngLang}
                />
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
    </Collapsible>
  );
};

const mapStateToProps = () => {
  const defaultProps = {
    valueSetsAll: selectAllValueSets,
    graphData: makeSelectData(),
  };
  return createStructuredSelector(defaultProps);
};

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(ClientPageConfig);
