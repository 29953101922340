import { makeRequest, request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';
import  {getFormattedDate} from 'utils/formatters'
export const API_HOST = process.env.REACT_APP_API_HOST;

class ClassAPI {
  UploadImage = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('file', file[0], file[0].name);
    return makeRequest({
      url: `${API_HOST ? API_HOST : ''}/minio/upload/alfaresearch/avatars`,
      method: 'POST',
      data: bodyFormData,
      headers: {
        'Content-type': 'multipart/ourTeam-data',
        Authorization: `Bearer ${API_GLOBAL.token}`,
      },
    });
  };

  uploadCompanyXlsx = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('fileLoad', file[0], file[0].name);
    bodyFormData.append('loadDate', getFormattedDate(new Date()));
    return request({
      data: bodyFormData,
      url: 'upload/companyGraphLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };

  UploadSectorXlsx = (file: any) => {
    var bodyFormData = new FormData();
    bodyFormData.append('fileLoad', file[0], file[0].name);
    bodyFormData.append('loadDate', getFormattedDate(new Date()));
    return request({
      data: bodyFormData,
      url: 'upload/sectorGraphLoad',
      method: 'POST',
      token: API_GLOBAL.token,
    });
  };
}

const API_FILES = new ClassAPI();

export default API_FILES;
