import axios, { AxiosRequestConfig } from 'axios';
import { API_HOST } from './constants'

const successStatusCode = [200, 201]

export function makeRequest(options: AxiosRequestConfig) {
  // @ts-ignore
  return axios(options)
    .then((response) => {
      if (!successStatusCode.includes(response.status)) {
        throw response;
      }
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

interface IToken {
  token?: string
}

type IAxiosRequestConfigToken = AxiosRequestConfig & IToken;

export function request({ method, url, data, params, token }: IAxiosRequestConfigToken) {
  if (url?.includes('minio')){
    url = `${API_HOST ? API_HOST : ''}/${url}`;
  }else {
    url = `${API_HOST ? API_HOST : ''}/v1/${url}`;
  }
  //url = `${API_HOST}/${url}`;
  const options = { method, url } as AxiosRequestConfig;
  if (token) options.headers = {
    Authorization: `Bearer ${token}`
  };
  if (data) {
    options.data = data;
  }
  if (params) {
    options.params = params;
  }
  return makeRequest(options);
}
