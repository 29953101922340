// @ts-nocheck
import React from 'react';
import Button from 'arui-feather/button';
import { getLanguageFromStore } from 'utils/formatters';
import FormField from 'arui-feather/form-field';
import Paragraph from 'arui-feather/paragraph';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { LOGIN_INFO } from 'constants/languagesTable';

interface IActions {
  isMobile: boolean;
  restore: boolean;
  toggleRestore: () => void;
}

export const Actions = ({ isMobile, restore, toggleRestore }: IActions) => {
  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <GridCol
        width={{
          'mobile-s': 12,
          'mobile-m': 12,
          'mobile-l': 12,
          tablet: 6,
          desktop: 6,
        }}
      >
        <Button view="extra" type="submit" width="available">
          {LOGIN_INFO['signIn'][getLanguageFromStore()]}
        </Button>
      </GridCol>
      <GridCol
        width={{
          'mobile-s': 12,
          'mobile-m': 12,
          'mobile-l': 12,
          tablet: 6,
          desktop: 6,
        }}
      >
        {isMobile ? (
          <Button
            className="margin-top-15"
            width="available"
            onClick={toggleRestore}
          >
            {LOGIN_INFO['restore'][getLanguageFromStore()]}
          </Button>
        ) : (
          <div className="field-control__section mr-res">
            <span
              onClick={toggleRestore}
              className="link link_size_m link_theme_alfa-on-white"
            >
              <span className="link__text-container">
                <span className="link__text">
                  {LOGIN_INFO['restore'][getLanguageFromStore()]}
                </span>
              </span>
              <span className="link__icon">
                <span className="icon icon_size_s icon_name_arrow-right icon_theme_alfa-on-white" />
              </span>
            </span>
          </div>
        )}
      </GridCol>
      {restore && (
        <FormField className="field-control field-control-pa">
          <Paragraph
            view="normal"
            className="help-information field-control-pa"
          >
            {LOGIN_INFO['descrption'][getLanguageFromStore()]}
            <a
              className={'tel-pa'}
              href="mailto:supportresearchportal@alfabank.ru"
            >
              supportresearchportal@alfabank.ru
            </a>
          </Paragraph>
        </FormField>
      )}
    </GridRow>
  );
};
