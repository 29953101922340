export const GET_ALL_EQUITYRESEARCH_REQUEST = 'GET_ALL_EQUITYRESEARCH_REQUEST'
export const GET_ALL_EQUITYRESEARCH_SUCCESS = 'GET_ALL_EQUITYRESEARCH_SUCCESS'
export const GET_ALL_EQUITYRESEARCH_FAILURE = 'GET_ALL_EQUITYRESEARCH_FAILURE'

export const CREATE_NEW_EQUITYRESEARCH_REQUEST = 'CREATE_NEW_EQUITYRESEARCH_REQUEST'


export const EDIT_EQUITYRESEARCH_REQUEST = 'EDIT_EQUITYRESEARCH_REQUEST'


export const GET_FUTUREEVENTS_SORT_REQUEST = 'GET_FUTUREEVENTS_SORT_REQUEST';

