// @ts-nocheck
import React from 'react';
import SearchBar from 'components/SearchBar';
import { connect } from 'react-redux';
import { selectSearchData } from '../redux/selector';
import { throttle } from 'utils/throttle';
import { getDataSearchRequest } from '../redux/actions';
import { createStructuredSelector } from 'reselect';
import { getAllAuthorRequest } from '../../Author/redux/actions';
import {
  getAllCompanyDupRequest,
  getAllCurrencyRequest,
  getAllEquityTickersRequest,
  getAllStockRecommendationRequest,
  getAllStockRequest,
  getAllStockTypeRequest,
} from '../../Company/redux/actions';
import { getAllSectorsRequest } from '../../Sector/redux/actions';
import { getAllcategoriesRequest } from '../../Categories/redux/actions';
import { setValueSet } from '../../../components/ValueSet/actions';
import { selectAllValueSets } from '../../../components/ValueSet/selector';
import { getAllProductWithFilterRequest } from '../../Product/redux/actions';

class SearchComponent extends React.Component<any, any> {
  componentDidMount() {
    const { dispatch, isOpen } = this.props;
    const actions = {
      author: getAllAuthorRequest,
      companies: getAllCompanyDupRequest,
      sectors: getAllSectorsRequest,
      equityTickers: getAllEquityTickersRequest,
      currency: getAllCurrencyRequest,
      recommendation: getAllStockRecommendationRequest,
      stock: getAllStockRequest,
      categories: getAllcategoriesRequest,
      stockType: getAllStockTypeRequest,
      productsFilter: getAllProductWithFilterRequest,
    } as any;

    if (isOpen) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  onChangeSearch = (value: string) => {
    const { dispatch } = this.props;
    throttle(dispatch(getDataSearchRequest({ payload: { value } })), 3000);
  };

  render() {
    const { dataSearch, valueSetsAll } = this.props;
    return (
      <SearchBar
        valueSetsAll={valueSetsAll}
        dataSearch={dataSearch}
        onChangeSearch={this.onChangeSearch}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  dataSearch: selectSearchData,
  valueSetsAll: selectAllValueSets,
});

export default connect(mapStateToProps)(SearchComponent);
