// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import {isArray, includes} from 'lodash';
import {getAllCompanyRequest} from 'modules/Company/redux/actions'
import {ICategoryTable, IHeadTable, IBodyTable} from 'components/Flows/Table/interface';
import {ANALYST_PROFILE_TABLE} from 'constants/tables';
import {
    getEquityResearchSortRequest,
} from '../../redux/actions';
import {getProductByLanguageAndCategoryRequest} from 'modules/Category/redux/actions'
import {productTypeRequest} from 'modules/ProductType/redux/actions'
import {language, getLanguageFromStore} from 'utils/formatters'
import {TABLE_HEAD, TABLE_NAME} from "constants/languagesTable";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {ANALYST_PROFILE_FILTER} from "constants/forms";
import {getAllAuthorRequest} from "../../../Author/redux/actions";
import {getAllSectorsRequest} from "../../../Sector/redux/actions";
import {getAllcategoriesRequest} from "../../../Categories/redux/actions";

class EquityResearchTable extends React.Component<ICategoryTable> {

    normalizeTableData = (head: IHeadTable[], body: IBodyTable[], valuesTable: any): any => {

        // console.log('analist normalize', valuesTable)
        const {match: {params: {id}}}: any = this.props;
        const authorID = id;
        const {productType, product, author, sectors, categories, company} = valuesTable;
        // console.log('analist conditions', productType, author, sectors, categories, company)
        if (isArray(company) &&
            (isArray(productType) || isArray(product)) &&
            isArray(sectors) &&
            isArray(author) &&
            isArray(categories) &&
            isArray(body)) {
            // console.log('analist body=', body)
            let arrr = body.map((item: any, i) => {
                const {id, name}: any = item;
                let emptyWidth: any = '';

                let companyList = company.filter((o1: any) => {
                    return isArray(item.companies) && item.companies.some((o2: any) => {
                        //return item.companies.filter((o2: any) => {
                        return o1.id === o2
                    })
                }).map((y: any) => {
                    return language(y.name, 'en')
                });


/*
                let strategy = productType.filter((o1: any) => {
                    return o1.id === item.productTypeId
                }).map((lu: any) => {
                    return language(lu.name, 'en')
                }).join('');
*/
               let prodType
                if( productType ) {
                    prodType = productType
                }
                else {
                    prodType = product

                }


                let strategy = prodType.filter((o1: any) => {
                    return o1.id === item.productTypeId
                }).map((lu: any) => {
                    return language(lu.name, 'en')
                }).join('');



                let sector = sectors.filter((o1: any) => {
                    return isArray(item.sectors) && item.sectors.some((o2: any) => {
                        return o1.id === o2
                    })
                }).map((lu: any) => {
                    return ({
                        name: language(lu.name, 'en'),
                        id: lu.id,
                    })
                });
                let categoriesTitle = categories.filter((o1: any) => {
                    return o1.id === item.categoryId
                }).map((lu: any) => {
                    return language(lu.name, 'en')
                }).join('');

                let au = author.filter((o1: any) => {
                    return isArray(item.authors) && item.authors.some((o2: any) => {
                        return o1.id === o2
                    })
                }).map((y: any) => {
                    return ({
                        name: language(y.name, 'en'),
                        id: y.id,
                        photo: y.photo
                    })
                });

                // console.log('item = ', item)
                return {
                    id,
                    modal: {
                        sectors: sector,
                        authors: au,
                        name: item.name,
                        annotation: item.annotation,
                        file: item?.file,
                        downloadAvailable: item?.downloadAvailable,
                        date: item.date,
                        id: item.id,
                        strategy: strategy,
                        categoriesTitle: categoriesTitle
                    },
                    cells: [
                        {
                            element: item.name,
                            id,
                            type: 'name',
                            width: 'l',
                        },

                        {
                            element: companyList.map((v: any) => <div>{v}</div>),
                            id,
                            type: 'company',
                            width: 'l'
                        },
                        {
                            element: item.date,
                            id,
                            type: 'date',
                            width: 'l'
                        },
                    ]
                };
            }) || [];
            const normalizeData = arrr.map((val: any) => {
                let newCells = val;
                newCells.cells = val.cells.map((j: any) => {
                    const index = head.findIndex((m) => m.id === j.type);
                    j.width = head[index].style!.width;
                    return j;
                });
                return newCells
            });

            return normalizeData;

        }
    };


    getRowLink = (id: any) => `/interface-settings/${id}`

    render() {
        const {match: {params: {id}}, handleRowClick}: any = this.props;
        const head = [
            {
                id: 'name',
                label: TABLE_HEAD['name'][getLanguageFromStore()],
                type: 'modal',
                style: {width: 'l'},
                sort: 'default'
            },
            {
                id: 'company',
                label: TABLE_HEAD['company'][getLanguageFromStore()],
                type: 'modal',
                style: {width: 's'},
            },
            {
                id: 'date',
                label: TABLE_HEAD['date'][getLanguageFromStore()],
                type: 'modal',
                style: {width: 's'},
            },
        ];


        const graphId = {authorId: id};
        const actions = {
            author: getAllAuthorRequest,
            sectors: getAllSectorsRequest,
            productType: productTypeRequest,
            categories: getAllcategoriesRequest,
            company: getAllCompanyRequest,



        };

// @ts-ignore
        return (<GraphTable
                authorId={id}
                customTableClass={'standart-table-m'}
                actions={actions}
                graphId={graphId}
                type={'edit'}
                filterForm={ANALYST_PROFILE_FILTER}
                caption={TABLE_NAME[ANALYST_PROFILE_TABLE][getLanguageFromStore()]}
                table={ANALYST_PROFILE_TABLE}
                sizeTitle={'small'}
                normalizeTableData={this.normalizeTableData}
                sortRequest={getEquityResearchSortRequest}
                init={getProductByLanguageAndCategoryRequest}
                onRowClick={handleRowClick}
                head={head}
                link={this.getRowLink}
                width={'12'}
                gutter={{mobile: 0, tablet: 16, desktop: {m: 12}}}
                pagination={true}
            />
        );
    }
}

const withConnect = connect();


export default compose<any>(
    withConnect,
    withRouter
)(EquityResearchTable)
