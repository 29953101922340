// @ts-nocheck
import React from 'react';
import {
  BarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import Plate from 'arui-feather/plate/plate';
import { isArray } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  selectAllValueSets,
  selectValueSet,
} from 'components/ValueSet/selector';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { setValueSet } from 'components/ValueSet/actions';
import {
  getGraphByCompanyIdRequest,
  getCompanyStockIdRequest,
} from 'modules/Company/redux/actions';
import { getLanguageFromStore, language } from 'utils/formatters';
import Tabs from 'arui-feather/tabs';
import TabItem from 'arui-feather/tab-item';
import './Chart.scss';
import { ABOUT_COMPANY, CHART_COMPANY } from 'constants/languagesFields';

class Chart extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      filter: 'Все время',
      barActive: false,
    };
  }

  componentDidMount() {
    const { data, dispatch } = this.props;
    const { stockId, companyId } = data;
    // if (companyId && stockId) {
    // даже если stockId отсуствует, то все равно загружать, чтобы было пустое значение а не
    // значения графика предыдущей компании
    if (companyId) {
      dispatch(
        getGraphByCompanyIdRequest({
          payload: {
            req: {
              companyId,
              stockId: stockId || 0,
            },
          },
          meta: { form: 'graph', callback: setValueSet },
        }),
      );
    }
  }

  normalize = () => {
    const { valueSetsAll, dispatch, data } = this.props;
    const { graph, stockId } = valueSetsAll;
    if (isArray(graph)) {
      if (valueSetsAll && !valueSetsAll.stockId) {
        const stockId: any = graph && graph[0] && graph[0].stockId;
        if (stockId) {
          dispatch(
            getCompanyStockIdRequest({
              payload: { id: stockId },
              meta: { form: `stockId`, callback: setValueSet },
            }),
          );
        }
      }
      return {
        stockName: stockId && language(stockId.name, 'en'),
        currencyName: data && data.currencyName,
        content: graph.map((val: any) => {
          return {
            currency: data && data.currencyName,
            name: val.year,
            uv: val.amount,
          };
        }),
      };
    }
  };

  NumberMask = (value: any) => {
    const { data } = this.props;
    return (
      <div>
        {value}
        <span>{data && data.currencyName}</span>
      </div>
    );
  };

  customTooltip = ({ payload, label, active }: any) => {
    const { data } = this.props;
    if (active) {
      return (
        <Plate className="custom-tooltip">
          <div className={'custom-tooltip-year'}>
            {`${label} ${CHART_COMPANY['year'][getLanguageFromStore()]}`}
          </div>
          <div className={'custom-tooltip-divided'}>
            {CHART_COMPANY['dividendPerShare'][getLanguageFromStore()]}
            <span className={'dividend-red'}>
              {payload && payload[0].value}
            </span>
            {data && data.currencyName}
          </div>
        </Plate>
      );
    }
  };

  handleClick = (event: any) => {
    const name = event.target.innerHTML;
    const { data, dispatch } = this.props;
    const { stockId, companyId } = data;
    let req: any = {};
    switch (name) {
      case '5 лет':
        req = {
          fromYear: 2016,
          toYear: 2020,
        };
        break;
      case '5 years':
        req = {
          fromYear: 2016,
          toYear: 2020,
        };
        break;
      case 'All time':
        req = {};
        break;

      case 'Все время':
        req = {};
        break;

      default:
        req = {};
    }

    req.stockId = stockId;
    req.companyId = companyId;
    dispatch(
      getGraphByCompanyIdRequest({
        payload: { req },
        meta: { form: 'graph', callback: setValueSet },
      }),
    );

    this.setState({
      filter: name,
    });
  };

  render() {
    const { data } = this.props;
    const { filter } = this.state;
    const base = this.normalize();

    if (base && base.content.length > 0) {
      return (
        <div className="company" style={{ width: '100%' }}>
          <div className="chart-meta">
            <h4>{ABOUT_COMPANY['dividendsByYears'][getLanguageFromStore()]}</h4>
            <div className={'stock-title'}>
              {data?.stockTypeId}
              <span className={'gray-stock'}>({data?.stockName})</span>
            </div>
          </div>
          <div className="button-filter">
            <Tabs>
              <TabItem
                onClick={this.handleClick}
                checked={filter === '5 лет' || filter === '5 years'}
              >
                {ABOUT_COMPANY['fiveYears'][getLanguageFromStore()]}
              </TabItem>
              <TabItem
                onClick={this.handleClick}
                checked={filter === 'Все время' || filter === 'All time'}
              >
                {ABOUT_COMPANY['AllTime'][getLanguageFromStore()]}
              </TabItem>
            </Tabs>
          </div>

          <div style={{ clear: 'right' }} />

          <CompanyChart base={base} data={data} tooltip={this.customTooltip} />
        </div>
      );
    }

    return <div className="company" style={{ width: '100%' }} />;
  }
}

const CompanyChart = ({
  base,
  data,
  tooltip,
}: {
  base: any;
  data: any;
  tooltip: any;
}) => {
  return (
    <div className="custom-bar-chart-wrapper">
      <div
        className="custom-bar-chart"
        style={{
          height: 300,
          overflowX: base.content.length * 20 > 100 ? 'scroll' : 'hidden',
        }}
      >
        <ResponsiveContainer
          width={`${
            base.content.length * 20 > 100 ? base.content.length * 20 : 100
          }%`}
        >
          <BarChart
            data={base && base.content}
            margin={{
              top: 20,
              right: 30,
              left: 0,
              bottom: 5,
            }}
            barSize={35}
          >
            <CartesianGrid vertical={false} strokeDasharray="3 3" />
            <XAxis tickLine={false} axisLine={false} dataKey="name" />
            <YAxis />

            <Tooltip cursor={{ fill: 'none' }} content={tooltip} />
            <Bar
              className={'bar-hover'}
              name="Дивидент на акцию"
              dataKey="uv"
              stackId="a"
              fill="#6d7986"
            />
            <Legend
              content={
                <span className={'stock-main-text'}>
                  <span className={'test-lab'}>
                    {ABOUT_COMPANY['stockYear'][getLanguageFromStore()]}{' '}
                    {data && data.currencyName}
                  </span>
                </span>
              }
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(Chart);
