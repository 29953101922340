// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import CompaniesFilter from './filter';
import CompaniesTable from './table';
import CompaniesProductNews from './productNews';
import CompaniesEventsNews from './eventNews';
import ColTree from 'components/ColTree';
import GridCol from 'arui-feather/grid-col';
import { COMPANIES_TABLE } from 'constants/tables';
import { getLanguageFromStore } from 'utils/formatters';
import { Heading } from 'arui-feather/heading';
import { TABLE_NAME } from 'constants/languagesTable';
import { useResolution } from 'hooks/useResolution';
import { FIELD_NAME } from 'constants/languagesFields';

const Companies = () => {
  const { isMobile } = useResolution();
  return (
    <div className="custom-page-wrapper companies-page">
      {isMobile && <CompaniesEventsNews />}
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="custom-margin-bottom"
      >
        <GridCol>
          <Heading size="xl" className="custom-page-title">
            {TABLE_NAME[COMPANIES_TABLE][getLanguageFromStore()]}
          </Heading>
        </GridCol>
      </GridRow>
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="flex_no-wrap"
      >
        <ColTree className="custom-column-section custom-filter-section">
          <CompaniesFilter />
        </ColTree>
        <CompaniesTable showTitle={false} />
        <ColTree className="custom-column-section">
          {isMobile && (
            <Heading size="l">
              {FIELD_NAME['actual'][getLanguageFromStore()]}
            </Heading>
          )}
          <CompaniesProductNews />
          {!isMobile && <CompaniesEventsNews />}
        </ColTree>
      </GridRow>
    </div>
  );
};

export default Companies;
