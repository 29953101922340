import React from 'react';
import ConfigPage from './page'
import {getAllSectorsRequest} from '../../../Sector/redux/actions';
import {productTypeRequest} from '../../../ProductType/redux/actions';
import {getAllcategoriesRequest} from '../../../Categories/redux/actions';
// import { getAllSectorsRequest } from 'modules/Catalog/Sectors/redux/actions'
// import { productTypeRequest } from 'modules/Catalog/ProductTypes/redux/actions';
// import { getAllcategoriesRequest } from 'modules/Catalog/Categories/redux/actions';

export default (data: any) => [
    {
        fieldWidth: '12',
        customPosition: {
            label: 0,
            field: 12,
        },
        customProps: data,
        id: 'clientRightSettingsThree',
        component: ConfigPage,
        actions: {
            categories: getAllcategoriesRequest,
            productType: productTypeRequest,
            sectors: getAllSectorsRequest,
        },
        type: 'custom',
    },
];
