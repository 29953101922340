import * as ACTION_TYPE from './constants';



export const getFixedIncomeRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_FIXEDINCOME_FILTER_BY_ID_REQUEST,
    payload,
    meta,
})


export const getProductByLanguageAndCategoryFixedIncomeRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_FIXEDINCOME_REQUEST,
    meta,
    payload
});

export const getFixedIncomeSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_FIXEDINCOME_SORT_REQUEST,
    meta,
    payload
});


