// @ts-nocheck
import {all, call, put, takeLeading} from 'redux-saga/effects';
import API from '../api';
import {
    GET_ALL_PRODUCT_FOR_NEWS_BY_AUTHOR_REQUEST,
    GET_ALL_PRODUCT_FOR_NEWS_BY_COMPANY_REQUEST,
    GET_ALL_PRODUCT_FOR_NEWS_REQUEST,
    GET_ALL_PRODUCT_REQUEST
} from './constants';
import {errorMessageFailure} from '../../Error/redux/actions';
import {convertFileUrl} from "../../../utils/formatters";

function* getAllProduct({meta: {form, table, callback}, payload}: any) {
    try {
        const response = yield call(API.getAllProduct, table, payload);
        console.log('getAllProduct response=', response)
        if (callback) {
            if (table) {
                yield put(callback({payload: {response}, meta: {table}}));
            }
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

function* getAllProductForNews({meta: {form, table, callback}, payload}: any) {
    try {
        // const response = yield call(API.getAllProductForNews, table, payload);
////// 2023-01-24
        let response = yield call(API.getAllProductForNews, table, payload);
///        response = convertFileUrl( response )
        console.log('getAllProductForNews response=', response)

//////

        console.log('getAllProductForNews response=', response)
        if (callback) {
            if (table) {
                yield put(callback({payload: {response}, meta: {table}}));
            }
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}


function* getAllProductForNewsByAuthor({meta: {form, table, callback}, payload}: any) {
    try {
        const response = yield call(API.getAllProductForNewsByAuthor, table, payload);
        if (callback) {
            if (table) {
                yield put(callback({payload: {response}, meta: {table}}));
            }
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}

function* getAllProductForNewsByCompany({meta: {form, table, callback}, payload}: any) {
    try {
        const response = yield call(API.getAllProductForNewsByCompany, table, payload);
        if (callback) {
            if (table) {
                yield put(callback({payload: {response}, meta: {table}}));
            }
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}


function* getAllProductWithFilter({meta: {form, table, callback}, payload}: any) {
    try {
        console.log('SAGA getAllProductWithFilter');
        const response = yield call(API.getAllProductWithFilter);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}));
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_ALL_PRODUCT_REQUEST, getAllProduct),
        takeLeading(GET_ALL_PRODUCT_FOR_NEWS_REQUEST, getAllProductForNews),

        takeLeading(GET_ALL_PRODUCT_FOR_NEWS_BY_AUTHOR_REQUEST, getAllProductForNewsByAuthor),

        takeLeading(GET_ALL_PRODUCT_FOR_NEWS_BY_COMPANY_REQUEST, getAllProductForNewsByCompany),
// akayerov

//        takeLeading(GET_ALL_PRODUCT_WITH_FILTER_REQUEST, getAllProductWithFilter)

    ]);
}

export default Saga;
