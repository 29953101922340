// @ts-nocheck
import React, {Component} from 'react';
import {find, isEmpty} from 'lodash';
import GraphForm from 'components/Graphs/Form';
import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Button from 'arui-feather/button';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {createStructuredSelector} from 'reselect';
import validate from './validate';
import {connect} from 'react-redux';
import normalize from './normalize';
import config from './fields';
import {selectAllValueSets} from '../../../../components/ValueSet/selector';
import {editProfileClient, getProfileClient} from '../../redux/actions';
import {selectCurrentUser} from '../../../User/redux/selector';
import {formDataRequest} from '../../../../components/Graphs/Form/actions';

export const buildCategorySettingss = ({
  data = {},
  categories,
  productType,
}: any) => {
  const arrayCategories = [] as any;
  Object.keys(data).forEach((category: any) => {
    if (!isEmpty(data[category])) {
      const caty = find(categories, ({ id }) => id === category);
      const catyCopy = Object.assign({}, caty);
      if (catyCopy) {
        const productTypesObj = [] as any;
        Object.keys(data[category]).forEach((product: any) => {
          const productTypes = find(productType, ({ id }) => id === product);
          if (productTypes && data[category][product]) {
            productTypesObj.push(productTypes.id);
          }
        });
        arrayCategories.push(
          Object.assign(catyCopy, { productTypes: productTypesObj }),
        );
      }
    }
  });
  return arrayCategories;
};

class ClientPageConfig extends Component<any, any> {
  get actions() {
    return (
      <GridRow>
        <GridCol>
          <Button
            className={'button-float'}
            view="extra"
            size="s"
            type="submit"
          >
            Save
          </Button>
        </GridCol>
      </GridRow>
    );
  }

  handleClick = () => {
    const { user, dispatch, history  } = this.props;
    const { profileId } = user;

    // 2023-16-01  Снова вставил задержку теперь только при создании нового профиля AD
    // Задержка нужна, вызов проходит еще до сохранения чтения в redux
           let timerId = setTimeout( ()=> {
             dispatch(
                 formDataRequest({
                   type: '',
                   meta: { form: 'CLIENTS_CONFIG_FORM' },
                   payload: { init: getProfileClient, data: { id: profileId } },
                 }),
             );
           }, 2000)


  };

  submit = (data: any) => {
    const {
      valueSetsAll: { categories, productType },
    } = this.props;
    const deliveryData = Object.keys(data.deliveryData).map((key) => {
      // console.log('Submit data=', data)
      return {
        email: data.deliveryData[key].email,
        engLang: data.deliveryData[key].engLang,
        rusLang: data.deliveryData[key].rusLang,
        attachProduct: data.deliveryData[key].attachProduct,
        categories: buildCategorySettingss({
          data: data.deliveryData[key].checkboxes,
          categories,
          productType,
        }),
        sectors:
          data.deliveryData[key].sectors &&
          Object.keys(data.deliveryData[key].sectors).map(
            (sector) => data.deliveryData[key].sectors[sector] && sector,
          ),
        clientName: data.clientName,
        // password: data.password,
      };
    });

    return {
      clientId: data.clientId,
      id: data.id,
      portalDefaultLanguageId: data.portalDefaultLanguageId,
      deliveryData,
      clientName: data.clientName,
      password: data.password,
      confirmPassword: data.confirmPassword
    };
  };

  render() {
    const { user, userDataMail } = this.props;
    const { profileId } = user;

    return (
      <GraphForm
        form={'CLIENTS_CONFIG_FORM'}
        actions={this.actions}
        onSuccess={this.handleClick}
        graphId={profileId}
        init={getProfileClient}
        edit={editProfileClient}
        create={() => {}}
        title={'Client config form'}
        submit={this.submit}
        normalize={normalize}
        validate={validate}
        type={'edit'}
        columns={1}
        config={config(userDataMail)}
        width="12"
        reInitialize
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSetsAll: selectAllValueSets,
  user: selectCurrentUser,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(ClientPageConfig);
