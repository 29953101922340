import React, {FC} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Route, Switch} from 'react-router-dom';

import {createStructuredSelector} from 'reselect';
import {makeSelectToken} from './selectors';

import PublicRoute from 'app/Routes/public';
import PrivateRoute from 'app/Routes/private';
import NotFound from 'app/NotFound';

import routes from 'app/rootRoutes';
import {chain} from 'lodash';

import API from '../modules/IntefaceSettings/api';
import {setInterfaceSet} from '../modules/IntefaceSettings/redux/actions';
// 2021-08-25
import {getAllLawFileRequest} from '../modules/LawFile/redux/actions';
import {LAW_FILES_TABLE, STATIC_INFO_CURRENT_MARKET_PRICE_TABLE, STATIC_INFO_TABLE} from 'constants/tables';
import {tableDataSuccess} from '../components/Graphs/Table/actions';
import {getAllStaticInfoRequest, getStaticInfoByTypeRequest} from "../modules/FooterInfo/redux/actions";
// @ts-ignore
import {CURRENT_MARKET_PRICES} from "../constants/currentMarketPrices";

const CURRENT_MARKET_PRICE_INTERVAL = 60000

// Чтение настроек таблиц ТОЛЬКО при старте
const loadSetting = (props: any) => {
    const {dispatch}: any = props;
    API.getAllInterfaceSettingsBaseStyle().then((result) => {
        // массив в объект
        const obj = chain(result)
            .keyBy('settingName')
            .mapValues('tableOptions.size')
            .value();
        dispatch(setInterfaceSet({payload: obj}));
    });
};
// Чтение информации о файлах с юридической инфорнмацией только на старте
export const loadLawFile = (props: any) => {
    const {dispatch}: any = props;
    dispatch(
        getAllLawFileRequest({
            meta: {table: LAW_FILES_TABLE, callback: tableDataSuccess},
        }),
    );
};

// Чтение информации о футере/контактах/бегущей строки
// вызов по интервалу
let __counterStaticInfo: any = null
const loadStaticInfo = (props: any) => {
    loadStaticInfoCurrentMarketPrices(props)
    loadAllStaticInfo(props)
    if (__counterStaticInfo == null) {
        __counterStaticInfo = setInterval(loadStaticInfoCurrentMarketPrices, CURRENT_MARKET_PRICE_INTERVAL, props);
    }
};

// Чтение всей информации о футере/контактах/бегущей строки
const loadAllStaticInfo = (props: any) => {
    console.log("LOAD FOOTER INFO start..")
    const {dispatch}: any = props;
    dispatch(
        getAllStaticInfoRequest({
            meta: {table: STATIC_INFO_TABLE, callback: tableDataSuccess},
        }),
    );
};

// Чтение только бегущей строки
const loadStaticInfoCurrentMarketPrices = (props: any) => {
    // console.log("LOAD FOOTER HOT NEWS start..")
    const {dispatch, token}: any = props;
    if( token ) {
        dispatch(
            getStaticInfoByTypeRequest({
                payload: {type: CURRENT_MARKET_PRICES},
                meta: {table: STATIC_INFO_CURRENT_MARKET_PRICE_TABLE, callback: tableDataSuccess},
            }),
        );
    }
    else {
        console.log('No authorisation, Hot news not loaded')
    }
};


const App: FC<any> = (props: any) => {
    loadSetting(props);
    loadLawFile(props);
    loadStaticInfo(props)

    return (
        <Switch>
            {routes &&
                routes.map((item, index) => {
                    switch (item.type) {
                        case 'public':
                            return <PublicRoute key={index} {...item} />;
                        case 'private':
                            return <PrivateRoute key={index} {...item} />;
                        default:
                            return <Route render={() => <NotFound/>}/>;
                    }
                })}
            <Route path="*" render={() => <NotFound/>}/>
        </Switch>
    );
};

const mapStateToProps = createStructuredSelector({
    token: makeSelectToken(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(App);
