import convert from 'html-to-text';
const convertHTML = (annotation) => {
  console.log('convertHTML start', convert)
  const text = convert.htmlToText(annotation, {
    wordwrap: 40
  });
  console.log('convert annotation text=', text)
  return text
}

const deleteHTTP = (annotation) => {
  let idx1 = annotation.indexOf('[http')
  let idx2
  if( idx1 >= 0) {
    idx2 = annotation.indexOf(']', idx1 )
  }
  let text
  if( idx1 > 0 && idx2 > 0) {
     if( idx2 < annotation.length - 1)
       text = annotation.substr(0, idx1) + annotation.substr(idx2 + 1 )
     else
       text = annotation.substr(0, idx1)
  }
  if( text && text.length > 0)
    return text
  else
    return annotation
}


export function makeToShorter(annotation) {
  if (!annotation) {
    return annotation;
  }
  return annotation;
// 2021-11-30 Показ аннотаций в плашках по решению заказчика
// до следующей итерации
// Этот код сейчас не имеет смысла
  console.log('text before', annotation)
  let text = convertHTML(annotation)

  if (text == undefined) {
    return text;
  }
  text = deleteHTTP(text)

  console.log('text after', text)
  let count = 0;
  let pos = text.indexOf('<p>');
  while (pos != -1) {
    count++;
    if (count >= 4) {
      break;
    }
    pos = text.indexOf('<p>', pos + 1);
  }
  if (pos != -1) {
 //   console.log(`${text.substr(0, pos)}...`);
    const posMake = Math.min(pos, 140)
    return `${text.substr(0, posMake)}...`;
  }
  return `${text.substr(0, 140)}...`;
}

