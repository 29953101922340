// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import Team from './ourTeam';
import GridCol from 'arui-feather/grid-col';

const EquityResearch = () => {
  return (
    <div className="team-page">
      <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
        <GridCol offset={{ mobile: 0, tablet: 0, desktop: 2 }}>
          <Team />
        </GridCol>
      </GridRow>
    </div>
  );
};

export default EquityResearch;
