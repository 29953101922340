// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { configureStore } from 'app/configureStore';
import { createBrowserHistory } from 'history';
import App from 'app';
import 'styles/index.scss';

import pjson from '../package.json'

export const history = createBrowserHistory();
export const { store, persistor } = configureStore(history);

const str = `Версия сервиса  ${pjson.version}`
console.log( str );

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Router history={history}>
          <App />
        </Router>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.querySelector('#root'),
);
