// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import GraphTable from 'components/Graphs/Table';
import {
  ICategoryTable,
  IHeadTable,
  ItemNormalize,
} from 'components/Flows/Table/interface';
import { SEARCH_TABLE } from 'constants/tables';
import { isArray } from 'lodash';
import { productTypeRequest } from 'modules/ProductType/redux/actions';
import Label from 'components/Label';
import { getLanguageFromStore, language } from 'utils/formatters';
import { createStructuredSelector } from 'reselect';
import { selectSearchData } from '../../redux/selector';
import { getAllAuthorRequest } from '../../../Author/redux/actions';
import {
  getAllCompanyDupRequest,
  getAllCurrencyRequest,
  getAllEquityTickersRequest,
  getAllStockRecommendationRequest,
  getAllStockRequest,
  getAllStockTypeRequest,
} from '../../../Company/redux/actions';
import { getAllSectorsRequest } from '../../../Sector/redux/actions';
import { getAllcategoriesRequest } from '../../../Categories/redux/actions';
import { getAllProductWithFilterRequest } from '../../../Product/redux/actions';
import { setValueSet } from '../../../../components/ValueSet/actions';
import { SEARCH_FILTER } from '../../../../constants/forms';
import { getDataSearchSortRequest } from '../../redux/actions';
import { TABLE_HEAD } from '../../../../constants/languagesTable';
import { getAuthorsForTable } from '../../../../utils/authorsList';
import { AuthorsColumn } from 'components/AuthorsColumn/AuthorsColumn';

class SearchTable extends React.Component<ICategoryTable> {
  componentDidMount() {
    const { dispatch }: any = this.props;
    const actions = {
      author: getAllAuthorRequest,
      companies: getAllCompanyDupRequest,
      sectors: getAllSectorsRequest,
      equityTickers: getAllEquityTickersRequest,
      currency: getAllCurrencyRequest,
      recommendation: getAllStockRecommendationRequest,
      stock: getAllStockRequest,
      categories: getAllcategoriesRequest,
      stockType: getAllStockTypeRequest,
      productsFilter: getAllProductWithFilterRequest,
    } as any;
    Object.keys(actions).forEach((key) => {
      dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
    });
  }

  normalizeTableData = (
    head: IHeadTable[],
    body: any,
    valuesTable: any,
  ): any => {
    const productType = valuesTable && valuesTable.productType;
    const author = valuesTable && valuesTable.author;
    const sectors = valuesTable && valuesTable.sectors;
    const categories = valuesTable && valuesTable.categories;
    const productsFilter = valuesTable && valuesTable.productsFilter;

    const processedData =
      (isArray(productType) &&
        isArray(sectors) &&
        isArray(author) &&
        isArray(productType) &&
        isArray(categories) &&
        isArray(body) &&
        body.map((item: any) => {
          const { id }: any = item;

          const productForModal = productsFilter?.find(
            (value: any) => value.id === item.itemId,
          );
          const strategyId = item.productTypeId;
          const strategy = productType
            .filter((o1: any) => {
              return o1.id === strategyId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join('');

          const name = item.value;
          const date = item.date;

          const sector = sectors
            .filter((o1: any) => {
              return (
                isArray(item.sectors) &&
                item.sectors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((lu: any) => {
              return {
                name: language(lu.name, 'en'),
                id: lu.id,
              };
            });

          const categoriesTitle = categories
            ?.filter((o1) => {
              return o1.id === item?.categoryId;
            })
            .map((lu) => {
              return language(lu.name, 'en');
            })
            .join('');

          let au = author
            .filter((o1: any) => {
              return (
                isArray(item?.authors) &&
                item?.authors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((y: any) => {
              return {
                name: language(y.name, 'en'),
                id: y.id,
                photo: y.photo,
                blocked: y.blocked,
              };
            });
          // 2021-10-05 в модальных окнах только неблокированные авторы
          au = au.filter((o1: ItemNormalize) => o1.blocked !== true);

          // 2021-08-31 отфильтровать спискок в соотвествии еще  типом документа
          const authorsForTable = getAuthorsForTable(strategy, au);
          return {
            id,
            modal: {
              sectors: sector,
              authors: au,
              //                            name: productForModal?.name,
              name: item?.value,
              annotation: productForModal?.annotation,
              //                            file: productForModal?.file,
              //                            downloadAvailable: productForModal?.downloadAvailable,
              file: item?.file,
              downloadAvailable: item?.downloadAvailable,

              //                            date: productForModal?.date,
              date: item?.value,
              id: item?.itemId,
              strategy,
              categoriesTitle,
              // add 2021-11-12 for statistics
              categoryId: item.categoryId,
              productTypeId: item.productTypeId,
              languageId: item.languageId,
              // add 2021-11-17 for statistics
              companies: item.companies,

            },
            cells: [
              {
                element: name,
                id,
                type: 'name',
                width: 'l',
              },
              {
                element: <Label text={strategy} />,
                id,
                type: 'productType',
                width: 'l',
              },
              {
                element: <AuthorsColumn authorsForTable={authorsForTable} />,
                id,
                type: 'analyst',
                width: 'l',
              },
              {
                element: date,
                id,
                type: 'date',
                width: 'l',
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: any) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  getRowLink = (id: any) => `/interface-settings/${id}`;

  render() {
    const { handleRowClick, dataSearch }: any = this.props;
    const head = [
      {
        id: 'name',
        label: TABLE_HEAD['name'][getLanguageFromStore()],
        style: { width: 'l' },
        type: 'modal',
        sort: 'default',
      },
      {
        id: 'productType',
        label: TABLE_HEAD['productType'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'l' },
      },
      {
        id: 'analyst',
        label: TABLE_HEAD['analyst'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'l' },
        // 2021-08-04 Новое значение none отключает щелчок по колонке для сортировки
        sort: 'none',
      },
      {
        id: 'date',
        label: TABLE_HEAD['date'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'l' },
      },
    ];

    const graphId = {};
    const actions = { productType: productTypeRequest };

    if (dataSearch?.products) {
      return (
        <GraphTable
          actions={actions}
          graphId={graphId}
          type={'edit'}
          table={SEARCH_TABLE}
          filterForm={SEARCH_FILTER}
          normalizeTableData={this.normalizeTableData}
          sortRequest={getDataSearchSortRequest}
          init={null}
          onRowClick={handleRowClick}
          head={head}
          link={this.getRowLink}
          width={'12'}
          gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
          pagination={true}
        />
      );
    }
    return null;
  }
}

const mapStateToProps = createStructuredSelector({
  dataSearch: selectSearchData,
  //  productType: null
});

export default connect(mapStateToProps)(SearchTable);
