import React from 'react';
import LoginForm from './form';
import style from './login.module.scss';
import './Login.scss';
import { LOGIN_INFO } from 'constants/languagesTable';
import { getLanguageFromStore } from 'utils/formatters';

const Login = () => {
  return (
    <div className={`${style.loginForm} custom-login-page`}>
      <h2 className="login-form__title">
        {LOGIN_INFO['logIn'][getLanguageFromStore()]}
      </h2>
      <LoginForm type="create" />
    </div>
  );
};
export default Login;
