import {language} from 'utils/formatters'

export const normalizeTeam = (author: any[], authorTypes: any[]) => {
    if (author && author.length && authorTypes && authorTypes.length) {
        return author.map((value: any) => {
            let type = authorTypes
                .filter((val) => val.id === value.authorTypeId).map((item) => {
                    const o = item.name;
                    return o
                }).join('');

            const {birthday, email, id, phone1, phone2, photo} = value;
            return {
                name: language(value.name, 'en'),
                address: language(value.address, 'en'),
                biography: language(value.biography, 'en'),
                birthday: birthday,
                email: email,
                id: id,
                type: type,
                phone1: phone1,
                phone2: phone2,
                photo: photo,
                position: language(value.position, 'en'),
                modal: {
                    addres: language(value.address, 'en'),
                    comment: language(value.biography, 'en'),
                    department: language(value.position, 'en'),
                    email: email,
                    fullName: language(value.name, 'en'),
                    id: id,
                    phones: [phone1, phone2],
                    photo: photo,
                }
            }
        })
    }
};