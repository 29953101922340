import React from 'react';
import { isArray, isNil, isEmpty } from 'lodash';
import cn from 'bem-cn';

import TargetPrice from 'components/TargetPrice';

import './CoverageList.scss';
import { FIELD_NAME, ABOUT_COMPANY, NOT_FIND } from 'constants/languagesFields';
import { getLanguageFromStore } from 'utils/formatters';

const coverageList = cn('coverage-list');
const itemValue = cn('item-value');
const detailInfo = cn('coverage-detail');

const stateColor = {
  GREY: 'default',
  RED: 'down',
  GREEN: 'up',
};

class CoverageList extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className={'coverage-list cover-list-m'}>
        <div className={coverageList('caption')}>Coverage list</div>
        <div className={coverageList('row-head')}>
          <div className={coverageList('cell')} />
          <div className={coverageList('cell')}>
            {FIELD_NAME['companyOne'][getLanguageFromStore()]}
          </div>
          <div className={coverageList('cell')}>
            {ABOUT_COMPANY['ticker'][getLanguageFromStore()]}
          </div>
          <div className={coverageList('cell')}>
            {ABOUT_COMPANY['targetPrice'][getLanguageFromStore()]}
          </div>
          <div className={coverageList('cell')}>
            {ABOUT_COMPANY['recommendation'][getLanguageFromStore()]}
          </div>
        </div>

        {isEmpty(data) && (
          <div className={'empty-value-block'}>
            {NOT_FIND[getLanguageFromStore()]}
          </div>
        )}

        {isArray(data) &&
          data.map((item, y) => {
            const { sector, stockRecommendation } = item;
            return (
              <div key={y} className={detailInfo()}>
                <div className={detailInfo('column--current')}>
                  <span className={itemValue({ company: 'name' })}>
                    {isNil(sector) ? NOT_FIND[getLanguageFromStore()] : sector}
                  </span>
                </div>
                <div className={detailInfo('column')}>
                  {isArray(stockRecommendation) &&
                    stockRecommendation.map((row, l) => {
                      const { currency, recommendation } = row;
                      const recSlice = recommendation.slice(0, 1);
                      // let recSlice = recommendation;
                      return recSlice.map((rot) => {
                        const {
                          company,
                          tickerName,
                          targetPrice,
                          state,
                          recommendation,
                        } = rot;
                        return (
                          <div key={l} className={detailInfo('column', 'row')}>
                            <div className={detailInfo('column', 'cell')}>
                              <span className={itemValue()}>{company}</span>
                            </div>
                            <div className={detailInfo('column', 'cell')}>
                              <span
                                className={itemValue({ company: 'ticker' })}
                              >
                                {tickerName}
                              </span>
                            </div>
                            {/* <div className={detailInfo('column', 'cell')}>*/}
                            {/* <span className={itemValue()}>*/}
                            {/*    {currency}*/}
                            {/* </span>*/}
                            {/* </div>*/}
                            <div className={detailInfo('column', 'cell')}>
                              <span
                                className={
                                  'item-value item-value_company_price price-cover-loi'
                                }
                              >
                                <TargetPrice
                                  amount={{
                                    value: targetPrice,
                                    currency: {
                                      minority: 0,
                                    },
                                    unit: currency,
                                    status: stateColor[state ? state : 'GREY'],
                                  }}
                                  isUnit={false}
                                />
                              </span>
                            </div>

                            <div className={detailInfo('column', 'cell')}>
                              <span className={itemValue()}>
                                {recommendation}
                              </span>
                            </div>
                          </div>
                        );
                      });
                    })}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default CoverageList;
