import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'
import {CATEGORIES} from "../../../constants/categories";
import {getLanguageFromStore} from "../../../utils/formatters";
import {LANGUAGE_EN, LANGUAGE_RU} from "../../../constants/languages";

class ClassAPI {

    getAllEvents = (table: string, data: any) => {
        const currentDate = new Date();
        const day = currentDate.getDate();
        const month = currentDate.getMonth() + 1;
        const year = currentDate.getFullYear();

        const req = {
            interfaceSettingName: 'CALENDAR_EVENTS_PAGE',
            page: 1,
            languageId: getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
            direction: 'asc',
            sort: 'startDate',
            dateFrom:day + "." + month + "." + year

        };
        const q = queryString.stringify(req);
        return request({
            url: `event/read-by-filters?${q}`,
            method: 'GET',
            token: API_GLOBAL.token
        });
    }

}

const API_EVENT = new ClassAPI();

export default API_EVENT;
