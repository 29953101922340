import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'

class ClassAPI {

    getAllCompany = () => request({
        url: 'company',
        method: 'GET',
        token: API_GLOBAL.token
    });


    getAllCountry = () => request({
        url: 'catalog/country',
        method: 'GET',
        token: API_GLOBAL.token
    });
    getAllEventType = () => request({
        url: 'catalog/event-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllEvents = () => request({
        url: 'event',
        method: 'GET',
        token: API_GLOBAL.token
    });


    getAllProduct = () => request({
        url: 'product?page=0&size=3',
        method: 'GET',
        token: API_GLOBAL.token
    });


    getAllAuthorTypes = () => request({
        url: 'catalog/author-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllCategories = () => request({
        url: 'catalog/category',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllSectors = () => request({
        url: 'catalog/sector',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllInterfaceSettings = () => request({
        url: 'catalog/sector',
        method: 'GET',
        token: API_GLOBAL.token
    });

    createNewInterfaceSettings = ({data}: any) => request({
        url: 'catalog/sector',
        data,
        method: 'POST',
        token: API_GLOBAL.token
    });

    editInterfaceSettings = ({data}: any) => request({
        url: 'catalog/sector',
        data,
        method: 'PUT',
        token: API_GLOBAL.token
    });

    getByIdInterfaceSettings = ({id}: any) => request({
        url: `catalog/sector/${id}`,
        method: 'GET',
        token: API_GLOBAL.token
    });

    getProductType = () => request({
        url: 'catalog/product-type',
        method: 'GET',
        token: API_GLOBAL.token
    });


}

const API_INTERFACESETTINGS = new ClassAPI();

export default API_INTERFACESETTINGS;
