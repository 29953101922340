import {createSelector} from 'reselect';

const selectGraphFormState = (state: any) => state.form;

export const selectCurrentForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => {
            return ownProps.meta.form
        },
    ],
    (graphForm, form) => {
        return graphForm?.[form]?.values
    }
);

export const makeSelectData = () => createSelector(
    selectCurrentForm,
    state => state,
);

