// @ts-nocheck
import {all, call, put, takeLatest} from 'redux-saga/effects';

import {GET_STATIC_INFO_BY_TYPE_REQUEST, GET_STATIC_INFO_REQUEST} from './constants';
import API_STATIC_INFO from "../api";

// import { createNewAuthorsFailure } from '../../Authors/redux/actions';

function* getAllStaticInfo({ meta: { form, table, callback } }: any) {
  try {
    console.log('Загрузка Static Info');
    const response = yield call(API_STATIC_INFO.getAllStaticInfo);
    // console.log('Получены LawFile', response);
    if (callback) {
      // console.log('table = ', table);
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
      if (form) {
        yield put(callback({ payload: { response }, meta: { form } }));
      }
    }
  } catch (error) {
    console.log('Ошибка загрузки статических данных');
    //       yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* getStaticInfoByType({ meta: { form, table, callback }, payload }: any) {
  try {
    // console.log('Загрузка Static Info By Type');
    const response = yield call(API_STATIC_INFO.getStaticInfoByType, payload);
    // console.log('Получены Static Info By Type ', response);
    if (callback) {
      // console.log('table = ', table);
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
      if (form) {
        yield put(callback({ payload: { response }, meta: { form } }));
      }
    }
  } catch (error) {
    console.log('Ошибка загрузки статических данных по типу');
    //       yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}



function* Saga() {
  yield all([takeLatest(GET_STATIC_INFO_REQUEST, getAllStaticInfo)]);
  yield all([takeLatest(GET_STATIC_INFO_BY_TYPE_REQUEST, getStaticInfoByType)]);
}

export default Saga;
