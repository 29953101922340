// @ts-nocheck
import React from 'react';
import { isArray } from 'lodash';
import GraphTable from 'components/Graphs/Table';
import {
  ICategoryTable,
  IHeadTable,
  IBodyTable,
  ISetValues,
  ItemNormalize,
} from 'components/Flows/Table/interface';
import { EQUITYRESEARCH_TABLE } from 'constants/tables';
import { TABLE_NAME, TABLE_HEAD } from 'constants/languagesTable';
import { getEquityResearchSortRequest } from '../../redux/actions';
import { getProductByLanguageAndCategoryRequest } from 'modules/Category/redux/actions';

import { productTypeRequest } from 'modules/ProductType/redux/actions';
import Label from 'components/Label';
import { language, getLanguageFromStore } from 'utils/formatters';
import { EQUITYRESEARCH_FILTER } from 'constants/forms';
import { getAuthorsForTable } from '../../../../utils/authorsList';
import { AuthorsColumn } from 'components/AuthorsColumn/AuthorsColumn';

class EquityResearchTable extends React.Component<ICategoryTable> {
  normalizeTableData = (
    head: IHeadTable[],
    body: IBodyTable[],
    valuesTable: ISetValues,
  ) => {
    // akayerov чтобы работало по F5
    const { productType, sectors, categories } = valuesTable;
    const author = isArray(valuesTable.author)
      ? valuesTable.author
      : valuesTable.authorType;

    const processedData =
      (isArray(productType) &&
        isArray(sectors) &&
        isArray(author) &&
        isArray(productType) &&
        isArray(categories) &&
        isArray(body) &&
        body.map((item: ItemNormalize) => {
          const { id } = item;

          const strategy = productType
            .filter((o1: ItemNormalize) => {
              return o1.id === item.productTypeId;
            })
            .map((lu: ItemNormalize) => {
              return language(lu.name, 'en');
            })
            .join('');

          const sector = sectors
            .filter((o1: ItemNormalize) => {
              return (
                isArray(item.sectors) &&
                item.sectors.some((o2: ItemNormalize) => {
                  return o1.id === o2;
                })
              );
            })
            .map((lu: ItemNormalize) => {
              return {
                name: language(lu.name, 'en'),
                id: lu.id,
              };
            });
          const categoriesTitle = categories
            .filter((o1: ItemNormalize) => {
              return o1.id === item.categoryId;
            })
            .map((lu: ItemNormalize) => {
              return language(lu.name, 'en');
            })
            .join('');

          const au = author
            .filter((o1: ItemNormalize) => {
              return (
                isArray(item.authors) &&
                item.authors.some((o2: ItemNormalize) => {
                  return o1.id === o2;
                })
              );
            })
            .map((y: ItemNormalize) => {
              return {
                name: language(y.name, 'en'),
                id: y.id,
                photo: y.photo,
                blocked: y.blocked,
              };
            });
          // 2021-08-31 отфильтровать спискок в соотвествии еще  типом документа
          const authorsForTable = getAuthorsForTable(
            item.productTypeNameSort,
            au,
          );
          // 2021-10-04 в модальных окнах только неблокированные авторы
          const auModal = au.filter((o1: ItemNormalize) => o1.blocked !== true);

          return {
            id,
            modal: {
              sectors: sector,
              authors: auModal,
              name: item.name,
              annotation: item.annotation,
              file: item?.file,
              downloadAvailable: item?.downloadAvailable,
              date: item.date,
              id: item.id,
              strategy,
              categoriesTitle,

              // add 2021-11-12 for statistics
              categoryId: item.categoryId,
              productTypeId: item.productTypeId,
              languageId: item.languageId,
              // add 2021-11-17 for statistics
              companies: item.companies,

            },
            cells: [
              {
                element: item.name,
                id,
                type: 'name',
              },
              {
                element: <Label text={strategy} />,
                id,
                type: 'productType',
              },
              {
                element: <AuthorsColumn authorsForTable={authorsForTable} />,
                id,
                type: 'analyst',
              },
              {
                element: item.date,
                id,
                type: 'date',
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: ItemNormalize) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: ItemNormalize) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  render() {
    const { showTitle } = this.props;
    const head = [
      {
        id: 'name',
        label: TABLE_HEAD['name'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'l' },
        sort: 'default',
      },
      {
        id: 'productType',
        label: TABLE_HEAD['productType'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
      },
      {
        id: 'analyst',
        label: TABLE_HEAD['analyst'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
        // 2021-08-04 Новое значение none отключает щелчок по колонке для сортировки
        sort: 'none',
      },
      {
        id: 'date',
        label: TABLE_HEAD['date'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 's' },
      },
    ];

    const actions = {
      productType: productTypeRequest,
    };

    return (
      <GraphTable
        customTableClass={'standart-table-m'}
        actions={actions}
        graphId={{}}
        type={'edit'}
        filterForm={EQUITYRESEARCH_FILTER}
        caption={
          showTitle
            ? TABLE_NAME[EQUITYRESEARCH_TABLE][getLanguageFromStore()]
            : ''
        }
        table={EQUITYRESEARCH_TABLE}
        normalizeTableData={this.normalizeTableData}
        sortRequest={getEquityResearchSortRequest}
        init={getProductByLanguageAndCategoryRequest}
        head={head}
        width={'7'}
        pagination={true}
      />
    );
  }
}

export default EquityResearchTable;
