
export const GET_ALL_COMPANY_REQUEST = 'GET_ALL_COMPANY_REQUEST'

export const GET_ALL_COMPANY_DUP_REQUEST = 'GET_ALL_COMPANY_DUP_REQUEST'

export const GET_STOCK_REQUEST = 'GET_STOCK_REQUEST'

export const GET_STOCK_TYPE_REQUEST = 'GET_STOCK_TYPE_REQUEST'

export const GET_COMPANY_BY_ID_REQUEST = 'GET_COMPANY_BY_ID_REQUEST'

export const GET_COMPANY_FILE_BY_ID_REQUEST = 'GET_COMPANY_FILE_BY_ID_REQUEST'


export const GET_COMPANY_WITH_PARAMS_REQUEST = 'GET_COMPANY_WITH_PARAMS_REQUEST'

export const GET_COMPANY_BY_AUTHOR_REQUEST = 'GET_COMPANY_BY_AUTHOR_REQUEST'

export const GET_STOCK_RECOMMENDATION_TICKER_ID_REQUEST = 'GET_STOCK_RECOMMENDATION_TICKER_ID_REQUEST'

export const GET_GRAPH_BY_COMPANY_ID_REQUEST = 'GET_GRAPH_BY_COMPANY_ID_REQUEST'

export const GET_COMPANY_BY_STOCK_ID_REQUEST = 'GET_COMPANY_BY_STOCK_ID_REQUEST'


export const GET_DIVIDENDS_BY_COMPANY_REQUEST = 'GET_DIVIDENDS_BY_COMPANY_REQUEST'

export const GET_EQUITY_TICKER_BY_COMPANY_REQUEST = 'GET_EQUITY_TICKER_BY_COMPANY_REQUEST'

export const GET_ALL_EQUITY_TICKERS_REQUEST = 'GET_ALL_EQUITY_TICKERS_REQUEST'

export const GET_ALL_CURRENCY_REQUEST = 'GET_ALL_CURRENCY_REQUEST'

export const GET_ALL_STOCK_RECOMMENDATION_REQUEST = 'GET_ALL_STOCK_RECOMMENDATION_REQUEST'


export const GET_CATALOG_CURRENCY_REQUEST = 'GET_CATALOG_CURRENCY_REQUEST';


export const GET_RECOMMENDATION_BY_COMPANY_ID_REQUEST = 'GET_RECOMMENDATION_BY_COMPANY_ID_REQUEST';
// 2021-09-15
export const GET_STOCK_RECOMMENDATION_BY_COMPANY_ID_REQUEST = 'GET_STOCK_RECOMMENDATION_BY_COMPANY_ID_REQUEST'

