import React from 'react';

import cn from 'bem-cn';

import Input from 'arui-feather/input';
import SearchIcon from 'arui-feather/icon/action/search';

import './SearchInput.scss';
import { createStructuredSelector } from 'reselect';
import { selectSearchData } from '../../modules/Search/redux/selector';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import qs from 'querystring';
import { getDataSearchRequest } from '../../modules/Search/redux/actions';

const searchFrom = cn('search-from');

class SearchInput extends React.Component {
  componentDidMount() {
    const { location, dispatch } = this.props;
    const { query } = qs.parse(location.search.replace('?', ''));
    if (query) {
      dispatch(getDataSearchRequest({ payload: { value: query } }));
    }
  }

  handleChange = (value) => {
    const { dispatch, history } = this.props;
    history.push({
      pathname: '/search',
      search: `?query=${value}`
    });
    dispatch(getDataSearchRequest({ payload: { value } }));
  };

  render() {
    const { location } = this.props;
    const { query } = qs.parse(location.search.replace('?', ''));
    return (
      <div className={searchFrom()}>
        <Input
          placeholder=""
          view="filled"
          size="m"
          width="available"
          defaultValue={query}
          onChange={this.handleChange}
          rightAddons={<SearchIcon />}
        />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  dataSearch: selectSearchData,
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withRouter)(SearchInput);
