// @ts-nocheck
import React, { FC } from 'react';
import MetaData from 'app/metaData';
import Header from 'components/Header/Header';
import Message from 'components/Message';
import Footer from 'components/Footer';
import { getLanguageFromStore } from 'utils/formatters';
import { ILayoutProps } from 'types';

const Private: FC<ILayoutProps> = ({ children }) => {
  return (
    <>
      <MetaData />
      <Header />
      <span className="admin-spacer" />
      <div className="container">{children}</div>
      <Footer language={getLanguageFromStore() === 'en' ? 'en' : 'rus'} />

      <Message id="error" variant="error" vertical="top" horizontal="right" />
    </>
  );
};

export default Private;
