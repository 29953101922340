import React from 'react';

import cn from 'bem-cn';

import Amount from 'arui-feather/amount';

import './TargetPrice.scss';

const price = cn('target-price');

/**
 * Компонент для вывода форматированной цены
 */
class TargetPrice extends React.Component {

    render() {
        const { amount } = this.props;
        return (
            <div className={ price() }>
                { amount?.unit && (
                    <div className={ price('unit') }>
                        { amount.unit }
                    </div>
                ) }
              <div className={ price('amount') }>
                <div className={ `price-status--${ amount.status }` }>{amount.value}</div>
                {/*{*/}
                {/*  amount.value < 0.999999999999999999 ? <div className={ `price-status--${ amount.status }` }>{amount.value}</div> : (*/}
                {/*    <Amount*/}
                {/*      size="l"*/}
                {/*      amount={ amount }*/}
                {/*      */}
                {/*      className={ `price-status--${ amount.status }` }*/}
                {/*    />*/}
                {/*  )*/}
                {/*}*/}
              </div>
            </div>
        )
    }
}

export default TargetPrice;
