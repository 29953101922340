// @ts-nocheck
import {all, call, put, select, takeLeading} from 'redux-saga/effects';
import API from '../api';
import API_COMPANY from '../api';
import {
    GET_ALL_COMPANY_DUP_REQUEST,
    GET_ALL_COMPANY_REQUEST,
    GET_ALL_CURRENCY_REQUEST,
    GET_ALL_EQUITY_TICKERS_REQUEST,
    GET_ALL_STOCK_RECOMMENDATION_REQUEST,
    GET_CATALOG_CURRENCY_REQUEST,
    GET_COMPANY_BY_AUTHOR_REQUEST,
    GET_COMPANY_BY_ID_REQUEST,
    GET_COMPANY_BY_STOCK_ID_REQUEST,
    GET_COMPANY_FILE_BY_ID_REQUEST,
    GET_COMPANY_WITH_PARAMS_REQUEST,
    GET_DIVIDENDS_BY_COMPANY_REQUEST,
    GET_EQUITY_TICKER_BY_COMPANY_REQUEST,
    GET_GRAPH_BY_COMPANY_ID_REQUEST,
    GET_RECOMMENDATION_BY_COMPANY_ID_REQUEST,
    GET_STOCK_RECOMMENDATION_BY_COMPANY_ID_REQUEST,
    GET_STOCK_RECOMMENDATION_TICKER_ID_REQUEST,
    GET_STOCK_REQUEST,
    GET_STOCK_TYPE_REQUEST
} from './constants';
import {errorMessageFailure} from 'modules/Error/redux/actions'
import {getGlobalCompanyId} from "../../../globalVariables";
import {setValueSet} from "../../../components/ValueSet/actions";
import {COMPANIES_STOCK_RECOMMENDATION_TABLE} from "../../../constants/tables";
import {parseFilters} from "../../../utils/normalizeFilters";
import {COMPANIES_STOCK_RECOMMENDATION_FILTER} from "../../../constants/forms";

// 2022-12-27 Попытка уменьшить число запросов, берем из хранилища, если страны там уже есть
function* getAllCompany({meta: {form, table, callback}}: any) {
    try {
        console.log('getAllCompany starts..')
        const state = yield select();
        const companies = (state.valueSets && state.valueSets.get('company')) ? state.valueSets.get('company') : []
        if(  companies.length > 0) {
            console.log('Company was to get from redux store, no request');
            return;
        }
        const response = yield call(API.getAllCompany);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getCompanyWithParamsRequest({payload, meta: {form, currentPage, table, callback}}: any) {
    payload.page = currentPage ? currentPage : 1;
    try {
        console.log('getCompanyWithParamsRequest starts..')
        const response = yield call(API.getCompanyWithParamsRequest, payload);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllStockRequest({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllStock);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllStockTypeRequest({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllStockType);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllEquityTickersRequest({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllEquityTickersRequest);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

// 2022-12-27 Попытка уменьшить число запросов, берем из хранилища, если страны там уже есть
function* getAllCurrencyRequest({meta: {form, table, callback}}: any) {
    try {
        const state = yield select();
        const currency = (state.valueSets && state.valueSets.get('currency')) ? state.valueSets.get('currency') : []
        if(  currency.length > 0) {
            console.log('Currency was to get from redux store, no request');
            return;
        }
        const response = yield call(API.getAllCurrencyRequest);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllStockRecommendationRequest({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllStockRecommendationRequest);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getCompanyByAuthorIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getCompanyByAuthorIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getEquityTickerByCompanyIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getEquityTickerByCompanyIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getCompanyByIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getCompanyByIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getCompanyFileByIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getCompanyFileByIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getStockRecommendationTickerIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getStockRecommendationTickerIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getGraphByCompanyIdRequest({payload: {req}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getGraphByCompanyIdRequest, {req});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getCompanyStockIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getCompanyStockIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* getCatalogCurrencyIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getCatalogCurrencyIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getDividendsByCompanyIdAndStockIdRequest({payload: {companyId, stockId}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getDividendsByCompanyIdAndStockIdRequest, {companyId, stockId});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* getRecommendationByCompanyIdRequest({payload: {id}, meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getRecommendationByCompanyIdRequest, {id});
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

// 2021-09-15 рекомендации по одной компании вместо всего списка
function* getAllStockRecommendationByCompanyIdRequest({payload: {id}, meta: {form, table, callback, currentPage}}: any) {
    try {
//       console.log('!!!!!!getAllStockRecommendationByCompanyIdRequest2', id, form, table, callback, currentPage )
// 2020-09-16 Вызов через метод init компонента GraphTable теряет парметры при одновременном вызове нескольких
// init (несколько таблиц) поэтому в этом случае сейчас я просто здесь восстанавливаю параметры
        if(!id) {
            id =  getGlobalCompanyId();
            callback = setValueSet;
            form = 'recommendationHistory'
        }
        const page = (!currentPage) ? 1:currentPage;
// традиционный метод получаем все, а по фильтру отдельно
//        const response = yield call(API.getAllStockRecommendationByCompanyIdRequest, {id, page});
// 2021-09-16 универсальный метод получения данных с фильтром и страницей
        const form2 = 'COMPANIES_STOCK_RECOMMENDATION_FILTER';
        const state = yield select();
        // console.log( "state", state );
        const tableSort = state.graphTable &&
            state.graphTable.get(COMPANIES_STOCK_RECOMMENDATION_TABLE) &&
            state.graphTable.get(COMPANIES_STOCK_RECOMMENDATION_TABLE).get('filterMeta');
        // console.log( "tableSort", tableSort );
        let filterForm = (state.form && state.form[form2] && state.form[form2].values) || {} ;
        // добавляем текущую стнаицу точнее размер возврата!!!
        filterForm.page = page;
        let graphId = parseFilters(filterForm, tableSort);
/*
        console.log( "filrerForm/graphId", filterForm, graphId );
        console.log( "graphId", graphId );
        console.log( "onChangeFilter graphId=", graphId );
*/
        const response = yield call(API_COMPANY.getAllStockRecommendationByCompanyIdFilteredRequest, COMPANIES_STOCK_RECOMMENDATION_TABLE, graphId);



        /*  можно так но в форме нет подписки на этот раздел
                yield put(tableDataSuccess({
                    type: "", payload: {response},
                    meta: {table: COMPANIES_STOCK_RECOMMENDATION_TABLE, callback: tableDataSuccess}
                }))
        */

        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) {
                yield put(callback({payload: {response}, meta: {form}}))

            }
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* Saga() {
    yield all([
        takeLeading(GET_ALL_COMPANY_REQUEST, getAllCompany),
        takeLeading(GET_ALL_COMPANY_DUP_REQUEST, getAllCompany),


        takeLeading(GET_COMPANY_WITH_PARAMS_REQUEST, getCompanyWithParamsRequest),

        takeLeading(GET_STOCK_REQUEST, getAllStockRequest),
        takeLeading(GET_STOCK_TYPE_REQUEST, getAllStockTypeRequest),


        takeLeading(GET_ALL_EQUITY_TICKERS_REQUEST, getAllEquityTickersRequest),
        takeLeading(GET_ALL_STOCK_RECOMMENDATION_REQUEST, getAllStockRecommendationRequest),
        takeLeading(GET_ALL_CURRENCY_REQUEST, getAllCurrencyRequest),
        takeLeading(GET_COMPANY_BY_AUTHOR_REQUEST, getCompanyByAuthorIdRequest),
        takeLeading(GET_COMPANY_BY_ID_REQUEST, getCompanyByIdRequest),
        takeLeading(GET_EQUITY_TICKER_BY_COMPANY_REQUEST, getEquityTickerByCompanyIdRequest),
        takeLeading(GET_COMPANY_FILE_BY_ID_REQUEST, getCompanyFileByIdRequest),
        takeLeading(GET_STOCK_RECOMMENDATION_TICKER_ID_REQUEST, getStockRecommendationTickerIdRequest),
        takeLeading(GET_DIVIDENDS_BY_COMPANY_REQUEST, getDividendsByCompanyIdAndStockIdRequest),
        takeLeading(GET_GRAPH_BY_COMPANY_ID_REQUEST, getGraphByCompanyIdRequest),
        takeLeading(GET_COMPANY_BY_STOCK_ID_REQUEST, getCompanyStockIdRequest),
        takeLeading(GET_CATALOG_CURRENCY_REQUEST, getCatalogCurrencyIdRequest),


        takeLeading(GET_RECOMMENDATION_BY_COMPANY_ID_REQUEST, getRecommendationByCompanyIdRequest),
// 2021-09-15
        takeLeading(GET_STOCK_RECOMMENDATION_BY_COMPANY_ID_REQUEST, getAllStockRecommendationByCompanyIdRequest),

    ]);
}

export default Saga;
