import React from 'react';
import cn from 'bem-cn';
import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Heading from 'arui-feather/heading';
import UserForm from './form';
import './ProfileForm.scss';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../User/redux/selector';
import { connect } from 'react-redux';
import { makeSelectForm } from '../../../components/Graphs/Form/selector';
// 2021-08-05
import { getLanguageFromStore } from '../../../utils/formatters';

const userLink = cn('user-form');

class ProfileForm extends React.Component {
  render() {
    const {
      user: { clientRuName, clientEnName },
      formValue,
    } = this.props;
    const name = getLanguageFromStore() === 'en' ? clientEnName : clientRuName;

    const userDataMail =
      formValue?.['CLIENTS_CONFIG_FORM']?.values?.deliveryData;
    return (
      <div className={userLink()}>
        <GridRow justify="center">
          <GridCol width="12">
            <Heading className={'paddding-margin-none'} size="m">
              {name}
            </Heading>
            <UserForm userDataMail={userDataMail} />
          </GridCol>
        </GridRow>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: selectCurrentUser,
  formValue: makeSelectForm(),
});

export default connect(mapStateToProps)(ProfileForm);
