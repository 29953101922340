// @ts-nocheck
import React from 'react';
import {withRouter} from 'react-router-dom';
import {compose} from 'redux';
import {
    CartesianGrid,
    ComposedChart,
    Label,
    LabelList,
    Line,
    ResponsiveContainer,
    Scatter,
    XAxis,
    YAxis
} from "recharts";
import {getLanguageFromStore} from "../../../utils/formatters";

const FlowChartOFZ = ({normalizeTableData, customClass, data, titleX, titleY, title}: any) => {
    const dataNorm = (data) ? normalizeTableData(data) : null
    const minX = (dataNorm) ? dataNorm.axis.minX : 0
    const maxX = (dataNorm) ? dataNorm.axis.maxX : 100
    const minY = (dataNorm) ? dataNorm.axis.minY : 0
    const maxY = (dataNorm) ? dataNorm.axis.maxY : 100
    const showLabel = false
    const colorDots = "#ef3124"

    let titleLabel = (title) ? title[getLanguageFromStore()] : ""
    if (dataNorm && dataNorm.titleEn && getLanguageFromStore() == 'en') {
        titleLabel = dataNorm.titleEn;
    } else if (dataNorm && dataNorm.titleRu && getLanguageFromStore() == 'ru') {
        titleLabel = dataNorm.titleRu;
    }


    const arrayTick = []
    let step = ( (maxY - minY) <= 5) ? 0.5: 1
    for( let i = minY; i <= maxY;  ) {
        arrayTick.push(i)
        i += step
    }
    console.log('dataNorm =', dataNorm )
    return (
        <>
            {dataNorm && (
                <>
                    <div
                        className={`${customClass} plateq plateq-w plate_theme_alfa-on-white chart`}
                    >
                        <div
                            className={'heading heading_size_s heading_theme_alfa-on-white'}
                            style={{width: '50%', margin: '0 auto'}}
                        >
                            {titleLabel}
                        </div>

                        <ResponsiveContainer height={370}>
                            <ComposedChart
                                data={dataNorm.graphData}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    bottom: 20,
                                    left: 0
                                }}
                            >
                                <CartesianGrid stroke="#888888" vertical={false} strokeDasharray="4 1 2"/>
                                <XAxis dataKey="x" type="number" domain={[minX, maxX]} tickCount={10}>
                                    <Label value={titleX} stroke={'none'} fill={'#888888'} offset={-10} position="insideBottom"/>
                                </XAxis>
                                <YAxis type="number" domain={[minY, maxY]}
                                       allowDecimals={true}
                                       ticks={arrayTick}
                                       label={{
                                           value: titleY,
                                           angle: -90,
                                           position: 'insideLeft',
                                           textAnchor: 'middle',
                                           dy: 100,
                                           stroke: 'none',
                                           fill:'#888888'
                                       }}
                                />
                                {/*<Tooltip />*/}
                                {/*<Legend />*/}
                                <Line type="monotone" dataKey="z" stroke="#666" dot={false} activeDot={false}
                                      legendType="none"/>
                                <Scatter dataKey="y" fill={colorDots}>
                                    {showLabel &&
                                        <LabelList dataKey="text" position="right"/>
                                    }
                                </Scatter>
                            </ComposedChart>
                        </ResponsiveContainer>

                    </div>
                </>
            )}
        </>
    );
};

export default compose<any>(withRouter)(FlowChartOFZ);
