import {createSelector} from 'reselect';

const selectGraphFormState = (state: any) => state.graphTable;
const selectFilterState = (state: any) => state.form;

export const selectCurrentForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => ownProps.table,
    ],
    (graphForm, table) => graphForm.get(table),
);

export const selectCurrentFilter = createSelector(
    [
        selectFilterState,
        (state: any, ownProps: any) => {
            return ownProps.filterForm
        },
    ],
    (graphForm, table) => {
        return graphForm
    },
);

export const makeSelectData = () => createSelector(
    selectCurrentForm,
    state => state && state.get('data'),
);

export const makeSelectSortData = () => createSelector(
    selectCurrentForm,
    state => state && state.get('filterMeta'),
);


export const tablePage = () => createSelector(
    selectCurrentForm,
    state => state && state.get('page'),
);

export const filterPage = () => createSelector(
    selectCurrentFilter,
    state => state,
);