import React from 'react';
import cn from 'bem-cn';
import { isEmpty, includes } from 'lodash';
import Link from 'arui-feather/link/link';
import { IconPdf, IconXls, IconPng, IconDoc, IconJpg } from '../Icon/Icons';
import './FileManager.scss';

const fileBlock = cn('files-block');

class FileManager extends React.Component {
  showFileSize = (size) => {
    if (!isEmpty(size)) {
      // 2021-04-12 akayerov интересно кто написал ниже  поделил на 10 млн?
      // return (Number(size) / 10000000).toFixed(3) + 'Mb'
      return `${(Number(size) / 1048576).toFixed(3)}Mb`;
    }
  };

  checkXls = (type) => (includes(['xlsx', 'xls'], type) ? type : '');
  checkJpg = (type) => (includes(['jpg', 'jpeg'], type) ? type : '');
  checkDoc = (type) => (includes(['doc', 'docx'], type) ? type : '');

  render() {
    const { name, type, size } = this.props.file;
    let icoType;

    switch (type) {
      case 'pdf':
        icoType = <IconPdf />;
        break;

      case this.checkJpg(type):
        icoType = <IconJpg />;
        break;

      case this.checkXls(type):
        icoType = <IconXls />;
        break;

      case 'png':
        icoType = <IconPng />;
        break;

      case this.checkDoc(type):
        icoType = <IconDoc />;
        break;

      default:
        icoType = <IconPdf />;
        break;
    }

    return (
      <div className={fileBlock()}>
        <div className={fileBlock('item')}>
          <div
            className={fileBlock('item', 'file-type')}
            style={{ cursor: 'pointer' }}
          >
            {icoType}
          </div>
          <div className={fileBlock('item', 'file-name')}>
            <Link text={name} size="l" />
            <span className={fileBlock('item', 'file-size')}>
              {this.showFileSize(size)}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default FileManager;
