// @ts-nocheck
import {all, call, put, select, takeLeading} from 'redux-saga/effects';
import API from '../api';
import {
    CREATE_NEW_EQUITYRESEARCH_REQUEST,
    EDIT_EQUITYRESEARCH_REQUEST,
    GET_ALL_EQUITYRESEARCH_REQUEST,
    GET_COMPANIES_SORT_REPORT_REQUEST,
    GET_COMPANIES_SORT_REQUEST,
    GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_COMPANIES_REQUEST,
    GET_PRODUCT_BY_LANGUAGE_COMPANIES_REQUEST,
    GET_STOCK_RECOMMENDATION_REQUEST
} from './constants';
import {COMPANIES_REPORT_TABLE, COMPANIES_STOCK_RECOMMENDATION_TABLE, COMPANIES_TABLE} from 'constants/tables';
import {COMPANIES_FILTER, COMPANIES_REPORT_FILTER, COMPANIES_STOCK_RECOMMENDATION_FILTER} from 'constants/forms';

import {parseFilters} from 'utils/normalizeFilters'
import API_CATEGORY from 'modules/Category/api'
import {tableDataLoading, tableDataSuccess} from 'components/Graphs/Table/actions'
import {errorMessageFailure} from "../../Error/redux/actions";
import {checkTouchFilter} from "../../../utils/formatters";
import API_COMPANY from "../../Company/api";
import {setValueSet} from "../../../components/ValueSet/actions";

function* getAllInterfaceSettings({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllInterfaceSettings);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* createNewInterfaceSettings({payload: {callback, fields}, meta: {form, onSuccess}}: any) {
    try {
        const response = yield call(API.createNewInterfaceSettings, {data: fields});
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getInterfaceSettingsById({payload: {callback, data: {id}}, meta: {form}}: any) {
    try {
        const response = yield call(API.getByIdInterfaceSettings, {id});
        if (callback) {
            return yield put(callback({payload: {response}, meta: {form}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* editInterfaceSettings({payload: {callback, fields}, meta: {form, onSuccess}}: any) {
    try {
        const response = yield call(API.editInterfaceSettings, {data: fields});
        if (callback) {
            yield put(callback({payload: {response}, meta: {form, onSuccess}}));
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllCategories({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllCategories);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllSectors({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllSectors);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* getProductType({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getProductType);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllAuthorTypes({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllAuthorTypes);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllProduct({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllProduct);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            //if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllEvents({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllEvents);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllAuthor({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllAuthor);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* getAllEventType({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllEventType);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllCountry({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllCountry);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getAllCompany({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllCompany);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* getProductByLanguageAndCategory({payload, meta: {table, callback}}: any) {
    try {
        const response = yield call(API_CATEGORY.getProductByLanguageAndCategoryId, {}, table);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getProductByLanguage({payload, meta: {table, currentPage, callback}}: any) {
    console.log('!!!getProductByLanguage',payload, table, currentPage, callback)
    payload.page = currentPage ? currentPage : 1;
    try {
        const response = yield call(API_CATEGORY.getProductByLanguageId, table, payload);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}

function* getCompaniesSortRequest({payload, meta: {table, callback}}: any) {
    if (table === COMPANIES_TABLE) {
        try {
            const state = yield select();

            const graphTableDate = state.graphTable &&
                state.graphTable.get(COMPANIES_TABLE) &&
                state.graphTable.get(COMPANIES_TABLE);
            const tableSort = graphTableDate?.get('filterMeta');
            const page = graphTableDate?.get('page');
            const filterForm = state.form && state.form[COMPANIES_FILTER] && state.form[COMPANIES_FILTER].values;
            const filterActive = filterForm ? filterForm : [];
            let graphId = parseFilters(filterActive, tableSort);
            graphId.page = page;
            ///// 2022-12-20 Поставить перед загрузкой флаг loading
            yield put(tableDataLoading({
                type: "", payload: {},
                meta: {table: COMPANIES_TABLE}
            }))
            /////
            const response = yield call(API_CATEGORY.getCompanyMassFilterId, graphId, COMPANIES_TABLE);
            yield put(tableDataSuccess({
                type: "", payload: {response},
                meta: {table: COMPANIES_TABLE, callback: tableDataSuccess}
            }))
        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}))
        }
    }

}



function* getCompaniesSortReportsRequest({payload, meta: {table, callback}}: any) {
    if (table === COMPANIES_REPORT_TABLE) {
        try {

            const state = yield select();
            const graphTableDate = state.graphTable &&
                state.graphTable.get(COMPANIES_REPORT_TABLE) &&
                state.graphTable.get(COMPANIES_REPORT_TABLE);
            const tableSort = graphTableDate?.get('filterMeta');
            const page = graphTableDate?.get('page');
            const filterForm = state.form && state.form[COMPANIES_REPORT_FILTER] && state.form[COMPANIES_REPORT_FILTER].values;
            const filterActive = filterForm ? filterForm : [];
            let graphId = parseFilters(filterActive, tableSort);
            graphId.page = page;
            ///// 2022-12-20 Поставить перед загрузкой флаг loading
            yield put(tableDataLoading({
                type: "", payload: {},
                meta: {table: COMPANIES_REPORT_TABLE}
            }))
            /////
            const response = yield call(API_CATEGORY.getProductByLanguageId, COMPANIES_REPORT_TABLE, graphId);

            yield put(tableDataSuccess({
                type: "", payload: {response},
                meta: {table: COMPANIES_REPORT_TABLE, callback: tableDataSuccess}
            }))
        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}))
        }
    }
}

function* onChangeFilter({payload, meta}: any) {
    if (meta.form === COMPANIES_FILTER && checkTouchFilter(payload,meta)) {
        try {
            const state = yield select();
            const graphTableDate = state.graphTable &&
                state.graphTable.get(COMPANIES_TABLE) &&
                state.graphTable.get(COMPANIES_TABLE)
            const tableSort = graphTableDate?.get('filterMeta');
            const page = graphTableDate?.get('page');
            const filterForm = state.form && state.form[meta.form] && state.form[meta.form].values;
            let graphId = parseFilters(filterForm, tableSort);
            graphId.page = page;
            ///// 2022-12-20 Поставить перед загрузкой флаг loading
            yield put(tableDataLoading({
                type: "", payload: {},
                meta: {table: COMPANIES_TABLE}
            }))
            /////
            const response = yield call(API_CATEGORY.getCompanyMassFilterId, graphId, COMPANIES_TABLE);
            yield put(tableDataSuccess({
                type: "", payload: {response},
                meta: {table: COMPANIES_TABLE, callback: tableDataSuccess}
            }))
        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}))
        }
    }
    else if (meta.form === COMPANIES_REPORT_FILTER) {
        try {
            const state = yield select();
            const tableSort = state.graphTable &&
                state.graphTable.get(COMPANIES_REPORT_TABLE) &&
                state.graphTable.get(COMPANIES_REPORT_TABLE).get('filterMeta');
            const filterForm = state.form && state.form[meta.form] && state.form[meta.form].values;
            let graphId = parseFilters(filterForm, tableSort);
            ///// 2022-12-20 Поставить перед загрузкой флаг loading
            yield put(tableDataLoading({
                type: "", payload: {},
                meta: {table: COMPANIES_REPORT_TABLE}
            }))
            /////
            const response = yield call(API_CATEGORY.getProductByLanguageId, COMPANIES_REPORT_TABLE, graphId);
            yield put(tableDataSuccess({
                type: "", payload: {response},
                meta: {table: COMPANIES_REPORT_TABLE, callback: tableDataSuccess}
            }))
        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}))
        }
    }
    else if (meta.form === COMPANIES_STOCK_RECOMMENDATION_FILTER) {
        try {
            const state = yield select();
            const tableSort = state.graphTable &&
                state.graphTable.get(COMPANIES_STOCK_RECOMMENDATION_TABLE) &&
                state.graphTable.get(COMPANIES_STOCK_RECOMMENDATION_TABLE).get('filterMeta');
            const filterForm = state.form && state.form[meta.form] && state.form[meta.form].values;
            let graphId = parseFilters(filterForm, tableSort);
            console.log( "onChangeFilter graphId=", graphId );
            const response = yield call(API_COMPANY.getAllStockRecommendationByCompanyIdFilteredRequest, COMPANIES_STOCK_RECOMMENDATION_TABLE, graphId);
/*  Это хорошее место, но подписка в форме идет в другое место  recommendationHistory
            yield put(tableDataSuccess({
                type: "", payload: {response},
                meta: {table: COMPANIES_STOCK_RECOMMENDATION_TABLE, callback: tableDataSuccess}
            }))
*/
            const callback = setValueSet;
            const form = 'recommendationHistory';
            yield put(callback({payload: {response}, meta: {form}}))


        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}))
        }
    }


}

// 2021-09-14
function* getStockRecommendation({payload, meta: {table, currentPage, callback}}: any) {
    payload.page = currentPage ? currentPage : 1;
    try {
        const response = yield call(API_CATEGORY.getProductByLanguageId, table, payload);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({payload: {error}}))
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_ALL_EQUITYRESEARCH_REQUEST, getAllInterfaceSettings),
        takeLeading(CREATE_NEW_EQUITYRESEARCH_REQUEST, createNewInterfaceSettings),
        takeLeading(EDIT_EQUITYRESEARCH_REQUEST, editInterfaceSettings),
        takeLeading(GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_COMPANIES_REQUEST, getProductByLanguageAndCategory),
        takeLeading(`@@redux-form/CHANGE`, onChangeFilter),
        takeLeading(GET_PRODUCT_BY_LANGUAGE_COMPANIES_REQUEST, getProductByLanguage),
        takeLeading(GET_COMPANIES_SORT_REQUEST, getCompaniesSortRequest),
        takeLeading(GET_COMPANIES_SORT_REPORT_REQUEST, getCompaniesSortReportsRequest),
// 2021-09-14
        takeLeading(GET_STOCK_RECOMMENDATION_REQUEST, getStockRecommendation)
    ]);
}

export default Saga;
