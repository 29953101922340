export const TABLE_DATA_REQUEST = 'TABLE_DATA_REQUEST'
export const TABLE_DATA_SUCCESS = 'TABLE_DATA_SUCCESS'
export const TABLE_DATA_FAILURE = 'TABLE_DATA_FAILURE'

export const CHANGE_TABLE_FILTER = 'CHANGE_TABLE_FILTER'
export const RESET_TABLE_FILTER = 'RESET_TABLE_FILTER'


export const TABLE_DATA_PAGINATION_REQUEST = 'TABLE_DATA_PAGINATION_REQUEST'

export const TABLE_DATA_PAGINATION_SUCCESS = 'TABLE_DATA_PAGINATION_SUCCESS'

//--- 2022-12-19
export const TABLE_DATA_LOADING = 'TABLE_DATA_LOADING'

