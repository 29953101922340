import React from 'react';
import cn from 'bem-cn';
import Paragraph from 'arui-feather/paragraph';
import './ManagementProfile.scss';
import { useResolution } from 'hooks/useResolution';
import { getLanguageFromStore } from 'utils/formatters';
import { FIELD_NAME } from 'constants/languagesFields';

const userProfile = cn('management-profile');
const employee = cn('employee');

const EmployeeMoreInfo = ({ profile }) => {
  return (
    <div className="employee-more-info">
      <span className="employee-email">
        <span className="title">E-mail</span>
        {profile.email}
      </span>
      <span className="employee-other_info">
        <span className="title">Address</span>
        {profile.addres}
      </span>
      <span className="employee-other_info">
        <span className="title">Phone</span>
        {profile.phones &&
          profile.phones.map((phone, index) => {
            return <span key={index}>{phone}</span>;
          })}
      </span>
    </div>
  );
};

const ManagementProfile = ({ profile }) => {
  const { isMobile } = useResolution();
  return (
    <div className={userProfile()}>
      <div className={userProfile('employee')}>
        <div className={employee()}>
          <div className={employee('contact-info')}>
            <span className="employee-name">{profile.fullName}</span>
            <span className="employee-post">{profile.department}</span>
            {!isMobile && <EmployeeMoreInfo profile={profile} />}
          </div>
          <div className={employee('photo')}>
            <img
              className="img-fluid"
              src={`${profile.photo}`}
              alt={`${profile.fullName}`}
            />
          </div>
        </div>
        <Paragraph>
          <div
            dangerouslySetInnerHTML={{
              __html: profile.comment,
            }}
          />
        </Paragraph>
      </div>
      {isMobile && <EmployeeMoreInfo profile={profile} />}
      <div className="research-content__close mobile research-content__row">
        <button role="button" type="button">
          {FIELD_NAME['close'][getLanguageFromStore()]}
        </button>
      </div>
    </div>
  );
};

export default ManagementProfile;
