import React from 'react';
import ModalTable from 'components/ModalTable';
import Research from 'components/ModalContent/reasearch';
import cn from 'bem-cn';
import { isEmpty, isArray } from 'lodash';
import Form from 'arui-feather/form';
import Input from 'arui-feather/input';
import IconButton from 'arui-feather/icon-button';
import SearchIcon from 'arui-feather/icon/action/search';
import Link from 'arui-feather/link/link';
import ArrowRight from 'arui-feather/icon/ui/arrow-right';
import './SearchBar.scss';
import SearchLeftBlock from './searchLeftBlock';
import SearchRightBlock from './searchRightBlock';
import { getLanguageFromStore, language } from '../../utils/formatters';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { FIELD_NAME } from '../../constants/languagesFields';

const search = cn('search-bar');
const searchResult = cn('search-bar-result');

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isOpen,
      isOpenResult: false,
      isShowing: false,
      productId: null,
      modal: null,
    };
  }

  toggleSearchBar() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  closeSearchBar() {
    this.setState({
      isOpen: false,
    });
  }

  onChangeSearch(value) {
    this.setState({
      isOpenResult: !!value,
    });
  }

  normaModal = (item, set) => {
    const { productType, author, sectors, categories } = set;

    // const arr = productsFilter?.find((value) => value.id === item.itemId);
    // akayerov 2021-04-13
    const arr = item;

    const strategy = productType
      .filter((o1) => {
        return o1.id === arr.productTypeId;
      })
      .map((lu) => {
        return language(lu.name, 'en');
      })
      .join('');

    const sector = sectors
      ?.filter((o1) => {
        return (
          isArray(arr.sectors) &&
          arr.sectors.some((o2) => {
            return o1.id === o2;
          })
        );
      })
      .map((lu) => {
        return {
          name: language(lu.name, 'en'),
          id: lu.id,
        };
      });

    const au = author
      ?.filter((o1) => {
        return (
          isArray(arr.authors) &&
          arr.authors.some((o2) => {
            return o1.id === o2;
          })
        );
      })
      .map((y) => {
        return {
          name: language(y.name, 'en'),
          id: y.id,
          photo: y.photo,
        };
      });

    const categoriesTitle = categories
      ?.filter((o1) => {
        return o1.id === arr.categoryId;
      })
      .map((lu) => {
        return language(lu.name, 'en');
      })
      .join('');
    return {
      sectors: sector,
      authors: au,
      name: arr.name,
      annotation: arr.annotation,
      file: arr.file,
      downloadAvailable: arr.downloadAvailable,
      date: arr.date,
      id: arr.id,
      strategy,
      categoriesTitle,
    };
  };

  modalHandler = (item) => {
    const { valueSetsAll } = this.props;
    const modal = this.normaModal(item, valueSetsAll);
    this.setState({
      isShowing: true,
      modal: modal ? modal : null,
    });
  };

  modalClose = () => {
    this.setState({ isShowing: false, modal: null });
  };

  handleChange = (value) => {
    const { onChangeSearch } = this.props;
    onChangeSearch(value);
    this.setState({
      value,
    });
  };

  render() {
    const { isOpen, value, isShowing, modal } = this.state;
    const { dataSearch, dispatch } = this.props;

    // @TODO: Super hardcode! Refactoring!!
    if (isOpen) {
      document.querySelector('body').classList.add('modal-open');
    } else {
      document.querySelector('body').classList.remove('modal-open');
    }

    return (
      <div className={search()}>
        <IconButton
          onClick={this.toggleSearchBar.bind(this)}
          size="s"
          icon={<SearchIcon size="s" />}
        />
        <div className={search('block').state({ open: isOpen })} onDoubleClick = {this.closeSearchBar.bind(this)}>
          <div className={search('block', 'form')} >
            <Form size="l">
              <Input
                placeholder={FIELD_NAME['searchBar'][getLanguageFromStore()]}
                view="filled"
                size="m"
                width="available"
                clear={true}
                onChange={this.handleChange.bind(this)}
                onClearClick={this.closeSearchBar.bind(this)}
                onBlur={() => this.setState({ isOpen: false })}
              />
            </Form>
          </div>

          {value && (
            <div
              className={search('block', 'result').state({
                result: !isEmpty(dataSearch),
              })}
            >
              <div className={searchResult()}>
                <div className={searchResult('detail--left-side')}>
                  <SearchLeftBlock data={dataSearch} />
                </div>
                <div className={searchResult('detail--right-side')}>
                  <SearchRightBlock
                    data={dataSearch}
                    modalHandler={this.modalHandler}
                  />
                </div>
              </div>
              <div className={search('block', 'bottom')}>
                <Link
                  text={`See all results "${value}"`}
                  size="l"
                  icon={<ArrowRight size="m" />}
                  iconPosition="right"
                  url={`/search?query=${value}`}
                />
              </div>
            </div>
          )}
        </div>
        <ModalTable isShowing={isShowing} onClose={this.modalClose}>
          <Research dispatch={dispatch} modal={modal} />
        </ModalTable>
      </div>
    );
  }
}

export default compose(withRouter)(SearchBar);
