// @ts-nocheck
import { call, put, takeLatest, all } from 'redux-saga/effects';

import API_LAWFILES from '../api';
import { GET_ALL_LAW_FILE_REQUEST } from './constants';
// import { createNewAuthorsFailure } from '../../Authors/redux/actions';

function* getAllLawFile({ meta: { form, table, callback } }: any) {
  try {
    // console.log('Загрузка LawFile');
    const response = yield call(API_LAWFILES.getAllLawFile);
    // console.log('Получены LawFile', response);
    if (callback) {
      // console.log('table = ', table);
      if (table) {
        yield put(callback({ payload: { response }, meta: { table } }));
      }
      if (form) {
        yield put(callback({ payload: { response }, meta: { form } }));
      }
    }
  } catch (error) {
    console.log('Ошибка загрузки юридических файлов');
    //       yield put(createNewAuthorsFailure({ payload: { error } }))
  }
}

function* Saga() {
  yield all([takeLatest(GET_ALL_LAW_FILE_REQUEST, getAllLawFile)]);
}

export default Saga;
