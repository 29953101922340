import { request } from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';
import queryString from 'query-string';
import { getLanguageFromStore } from '../../../utils/formatters';
import { LANGUAGE_EN, LANGUAGE_RU } from 'constants/languages';
import { getGlobalCompanyId } from '../../../globalVariables';

class ClassAPI {
  getAllCompany = () =>
    request({
      url: 'company',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getCompanyWithParamsRequest = (data: any) => {
    const sent = Object.assign(
      {
        interfaceSettingName: 'COMPANIES_TABLE',
        languageId: getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
      },
      data,
    );
    const q = queryString.stringify(sent);
    return request({
      url: `company/read-by-filters?${q}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  getAllStock = () =>
    request({
      url: 'company/stock',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getAllStockType = () =>
    request({
      url: 'catalog/stock-type',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getAllEquityTickersRequest = () =>
    request({
      url: 'company/equity-ticker',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getAllCurrencyRequest = () =>
    request({
      url: 'catalog/currency',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getAllStockRecommendationRequest = () =>
    request({
      url: 'company/stock-recommendation',
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getCompanyByAuthorIdRequest = ({ id }: any) => {
    return request({
      url: `company/by-author/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  getEquityTickerByCompanyIdRequest = ({ id }: any) =>
    request({
      url: `company/equity-ticker/company/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getCompanyByIdRequest = ({ id }: any) =>
    request({
      url: `company/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getCompanyFileByIdRequest = ({ id }: any) =>
    request({
      url: `company/file/company/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getStockRecommendationTickerIdRequest = ({ id }: any) =>
    request({
      url: `company/stock-recommendation/ticker/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getDividendsByCompanyIdAndStockIdRequest = ({ companyId, stockId }: any) =>
    request({
      url: `company/dividend/${companyId}/${stockId}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getGraphByCompanyIdRequest = ({ req }: any) => {
    const url = queryString.stringify(req);
    console.log('getGraphByCompanyIdRequest:', url);
    return request({
      url: `company/dividend/read-dividends-for-charts?${url}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  getCatalogCurrencyIdRequest = ({ id }: any) =>
    request({
      url: `catalog/currency/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });

  getCompanyStockIdRequest = ({ id }: any) => {
    return request({
      url: `company/stock/${id}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  getRecommendationByCompanyIdRequest = ({ id }: any) => {
    return request({
      url: `company/equity-ticker/company/${id}?page=0&size=100`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  // 2021-09-15
  getAllStockRecommendationByCompanyIdRequest = ({ id, page }: any) => {
    console.log('!!!getAllStockRecommendationByCompanyIdRequest page=', page )
    const url = id
      ? `company/stock-recommendation/read-by-filters/?companyId=${id}`
      : `company/stock-recommendation/read-by-filters`;
    return request({
      url,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };

  // 2021-09-15 с добавлением фильтра по датам
  getAllStockRecommendationByCompanyIdFilteredRequest = (table: string, data: any) => {
    //    console.log('getProductByLanguageId', table, data);

    const {} = this;
    const companyId = getGlobalCompanyId();
    const req = companyId
      ? {
          languageId:
            getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
          page: 1,
          interfaceSettingName: table,
          companyId: getGlobalCompanyId(),
        }
      : {
          languageId:
            getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
          page: 1,
          interfaceSettingName: table,
        };

    const sent = Object.assign(req, data);
//    console.log('API getAllStockRecommendationByCompanyIdFilteredRequest data/req/sent', data, req, sent);
    const q = queryString.stringify(sent);
//    console.log('API getAllStockRecommendationByCompanyIdFilteredRequest par=', q);
    return request({
      url: `company/stock-recommendation/read-by-filters/?${q}`,
      method: 'GET',
      token: API_GLOBAL.token,
    });
  };
}

const API_COMPANY = new ClassAPI();

export default API_COMPANY;
