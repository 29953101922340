// @TODO: remove ts-nocheck!!
// @ts-nocheck

import {fromJS} from 'immutable';
import {IAction} from 'types/index';
import {
  CHANGE_TABLE_FILTER,
  RESET_TABLE_FILTER,
  TABLE_DATA_FAILURE,
  TABLE_DATA_LOADING,
  TABLE_DATA_PAGINATION_REQUEST,
  TABLE_DATA_PAGINATION_SUCCESS,
  TABLE_DATA_REQUEST,
  TABLE_DATA_SUCCESS,
} from './constants';

const initialState = fromJS({});

const reducer = (
  state = initialState,
  { type, meta, payload }: IAction<void>,
) => {
  const table = meta && meta.table;
  const page = meta && meta.currentPage ? meta.currentPage : 1;
  const response = payload && payload.response;

  switch (type) {
    case TABLE_DATA_REQUEST: {
      return state
        .setIn([table, 'data'], null)
        .setIn([table, 'page'], 1)
        .setIn([table, 'loading'], true)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null);
    }

    case TABLE_DATA_SUCCESS: {
      return state
        .setIn([table, 'data'], response)
        .setIn([table, 'loading'], false)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null);
    }

    case TABLE_DATA_FAILURE: {
      return state
        .setIn([table, 'request'], false)
        .setIn([table, 'loading'], false)
        .setIn([table, 'failureData'], payload)
        .setIn([table, 'error'], payload);
    }

    case CHANGE_TABLE_FILTER: {
      return state.setIn([table, 'filterMeta'], payload);
    }
    case RESET_TABLE_FILTER: {
      return state.setIn([table, 'filterMeta'], null);
    }

    case TABLE_DATA_PAGINATION_REQUEST: {
      return state
        .setIn([table, 'loading'], true)
        .setIn([table, 'page'], page)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null);
    }
    case TABLE_DATA_PAGINATION_SUCCESS: {
      // const stateTable = state.updateIn([table, 'data'],
      //     (data: any) => [...data, ...response]);
      //
      // return stateTable
      return state
        .setIn([table, 'data'], response)
        .setIn([table, 'loading'], false)
        .setIn([table, 'initialized'], false)
        .setIn([table, 'error'], null);
    }
// add 2022-12-19
    case TABLE_DATA_LOADING: {
      return state
          .setIn([table, 'data'], null)
          .setIn([table, 'loading'], true)
    }
    default:
      return state;
  }
};

export default reducer;
