import React from 'react';
import { connect } from 'react-redux';
import cn from 'bem-cn';
import UserCartItem from '../UserCartItem';
import TickerInfo from '../TickerInfo';
import Plate from 'arui-feather/plate';
import './SearchResult.scss';
import { language } from '../../utils/formatters';
import Link from 'arui-feather/link';
import { withRouter } from 'react-router';

const searchDetail = cn('search-detail');

class CompanyResultSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClick = (collection, item) => {
    const { history } = this.props;
    switch (collection) {
      case 'companies':
        history.push(`/companies/about/${item.id}`);
        break;
      case 'ourTeam':
        history.push(`/analyst-profile/${item.itemId}`);
        break;
      default:
        return null;
    }
  };

  render() {
    const { company, analystsSeacrch, recommendation } = this.props;
    if (company) {
      return (
        <Plate
          onClick={() => this.handleClick('companies', company)}
          isFlat={true}
          view="filled"
          className={searchDetail()}
        >
          <span className={searchDetail('title')}>
            <img
              className={searchDetail('content', 'company-logo')}
              src={company.logo}
              alt="company-logo"
            />
            {language(company.name)}
          </span>
          <div className={searchDetail('content')}>
            <div className={'img-search'}>
              <img
                className={searchDetail('content', 'company-logo')}
                src={company.logo}
                alt="company-logo"
              />
            </div>
            <div
              className={'search-detail__content__analysts author-search-img'}
            >
              {analystsSeacrch.map((employee, index) => {
                return (
                  <Link to={`/analyst-profile/${employee.id}`}>
                    <UserCartItem key={index} user={employee} type="analyst" />
                  </Link>
                );
              })}
            </div>
            <div className={searchDetail('content', 'ticker-table')}>
              {recommendation && (
                <TickerInfo
                  data={[
                    {
                      name: recommendation.tickerName,
                      price: {
                        value: recommendation.targetPrice,
                        currency: {
                          minority: 1,
                        },
                        unit: recommendation.currencyName,
                        status: recommendation.status,
                      },
                      rec: recommendation.recommendationName,
                    },
                  ]}
                />
              )}
            </div>
          </div>
        </Plate>
      );
    }
    return null;
  }
}

const mapStateToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, {})(CompanyResultSearch));
