import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'
import {getLanguageFromStore} from "../../../utils/formatters";
import {LANGUAGE_EN, LANGUAGE_RU} from "../../../constants/languages";
import {CATEGORIES} from "../../../constants/categories";

class ClassAPI {

    getAllProductForNews = (table: string, data: any) => {
        const req = {
            interfaceSettingName: table,
            page: 1,
            mark: true,
            categoryId: CATEGORIES[table],
        };
        const q = queryString.stringify(req);
        return request({
            url: `product/read-by-filters?${q}`,
            method: 'GET',
            token: API_GLOBAL.token
        });
    };

    getAllProductForNewsByAuthor = (table: string, data: any) => {
        const req = {
            interfaceSettingName: table,
            page: 1,
            mark: true,
            authorId: data?.graphId,
        };
        const q = queryString.stringify(req);
        return request({
            url: `product/read-by-filters?${q}`,
            method: 'GET',
            token: API_GLOBAL.token
        });
    };

    getAllProductForNewsByCompany = (table: string, data: any) => {
        const req: any = {
            interfaceSettingName: table,
            page: 1,
            mark: true,
        };

        if (data?.graphId) {
            req.companyId = data?.graphId
        } else {
            req.categoryId = CATEGORIES[table]
        }
        const q = queryString.stringify(req);
        return request({
            url: `product/read-by-filters?${q}`,
            method: 'GET',
            token: API_GLOBAL.token
        });
    };

    getAllProduct = (table: string, data: any) => {
        const req = {
            interfaceSettingName: table,
            page: 1,
            mark: true,
            categoryId: CATEGORIES[table],
        };
        const q = queryString.stringify(req);
        return request({
            url: `product?${q}`,
            method: 'GET',
            token: API_GLOBAL.token
        });
   };

    getAllProductWithFilter = () => {
/* akayerov код непонятно гже использовался и при этом потреблял много ресцурсов */
        const req = {};
        const q = queryString.stringify(req);
        console.log('API !!!! getAllProductWithFilter:' +  ':' + q );
        return request({
            url: `product/read-by-filters`,
            method: 'GET',
            token: API_GLOBAL.token
        });

    };

}

const API_AUTHOR = new ClassAPI();

export default API_AUTHOR;
