// @ts-nocheck
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import GridCol from 'arui-feather/grid-col';

class ColThree extends React.Component<any, any> {
  render() {
    const { children, width, className }: any = this.props;
    return (
      <GridCol
        width={width}
        className={`grid-col_gutter-desktop-m_24 ${className}`}
      >
        {children}
      </GridCol>
    );
  }
}

const withConnect = connect();

export default compose(withConnect)(ColThree);
