// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import CommoditiesFilter from './filter';
import CommoditiesTable from './table';
import CommoditiesProductNews from './productNews';
import CommoditiesEventsNews from './eventNews';
import ColTree from 'components/ColTree';
import GridCol from 'arui-feather/grid-col';
import {COMMODITIES_TABLE} from 'constants/tables';
import {getLanguageFromStore} from 'utils/formatters';
import {Heading} from 'arui-feather/heading';
import {TABLE_NAME} from 'constants/languagesTable';
import {useResolution} from 'hooks/useResolution';
import {FIELD_NAME} from 'constants/languagesFields';
import ForecastFiles from '../../_Common/forecastFiles';

const page = ['Commodities', 'Сырье'];

const Commodities = () => {
  const { isMobile } = useResolution();
  return (
    <div className="custom-page-wrapper commodities-page">
      {isMobile && <CommoditiesEventsNews />}
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="custom-margin-bottom"
      >
        <GridCol>
          <Heading size="xl" className="custom-page-title">
            {TABLE_NAME[COMMODITIES_TABLE][getLanguageFromStore()]}
          </Heading>
        </GridCol>
      </GridRow>
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="flex_no-wrap"
      >
        <ColTree className="custom-column-section custom-filter-section">
          {isMobile && <ForecastFiles category={page} />}
          <CommoditiesFilter />
          {!isMobile && <ForecastFiles category={page} />}
        </ColTree>
        <CommoditiesTable showTitle={false} />
        <ColTree className="custom-column-section">
          {isMobile && (
            <Heading size="l">
              {FIELD_NAME['actual'][getLanguageFromStore()]}
            </Heading>
          )}
          <CommoditiesProductNews />
          {!isMobile && <CommoditiesEventsNews />}
        </ColTree>
      </GridRow>
    </div>
  );
};

export default Commodities;
