import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'

class ClassAPI {

    getAllSectors = () => request({
        url: 'catalog/sector',
        method: 'GET',
        token: API_GLOBAL.token
    });

}

const API_SECTOR = new ClassAPI();

export default API_SECTOR;
