import * as ACTION_TYPE from './constants';

export const addDownloadAction = ({payload}: any) => ({
    type: ACTION_TYPE.DOWNLOAD_LOGGING,
    payload
});


export const addShowProductAction = ({payload}: any) => ({
    type: ACTION_TYPE.SHOW_PRODUCT_LOGGING,
    payload
});

export const addAuthorisationAction = ({payload}: any) => ({
    type: ACTION_TYPE.AUTH_LOGGING,
    payload
});
