// @ts-nocheck
import React from 'react';
import GraphTable from 'components/Graphs/Table';
import {IBodyTable, ICategoryTable, IHeadTable, ItemNormalize,} from 'components/Flows/Table/interface';
import {ECONOMICS_TABLE} from 'constants/tables';
import {getEconomicsSortRequest, getProductByLanguageAndCategoryEconomicsRequest,} from '../../redux/actions';
import {isArray} from 'lodash';
import {productTypeRequest} from 'modules/ProductType/redux/actions';
import {getLanguageFromStore, language} from 'utils/formatters';
import {TABLE_HEAD, TABLE_NAME} from 'constants/languagesTable';
import {ECONOMICS_FILTER} from 'constants/forms';
import {getAuthorsForTable} from '../../../../utils/authorsList';
import {AuthorsColumn} from 'components/AuthorsColumn/AuthorsColumn';

class EquityResearchTable extends React.Component<ICategoryTable> {
  normalizeTableData = (
    head: IHeadTable[],
    body: IBodyTable[],
    valuesTable: any,
  ): any => {
    const productType = valuesTable && valuesTable.productType;

    // const author = valuesTable && valuesTable.author;
    // akayerov чтобы работало по F5
    const author = isArray(valuesTable.author)
      ? valuesTable.author
      : valuesTable.authorType;

    const sectors = valuesTable && valuesTable.sectors;
    const categories = valuesTable && valuesTable.categories;

    const processedData =
      (productType &&
        productType.length &&
        sectors &&
        sectors.length &&
        author &&
        author.length &&
        productType &&
        productType.length &&
        categories &&
        categories.length &&
        body &&
        body.map((item: any) => {
          const { id }: any = item;

          const sector = sectors
            .filter((o1: any) => {
              return (
                isArray(item.sectors) &&
                item.sectors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((lu: any) => {
              return {
                name: language(lu.name, 'en'),
                id: lu.id,
              };
            });
          const categoriesTitle = categories
            .filter((o1: any) => {
              return o1.id === item.categoryId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join('');

          const strategy = productType
            .filter((o1: any) => {
              return o1.id === item.productTypeId;
            })
            .map((lu: any) => {
              return language(lu.name, 'en');
            })
            .join('');

          const au = author
            .filter((o1: any) => {
              return (
                isArray(item.authors) &&
                item.authors.some((o2: any) => {
                  return o1.id === o2;
                })
              );
            })
            .map((y: any) => {
              return {
                name: language(y.name, 'en'),
                id: y.id,
                photo: y.photo,
                blocked: y.blocked,
              };
            });
          // 2021-08-31 отфильтровать спискок в соотвествии еще  типом документа
          const authorsForTable = getAuthorsForTable(
            item.productTypeNameSort,
            au,
          );
          // 2021-10-05 в модальных окнах только неблокированные авторы
          const auModal = au.filter((o1: ItemNormalize) => o1.blocked !== true);
          return {
            id,
            modal: {
              sectors: sector,
              authors: auModal,
              name: item.name,
              annotation: item.annotation,
              file: item?.file,
              downloadAvailable: item?.downloadAvailable,
              date: item.date,
              id: item.id,
              strategy,
              categoriesTitle,
// add 2021-11-12 for statistics
              categoryId: item.categoryId,
              productTypeId: item.productTypeId,
              languageId: item.languageId,
              // add 2021-11-17 for statistics
              companies: item.companies,

            },
            cells: [
              {
                element: item.name,
                id,
                type: 'name',
                width: 'l',
              },
/*
              {
                  element: <Label text={strategy}/>,
                  id,
                  type: 'productType',
                  width: 'l'
              },

 */
              {
                element: <AuthorsColumn authorsForTable={authorsForTable} />,
                id,
                type: 'author',
                width: 'l',
              },
              {
                element: item.date,
                id,
                type: 'date',
                width: 'l',
              },
            ],
          };
        })) ||
      [];

    return processedData.map((val: any) => {
      const newCells = val;
      newCells.cells = val.cells.map((j: any) => {
        const index = head.findIndex((m) => m.id === j.type);
        j.width = head[index].style!.width;
        return j;
      });
      return newCells;
    });
  };

  getRowLink = (id: any) => `/interface-settings/${id}`;

  render() {
    const { handleRowClick, showTitle }: any = this.props;
    const head = [
      {
        id: 'name',
        label: TABLE_HEAD['name'][getLanguageFromStore()],
        style: { width: 'l' },
        type: 'modal',
        sort: 'default',
      },
/*
      {
        id: 'productType',
        label: TABLE_HEAD['productType'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
      },

 */
      {
        id: 'author',
        label: TABLE_HEAD['analyst'][getLanguageFromStore()],
        type: 'modal',
        style: { width: 'm' },
        // 2021-08-04 Новое значение none отключает щелчок по колонке для сортировки
        sort: 'none',
      },
      {
        id: 'date',
        type: 'modal',
        label: TABLE_HEAD['date'][getLanguageFromStore()],
        style: { width: 's' },
      },
    ];

    const graphId = {};
    const actions = {
      productType: productTypeRequest,
    };

    return (
      <GraphTable
        customTableClass={'standart-table-m'}
        actions={actions}
        graphId={graphId}
        type={'edit'}
        filterForm={ECONOMICS_FILTER}
        caption={
          showTitle ? TABLE_NAME[ECONOMICS_TABLE][getLanguageFromStore()] : ''
        }
        table={ECONOMICS_TABLE}
        normalizeTableData={this.normalizeTableData}
        sortRequest={getEconomicsSortRequest}
        init={getProductByLanguageAndCategoryEconomicsRequest}
        onRowClick={handleRowClick}
        head={head}
        link={this.getRowLink}
        width={'7'}
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        pagination={true}
      />
    );
  }
}

export default EquityResearchTable;
