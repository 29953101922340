// @ts-nocheck
import React from 'react';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import FiltersForm from 'components/Graphs/Filters';
import { getSectorByIdRequest } from 'modules/SectorById/redux/actions';
import { normalize } from './normalize';
import config from './fields';
import { COMPANIES_REPORT_FILTER } from 'constants/forms';
import { connect } from 'react-redux';
import {
  resetTableFilter,
  tableDataRequest,
} from 'components/Graphs/Table/actions';
import { COMPANIES_REPORT_TABLE } from 'constants/tables';
import { getProductByLanguageCompaniesRequest } from '../../../redux/actions';
import { EN_LANGUAGE, RU_LANGUAGE } from '../../../../../utils/formatters';
// 2021-08-02
import { setGlobalCompanyId } from '../../../../../globalVariables';
import { Actions } from 'components/Filter/components/FilterActions';

interface IEquityResearchFilter {
  title?: string;
  form: string;
  match: any;
}

class EquityResearchFilter extends React.Component<
  IEquityResearchFilter,
  { isFilterHidden: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isFilterHidden: false,
    };
  }

  componentDidMount() {
    const { companyId }: any = this.props;
    setGlobalCompanyId(companyId);
  }

  handleHideClick = () => {
    const { isFilterHidden } = this.state;
    this.setState({
      isFilterHidden: !isFilterHidden,
    });
  };

  handleClick = () => {
    const { dispatch, companyId }: any = this.props;
    setGlobalCompanyId(null);
    dispatch(reset(COMPANIES_REPORT_FILTER));
    dispatch(
      resetTableFilter({
        payload: {},
        meta: { table: COMPANIES_REPORT_TABLE },
        type: '',
      }),
    );
    dispatch(
      tableDataRequest({
        type: 'edit',
        meta: {
          table: COMPANIES_REPORT_TABLE,
          init: getProductByLanguageCompaniesRequest,
        },
        payload: { companyId },
      }),
    );
  };

  submit = ({ shortNameEN, nameEN, nameRU, shortNameRU }: any) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return {
      id,
      name: [
        {
          language: { id: RU_LANGUAGE, name: 'Рус' },
          translatedValue: nameRU,
        },
        {
          language: { id: EN_LANGUAGE, name: 'En' },
          translatedValue: nameEN,
        },
      ],
      shortName: [
        {
          language: { id: RU_LANGUAGE, name: 'Рус' },
          translatedValue: shortNameRU,
        },
        {
          language: { id: EN_LANGUAGE, name: 'En' },
          translatedValue: shortNameEN,
        },
      ],
    };
  };

  render() {
    const {
      title,
      match: {
        params: { id },
      },
    } = this.props;

    const { isFilterHidden } = this.state;

    return (
      <FiltersForm
        customClass={''}
        form={COMPANIES_REPORT_FILTER}
        actions={
          <Actions
            handleClick={this.handleClick}
            handleHideClick={this.handleHideClick}
            isFilterHidden={isFilterHidden}
          />
        }
        isFilterHidden={isFilterHidden}
        onSuccess={this.handleClick}
        graphId={id}
        init={getSectorByIdRequest}
        title={title}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="2"
        reInitialize
      />
    );
  }
}

export default compose<any>(connect(), withRouter)(EquityResearchFilter);
