// @ts-nocheck
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {createStructuredSelector} from 'reselect';
import {makeSelectData} from './selector';
import {IGraphTable} from './interface';
import {setValueSet} from '../../ValueSet/actions';
import {selectAllValueSets, selectValueSet} from '../../ValueSet/selector';
import {tableDataRequest} from '../Table/actions';

import {isEmpty} from 'lodash';
import FlowForecast from '../../Flows/Forecast';

class GraphForecast extends React.PureComponent<IGraphTable> {
  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
    this.getForecastData(this.props);
  }

  getForecastData({ dispatch, table, init, graphId = {} }: IGraphTable) {
    const type = '';
    if (init !== undefined) {
      // данные по категориям выбираются ранее другими модулями, нет необходимости дублировать
      dispatch(
        tableDataRequest({
          type,
          meta: { table, init },
          payload: { graphId: isEmpty(graphId) ? null : graphId },
        }),
      );
    }
  }

  render() {
    const {
      normalizeTableData,
      width,
      gutter,
      graphData,
      caption,
      valuesModal,
      ...rest
    }: IGraphTable = this.props;
    return (
      <FlowForecast
        valuesModal={valuesModal}
        caption={caption}
        width={width}
        normalizeTableData={normalizeTableData}
        data={graphData}
        gutter={gutter}
        {...rest}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valuesModal: selectAllValueSets,
  graphData: makeSelectData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(GraphForecast);
