import React from 'react';

export const AuthorsColumn = ({
  authorsForTable,
}: {
  authorsForTable: any;
}) => {
  return (
    <div>
      <div className="author-table-photo-wrapper">
        {authorsForTable.map((d: any) => (
          <img className="author-table-photo" key={d.id} src={d.photo} />
        ))}
        {/* <span>{authorsForTable.length}</span> */}
      </div>
      <div>
        {authorsForTable.map((d: any) => (
          <div className={'author-table'} key={d.id}>
            {d.name}
          </div>
        ))}
      </div>
    </div>
  );
};
