import {all, takeEvery,takeLeading, put} from 'redux-saga/effects';
import {tableDataSuccess, tableDataPaginationSuccess} from './actions';
import {TABLE_DATA_REQUEST, TABLE_DATA_PAGINATION_REQUEST} from './constants';
import {IAction} from 'types/index'

function* get({payload, meta: {table, init}}: IAction<void>) {
    yield put(init({payload, meta: {table, callback: tableDataSuccess, payload}}))
}

function* getPagination({payload, meta: {table, init, currentPage}}: IAction<void>) {
    yield put(init({payload, meta: {table, currentPage, callback: tableDataPaginationSuccess, payload}}))
}

function* Saga() {
    yield all([
        takeEvery(TABLE_DATA_REQUEST, get),
        takeLeading(TABLE_DATA_PAGINATION_REQUEST, getPagination),
    ]);
}

export default Saga;
