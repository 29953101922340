import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {

    getProductType = () => request({
        url: 'catalog/product-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getProductTypeByCategory = (id: any) => {
        return request({
            url: `catalog/product-type/by-category-id/${id}`,
            method: 'GET',
            token: API_GLOBAL.token
        });
    }
}

const API_PRODUCT_TYPE = new ClassAPI();

export default API_PRODUCT_TYPE;
