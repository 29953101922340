// @ts-nocheck
import {fromJS} from 'immutable';
import {LOGOUT_SUCCESS} from 'modules/Auth/redux/constants';
import {SET_PROFILEID, SET_USER} from './constants';

const initialState = fromJS({ profileId: null});

const userReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_USER: {
      return payload;
    }
    case SET_PROFILEID: {
      return { ...state, profileId: payload }
      // return state.set('profileId', payload);

    }
    case LOGOUT_SUCCESS: {
      return initialState;
    }

    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default userReducer;
