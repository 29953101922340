import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'
import queryString from 'query-string'

class ClassAPI {

    getSectorById = ({id}: any) => request({
        url: `catalog/sector/${id}`,
        method: 'GET',
        token: API_GLOBAL.token
    });

}

const API_SECTOR_ID = new ClassAPI();

export default API_SECTOR_ID;
