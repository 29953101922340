import { createSelector } from 'reselect';

const selectGraphFormState = (state: any) => state.graphForm;

export const selectCurrentForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => ownProps.form || ownProps.meta.form
    ],
    (graphForm, form) => {
        return graphForm.get(form)
    },
);

export const makeSelectData = () => createSelector(
    selectCurrentForm,
    state => state && state.get('data'),
);



const selectForm = (state: any) => state.form;

export const makeSelectForm = () => createSelector(
    selectForm,
    form => form && form,
);