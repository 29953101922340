export const GET_ALL_EQUITYRESEARCH_REQUEST = 'GET_ALL_EQUITYRESEARCH_REQUEST'
export const GET_ALL_EQUITYRESEARCH_SUCCESS = 'GET_ALL_EQUITYRESEARCH_SUCCESS'
export const GET_ALL_EQUITYRESEARCH_FAILURE = 'GET_ALL_EQUITYRESEARCH_FAILURE'

export const CREATE_NEW_EQUITYRESEARCH_REQUEST = 'CREATE_NEW_EQUITYRESEARCH_REQUEST'

export const EDIT_EQUITYRESEARCH_REQUEST = 'EDIT_EQUITYRESEARCH_REQUEST'

export const GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST = 'GET_ALL_COMPANIES_WIDGETSCHARTS_REQUEST'

export const GET_ALL_SECTORS_WIDGETSCHARTS_REQUEST = 'GET_ALL_SECTORS_WIDGETSCHARTS_REQUEST'




export const GET_PRODUCT_BY_EQUITY_REQUEST = 'GET_PRODUCT_BY_EQUITY_REQUEST';
export const GET_PRODUCT_BY_ECONOMICS_REQUEST = 'GET_PRODUCT_BY_ECONOMICS_REQUEST';
export const GET_PRODUCT_BY_FIXEDINCOME_REQUEST = 'GET_PRODUCT_BY_FIXEDINCOME_REQUEST';
export const GET_PRODUCT_BY_COMMODITIES_REQUEST = 'GET_PRODUCT_BY_COMMODITIES_REQUEST';



export const GET_EQUITY_RESEARCH_SORT_REQUEST = 'GET_EQUITY_RESEARCH_SORT_REQUEST';
export const GET_ECONOMICS_SORT_REQUEST = 'GET_ECONOMICS_SORT_REQUEST';
export const GET_FIXEDINCOME_SORT_REQUEST = 'GET_FIXEDINCOME_SORT_REQUEST';
export const GET_COMMODITIES_SORT_REQUEST = 'GET_COMMODITIES_SORT_REQUEST';



