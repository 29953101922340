import Profile from './pages/ProfileForm';


export default [
  {
    type: 'private',
    exact: true,
    path: '/profile',
    roles: ['admin'],
    component: Profile,
  },
];
