// @ts-nocheck
import React from 'react';
import { compose } from 'redux';
import { reset } from 'redux-form';
import { withRouter } from 'react-router';
import GraphTeam from 'components/Graphs/Team';
import { getSectorByIdRequest } from 'modules/SectorById/redux/actions';
import { normalize } from './normalize';
import config from './fields';
import { TEAM } from 'constants/forms';
import { connect } from 'react-redux';
import { EN_LANGUAGE, RU_LANGUAGE } from '../../../../utils/formatters';

interface ITeam {
  title?: string;
  form: string;
  match: any;
}

class Team extends React.Component<ITeam> {
  handleClick = () => {
    const { dispatch }: any = this.props;
    dispatch(reset(TEAM));
  };

  submit = ({ shortNameEN, nameEN, nameRU, shortNameRU }: any) => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    return {
      id,
      name: [
        {
          language: { id: RU_LANGUAGE, name: 'Рус' },
          translatedValue: nameRU,
        },
        {
          language: { id: EN_LANGUAGE, name: 'En' },
          translatedValue: nameEN,
        },
      ],
      shortName: [
        {
          language: { id: RU_LANGUAGE, name: 'Рус' },
          translatedValue: shortNameRU,
        },
        {
          language: { id: EN_LANGUAGE, name: 'En' },
          translatedValue: shortNameEN,
        },
      ],
    };
  };

  render() {
    const {
      title,
      match: {
        params: { id },
      },
    } = this.props;
    return (
      <GraphTeam
        customClass={'our-sidebar__item'}
        form={TEAM}
        actions={[]}
        onSuccess={this.handleClick}
        graphId={id}
        init={getSectorByIdRequest}
        title={title}
        submit={this.submit}
        normalize={normalize}
        validate={() => {}}
        type={id ? 'edit' : 'create'}
        columns={1}
        config={config}
        width="8"
        reInitialize
      />
    );
  }
}

export default compose<any>(connect(), withRouter)(Team);
