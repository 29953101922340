import Economics from './pages';


export default [
    {
        type: 'private',
        exact: true,
        path: '/economics',
        roles: ['admin'],
        component: Economics,
    },
];
