// @ts-nocheck
import React from 'react';
import { language } from 'utils/formatters';
import cn from 'bem-cn';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import Paragraph from 'arui-feather/paragraph';
import {
  selectAllValueSets,
  selectValueSet,
} from 'components/ValueSet/selector';

const analyst = cn('analyst-profile');

class Profile extends React.Component<any> {
  normalizeTableData = () => {
    const {
      match: {
        params: { id },
      },
      valueSetsAll,
    } = this.props;
    const author = valueSetsAll && valueSetsAll.author;
    if (author && author.length && id) {
      const currentID = id;
      const item = author.find((item: { id: string }) => item.id === currentID);
      const { name, biography, email, phone1, phone2, photo, position } = item;

      return (
        <div className={analyst()}>
          <div className={analyst('wrapper')}>
            <div className={analyst('photo')}>
              <img src={photo} alt={`${language(name, 'en')}`} />
            </div>
            <div className={analyst('contact')}>
              <h2 className={analyst('contact', 'fullname')}>
                {language(name, 'en')}
              </h2>
              <span className={analyst('contact', 'post')}>
                {language(position, 'en')}
              </span>
              <span className={analyst('contact', 'email')}>{email}</span>
              <span className={analyst('contact', 'phone')}>{phone1}</span>
              <span className={analyst('contact', 'phone')}>{phone2}</span>
            </div>
          </div>
          <div className={analyst('about')}>
            <Paragraph view="normal">
              <div
                dangerouslySetInnerHTML={{ __html: language(biography, 'en') }}
              />
            </Paragraph>
          </div>
        </div>
      );
    }
  };

  render() {
    return <>{this.normalizeTableData()}</>;
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(Profile);
