import * as ACTION_TYPE from './constants';

export const getAllStaticInfoRequest = ({ payload, meta }: any) => ({
    type: ACTION_TYPE.GET_STATIC_INFO_REQUEST,
    meta,
    payload
});

export const getStaticInfoByTypeRequest = ({ payload, meta }: any) => ({
    type: ACTION_TYPE.GET_STATIC_INFO_BY_TYPE_REQUEST,
    meta,
    payload
});






