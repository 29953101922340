import {getLanguageFromStore} from "../../../../utils/formatters";

const validate = (values: any) => {
  const errors = {} as any;
  const language = getLanguageFromStore();
  // console.log('changeNameClient name',names )

  if( !values.userFromAD ) {  // для пользователей из AD не редактируем и не проверяем
    if (values && values.clientName) {
      const trimName = values.clientName.trim()
//    console.log('trimName=', trimName)
      if (!trimName) {
        if (language == 'en') {
          errors.clientName = 'Name SecondName';
        } else {
          errors.clientName = 'Имя Фамилия';
        }
      } else if (trimName && trimName.indexOf(' ') == -1) {
        if (language == 'en') {
          errors.clientName = 'Name SecondName';
        } else {
          errors.clientName = 'Имя Фамилия';
        }
      }
    }
    if (values && values.password) {
      if (values.password.length < 6) {
        if (language == 'en') {
          errors.password = "Minimum 6 character";
        } else {
          errors.password = "Минимум 6 символов";
        }
      }
    }

    if (values && values.confirmPassword) {
      if (values.confirmPassword.length < 6) {
        if (language == 'en') {
          errors.confirmPassword = "Minimum 6 character";
        } else {
          errors.confirmPassword = "Минимум 6 символов";
        }
      }
    }
    if (values && values.password && values.password.length > 5 &&
        values.confirmPassword && values.confirmPassword.length > 5 &&
        values.password != values.confirmPassword) {
      if (language == 'en') {
        errors.password = "The password fields must match";
        errors.confirmPassword = "The password fields must match";
      } else {
        errors.password = "Поля паролей совпадать";
        errors.confirmPassword = "Поля паролей совпадать";
      }
    }
  }
  return errors;
};

export default validate;