import * as ACTION_TYPE from './constants';

export const productTypeRequest = ({payload, meta}: any) => ({
    type: ACTION_TYPE.GET_PRODUCT_TYPE_REQUEST,
    payload,
    meta,
});


export const productTypeByCategoryRequest = ({payload, meta}: any) => {
    return ({
        type: ACTION_TYPE.GET_PRODUCT_TYPE_BY_CATEGORY_REQUEST,
        payload,
        meta,
    });
}