import {language} from 'utils/formatters'
//1 sector
//2 company
export const normalizeSectorCompany = (sectors: any[], company: any[]) => {
    if (company && company.length && sectors && sectors.length) {

        return sectors.map((value: any) => {
            const children = company
                .filter((element: any) => value.id == element.sectorId)
                .map((lu: any) => {
                    return ({
                        name: language(lu.name, 'en'),
                        id: lu.id
                    })
                });
            return {
                name: language(value.name, 'en'),
                id: value.id,
                children: children
            }
        })
    }


};

export const normalizeCompanyOne = (company: any[]) => {
    if (company && company.length) {
        return company.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }
};

export const normalizeCountryOne = (country: any[]) => {
    if (country && country.length) {
        return country.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }
};

export const normalizePeriodTypes = (periodTypes: any[]) => {
    if (periodTypes && periodTypes.length) {
        return periodTypes.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }
};

export const normalizeSector = (sectors: any[]) => {
    if (sectors && sectors.length) {
        return sectors.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }


};

export const normalizeCommodities = (commodities: any[]) => {
    if (commodities && commodities.length) {
        return commodities.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }


};

export const normalizeProductType = (product: any[]) => {
    if (product && product.length) {
        return product.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }
};

export const normalizeAuthor = (author: any[]) => {
    if (author && author.length) {
        return author.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }
};

export const normalizeEventType = (eventType: any[]) => {
    if (eventType && eventType.length) {
        return eventType.map((value: any) => {
            return {
                name: language(value.name, 'en'),
                id: value.id
            }
        })
    }
};

