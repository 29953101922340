import {createSelector} from 'reselect';
import {STATIC_INFO_CURRENT_MARKET_PRICE_TABLE, STATIC_INFO_TABLE} from "../../../constants/tables";

const selectGraphFormState = (state: any) => state.graphTable;

export const selectCurrentForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => ownProps.table,
    ],
    (graphForm, table) => {
        return graphForm.get(table)
    },
);

export const makeSelectData = () => createSelector(
    selectCurrentForm,
    state => state && state.get('data'),
);


// не очень красиво в этом месте
//-------------------------------------------------------------------
export const selectFooterInfoForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => STATIC_INFO_TABLE,

    ],
    (graphForm, table) => {
        return graphForm.get(table)
    },
);

export const makeSelectFooterInfoData = () => createSelector(
    selectFooterInfoForm,
    state => state && state.get('data'),
);

export const selectStaticCurrentMarketPriceForm = createSelector(
    [
        selectGraphFormState,
        (state: any, ownProps: any) => STATIC_INFO_CURRENT_MARKET_PRICE_TABLE,

    ],
    (graphForm, table) => {
        return graphForm.get(table)
    },
);

export const makeSelectStaticCurrentMarketPricesData = () => createSelector(
    selectStaticCurrentMarketPriceForm,
    state => state && state.get('data'),
);
