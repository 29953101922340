import { getLanguageFromStore } from './formatters';

export function getAuthorsForTable(typeProduct, authors) {
  // console.log('getAuthorsForTable typeProduct/authors', typeProduct, authors);
  if (typeProduct !== 'Daily' && typeProduct !== 'Ежедневный обзор') {
    return authors.slice(0, 3);
  }

  if (authors.length > 3) {
    const result = [];
    result.push({
      id: 1,
      name:
        getLanguageFromStore() === 'en'
          ? 'Research team'
          : 'Аналитическая команда',
    });
    return result;
  }
  return authors.slice(0, 3);
}
