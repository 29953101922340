// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import EquityResearchFilter from './filter';
import EquityResearchTable from './table';
import EquityResearchProductNews from './productNews';
import EquityResearchEventsNews from './eventNews';
import ColTree from 'components/ColTree';
import GridCol from 'arui-feather/grid-col';
import {EQUITYRESEARCH_TABLE} from 'constants/tables';
import {getLanguageFromStore} from 'utils/formatters';
import {Heading} from 'arui-feather/heading';
import {TABLE_NAME} from 'constants/languagesTable';
import {useResolution} from 'hooks/useResolution';
import {FIELD_NAME, GRAPH_OFZ} from 'constants/languagesFields';
import ForecastFiles from '../../_Common/forecastFiles';
import ChartOFZ from "../../_Common/chartOFZ";

const page = ['Equity', 'Рынок акций'];

const EquityResearch = () => {
    const {isMobile} = useResolution();
    return (
        <div className="custom-page-wrapper equity-research-page">
            {isMobile && <EquityResearchEventsNews/>}
            <GridRow
                gutter={{mobile: 0, tablet: 16, desktop: {m: 12}}}
                className="custom-margin-bottom"
            >
                <GridCol>
                    <Heading size="xl" className="custom-page-title">
                        {TABLE_NAME[EQUITYRESEARCH_TABLE][getLanguageFromStore()]}
                    </Heading>
                </GridCol>
            </GridRow>
            <GridRow
                gutter={{mobile: 0, tablet: 16, desktop: {m: 12}}}
                className="flex_no-wrap"
            >
                <ColTree className="custom-column-section custom-filter-section">
                    {isMobile && <ForecastFiles category={page}/>}
                    <EquityResearchFilter/>
                    {!isMobile && <ForecastFiles category={page}/>}
                </ColTree>
                <EquityResearchTable showTitle={false}/>
                <ColTree className="custom-column-section">
                    {isMobile && (
                        <Heading size="l">
                            {FIELD_NAME['actual'][getLanguageFromStore()]}
                        </Heading>
                    )}
                    <EquityResearchProductNews/>
                    {!isMobile && <EquityResearchEventsNews/>}
                </ColTree>
            </GridRow>
        </div>
    );
};

export default EquityResearch;
