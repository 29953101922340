import { hashCode } from 'utils/formatters'

const normalize = (data: any) => {
  if (!data) return null;
  const objectData = {} as any


  data.deliveryData.forEach((item: any) => {

    const ThreeValues = {} as any
    item && item.categories && item.categories.forEach((itemC: any) => {
      ThreeValues[itemC.id] = {};
      itemC.productTypes.forEach((types: any) => {
        ThreeValues[itemC.id][types] = true
      })
    });

    const ThreeSectors = {} as any
    item && item.sectors && item.sectors.forEach((itemC: any) => {
      ThreeSectors[itemC] = true
    });

    if (item.email) {
      objectData[hashCode(item.email)] = Object.assign(
        {
          email: item.email,
          engLang: item.engLang,
          rusLang: item.rusLang,
          attachProduct: item.attachProduct,
          sectors: ThreeSectors,
          clientRightSettings: item.clientRightSettings
        }, { checkboxes: ThreeValues }
      )
    }
  });

  let cormData = {
    ...data,
    portalDefaultLanguageId: data.portalDefaultLanguageId,
    deliveryData: objectData,
    email: data.email,
    engLang: data.engLang,
    rusLang: data.rusLang,
    attachProduct: data.attachProduct,
    // 2021-08-05
    clientName: data.clientName,
//    password: data.password,
    password: '',
    confirmPassword: ''
  };

  return cormData;
};

export default normalize;
