// @ts-nocheck
import React from 'react';
import { isArray } from 'lodash';
import Tabs from 'arui-feather/tabs';
import TabItem from 'arui-feather/tab-item';
import { Route, Switch, withRouter } from 'react-router';
import {
  getCompanyByIdRequest,
  getCompanyFileByIdRequest,
  getAllEquityTickersRequest,
  getAllCurrencyRequest,
  getAllStockRequest,
  getAllStockTypeRequest,
  getRecommendationByCompanyIdRequest,
  // 2021-09-15
} from 'modules/Company/redux/actions';
import { setValueSet } from '../../../components/ValueSet/actions';
import { createStructuredSelector } from 'reselect';
import {
  selectAllValueSets,
  selectValueSet,
} from '../../../components/ValueSet/selector';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getAllAuthorRequest } from '../../Author/redux/actions';
import { getAllSectorsRequest } from '../../Sector/redux/actions';
import { getLanguageFromStore, language } from 'utils/formatters';
import HeadCompany from 'components/SearchResult/HeadCompany';
import About from 'modules/Companies/pages/about/about';
import Files from 'modules/Companies/pages/files/files';
import Reports from 'modules/Companies/pages/reports/reports';
import { ABOUT_COMPANY } from 'constants/languagesFields';
import GridRow from 'arui-feather/grid-row';
import ColTree from '../../../components/ColTree';
import { getAllcategoriesRequest } from '../../Categories/redux/actions';
import TargetPrices from './targetPrices/targetPrices';
import CompaniesEventsNews from './companies/eventNews';
// 2021-08-15
import { setGlobalCompanyId } from '../../../globalVariables';
import { ItemNormalize } from '../../../components/Flows/Table/interface';

const stateColor: any = {
  GREY: 'default',
  RED: 'down',
  GREEN: 'up',
};

class CompanyView extends React.Component<any, any> {
  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    const { dispatch }: any = this.props;
    const actions: any = {
      author: getAllAuthorRequest,
      sectors: getAllSectorsRequest,
      equityTickers: getAllEquityTickersRequest,
      currency: getAllCurrencyRequest,
      // 2021-09-15 думаю не надо совсем здесь получать полный список, надо только по этой компании
      // здесь не передается id
      // recommendation: getAllStockRecommendationRequest,

      stock: getAllStockRequest,
      categories: getAllcategoriesRequest,
      stockType: getAllStockTypeRequest,
    };

    Object.keys(actions).forEach((key) => {
      dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
    });

    // 2021-09-15 способ взять companyId для запросов в saga
    setGlobalCompanyId(id);

    dispatch(
      getCompanyByIdRequest({
        payload: { id },
        meta: { form: 'companyId', callback: setValueSet },
      }),
    );

    dispatch(
      getRecommendationByCompanyIdRequest({
        payload: { id },
        meta: { form: 'recommendationByIdCompany', callback: setValueSet },
      }),
    );

    dispatch(
      getCompanyFileByIdRequest({
        payload: { id },
        meta: { form: 'files', callback: setValueSet },
      }),
    );
  }

  normalize = () => {
    const {
      valueSetsAll,
      match: {
        params: { id },
      },
    } = this.props;

    const {
      companyId,
      author,
      sectors,
      files,
      equityTickers,
      currency,
      // 2021-09-15 все рекомендации не нужны здесь
      // recommendation,
      stock,
      categories,
      stockType,
      recommendationByIdCompany,
    } = valueSetsAll;

    if (
      companyId &&
      companyId.id === id &&
      isArray(stockType) &&
      isArray(author) &&
      isArray(categories) &&
      isArray(files) &&
      isArray(sectors) &&
      isArray(equityTickers) &&
      isArray(currency) &&
      // isArray(recommendation) &&
      isArray(stock) &&
      isArray(recommendationByIdCompany)
    ) {
      const normFiles = files.map((file: any, k: number) => {
        return {
          name: file.name,
          id: k + 1,
          type: file.fileFormat,
          size: file.fileSize,
          url: file.url,
        };
      });

      // const recommendationName = recommendation
      // .find((iy: any) => iy.id === companyId.recommendation);

      const recBlock = recommendationByIdCompany.map((value) => {
        const currencyName = currency.find(
          (iy: any) => iy.id === value.currencyId,
        );
        return {
          name: value?.name,
          price: {
            value: value?.stockRecommendation?.targetPrice,
            currency: {
              minority: 1,
            },
            unit: currencyName?.name,
            status:
              stateColor[value?.tickerState ? value?.tickerState : 'GREY'],
          },
          rec: language(value?.stockRecommendation?.recommendation, 'en'),
        };
      });

      const equityTickerId = equityTickers.find(
        (iy: any) => iy.id === companyId.equityTickerId,
      );

      const currencyId = equityTickerId && equityTickerId.currencyId;

      const currencyName = currency.find((iy: any) => iy.id === currencyId);

      let au = author
        .filter((o1: any) => {
          return (
            isArray(companyId.authors) &&
            companyId.authors.some((o2: any) => {
              return o1.id === o2;
            })
          );
        })
        .map((y: any) => {
          return {
            fullName: language(y.name, 'en'),
            id: y.id,
            photo: y.photo,
            urlPage: `/analyst-profile/${y.id}`,
            blocked: y.blocked,
          };
        });
      // 2021-10-05 только неблокированные авторы
      au = au.filter((o1: ItemNormalize) => o1.blocked !== true);

      const sector = language(
        sectors.find((u: any) => {
          return u.id === companyId.sectorId;
        }).name,
        'en',
      );

      const stockId = stock.find((iy: any) => iy.companyId === companyId.id);
      const stockTypeId =
        stockId && stockType.find((ite: any) => ite.id === stockId.stockTypeId);

      return {
        stockTypeId: stockTypeId && language(stockTypeId.name, 'en'),
        stockId: stockId && stockId.id,
        recBlock,
        stockName: language(stockId?.name, 'en'),
        recommendationName: language(
          equityTickerId?.stockRecommendation?.recommendation,
          'en',
        ),
        ticker: equityTickerId && equityTickerId.name,
        currencyName: currencyName && currencyName.name,
        companyId: companyId.id,
        logo: companyId.logo,
        financialIndicators: companyId.tableInfo,
        name: language(companyId.name, 'en'),
        targetPrice: companyId.targetPrice,
        authors: au,
        sector,
        files: isArray(normFiles) ? normFiles : [],
        description: language(companyId.description, 'en'),
        shortName: language(companyId.shortName, 'en'),
      };
    }
  };

  handleClick = (event: any) => {
    event.preventDefault();
    const { history } = this.props;
    history.push(event.target.getAttribute('href'));
  };

  render() {
    const {
      location: { pathname },
      match: {
        params: { id },
      },
    } = this.props;
    const baseUrl = `/companies`;

    const data = this.normalize();
    return (
      <div className="company-inner">
        <CompaniesEventsNews />
        <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
          <ColTree width={12}>
            <HeadCompany data={data} />
          </ColTree>
          <ColTree width={12}>
            <Tabs className={'general-form__tabs'}>
              <TabItem
                url={`${baseUrl}/about/${id}`}
                onClick={this.handleClick}
                checked={pathname === `${baseUrl}/about/${id}`}
              >
                {ABOUT_COMPANY['about'][getLanguageFromStore()]}
              </TabItem>
              <TabItem
                url={`${baseUrl}/reports/${id}`}
                onClick={this.handleClick}
                checked={pathname === `${baseUrl}/reports/${id}`}
              >
                {ABOUT_COMPANY['reports'][getLanguageFromStore()]}
              </TabItem>
              <TabItem
                url={`${baseUrl}/files/${id}`}
                onClick={this.handleClick}
                checked={pathname === `${baseUrl}/files/${id}`}
              >
                {ABOUT_COMPANY['files'][getLanguageFromStore()]}
              </TabItem>
              <TabItem
                url={`${baseUrl}/targetprice/${id}`}
                onClick={this.handleClick}
                checked={pathname === `${baseUrl}/targetprice/${id}`}
              >
                {ABOUT_COMPANY['targetPrice'][getLanguageFromStore()]}
              </TabItem>
            </Tabs>
          </ColTree>
          <ColTree width={12}>
            <Switch>
              <Route exact path={`${baseUrl}/about/${id}`}>
                <About id={id} data={data} />
              </Route>

              <Route exact path={`${baseUrl}/reports/${id}`}>
                <Reports id={id} data={data} />
              </Route>

              <Route exact path={`${baseUrl}/files/${id}`}>
                <Files id={id} data={data} />
              </Route>

              <Route exact path={`${baseUrl}/targetprice/${id}`}>
                <TargetPrices id={id} data={data} />
              </Route>
            </Switch>
          </ColTree>
        </GridRow>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose<any>(withConnect, withRouter)(CompanyView);
