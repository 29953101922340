import React from 'react';
import { isArray } from 'lodash';
import UserCartItem from 'components/UserCartItem';
import TickerInfoArray from 'components/TickerInfo/TickerInfoArray';
import './SearchResult.scss';
import GridRow from 'arui-feather/grid-row';
import GridCol from 'arui-feather/grid-col';
import Link from 'arui-feather/link';
import Heading from 'arui-feather/heading';
import Plate from 'arui-feather/plate';
import ArrowLeft from 'arui-feather/icon/ui/arrow-left';
import { useResolution } from 'hooks/useResolution';

const HeadCompany = ({ data }) => {
  const { isMobile } = useResolution();
  return (
    <div className="about-company">
      {isMobile && (
        <div className="about-company__meta">
          {data?.logo && (
            <div className="image-wrapper">
              <img alt="" src={data?.logo} />
            </div>
          )}
          <div className="info">
            <h4>{data?.name}</h4>
            <span>{data?.sector}</span>
          </div>
        </div>
      )}
      <GridRow className="about-company__wrapper">
        <GridCol
          width={{ mobile: 12, tablet: 2, desktop: 2 }}
          align="top"
          className="about-company__right-block 1"
        >
          <Link
            text={data && data.sector}
            size="l"
            icon={<ArrowLeft size="l" />}
            url="/companies"
          />
        </GridCol>
        <GridCol
          width={{ mobile: 12, tablet: 7, desktop: 7 }}
          align="top"
          className="about-company__content-block"
        >
          <Heading className="about-company__content-block__title">
            {data && data.name}
          </Heading>
          <Plate
            isFlat={true}
            hasCloser={false}
            view="filled"
            className="plate_type_default-no-shadow about-company__content-block__analysts"
          >
            {data &&
              data.authors &&
              data.authors.map((employee, index) => {
                return (
                  <div key={index}>
                    <Link to={`/analyst-profile/${employee.id}`}>
                      <UserCartItem
                        key={index}
                        user={employee}
                        type="analyst"
                      />
                    </Link>
                  </div>
                );
              })}
          </Plate>
        </GridCol>
        <GridCol width={{ mobile: 12, tablet: 3, desktop: 3 }} align="top">
          {data && isArray(data.recBlock) && (
            <TickerInfoArray data={data.recBlock} />
          )}
        </GridCol>
      </GridRow>
    </div>
  );
};

export default HeadCompany;
