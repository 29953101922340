import React from 'react';
import {Link} from 'react-router-dom';

import cn from 'bem-cn';

import './User.scss';
import HotNews from "../HotNews";

const userLink = cn('user-navigation');

class User extends React.Component {
  render() {
    return (
      <div className={userLink()} style={{paddingLeft:"0px"}}>
        <HotNews />
        <Link className={userLink('profile')} to="/profile">
          {this.props.username}
        </Link>

        <Link className={userLink('logout')} to="/login" />
      </div>
    );
  }
}

export default User;
