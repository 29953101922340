import * as ACTION_TYPE from './constants';

export const getAllProductForNews = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_ALL_PRODUCT_FOR_NEWS_REQUEST,
    meta,
    payload
});

export const getAllProductForNewByCompany = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_ALL_PRODUCT_FOR_NEWS_BY_COMPANY_REQUEST,
    meta,
    payload
});


export const getAllProductForNewByAuthor = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_ALL_PRODUCT_FOR_NEWS_BY_AUTHOR_REQUEST,
    meta,
    payload
});

export const getAllProductRequest = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_ALL_PRODUCT_REQUEST,
    meta,
    payload
});
export const getAllProductWithFilterRequest = ({meta, payload}: any) => ({
    type: ACTION_TYPE.GET_ALL_PRODUCT_WITH_FILTER_REQUEST,
    meta,
    payload
});