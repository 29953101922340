// @ts-nocheck
import React from 'react';
import { Field } from 'redux-form';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CheckBox from 'arui-feather/checkbox';
import CheckboxGroup from 'arui-feather/checkbox-group';
import Radio from 'arui-feather/radio';
import { language } from 'utils/formatters';
import './style.scss';
import { setValueSet } from '../ValueSet/actions';
import { createStructuredSelector } from 'reselect';
import { selectValueSet, selectAllValueSets } from '../ValueSet/selector';

export const RadioComponent = (props: any) => {
  const onChange = (value: any) => {
    props.input.onChange(value);
  };
  return (
    <Radio
      className="radio_tecman"
      onChange={onChange}
      text={props.label}
      key={props.label}
      value={props.input.value}
      checked={props.input.checked}
    />
  );
};

export const CheckBoxComponent = (props: any) => {
  const onChange = (value: any) => {
    const { input } = props;
    input.onChange(value);
  };

  return (
    <CheckBox
      disabled={props.disabled}
      className={props.className}
      onChange={onChange}
      text={props.label}
      checked={props.input.value}
    />
  );
};

class CheckboxesThree extends React.Component<any, any> {
  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  render() {
    const { valueSetsAll }: any = this.props;

    return (
      <>
        {valueSetsAll &&
          valueSetsAll.categories &&
          valueSetsAll.categories.map((item: any) => {
            return (
              <div className="form-row checkbox-array">
                <div className="form-row__cell input">
                  <div className="input__group-checkbox">
                    <CheckboxGroup
                      label={
                        <Field
                          name={`checkboxes.${item.id}`}
                          type="text"
                          component={CheckBoxComponent}
                          view="filled"
                          width="available"
                          size="s"
                          label={language(item.name, 'en')}
                        />
                      }
                    >
                      <div className="input__group-checkbox-items">
                        {valueSetsAll &&
                          valueSetsAll.productType &&
                          valueSetsAll.productType.map((itemChild: any) => {
                            return (
                              <Field
                                name={`checkboxes.${item.id}.${itemChild.id}`}
                                type="text"
                                component={CheckBoxComponent}
                                view="filled"
                                width="available"
                                size="s"
                                label={language(itemChild.name, 'en')}
                              />
                            );
                          })}
                      </div>
                    </CheckboxGroup>
                  </div>
                </div>
              </div>
            );
          })}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(CheckboxesThree);
