// @ts-nocheck
import React from 'react';
import {isArray} from 'lodash';
import Paragraph from 'arui-feather/paragraph';
import Index from '../../UserCartItem';
import Label from '../../Label';
import {pdfValidateTypeForModal} from '../../Icon/Icons';
import {getLanguageFromStore} from 'utils/formatters';
import './Research.scss';
import {connect} from 'react-redux';
import {getCurrentUser} from '../../../utils/currentUser';
import {addShowProductAction} from '../../../modules/Statistics/redux/actions';
import {Heading} from 'arui-feather/heading';
import {FIELD_NAME} from 'constants/languagesFields';
import {useResolution} from 'hooks/useResolution';

/* С отображением множества авторов решение в лоб сейчас, как в ТЗ, переношу на вторуй строчку
 лучше сделать flex, н это позже кода будет время 
*/

const Research = (data: any) => {
  const { modal, dispatch, history } = data;
  if (modal != null) {
    const user = getCurrentUser();
    if (modal.id) {
      console.log('add modal statistic modal=', user, modal);
      dispatch(addShowProductAction({ payload: { user, item: modal } }));
    }
  }
  const { isMobile } = useResolution();
  let line0 = [];
  let line1 = [];
  let line2 = [];
  if (modal && modal.authors) {
    if (modal.authors.length) {
      line0 = modal.authors.slice(0, 3);
    }
    if (modal.authors.length > 3) {
      line1 = modal.authors.slice(3, 6);
    }
    if (modal.authors.length > 6) {
      line2 = modal.authors.slice(6);
    }
  }

  return modal ? (
    <>
      <div className="research-content">
        <div className="research-content__top research-content__row">
          <div className="research-content__top_title">
            <Heading className="name-section" size="s">
              {modal.categoriesTitle}
            </Heading>
            <span onClick={ () => { history.push('/search?query=&productTypeId=' + modal.productTypeId)}} > <Label text={modal.strategy} /> </span>
            {/*<Label text={modal.strategy} />*/}
          </div>
          <div className="research-content__top_file cursor">
            {pdfValidateTypeForModal(modal, data.dispatch)}
          </div>
        </div>

        <div className="research-content__title research-content__row">
          <h3>{modal.name}</h3>
          <span className="research-content__title_date">{modal.date}</span>
        </div>

        {isMobile ? (
          <>
            {modal.annotation && (
              <div className="research-content__text research-content__row">
                <Paragraph view="normal">
                  <div
                    style={{ overflowWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{ __html: modal.annotation }}
                  />
                </Paragraph>
              </div>
            )}

            <div className="research-content__companies research-content__row">
              <ul>
                {isArray(modal.sectors) &&
                  modal.sectors.map((item: any) => <li>{item.name}</li>)}
              </ul>
            </div>
            <div className="research-content__analysts research-content__row">
              {modal &&
                line0.length > 0 &&
                line0.map((val: any) => {
                  return (
                    <Index
                      key={val.id}
                      user={{
                        id: val.id,
                        fullName: val.name,
                        photo: val.photo,
                        urlPage: `/analyst-profile/${val.id}`,
                      }}
                      type="analyst"
                    />
                  );
                })}
            </div>

            <div className="research-content__analysts research-content__row">
              {modal &&
                modal.authors &&
                line1.length > 0 &&
                line1.map((val: any) => {
                  return (
                    <Index
                      key={val.id}
                      user={{
                        id: val.id,
                        fullName: val.name,
                        photo: val.photo,
                        urlPage: `/analyst-profile/${val.id}`,
                      }}
                      type="analyst"
                    />
                  );
                })}
            </div>

            <div className="research-content__analysts research-content__row">
              {modal &&
                modal.authors &&
                line2.length > 0 &&
                line2.map((val: any) => {
                  return (
                    <Index
                      key={val.id}
                      user={{
                        id: val.id,
                        fullName: val.name,
                        photo: val.photo,
                        urlPage: `/analyst-profile/${val.id}`,
                      }}
                      type="analyst"
                    />
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <div className="research-content__analysts research-content__row">
              {modal &&
                line0.length > 0 &&
                line0.map((val: any) => {
                  return (
                    <Index
                      key={val.id}
                      user={{
                        id: val.id,
                        fullName: val.name,
                        photo: val.photo,
                        urlPage: `/analyst-profile/${val.id}`,
                      }}
                      type="analyst"
                    />
                  );
                })}
            </div>

            <div className="research-content__analysts research-content__row">
              {modal &&
                modal.authors &&
                line1.length > 0 &&
                line1.map((val: any) => {
                  return (
                    <Index
                      key={val.id}
                      user={{
                        id: val.id,
                        fullName: val.name,
                        photo: val.photo,
                        urlPage: `/analyst-profile/${val.id}`,
                      }}
                      type="analyst"
                    />
                  );
                })}
            </div>

            <div className="research-content__analysts research-content__row">
              {modal &&
                modal.authors &&
                line2.length > 0 &&
                line2.map((val: any) => {
                  return (
                    <Index
                      key={val.id}
                      user={{
                        id: val.id,
                        fullName: val.name,
                        photo: val.photo,
                        urlPage: `/analyst-profile/${val.id}`,
                      }}
                      type="analyst"
                    />
                  );
                })}
            </div>

            {modal.annotation && (
              <div className="research-content__text research-content__row">
                <Paragraph view="normal">
                  <div
                    style={{ overflowWrap: 'break-word' }}
                    dangerouslySetInnerHTML={{ __html: modal.annotation }}
                  />
                </Paragraph>
              </div>
            )}

            <div className="research-content__companies research-content__row">
              <ul>
                {isArray(modal.sectors) &&
                  modal.sectors.map((item: any, index:any) => <li key={index}>{item.name}</li>)}
              </ul>
            </div>
          </>
        )}

        <div className="research-content__top_file cursor mobile research-content__row">
          {pdfValidateTypeForModal(modal, data.dispatch)}{' '}
        </div>

        <div className="research-content__close mobile research-content__row">
          <button role="button" type="button">
            {FIELD_NAME['close'][getLanguageFromStore()]}
          </button>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default connect()(Research);
