import React from 'react';
import {dowloadFile, dowloadFileExtended, getExtension, isValidUrl,} from '../../utils/formatters';
import {loginFailure} from '../../modules/Auth/redux/actions';
import {FIELD_NAME} from 'constants/languagesFields';
import {getLanguageFromStore} from 'utils/formatters';
import {isArray} from 'lodash';

export const IconPdf = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      version="1"
      viewBox="0 0 36 36"
    >
      <path
        fill="#ef3124"
        d="M24.558 34h-17a4.505 4.505 0 0 1-4.5-4.5V10.294L11.292 
                2h13.266c2.481 0 4.5 2.019 4.5 4.5V12h-1V6.5c0-1.93-1.57-3.5-3.5-3.5h-12.85l-7.65 
                7.706V29.5c0 1.93 1.57 3.5 3.5 3.5h17c1.93 0 3.5-1.57 3.5-3.5V28h1v1.5c0 2.481-2.019 4.5-4.5 4.5z"
      />
      <path
        fill="#ef3124"
        d="M10 21.989V25H9v-8.99h3.427c.956 0 1.698.247 2.228.741s.793 1.421.793 
                2.228c0 .819-.264 1.791-.793 2.279s-1.271.732-2.228.732H10zm0-.985h2.427c.607 0 1.061-.188 
                1.359-.564.3-.376.449-.85.449-1.423s-.15-1.051-.452-1.434c-.301-.383-.754-.575-1.356-.575H10v3.996zM18 
                25v-8.99h3.051c1.104 0 1.99.362 2.659 1.084.667.722 1.002 1.656 1.002 2.8v1.229c0 1.149-.335 2.082-1.002 
                2.8-.668.718-1.555 1.077-2.659 1.077H18zm1-8.033v7.076h2c.828 0 
                1.481-.273 1.96-.82.478-.547.717-1.248.717-2.104v-1.24c0-.847-.239-1.544-.717-2.091-.479-.547-1.132-.821-1.96-.821h-2zM33 21h-4v4h-1v-8.99h6V17h-5v3.005h4V21z"
      />
    </svg>
  );
};

export const IconPdfGray = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      version="1"
      viewBox="0 0 36 36"
    >
      <path
        fill="#0B1F35"
        d="M24.558 34h-17a4.505 4.505 0 0 1-4.5-4.5V10.294L11.292
                2h13.266c2.481 0 4.5 2.019 4.5 4.5V12h-1V6.5c0-1.93-1.57-3.5-3.5-3.5h-12.85l-7.65
                7.706V29.5c0 1.93 1.57 3.5 3.5 3.5h17c1.93 0 3.5-1.57 3.5-3.5V28h1v1.5c0 2.481-2.019 4.5-4.5 4.5z"
      />
      <path
        fill="#0B1F35"
        d="M10 21.989V25H9v-8.99h3.427c.956 0 1.698.247 2.228.741s.793 1.421.793
                2.228c0 .819-.264 1.791-.793 2.279s-1.271.732-2.228.732H10zm0-.985h2.427c.607 0 1.061-.188
                1.359-.564.3-.376.449-.85.449-1.423s-.15-1.051-.452-1.434c-.301-.383-.754-.575-1.356-.575H10v3.996zM18
                25v-8.99h3.051c1.104 0 1.99.362 2.659 1.084.667.722 1.002 1.656 1.002 2.8v1.229c0 1.149-.335 2.082-1.002
                2.8-.668.718-1.555 1.077-2.659 1.077H18zm1-8.033v7.076h2c.828 0
                1.481-.273 1.96-.82.478-.547.717-1.248.717-2.104v-1.24c0-.847-.239-1.544-.717-2.091-.479-.547-1.132-.821-1.96-.821h-2zM33 21h-4v4h-1v-8.99h6V17h-5v3.005h4V21z"
      />
    </svg>
  );
};

export const IconSiteLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#EF3124"
          d="M0 0h150v110c0 2.761424-2.238576 5-5 5H5c-2.76142375 0-5-2.238576-5-5V0z"
        />
        <path
          fill="#FFF"
          d="M58 83.7426632v-7.0650728h34v7.0650728H58zM70.142948 53.965343h9.449314l-4.5041165-14.1739709h-.1767151L70.142948 53.965343zm11.0372765-16.7904158l10.025052 29.9044491h-7.4185031l-2.2520582-7.1088981H68.1117838l-2.4287734 7.1088981h-6.9763618l10.5202079-29.9044491C70.24721 34.2746778 71.4361497 32 75.2648607 32c3.8283576 0 4.9459044 2.2838669 5.9153638 5.1749272z"
        />
      </g>
    </svg>
  );
};

export const IconFilter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.5 13.5V10.5" stroke="#0B1F35" strokeLinecap="round" />
      <path d="M11 7.5V4.5" stroke="#0B1F35" strokeLinecap="round" />
      <path d="M11 19.5V16.5" stroke="#0B1F35" strokeLinecap="round" />
      <path
        d="M4 6H11M20 6H15M4 12H7M11 12H20M4 18H11M20 18H15"
        stroke="#0B1F35"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const IconBurger = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6H22M2 12H7M11 12H22M2 18H22"
        stroke="black"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const IconXls = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      version="1"
      viewBox="0 0 36 36"
    >
      <path
        fill="#ef3124"
        d="M24.558 34h-17a4.505 4.505 0 0 1-4.5-4.5V10.294L11.292 2h13.266c2.481 0 4.5 2.019 4.5 4.5V12h-1V6.5c0-1.93-1.57-3.5-3.5-3.5h-12.85l-7.65 7.706V29.5c0 1.93 1.57 3.5 3.5 3.5h17c1.93 0 3.5-1.57 3.5-3.5V28h1v1.5c0 2.481-2.019 4.5-4.5 4.5z"
      />
      <path
        fill="#ef3124"
        d="M19 24.001h5V25h-6v-8.983h1v7.984zm-10.755.964l2.794-4.498-2.726-4.408h1.092l2.087 3.83 2.243-3.879.971.035-2.735 4.422 2.786 4.485h-1.084l-2.154-3.89L9.215 25zM29.017 25c-.872 0-1.654-.238-2.323-.708-.649-.457-.965-1.046-.963-1.798h.978c.023.556.251.989.676 1.288.439.309.989.466 1.633.466.632 0 1.143-.136 1.517-.403.384-.274.578-.652.578-1.124 0-.434-.167-.803-.496-1.095-.324-.288-.876-.522-1.687-.715-.933-.221-1.668-.549-2.187-.976-.514-.423-.764-.939-.764-1.577 0-.663.278-1.224.825-1.67.554-.45 1.284-.678 2.17-.678.954 0 1.722.261 2.282.777.541.498.814 1.08.812 1.73h-.978c-.021-.505-.206-.923-.551-1.243-.366-.339-.894-.511-1.566-.511-.627 0-1.117.145-1.457.432-.346.29-.521.674-.521 1.14 0 .42.189.773.565 1.052.358.266.944.502 1.74.701.927.228 1.639.567 2.117 1.007.471.433.71.976.71 1.614 0 .693-.278 1.235-.85 1.656-.569.421-1.331.635-2.26.635z"
      />
    </svg>
  );
};

export const IconDoc = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      version="1"
      viewBox="0 0 36 36"
    >
      <path
        fill="#ef3124"
        d="M24.558 34h-17a4.505 4.505 0 0 1-4.5-4.5V10.294L11.292 2h13.266c2.481 0 4.5 2.019 4.5 4.5V12h-1V6.5c0-1.93-1.57-3.5-3.5-3.5h-12.85l-7.65 7.706V29.5c0 1.93 1.57 3.5 3.5 3.5h17c1.93 0 3.5-1.57 3.5-3.5V28h1v1.5c0 2.481-2.019 4.5-4.5 4.5z"
      />
      <path
        fill="#ef3124"
        d="M21.437 24.995c-.983 0-1.793-.352-2.407-1.047-.616-.698-.93-1.59-.93-2.651v-1.589c0-1.058.313-1.949.93-2.651.614-.698 1.424-1.053 2.407-1.053 1.02 0 1.861.354 2.499 1.051.64.698.965 1.591.965 2.652v1.589c0 1.061-.325 1.952-.965 2.649-.637.698-1.478 1.05-2.499 1.05zm-.005-8.173c-.767 0-1.388.274-1.846.814-.455.537-.686 1.231-.686 2.064v1.588c0 .841.23 1.539.686 2.076.458.54 1.079.814 1.846.814.812 0 1.468-.272 1.949-.81.478-.535.719-1.235.719-2.08V19.7c0-.834-.242-1.528-.722-2.066-.484-.538-1.138-.812-1.946-.812zm8.906 8.173c-.983 0-1.793-.352-2.407-1.047-.616-.698-.93-1.59-.93-2.651v-1.589c0-1.058.313-1.949.93-2.651.614-.698 1.424-1.053 2.407-1.053 1.021 0 1.861.354 2.499 1.051.46.503.758 1.111.887 1.809H32.9a2.77 2.77 0 0 0-.621-1.231c-.483-.539-1.138-.813-1.946-.813-.767 0-1.388.274-1.846.815-.455.536-.686 1.231-.686 2.063v1.588c0 .841.23 1.539.686 2.075.458.54 1.079.815 1.846.815.812 0 1.468-.272 1.949-.81.298-.334.506-.749.618-1.234h.824a3.59 3.59 0 0 1-.888 1.812c-.636.699-1.478 1.051-2.498 1.051zM9 25v-9h3.051c1.104 0 1.99.362 2.659 1.085.667.723 1.002 1.658 1.002 2.803v1.23c0 1.15-.335 2.084-1.002 2.803-.668.719-1.555 1.079-2.659 1.079H9zm1-8.042v7.084h2c.828 0 1.481-.274 1.96-.821.478-.548.717-1.249.717-2.106v-1.242c0-.848-.239-1.546-.717-2.093-.479-.547-1.132-.822-1.96-.822h-2z"
      />
    </svg>
  );
};

export const IconPng = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      version="1"
      viewBox="0 0 36 36"
    >
      <path
        fill="#ef3124"
        d="M24.558 34h-17a4.505 4.505 0 0 1-4.5-4.5V10.294L11.292 2h13.266c2.481 0 4.5 2.019 4.5 4.5V12h-1V6.5c0-1.93-1.57-3.5-3.5-3.5h-12.85l-7.65 7.706V29.5c0 1.93 1.57 3.5 3.5 3.5h17c1.93 0 3.5-1.57 3.5-3.5V28h1v1.5c0 2.481-2.019 4.5-4.5 4.5z"
      />
      <path
        fill="#ef3124"
        d="M23 25h-1.252l-4.457-6.947-.291.012V25h-1v-8.983h1.253l4.456 6.935.291-.012v-6.923h1V25zm6.789 0c-1.094 0-2.008-.336-2.719-.999-.71-.659-1.069-1.526-1.069-2.576v-1.843c0-1.05.337-1.918.999-2.579.66-.659 1.527-.995 2.575-.995 1.047 0 1.88.246 2.475.732.571.466.875 1.071.904 1.802h-1.029a1.93 1.93 0 0 0-.648-1.257c-.396-.352-.968-.53-1.701-.53-.747 0-1.358.269-1.817.802-.453.528-.683 1.204-.683 2.011v1.851c0 .815.257 1.497.765 2.029.509.532 1.164.801 1.949.801.544 0 .998-.07 1.35-.208.36-.143.612-.302.77-.489l.024-2.55h-2.062v-.999h3.129v3.783c-.195.251-.574.544-1.11.81-.536.27-1.244.404-2.102.404zM8 21.99V25H7v-8.99h3.427c.956 0 1.698.247 2.228.741s.793 1.421.793 2.228c0 .819-.264 1.791-.793 2.279s-1.271.732-2.228.732H8zm0-.985h2.427c.607 0 1.061-.188 1.359-.564.3-.376.449-.85.449-1.423s-.15-1.051-.452-1.434c-.301-.383-.754-.575-1.356-.575H8v3.996z"
      />
    </svg>
  );
};

export const IconJpg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <path d="M0 0h36v36H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.558 34h-17a4.505 4.505 0 01-4.5-4.5V10.294L10.292 2h13.266c2.481 0 4.5 2.019 4.5 4.5V12h-1V6.5c0-1.93-1.57-3.5-3.5-3.5h-12.85l-7.65 7.706V29.5c0 1.93 1.57 3.5 3.5 3.5h17c1.93 0 3.5-1.57 3.5-3.5V28h1v1.5c0 2.481-2.019 4.5-4.5 4.5z"
        fill="#ef3124"
      />
      <path
        d="M12.487 15.12h1.193v6.203c0 .838-.26 1.494-.779 1.967-.515.473-1.203.71-2.064.71-.894 0-1.59-.223-2.09-.668C8.25 22.887 8 22.263 8 21.462h1.187c0 .5.14.892.42 1.172.284.281.694.421 1.23.421.49 0 .886-.15 1.186-.45.305-.302.46-.723.464-1.264v-6.22zm5.736 5.33v3.43h-1.187v-8.76h3.319c.985 0 1.755.245 2.311.734.56.49.84 1.137.84 1.944 0 .85-.273 1.506-.821 1.967-.544.457-1.325.686-2.343.686h-2.12zm0-.944h2.132c.634 0 1.12-.144 1.458-.433.338-.293.507-.714.507-1.264 0-.52-.169-.938-.507-1.25-.337-.314-.801-.476-1.39-.488h-2.2v3.435zM32.5 22.73c-.305.426-.731.745-1.28.957-.543.209-1.178.313-1.903.313-.733 0-1.384-.166-1.953-.5a3.42 3.42 0 01-1.322-1.431c-.31-.618-.468-1.334-.476-2.148v-.764c0-1.32.315-2.342.945-3.068.635-.726 1.525-1.089 2.67-1.089.94 0 1.696.235 2.268.704.573.465.923 1.127 1.051 1.985h-1.187c-.222-1.159-.93-1.739-2.126-1.739-.795 0-1.398.273-1.81.819-.408.541-.614 1.327-.618 2.358v.716c0 .983.23 1.765.692 2.346.461.578 1.085.866 1.872.866a4.24 4.24 0 001.168-.144c.334-.096.61-.259.829-.487v-1.967h-2.083v-.939H32.5v3.213z"
        fill="#ef3124"
      />
    </svg>
  );
};

export const pdfValidateTypeForHome = (item, dispatch) => {
  if (
    isValidUrl(item.file) &&
    item.file !== 'null' &&
    item.file !== null &&
    item.downloadAvailable === true
  ) {
    return (
      <div className={'pdf-icon-t cursor'}>
        <IconPdf />
      </div>
    );
  }
  if (item.file !== 'null' && item.file !== null) {
    return (
      <div
        className={'pdf-icon-t not-allowed'}
        onClick={(event) => stop(event, dispatch)}
      >
        <IconPdfGray />
      </div>
    );
  }

  return <div />;
};

const stop = (event, dispatch) => {
  event.stopPropagation();
  // 2021-09-14  add modal message
  const customMessage = {
    en: 'Insufficient permissions to download the file',
    ru: 'Недостаточно прав для загрузки файла',
  };
  const error = 'Insufficient permissions';
  dispatch(loginFailure({ error }, customMessage));
};

// !!!! 2021-10-12 Внимание вызов новой функции dowExtended
// Если что то  не так, то до разбирателстсва мож
// но запускать проверненную функцию dow

export const pdfValidateTypeForModal = (item, dispatch) => {
  if (
    isValidUrl(item.file) &&
    item.file !== 'null' &&
    item.file !== null &&
    item.downloadAvailable === true
  ) {
    return (
      <div
        className={'pointer'}
        onClick={dowExtended(item, item.file, dispatch)}
      >
        <IconPdf />
        {FIELD_NAME['download'][getLanguageFromStore()]}
      </div>
    );
  }
  if (item.file !== 'null' && item.file !== null) {
    return (
      <div onClick={(event) => stop(event, dispatch)} className={'not-allowed'}>
        <IconPdfGray />
        {FIELD_NAME['download'][getLanguageFromStore()]}
      </div>
    );
  }

  return <div />;
};

function getIcon(url) {
  const ext = getExtension(url);
  if (isArray(ext)) {
    switch (ext[0]) {
      case 'pdf':
        return <IconPdf />;
      case 'xls':
      case 'xlsx':
        return <IconXls />;
      case 'doc':
      case 'docx':
        return <IconDoc />;
      default:
        return <></>;
    }
  } else {
    return <></>;
  }
}

// 2022-09-12 Дополнительно разбирает какой значок выводить
//

export const fileValidateTypeForModal = (item, dispatch) => {
  if (
    isValidUrl(item.file) &&
    item.file !== 'null' &&
    item.file !== null &&
    item.downloadAvailable === true
  ) {
    const fileIcon = getIcon(item.file);
    return (
      <div className={'pointer'} onClick={dowSimple(item.file, dispatch)}>
        {fileIcon}
        {FIELD_NAME['download'][getLanguageFromStore()]}
      </div>
    );
  }
  if (item.file !== 'null' && item.file !== null) {
    return (
      <div onClick={(event) => stop(event, dispatch)} className={'not-allowed'}>
        <IconPdfGray />
        {FIELD_NAME['download'][getLanguageFromStore()]}
      </div>
    );
  }

  return <div />;
};

/*  2021-10-12  расширенная функия загрузки
    контроль какой продукт загружается
    для последующей регистрации статистики
*/
const dowExtended = (item, file, dispatch) => (event) => {
  // Здесь надо еще 'подцепить' из redux хранилища  код текущего пользователя
  event.stopPropagation();
  dowloadFileExtended(item, file, dispatch);
};

const dowSimple = (file, dispatch) => (event) => {
  event.stopPropagation();
  dowloadFile(file, dispatch);
};
