import {all, fork} from 'redux-saga/effects';
import graphFormSaga from 'components/Graphs/Form/saga'
import graphTableSaga from 'components/Graphs/Table/saga'
import graphNewsSaga from 'components/Graphs/News/saga'
import fileInputSaga from 'components/InputFile/saga'

import authSaga from 'modules/Auth/redux/saga'
import errorSaga from 'components/Errors/saga'
import equityResearchSaga from 'modules/EquityResearch/redux/saga'
import economicsSaga from 'modules/Economics/redux/saga'
import fixedIncomeSaga from 'modules/FixedIncome/redux/saga'
import commoditiesSaga from 'modules/Commodities/redux/saga'
import companiesSaga from 'modules/Companies/redux/saga'
import futureEventsSaga from 'modules/FutureEvents/redux/saga'
import ourTeamSaga from 'modules/OurTeam/redux/saga'
import homeSaga from 'modules/Home/redux/saga'
import analystProfileSaga from 'modules/AnalystProfile/redux/saga'
import searchSaga from 'modules/Search/redux/saga'
import profileSaga from 'modules/Profile/redux/saga'
import authorSaga from 'modules/Author/redux/saga'
import productTypeSaga from 'modules/ProductType/redux/saga'
import companySaga from 'modules/Company/redux/saga'
import sectorSaga from 'modules/Sector/redux/saga'
import productSaga from 'modules/Product/redux/saga'
import сategoriesSaga from 'modules/Categories/redux/saga'
import eventSaga from 'modules/Event/redux/saga'
import countrySaga from 'modules/Country/redux/saga'
import eventTypeSaga from 'modules/EventType/redux/saga'
import authorTypeSaga from 'modules/AuthorType/redux/saga'
import sectorByIdSaga from 'modules/SectorById/redux/saga'
import categorySaga from 'modules/Category/redux/saga'
import periodTypeSaga from 'modules/PeriodType/redux/saga'

import lawFileSaga from 'modules/LawFile/redux/saga'
import statisticsSaga from 'modules/Statistics/redux/saga'

import footerInfoSaga from 'modules/FooterInfo/redux/saga'

function* RootSaga() {
  yield all([
    // Components
    fork(errorSaga),
    fork(graphFormSaga),
    fork(graphTableSaga),
    fork(graphNewsSaga),
    fork(fileInputSaga),

    // Modules
    fork(authSaga),

    fork(equityResearchSaga),
    fork(economicsSaga),
    fork(fixedIncomeSaga),
    fork(commoditiesSaga),
    fork(companiesSaga),
    fork(futureEventsSaga),
    fork(ourTeamSaga),
    fork(homeSaga),
    fork(analystProfileSaga),
    fork(searchSaga),
    fork(profileSaga),

    fork(authorSaga),
    fork(productTypeSaga),
    fork(companySaga),
    fork(sectorSaga),
    fork(productSaga),
    fork(сategoriesSaga),
    fork(eventSaga),
    fork(countrySaga),
    fork(eventTypeSaga),
    fork(authorTypeSaga),
    fork(sectorByIdSaga),
    fork(categorySaga),
    fork(periodTypeSaga),
// 2021-08-25
    fork(lawFileSaga),
// 2021-10-12
    fork(statisticsSaga),

// 2022-10-03
    fork(footerInfoSaga)
  ]);
}

export default RootSaga;
