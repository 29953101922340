export const PRODUCT_NEWS = 'PRODUCT_NEWS'
export const MAX_IMPORTANT_REPORTS_FOR_ANALYST_PAGE = 'MAX_IMPORTANT_REPORTS_FOR_ANALYST_PAGE'

export const MAX_IMPORTANT_REPORTS_FOR_EQUITY_RESEARCH_PAGE = 'MAX_IMPORTANT_REPORTS_FOR_EQUITY_RESEARCH_PAGE'

export const MAX_IMPORTANT_REPORTS_FOR_HOME_PAGE = 'MAX_IMPORTANT_REPORTS_FOR_HOME_PAGE';

export const MAX_IMPORTANT_REPORTS_FOR_ECONOMICS_PAGE = 'MAX_IMPORTANT_REPORTS_FOR_ECONOMICS_PAGE';
export const MAX_FORESTCUSTS = 'MAX_FORESTCUSTS';

export const MAX_IMPORTANT_REPORTS_FOR_FIXED_INCOME_PAGE = 'MAX_IMPORTANT_REPORTS_FOR_FIXED_INCOME_PAGE';

export const MAX_IMPORTANT_REPORTS_FOR_COMMODITIES_PAGE = 'MAX_IMPORTANT_REPORTS_FOR_COMMODITIES_PAGE'

export const MAX_IMPORTANT_REPORTS_FOR_COMPANIES_PAGE = 'MAX_IMPORTANT_REPORTS_FOR_COMPANIES_PAGE'

export const CALENDAR_EVENTS_PAGE = 'CALENDAR_EVENTS_PAGE';



export const EVENTS_NEWS = 'EVENTS_NEWS';
export const CHART_COMPANY = 'CHART_COMPANY';
export const CHART_SECTORS = 'CHART_SECTORS';
export const CHART_OFZ = 'CHART_OFZ';