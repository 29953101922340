// @ts-nocheck

import {useState, useCallback} from 'react';
import {useLocalStorage} from '@rehooks/local-storage';

const EN = "en";
const RU = "ru";

// const useToggleLanguage = (): [(string | null), (() => void)] => {
//     const [storedCollapse] = useLocalStorage<string>('language');
//     const [language, setCollapsed] = useState(() => storedCollapse);
//     const handleToggleLanguage = useCallback(() => {
//         const value = language === EN ? RU : EN;
//
//         console.log("useToggleLanguage ", language, value);
//         setCollapsed(value);
//         console.log("after set collapsed");
// //        writeStorage('language', value);
// // akayerov 2021-07-30 Сирока выше вызывает ошибку, строка ниже делает тоже самое из учебника не вызывая проблем
//         localStorage.setItem('language', value);
//         console.log("after write storage");
//     }, [language, 'language']);
//
//     return [language, handleToggleLanguage];
// };

const useToggleLanguage = (): any => {
    const [storedCollapse] = useLocalStorage<string>('language');
    const [language, setCollapsed] = useState(() => storedCollapse);
    const handleToggleLanguage = useCallback(() => {
        const value = language === EN ? RU : EN;

        console.log("useToggleLanguage ", language, value);
        setCollapsed(value);
        console.log("after set collapsed");
//        writeStorage('language', value);
// akayerov 2021-07-30 Сирока выше вызывает ошибку, строка ниже делает тоже самое из учебника не вызывая проблем
        localStorage.setItem('language', value);
        console.log("after write storage");
    }, [language, 'language']);

    return [language, handleToggleLanguage];
}

export default useToggleLanguage;



