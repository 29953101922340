import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {

    getAllCompany = () => request({
        url: 'company',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllEventTypes = () => request({
        url: 'catalog/event-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllCountry = () => request({
        url: 'catalog/country',
        method: 'GET',
        token: API_GLOBAL.token
    });
    getAllEventType = () => request({
        url: 'catalog/event-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllEvents = () => request({
        url: 'event',
        method: 'GET',
        token: API_GLOBAL.token
    });


    getAllProduct = () => request({
        url: 'product?page=0&size=3',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllAuthor = () => request({
        url: 'author',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllAuthorTypes = () => request({
        url: 'catalog/author-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllCategories = () => request({
        url: 'catalog/category',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllSectors = () => request({
        url: 'catalog/sector',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getAllInterfaceSettings = () => request({
        url: 'catalog/sector',
        method: 'GET',
        token: API_GLOBAL.token
    });

    createNewInterfaceSettings = ({data}: any) => request({
        url: 'catalog/sector',
        data,
        method: 'POST',
        token: API_GLOBAL.token
    });

    editInterfaceSettings = ({data}: any) => request({
        url: 'catalog/sector',
        data,
        method: 'PUT',
        token: API_GLOBAL.token
    });

    getByIdInterfaceSettings = ({id}: any) => request({
        url: `catalog/sector/${id}`,
        method: 'GET',
        token: API_GLOBAL.token
    });

    getProductType = () => request({
        url: 'catalog/product-type',
        method: 'GET',
        token: API_GLOBAL.token
    });

    getProfileClient = ({ id }: any) => request({
        url: `client/profile/client/${id}`,
        method: 'GET',
        token: API_GLOBAL.token
    })


    editProfileClient = ({ data }: any) => request({
        url: `client/profile`,
        method: 'PUT',
        data,
        token: API_GLOBAL.token
    })
//---  2021-08-05 доп функции получение клиента
    getCurrentClient = (clientId: any) => request({
        url: 'client/' + clientId ,
        method: 'GET',
        token: API_GLOBAL.token
    });
//---  2021-08-06
    storeCurrentClient = ({data}: any) => request({
        url: 'client',
        data,
        method: 'PUT',
        token: API_GLOBAL.token
    });

    storeCurrentClientName = ({data}: any) => request({
        url: 'client/name',
        data,
        method: 'PUT',
        token: API_GLOBAL.token
    });

    //---  2021-08-31
    changePasswordClient = (clientId: any, password: any) =>
        request({
        url: 'user/change-user-password/id/' + clientId + '?password=' + password,
        method: 'GET',
        token: API_GLOBAL.token
    });
    // 2023-01-09 create new client без создания записи в Keycloak
    createNewClientsWithoutKeyCloak = ({ data, username }: any) => request({
        url: 'client/create-client/' + username ,
        data,
        method: 'POST',
        token: API_GLOBAL.token
    });

}

const API_INTERFACESETTINGS = new ClassAPI();

export default API_INTERFACESETTINGS;
