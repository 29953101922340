import React from 'react';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {createStructuredSelector} from 'reselect';
import {makeSelectStaticCurrentMarketPricesData} from "../Graphs/Forecast/selector";
import {connect} from "react-redux";
import {CURRENT_MARKET_PRICES} from "../../constants/currentMarketPrices";
import './HotNews.scss';

class HotNews extends React.Component {
    constructor(props) {
        super(props);
        this.isMobile = props.isMobile || false;
    }

    getStringForShow() {
        const { graphData } =  this.props
        let res = ''
        // console.log('getStringForShow data=', graphData)
        const staticInfo = (graphData) ? graphData.find( item => item.type == CURRENT_MARKET_PRICES) : null
        if( staticInfo )
            res = staticInfo.infoEn
        return res

    }
    render() {
        const StringForShow = this.getStringForShow()
        let pathname = this.props['location'].pathname
        return (
            <>
                {(pathname == '/economics' || pathname ==  '/fixed-income' || pathname ==  '/commodities' ||
                  pathname == '/equity' || pathname == '/companies'  ) &&
                    <div className={this.isMobile ? "hotnews__mobile" : ""} style={{ marginRight: "32px", width: this.isMobile ? "100%" : "1400px"}}>
                        <marquee direction="left" scrollamount={this.isMobile ? "5" : "10"}> {StringForShow}
                        </marquee>
                    </div>
                }
            </>
        );
    }

}

const mapStateToProps = createStructuredSelector({
    graphData: makeSelectStaticCurrentMarketPricesData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withRouter)(HotNews);



//export default compose(withRouter)(HotNews);
