// @ts-nocheck
import {call, takeLeading, takeLatest, all, put, select} from 'redux-saga/effects';
import {change} from 'redux-form';
import {setModalValue} from './actions';
import API from '../api';
import {GET_SEARCH_DATA_REQUEST, GET_SEARCH_DATA_REQUEST2, GET_SEARCH_DATA_SORT_REQUEST} from './constants';
import {tableDataSuccess} from '../../../components/Graphs/Table/actions';
import {errorMessageFailure} from 'modules/Error/redux/actions';
import {parseFilters} from "../../../utils/normalizeFilters";
import { EQUITYRESEARCH_FILTER, FUTUREEVENTS_FILTER, SEARCH_FILTER } from 'constants/forms';
import { EQUITYRESEARCH_TABLE, FUTUREEVENTS_TABLE, SEARCH_TABLE } from '../../../constants/tables';
import qs from "querystring";
import {checkTouch} from "../../../utils/formatters";
import API_CATEGORY from '../../Category/api';
function* getDataSearch({payload: {value}}: any) {
    try {
        console.log('getDataSearch value=', value)
        const response = yield call(API.getSearchRequest, value);
        console.log('getDataSearch response = ', response );
        yield put(
            tableDataSuccess({
                type: '',
                payload: {response: response?.products},
                meta: {table: 'SEARCH_TABLE'},
            }),
        );
        // yield put(change('search', 'SEARCH_TABLE', response?.products));
        yield put(setModalValue({payload: response}));
    } catch (error) {
        console.log(error);
    }
}

function* getDataSearch2({payload: {query, productTypeId}}: any) {
    try {
        console.log('getDataSearch value=', query, productTypeId)
        const response = yield call(API.getSearchRequest2, {query, productTypeId});
        console.log('getDataSearch response = ', response );
        yield put(
            tableDataSuccess({
                type: '',
                payload: {response: response?.products},
                meta: {table: 'SEARCH_TABLE'},
            }),
        );
        // yield put(change('search', 'SEARCH_TABLE', response?.products));
        yield put(setModalValue({payload: response}));
    } catch (error) {
        console.log(error);
    }
}



function* getDataSearchSortRequest({payload, meta: {table, callback}}: any) {
    debugger
    if (table === SEARCH_TABLE) {
        const state = yield select();
        const {query} = qs.parse(state?.router?.location.search.replace('?', ''));
        const graphTableDate = state.graphTable &&
          state.graphTable.get(SEARCH_TABLE) &&
          state.graphTable.get(SEARCH_TABLE)
        const tableSort = graphTableDate?.get('filterMeta');
        const page = graphTableDate?.get('page');
        const filterForm = state.form && state.form[SEARCH_FILTER] && state.form[SEARCH_FILTER].values;
        const filterActive = filterForm ? filterForm : [];
        let graphId = parseFilters(filterActive, tableSort);
        graphId.page = page;
        const response = yield call(API.getSearchWithFilterRequest, graphId, query);
        try {
            yield put(
              tableDataSuccess({
                  type: '',
                  payload: {response: response?.products},
                  meta: {table: 'SEARCH_TABLE'},
              }),
            );
        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}))
        }
    }

}

function* onChangeFilter({meta, payload}: any) {
    if (meta.form === SEARCH_FILTER && checkTouch(payload,meta)) {
        try {
            const state = yield select();
            const {query} = qs.parse(state?.router?.location.search.replace('?', ''));
            const filterForm = state?.form && state?.form[meta.form] &&
                state?.form[meta?.form]?.values;
            let graphId = parseFilters(filterForm, {});
            const response = yield call(API.getSearchWithFilterRequest, graphId, query);
            yield put(
                tableDataSuccess({
                    type: '',
                    payload: {response: response?.products},
                    meta: {table: 'SEARCH_TABLE'},
                }),
            );
            yield put(setModalValue({payload: response}));
        } catch (error) {
            yield put(errorMessageFailure({payload: {error}}))
        }
    }

}

function* Saga() {
    yield all([
        takeLeading(GET_SEARCH_DATA_REQUEST, getDataSearch),
        takeLeading(GET_SEARCH_DATA_REQUEST2, getDataSearch2),
        takeLeading(GET_SEARCH_DATA_SORT_REQUEST, getDataSearchSortRequest),
        takeLeading(`@@redux-form/CHANGE`, onChangeFilter),
    ]);
}

export default Saga;
