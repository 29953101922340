export const GET_ALL_EQUITYRESEARCH_REQUEST = 'GET_ALL_EQUITYRESEARCH_REQUEST'
export const GET_ALL_EQUITYRESEARCH_SUCCESS = 'GET_ALL_EQUITYRESEARCH_SUCCESS'
export const GET_ALL_EQUITYRESEARCH_FAILURE = 'GET_ALL_EQUITYRESEARCH_FAILURE'

export const CREATE_NEW_EQUITYRESEARCH_REQUEST = 'CREATE_NEW_EQUITYRESEARCH_REQUEST'

export const EDIT_EQUITYRESEARCH_REQUEST = 'EDIT_EQUITYRESEARCH_REQUEST'


export const GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_COMPANIES_REQUEST = 'GET_PRODUCT_BY_LANGUAGE_AND_CATEGORY_COMPANIES_REQUEST';
export const GET_PRODUCT_BY_LANGUAGE_COMPANIES_REQUEST = 'GET_PRODUCT_BY_LANGUAGE_COMPANIES_REQUEST'
export const GET_COMPANIES_SORT_REPORT_REQUEST = 'GET_COMPANIES_SORT_REPORT_REQUEST'

export const GET_COMPANIES_SORT_REQUEST = 'GET_COMPANIES_SORT_REQUEST'
export const GET_STOCK_RECOMMENDATION_REQUEST = 'GET_STOCK_RECOMMENDATION_REQUEST'

