import React from 'react';

import IconClose from 'arui-feather/icon/ui/close';
import IconButton from 'arui-feather/icon-button';

import './Modal.scss';

function setBodyClass({ visible }) {
    document.body.classList[visible ? 'add' : 'remove']('modal-visible');
}

class Modal extends React.Component {

    componentDidMount() {
        setBodyClass({ visible: this.props.isShowing });
    }

    componentWillReceiveProps(nextProps) {
        setBodyClass({ visible: nextProps.isShowing });
    }

    componentWillUnmount() {
        setBodyClass({ visible: false });
    }

    render() {
        const { isShowing, onClose } = this.props;
        return (
            <div className="modal-wrapper"
                onClick={onClose}
                style={{ visibility: isShowing ? 'visible' : 'hidden', opacity: isShowing ? 1 : 0 }}>
                <div className="modal"
                    style={{ visibility: isShowing ? 'visible' : 'hidden', opacity: isShowing ? 1 : 0 }}
                >
                    <div className="modal__container">
                        <div className="modal__content">
                            {this.props.children}
                        </div>
                        <div className="modal__close">
                            <IconButton size="s">
                                <IconClose size="s" />
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}

export default Modal;