import { fromJS } from 'immutable';
import { SET_VALUE_SET } from './constants';

const initialState = fromJS({});

const valueSetReducer = (
  state = initialState,
  { type, payload, meta }: any,
) => {
  switch (type) {
    case SET_VALUE_SET: {
      const { response: data } = payload;
      const { form } = meta;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state.set(form, data);
    }

    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default valueSetReducer;
