import { LOGIN_INFO } from 'constants/languagesTable';
import { getLanguageFromStore } from 'utils/formatters';

export default [
  {
    type: 'text',
    id: 'username',
    label: LOGIN_INFO['Input']['login'][getLanguageFromStore()],
    placeholder:
      LOGIN_INFO['Input']['placeholderLogin'][getLanguageFromStore()],
    view: 'filled',
    size: 'm',
    width: 'available',
    bigLabel: false,
  },
  {
    type: 'password',
    id: 'password',
    label: LOGIN_INFO['Input']['password'][getLanguageFromStore()],
    placeholder:
      LOGIN_INFO['Input']['placeholderPassword'][getLanguageFromStore()],
    view: 'filled',
    size: 'm',
    width: 'available',
    bigLabel: false,
  },
  {
    type: 'checkbox',
    id: 'active',
    text: LOGIN_INFO['remember'][getLanguageFromStore()],
    bigLabel: false,
  },
];
