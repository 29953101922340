// @ts-nocheck
import {all, call, put, select, takeLeading} from 'redux-saga/effects';
import API from '../api';
import {GET_ALL_SECTORS_REQUEST,} from './constants';
import {errorMessageFailure} from "../../Error/redux/actions";


// 2022-12-21 Попытка уменьшить число запросов, берем из хранилища, если страны там уже есть
function* getAllSectors({meta: {form, table, callback}}: any) {
    try {
        const state = yield select();
        const productType = (state.valueSets && state.valueSets.get('productType')) ? state.valueSets.get('productType') : []
        if(  productType.length > 0) {
            console.log('Sectors was to get from redux store, no request');
            return;
        }
        const response = yield call(API.getAllSectors);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_ALL_SECTORS_REQUEST, getAllSectors),
    ]);
}

export default Saga;
