interface lan {
  [key: string]: string;
}

interface fieldsName {
  [key: string]: lan;
}

export const FIELD_NAME: fieldsName = {
  sectorCompany: {
    ru: 'Сектор/Компания',
    en: 'Sector/Company',
  },
  date: {
    ru: 'Дата',
    en: 'Date',
  },
  productType: {
    ru: 'Тип продукта',
    en: 'ForestCust Type',
  },
  author: {
    ru: 'Аналитики',
    en: 'Analysts',
  },
  actual: {
    ru: 'Актуальные',
    en: 'Recent reports',
  },
  uncheckall: {
    ru: 'Выключить все',
    en: 'Uncheck all',
  },
  commoditiesOne: {
    ru: 'Сырье',
    en: 'Сommodities',
  },
  commoditiesOneCustom: {
    ru: 'Сырье',
    en: 'Commodity',
  },
  resetFilters: {
    ru: 'Очистить значения',
    en: 'Reset values',
  },
  hideFilters: {
    ru: 'Спрятать фильтр',
    en: 'Hide filters',
  },
  showFilters: {
    ru: 'Показать фильтр',
    en: 'Show filters',
  },
  searchBar: {
    ru: 'Поиск...',
    en: 'Search...',
  },
  sectorOne: {
    ru: 'Сектор',
    en: 'Sector',
  },
  eventType: {
    ru: 'Тип события',
    en: 'Event Type',
  },
  companyOne: {
    ru: 'Компания',
    en: 'Company',
  },
  periodTypes: {
    ru: 'Тип времени',
    en: 'Period Types',
  },
  periodTypesCustom: {
    ru: 'Тип времени',
    en: 'Period',
  },
  countryOne: {
    ru: 'Страна',
    en: 'Сountry',
  },
  download: {
    ru: 'Скачать',
    en: 'Download',
  },
  close: {
    ru: 'Закрыть',
    en: 'Close',
  },
};

export const CHART_NAME: any = {
  companyChart: {
    title: {
      en: 'Leaders & Laggards 1D*',
      ru: 'Лидеры роста и падения*',
    },
    legend: {
      en: '*All orices adjusted to US$',
      ru: '*За предыдущий день. Все цены пересчитаны в доллары.',
    },
  },
  sectorChart: {
    title: {
      en: 'Sector 1D perfomance*',
      ru: 'Динамика секторов*',
    },
    legend: {
      en: '*Most liquid MOEX blue chips',
      ru: '*Самые ликвидные акции ММВБ, за предыдущий день.',
    },
  },
};

export const CHART_COMPANY: fieldsName = {
  year: {
    ru: 'год',
    en: 'year',
  },
  dividendPerShare: {
    ru: 'Дивиденд на акцию',
    en: 'Dividend per share',
  },
};

export const ABOUT_COMPANY: fieldsName = {
  about: {
    ru: 'О компании',
    en: 'About',
  },
  reports: {
    ru: 'Отчеты',
    en: 'Reports',
  },
  files: {
    ru: 'Файлы',
    en: 'Files',
  },
  ticker: {
    ru: 'Акция',
    en: 'Ticker',
  },
  targetPrice: {
    ru: 'Целевая цена',
    en: 'Target Price',
  },
  currency: {
    ru: 'Валюта',
    en: 'Currency',
  },
  recommendation: {
    ru: 'Рек.',
    en: 'Rec.',
  },
  dividendsByYears: {
    ru: ' Прогноз ключевых показателей',
    en: 'Model summary',
  },
  financialIndicators: {
    ru: 'Финансовые индикаторы',
    en: 'Financial Indicators',
  },

  fiveYears: {
    ru: '5 лет',
    en: '5 years',
  },

  AllTime: {
    ru: 'Все время',
    en: 'All time',
  },
  ordinaryStock: {
    ru: 'Обыкновенная акция',
    en: 'Ordinary stock',
  },
  stock12: {
    ru: '(*) - Дивидент за акцию за последние 12 месяцев с учетом последних рекомендаций по дивидендам',
    en: '(*) - Dividend per stock for the last 12 months, taking into account the latest recommendations on dividends',
  },
  stockYear: {
    ru: 'Дивидент на акцию по годам',
    en: 'Dividend per stock by year',
  },
  companyManagement: {
    ru: 'Руководство компании',
    en: 'Company management',
  },
  analysts: {
    ru: 'Аналитики',
    en: 'Analysts',
  },
  contacts: {
    ru: 'Контакты',
    en: 'Contacts',
  },
  more: {
    ru: 'Больше',
    en: 'More',
  },
  less: {
    ru: 'Меньше',
    en: 'Less',
  },
};

export const NOT_FIND: any = {
  ru: 'По вашему запросу ничего не найдено',
  en: 'Nothing found',
};

export const EVENTS: any = {
  ru: 'Все мероприятия',
  en: 'All Events',
};

export const GRAPH_OFZ: any = {
  titleX: {
    ru: 'Дюрация, лет',
    en: 'Duration, years',
  },
  titleY: {
    ru: 'Доходность к опциону/погашению, %',
    en: 'Yield to option/maturity, %',
  },
};
