import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {

    getAllCountry = () => request({
        url: 'catalog/country',
        method: 'GET',
        token: API_GLOBAL.token
    });

}

const API_COUNTRY = new ClassAPI();

export default API_COUNTRY;
