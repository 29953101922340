// @TODO: remove ts-nocheck!!
// @ts-nocheck

import { fromJS } from 'immutable';
import {
  GET_ALL_EQUITYRESEARCH_FAILURE,
  GET_ALL_EQUITYRESEARCH_SUCCESS,
} from './constants';

const initialState = fromJS({
  error: null,
  token: null,
  logoutData: null,
});

const equityResearchReducer = (
  state = initialState,
  { type, payload }: any,
) => {
  switch (type) {
    case GET_ALL_EQUITYRESEARCH_FAILURE: {
      return state.set('token', payload).set('error', null);
    }
    case GET_ALL_EQUITYRESEARCH_SUCCESS: {
      return state.set('error', payload);
    }
    default:
      return state;
  }
};

export default equityResearchReducer;
