import { Button } from 'arui-feather/button';
import { useResolution } from 'hooks/useResolution';
import React from 'react';
import { getLanguageFromStore } from 'utils/formatters';

export const ShowMoreAction = ({ clickPag }: { clickPag: any }) => {
  const { isMobile } = useResolution();
  return isMobile ? (
    <Button size="l" className="button_default" onClick={clickPag}>
      {getLanguageFromStore() === 'ru' ? 'Показать еще' : 'Show more'}
    </Button>
  ) : (
    <div
      onClick={clickPag}
      className="link events__bottom-link btn-home-20 link_size_m link_theme_alfa-on-white"
    >
      <span className="link__text-container">
        <span className="link__text">
          {getLanguageFromStore() === 'ru' ? 'Показать еще' : 'Show more'}
        </span>
      </span>
      <span className="link__icon">
        <span className="icon icon_size_m icon_name_arrow-right icon_theme_alfa-on-white" />
      </span>
    </div>
  );
};
