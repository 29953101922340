// @ts-nocheck
import React from 'react'
import { connect } from 'react-redux'
import Spin from 'arui-feather/spin'
import { dowloadFile } from 'utils/formatters'
import queryString from 'query-string'

class DownloadFile extends React.Component<any, any>{
  componentDidMount() {
      const { dispatch } = this.props
      const parsed = queryString.parse(window.location.search)
      parsed?.url && dowloadFile(parsed?.url, dispatch, window.location.origin)
  }

  render() {
    return (
      <Spin
        size={"xl"}
        visible={ true }
      />
    );
  }
}

export default connect()(DownloadFile)