// @ts-nocheck
import React, { FC } from 'react';
import Message from 'components/Message';
import MetaData from 'app/metaData';
import { ILayoutProps } from 'types';
import Header from 'components/HeaderLogin';

const Public: FC<ILayoutProps> = ({ children }) => {
  return (
    <div className="admin-main">
      <MetaData />
      <Header />
      <div className="container">{children}</div>
      <Message id="error" variant="error" vertical="top" horizontal="center" />
    </div>
  );
};

export default Public;
