import React from 'react';
import Button from 'arui-feather/button';
import GridCol from 'arui-feather/grid-col';
import GridRow from 'arui-feather/grid-row';
import { IconFilter } from 'components/Icon/Icons';
import { useResolution } from 'hooks/useResolution';
import { FIELD_NAME } from 'constants/languagesFields';
import { getLanguageFromStore } from 'utils/formatters';

export const Actions = ({
  handleClick,
  handleHideClick,
  isFilterHidden = false,
}: {
  handleClick: any;
  handleHideClick: any;
  isFilterHidden: boolean;
}) => {
  const { isMobile } = useResolution();
  return (
    <GridRow>
      {!isFilterHidden && (
        <GridCol
          className="margin-bottom-15"
          width={{ mobile: 12, tablet: 12, desktop: 12 }}
        >
          <Button
            className={
              'button button_size_s button_width_available button_theme_alfa-on-white button_brand'
            }
            onClick={handleClick}
          >
            {FIELD_NAME['resetFilters'][getLanguageFromStore()]}
          </Button>
        </GridCol>
      )}
      {isMobile && (
        <GridCol width={{ mobile: 12, tablet: 12, desktop: 12 }}>
          <Button
            className={
              'button button_size_s button_width_available button_theme_alfa-on-white button_icon'
            }
            onClick={handleHideClick}
          >
            <IconFilter />
            {
              FIELD_NAME[isFilterHidden ? 'showFilters' : 'hideFilters'][
                getLanguageFromStore()
              ]
            }
          </Button>
        </GridCol>
      )}
    </GridRow>
  );
};
