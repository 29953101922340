import { store } from '../index';

export function getCurrentUser() {
  const state = store.getState();
  // console.log('getCurrentUser state=', state);
  if( state )
    return state.user;
  else
    return null;
}
