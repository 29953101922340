// @ts-nocheck
import React from 'react';
import GraphChart from 'components/Graphs/Chart';
import { sortBy } from 'lodash';
import { ICategoryTable } from 'components/Flows/Table/interface';
import { CHART_COMPANY } from 'constants/news';
import './Chart.scss';
import { getAllCompanyWidgetsChartsRequest } from '../../redux/actions';
import { getLanguageFromStore } from 'utils/formatters';
import { CHART_NAME } from 'constants/languagesFields';

class CompanyChart extends React.Component<ICategoryTable> {
  normalizeTableData = (valuesModal: any): any => {
    const companyChart = valuesModal && valuesModal.companyChart;
    if (companyChart && companyChart.length) {
      companyChart.push({
        tickerName: '',
        tikerVal: -0.00001,
      });

      const arr = sortBy(companyChart, 'tikerVal').reverse();

      return {
        title: CHART_NAME['companyChart']['title'][getLanguageFromStore()],
        legend: CHART_NAME['companyChart']['legend'][getLanguageFromStore()],
        content: arr.map((val: any) => {
          const position = val.tikerVal >= 0 ? 'plus' : 'minus';
          return {
            mr: val.tickerName,
            [position]: val.tikerVal,
            [`name${position}`]: val.tickerName,
          };
        }),
      };
    }
  };

  render() {
    const actions = {
      companyChart: getAllCompanyWidgetsChartsRequest,
    };
    return (
      <GraphChart
        customClass={'chart-padding-bottom'}
        actions={actions}
        type={'products'}
        table={CHART_COMPANY}
        normalizeTableData={this.normalizeTableData}
      />
    );
  }
}

export default CompanyChart;
