import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api';
import {getLanguageFromStore} from "../../../utils/formatters";
import {LANGUAGE_EN, LANGUAGE_RU} from "../../../constants/languages";
import queryString from "query-string";

class ClassAPI {
    getSearchRequest = (data: any) => {
        console.log('getSearchRequest data=', data)
        return request({
            url: `translation/search2?query=${data}`,
            method: 'GET',
            token: API_GLOBAL.token,
        });
    };

    getSearchRequest2 = (data: any) => {
        console.log('getSearchRequest data=', data)
        let url = `translation/search2?query=${data.query}`
        if( data.productTypeId ) {
            url = url + '&productTypeId=' + data.productTypeId
        }
        console.log('getSearchRequest url=', url)
        return request({
            url: url,
            method: 'GET',
            token: API_GLOBAL.token,
        });
    };


    getSearchWithFilterRequest = (data: any, query:any) => {
        const {} = this;
        const req = {
            //interfaceSettingName: table,
            // languageId: getLanguageFromStore() === 'en' ? LANGUAGE_EN : LANGUAGE_RU,
            // page: 1,
        };
        const sent = Object.assign(req, data);
        const q = queryString.stringify(sent);
        return request({
            url: `translation/search2?query=${query}&${q}`,
            method: 'GET',
            token: API_GLOBAL.token,
        });
    }

}

const API_INTERFACESETTINGS = new ClassAPI();

export default API_INTERFACESETTINGS;
