// @ts-nocheck
import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import CheckBox from 'arui-feather/checkbox';
import CheckboxGroup from 'arui-feather/checkbox-group';
import {language} from 'utils/formatters';
import './style.scss';
import {setValueSet} from '../ValueSet/actions';
import {createStructuredSelector} from 'reselect';
import {selectValueSet, selectAllValueSets} from '../ValueSet/selector';

import IconClose from 'arui-feather/icon/ui/close';
import IconButton from 'arui-feather/icon-button';


function setBodyClass({visible}: any) {
    document.body.classList[visible ? 'add' : 'remove']('modal-visible');
}

const CheckBoxComponent = (props: any) => {
    const onChange = (value: any) => {
        const {input} = props;
        input.onChange(value);
    };

    return (
        <CheckBox
            onChange={onChange}
            text={props.label}
            checked={props.input.value}
        />
    )
};


class ModalThree extends React.Component<any, any> {

    componentDidMount() {
        const {id, actions, dispatch}: any = this.props;
        // if (actions) {
        //     Object.keys(actions).forEach((key) => {
        //         dispatch(actions[key]({meta: {form: key, callback: setValueSet}}));
        //     });
        // }
        setBodyClass({visible: this.props.isShowing});
    }


    componentWillReceiveProps(nextProps: { isShowing: any; }) {
        setBodyClass({visible: nextProps.isShowing});
    }

    componentWillUnmount() {
        setBodyClass({visible: false});
    }


    render() {
        const {isShowing, onClose, modalValues, children}: any = this.props;
        return (
            <div className="modal-wrapper"
                 onClick={onClose}
                 style={{visibility: isShowing ? 'visible' : 'hidden', opacity: isShowing ? 1 : 0}}>
                <div className="modal"
                     style={{visibility: isShowing ? 'visible' : 'hidden', opacity: isShowing ? 1 : 0}}
                >
                    <div className="modal__container">
                        <div className="modal__content">
                            {this.props.children}
                        </div>
                        <div className="modal__close">
                            <IconButton size="s">
                                <IconClose size="s"/>
                            </IconButton>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = createStructuredSelector({
    modalValues: selectValueSet(),
    valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(ModalThree);
