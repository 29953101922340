// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import EquityResearchFilter from './filter';
import EquityResearchTable from './table';
import EquityResearchEventsNews from './eventNews';
import ColTree from 'components/ColTree';
import GridCol from 'arui-feather/grid-col';
import { FUTUREEVENTS_TABLE } from 'constants/tables';
import { getLanguageFromStore } from 'utils/formatters';
import { Heading } from 'arui-feather/heading';
import { TABLE_NAME } from 'constants/languagesTable';
import { useResolution } from 'hooks/useResolution';

const EquityResearch = () => {
  const { isMobile } = useResolution();
  return (
    <div className="custom-page-wrapper future-events-page">
      {isMobile && <EquityResearchEventsNews />}
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="custom-margin-bottom"
      >
        <GridCol>
          <Heading size="xl" className="custom-page-title">
            {TABLE_NAME[FUTUREEVENTS_TABLE][getLanguageFromStore()]}
          </Heading>
        </GridCol>
      </GridRow>
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="flex_no-wrap"
      >
        <ColTree className="custom-column-section custom-filter-section">
          <EquityResearchFilter />
        </ColTree>
        <EquityResearchTable showTitle={false} />
        <ColTree className="custom-column-section">
          {!isMobile && <EquityResearchEventsNews />}
        </ColTree>
      </GridRow>
    </div>
  );
};

export default EquityResearch;
