import Profile from './download';

export default [
  {
    type: 'private',
    exact: true,
    path: '/download',
    roles: ['admin'],
    component: Profile,
  },
];
