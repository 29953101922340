// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';
import ColTree from 'components/ColTree';
import BodyCompany from 'components/SearchResult/BodyCompany';
import Chart from './chart/Chart';
import CompaniesProductNews from 'modules/Companies/pages/companies/productNews';
import CompaniesEventsOneCompany from 'modules/Companies/pages/companies/eventNewsOneCompany/index';
import GridCol from 'arui-feather/grid-col';
import cn from 'bem-cn';
import {ABOUT_COMPANY} from '../../../../constants/languagesFields';
import {useResolution} from 'hooks/useResolution';
import {Heading} from 'arui-feather/heading';
import {FIELD_NAME} from 'constants/languagesFields';
import {getLanguageFromStore} from 'utils/formatters';

const about = cn('tab-content');

const About = ({ data, id }: { data: any; id: any }) => {
  const { isMobile } = useResolution();

  return (
    <GridRow gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}>
      <ColTree width={{ mobile: 12, tablet: 9, desktop: 9 }}>
        <BodyCompany data={data} />

        <GridRow justify="right">
          <GridCol align="top" className={'pl0 margin-bottom-chart'}>
            {data?.financialIndicators && (
              <div className={`${about('detail')} custom-detail`}>
                <h4>
                  {ABOUT_COMPANY['financialIndicators'][getLanguageFromStore()]}
                </h4>
                {data?.financialIndicators && (
                  <img src={data?.financialIndicators} />
                )}
              </div>
            )}
            {data && (
              <div className={about('detail')}>
                <div className={about('detail', 'company-charts')}>
                  {data && <Chart data={data} />}
                </div>
              </div>
            )}
          </GridCol>
        </GridRow>
      </ColTree>
      <ColTree width={{ mobile: 12, tablet: 3, desktop: 3 }}>
        <div className={'no-margin-product custom-paddings'}>
          {isMobile && (
            <Heading size="xl">
              {FIELD_NAME['actual'][getLanguageFromStore()]}
            </Heading>
          )}
          <CompaniesProductNews id={id} />
        </div>
        {!isMobile && <CompaniesEventsOneCompany id={id} />}
      </ColTree>
    </GridRow>
  );
};

export default About;
