// @ts-nocheck
/**
 * @TODO: Refactoring of all component!!
 */

import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import './style.scss';
import {setValueSet} from '../ValueSet/actions';
import {createStructuredSelector} from 'reselect';
import {selectAllValueSets, selectValueSet} from '../ValueSet/selector';
import UserCartItemManager from 'components/UserCartItemU/UserCartItemManager';
import UserCartItemAnalyst from 'components/UserCartItemU/UserCartItemAnalyst';
import Modal from 'components/Modal';
import ManagementProfile from 'components/ManagementProfile';
import {getLanguageFromStore} from 'utils/formatters';
import {normalizeTeam} from './normalize';
import {ABOUT_COMPANY} from 'constants/languagesFields';
import {GridCol} from 'arui-feather/grid-col';
import Collapsible from 'react-collapsible';
import {useResolution} from 'hooks/useResolution';
import {makeSelectFooterInfoData} from '../Graphs/Forecast/selector';

const lang = getLanguageFromStore();


const ShowSection = ({isMobile, isAllSectionsOpen, contact, title }: any) => {

  const [isOpen, setIsOpen] = useState(isMobile ? isAllSectionsOpen : true);

  useEffect(() => {
    if (isMobile) {
      setIsOpen(isAllSectionsOpen);
    }
  }, [isAllSectionsOpen]);

  if (isMobile && !title) {
    return null;
  }

  return (
    <div className="ourteam-section__contacts grid-col grid-col_gutter-desktop_12 grid-col_width_12">
      <Collapsible
        triggerDisabled={!isMobile}
        open={isOpen}
        trigger={
          <GridCol className="header-trigger" width={12}>
            {isMobile ? (
              <div onClick={() => setIsOpen(!isOpen)} className="ourteam-section__contacts-header__mobile">
                <div
                  className={'link user-info'}
                  style={{ paddingLeft: '12px', overflowWrap: 'break-word', color: isOpen ? "#ef3124" : "#111" }}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <div
                  className={`collapse-action ${isOpen ? '' : 'collapse-action__black'} ${isOpen}`}
                />
              </div>
            ) : (
              <div/>
            )}
          </GridCol>
        }
      >
        <div
          className={'link user-info'}
          style={{
            paddingLeft: '12px',
            paddingTop: '12px',
            overflowWrap: 'break-word',
          }}
          dangerouslySetInnerHTML={{ __html: contact }}
        />
      </Collapsible>
    </div>
  );
};

const ContactsSection = ({ props }: any) => {
  const { isMobile } = useResolution();
  const [isAllSectionsOpen, setIsAllSectionsOpen] = useState(false);

  // const graphTable = useSelector((state) => state.graphTable);
  // const footerTable = graphTable.get(FOOTER_INFO_TABLE);
  const contactDataArray = props.graphData;

  const nameContactType = isMobile ? 'contact-mob' : 'contact-desk';
  let contactData = [];
  if (contactDataArray && contactDataArray.length > 0)
    contactData = contactDataArray
      .filter((item: any) => item.type == nameContactType)
      .sort((a: any, b: any) => a.order - b.order);

  return (
    <div className="ourteam-section grid-row">
      <div className="collapse-header">
        <h4>{ABOUT_COMPANY['contacts'][lang]}</h4>
        <button
          type="button"
          className={`collapse-action ${isAllSectionsOpen}`}
          onClick={() => {
            setIsAllSectionsOpen(!isAllSectionsOpen);
          }}
        >
          {isAllSectionsOpen
            ? lang === 'en'
              ? 'Hide all'
              : 'Спрятать все'
            : lang === 'en'
            ? 'View all'
            : 'Показать все'}
        </button>
      </div>
      {contactData &&
        contactData.map((item: any, index: any) => {
          const contact =
            getLanguageFromStore() === 'en' ? item.infoEn : item.infoRu;
          const title =
              getLanguageFromStore() === 'en' ? item.nameEn : item.nameRu;
          return (
            <ShowSection
              key={index}
              {...item}
              contact={contact}
              title={title}
              isMobile={isMobile}
              isAllSectionsOpen={isAllSectionsOpen}
            />
          );
        })}
    </div>
  );
};

class Сommand extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      isExpanded: false,
      modalData: null,
    };
    this.handleExpandedChange = this.handleExpandedChange.bind(this);
  }

  componentDidMount() {
    const { actions, dispatch }: any = this.props;
    if (actions) {
      Object.keys(actions).forEach((key) => {
        dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
      });
    }
  }

  handleExpandedChange(modalData: any) {
    this.setState({
      isExpanded: !this.state.isExpanded,
      modalData: modalData ? modalData : null,
    });
  }

  normalizeFormat() {
    const { normalize, valueSetsAll } = this.props;
    switch (normalize) {
      case 'author': {
        return normalizeTeam(
          valueSetsAll && valueSetsAll.author,
          valueSetsAll && valueSetsAll.authorTypes,
        );
      }
      default:
        return [];
    }
  }

  render() {
    const { isExpanded, modalData } = this.state;
    const data = this.normalizeFormat();

    return (
      <>
        {isExpanded && (
          <Modal isShowing={isExpanded} onClose={this.handleExpandedChange}>
            <ManagementProfile profile={modalData} />
          </Modal>
        )}

        <div className="ourteam-section">
          {/*<h4>{ABOUT_COMPANY['companyManagement'][lang]}</h4>*/}
          <h4></h4>
          <div className="ourteam-section__employee employee-managment">
            {data &&
              data.map((management, index) => {
                return (
                  <UserCartItemManager
                    key={index}
                    user={management}
                    handleExpandedChange={this.handleExpandedChange}
                  />
                );
              })}
          </div>
        </div>

        <div className="ourteam-section">
          <h4>{ABOUT_COMPANY['analysts'][lang]}</h4>
          <div className="ourteam-section__employee employee-analysts">
            {data &&
              data.map((analyst, index) => {
                return <UserCartItemAnalyst key={index} user={analyst} />;
              })}
          </div>
        </div>
        {/*<ContactsSection/>*/}
        <ContactsSection props={this.props} />
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  valueSetsAll: selectAllValueSets,
  // 2022-10-14 добавлено
  graphData: makeSelectFooterInfoData(),
  //  graphData: makeSelectStaticInfo
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(Сommand);
