import * as ACTION_TYPE from './constants';
import {IAction} from 'types/index'

export const authRequest = ({payload} : IAction<void>) => {
  return ({
    type: ACTION_TYPE.AUTH_REQUEST,
    payload,
  });
};

export const authSuccess = (token: string) => ({
  type: ACTION_TYPE.AUTH_SUCCESS,
  payload: token,
});

export const logoutRequest = (payload: any) => ({
  type: ACTION_TYPE.LOGOUT_REQUEST,
  payload,
});

export const logoutSuccess = () => ({
  type: ACTION_TYPE.LOGOUT_SUCCESS,
  payload: '',
});

export const logoutFailure = (message: any) => ({
  type: ACTION_TYPE.LOGOUT_FAILURE,
  payload: message,
});

export const loginFailure = (payload: any, customMessage: object) => {
  return ({
    type: ACTION_TYPE.LOGIN_FAILURE,
    payload,
    customMessage,
  })
};
