import {request} from 'api/restClient';
import API_GLOBAL from 'api/restClient/api'

class ClassAPI {

    getAllCategories = () => request({
        url: 'catalog/category',
        method: 'GET',
        token: API_GLOBAL.token
    });

}

const API_CATEGORIES = new ClassAPI();

export default API_CATEGORIES;
