import * as ACTION_TYPE from './constants';



export const getEquityResearchSortRequest = ({meta, payload,}: any) => ({
    type: ACTION_TYPE.GET_EQUITY_RESEARCH_SORT_REQUEST,
    meta,
    payload
});




