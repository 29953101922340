// @ts-nocheck
import React from 'react';
import GridRow from 'arui-feather/grid-row';

import FixedIncomeFilter from './filter';
import FixedIncomeTable from './table';
import FixedIncomeProductNews from './productNews';
import FixedIncomeEventsNews from './eventNews';
import ColTree from 'components/ColTree';
import GridCol from 'arui-feather/grid-col';
import { FIXEDINCOME_TABLE } from 'constants/tables';
import { getLanguageFromStore } from 'utils/formatters';
import { Heading } from 'arui-feather/heading';
import { TABLE_NAME } from 'constants/languagesTable';
import { useResolution } from 'hooks/useResolution';
import { FIELD_NAME, GRAPH_OFZ } from 'constants/languagesFields';
import ForecastFiles from '../../_Common/forecastFiles';
import ChartOFZ from '../../_Common/chartOFZ';

const page = ['Fixed Income', 'Облигации'];

const Economics = () => {
  const { isMobile } = useResolution();
  return (
    <div className="custom-page-wrapper fixed-income-page">
      {isMobile && <FixedIncomeEventsNews />}
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="custom-margin-bottom"
      >
        <GridCol>
          <Heading size="xl" className="custom-page-title">
            {TABLE_NAME[FIXEDINCOME_TABLE][getLanguageFromStore()]}
          </Heading>
        </GridCol>
      </GridRow>
      <GridRow
        gutter={{ mobile: 0, tablet: 16, desktop: { m: 12 } }}
        className="flex_no-wrap"
      >
        <ColTree className="custom-column-section custom-filter-section">
          {isMobile && <ForecastFiles category={page} />}
          <FixedIncomeFilter />
          {!isMobile && <ForecastFiles category={page} />}
        </ColTree>
        <FixedIncomeTable showTitle={false} />
        <ColTree className="custom-column-section">
          {isMobile && (
            <Heading size="l">
              {FIELD_NAME['actual'][getLanguageFromStore()]}
            </Heading>
          )}
          <ChartOFZ
            dataTag={'ofz1'}
            title={{ en: 'OFZ #1', ru: 'ОФЗ #1' }}
            titleX={GRAPH_OFZ['titleX'][getLanguageFromStore()]}
            titleY={GRAPH_OFZ['titleY'][getLanguageFromStore()]}
          />
          <FixedIncomeProductNews />
          {!isMobile && <FixedIncomeEventsNews />}
        </ColTree>
      </GridRow>
    </div>
  );
};

export default Economics;
