

export const parseFilters = (data: any, tableSort: any): any => {
    const validateDate = (date:any) => {
        let RGEX = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/g;
        return RGEX.test(date);
    };

    const currentTableFilter = tableSort ? tableSort : {};
    let final: any = [];
    let resObject = {};
    final.push(currentTableFilter);
//    console.log('parseFilters data=', data)

    Object.keys(data).map(function (key) {
        let val = data[key];
        // console.log('parseFilters key/val=', key, val)
        switch (key) {
            case 'commoditiesOne':
                val.parent_index && final.push(
                    {'commodityId': val.parent_index},
                );
                break;
            case 'countryOne':
                val.parent_index && val.parent_index.length && final.push(
                    {'countryId': val.parent_index && val.parent_index.join()},
                );
                break;
            case 'sectorOne':
                val.parent_index && final.push(
                    {'sectorId': val.parent_index},
                );
                break;
            case 'periodFeature':
                val.parent_index && final.push(
                  {'future': val.parent_index},
                );
                break;
            case 'sectorCompany':
                val.parent_index &&
                val.child_index ? final.push(
                    {'sectorId': val.parent_index},
                    {'companyId': val.child_index}
                ) : final.push(
                    {'sectorId': val.parent_index},
                );
                break;

            case 'eventType':
                val.parent_index && final.push(
                    {'eventTypeId': val.parent_index},
                );
                break;

            case 'periodTypes':
                val.parent_index && final.push(
                    {'periodTypeId': val.parent_index},
                );
                break;

            case 'companyOne':
                val.parent_index && val.parent_index.length && final.push(
                    {'companyId': val.parent_index && val.parent_index.join()},
                );
                break;
            case 'productType':
                val.parent_index && val.parent_index.length && final.push(
                    {'productTypeId': val.parent_index && val.parent_index.join()},
                );
                break;
            case 'productTypeByCategory':
                val.parent_index && val.parent_index.length && final.push(
                    {'productTypeId': val.parent_index && val.parent_index.join()},
                );
                break;
            case 'authorTypes':
                val.parent_index && final.push(
                    {'authorTypeId': val.parent_index},
                );
                break;

            case 'author':
                val.parent_index && val.parent_index.length && final.push(
                    {'authorId': val.parent_index && val.parent_index.join()},
                );
                break;
            case 'authorType':
                val.parent_index && val.parent_index.length && final.push(
                    {'authorId': val.parent_index && val.parent_index.join()},
                );
                break;

            case 'dateFrom':
                val && validateDate(val) && final.push(
                    {'dateFrom': val},
                );
                break;

            case 'dateTo':
                val && validateDate(val) && final.push(
                    {'dateTo': val},
                );
                break;
// 2021-09-16
            case 'page':
                val && final.push(
                    {'page': val},
                );
                break;
            default:
                return '';
        }
    });

    final.forEach((item: any) => {
        Object.assign(resObject, item)
    });


    return resObject

}