// @ts-nocheck
import {call, put, takeLeading, takeLatest, all, select} from 'redux-saga/effects';
import API from '../api';
import {
    GET_ALL_AUTHORTYPES_REQUEST,
} from './constants';
import {errorMessageFailure} from "../../Error/redux/actions";


function* getAllAuthorTypes({meta: {form, table, callback}}: any) {
    try {
        const response = yield call(API.getAllAuthorTypes);
        if (callback) {
            if (table) yield put(callback({payload: {response}, meta: {table}}))
            if (form) yield put(callback({payload: {response}, meta: {form}}))
        }
    } catch (error) {
        yield put(errorMessageFailure({ payload: { error } }))
    }
}


function* Saga() {
    yield all([
        takeLeading(GET_ALL_AUTHORTYPES_REQUEST, getAllAuthorTypes),
    ]);
}

export default Saga;
