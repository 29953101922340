import React from 'react';
import cn from 'bem-cn';
import SearchInput from 'components/SearchInput';
import './SearchResult.scss';
import CompanyResultSearch from './CompanyResultSearch';
import { getAllAuthorRequest } from '../../modules/Author/redux/actions';
import { getAllSectorsRequest } from '../../modules/Sector/redux/actions';
import {
  getAllCompanyDupRequest,
  getAllCurrencyRequest,
  getAllEquityTickersRequest,
  getAllStockRecommendationRequest,
  getAllStockRequest,
  getAllStockTypeRequest,
} from '../../modules/Company/redux/actions';
import { getAllcategoriesRequest } from '../../modules/Categories/redux/actions';
import { setValueSet } from '../ValueSet/actions';
import { createStructuredSelector } from 'reselect';
import { selectAllValueSets, selectValueSet } from '../ValueSet/selector';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { language } from '../../utils/formatters';
import AuthorResultSearch from './AuthorResultSearch';
import { selectSearchData } from '../../modules/Search/redux/selector';

const searchResult = cn('search-result');
const searchFrom = cn('search-from');

const stateColor = {
  GREY: 'default',
  RED: 'down',
  GREEN: 'up',
};

class SearchResult extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    const actions = {
      author: getAllAuthorRequest,
      companies: getAllCompanyDupRequest,
      sectors: getAllSectorsRequest,
      equityTickers: getAllEquityTickersRequest,
      currency: getAllCurrencyRequest,
      recommendation: getAllStockRecommendationRequest,
      stock: getAllStockRequest,
      categories: getAllcategoriesRequest,
      stockType: getAllStockTypeRequest,
    };

    Object.keys(actions).forEach((key) => {
      dispatch(actions[key]({ meta: { form: key, callback: setValueSet } }));
    });
  }

  getCompanyById = (id) => {
    const { valueSetsAll } = this.props;
    if (valueSetsAll) {
      return valueSetsAll?.companies?.find((company) => company.id === id);
    }
  };

  getRecommendation = (id) => {
    const { valueSetsAll } = this.props;
    const { equityTickers, currency } = valueSetsAll;
    if (valueSetsAll) {
      const companyID = valueSetsAll?.companies?.find(
        (company) => company?.id === id,
      );
      if (companyID) {
        const equityID = equityTickers?.find((item) => {
          return item?.id === companyID?.equityTickerId;
        });

        const currencyName = currency?.find(
          (iy) => iy.id === equityID?.currencyId,
        );

        return {
          tickerName: equityID?.name,
          targetPrice: equityID?.stockRecommendation?.targetPrice,
          currencyName: currencyName?.name,
          status:
            stateColor[equityID?.tickerState ? equityID?.tickerState : 'GREY'],
          recommendationName: language(
            equityID?.stockRecommendation?.recommendation,
            'en',
          ),
        };
      }
    }
  };

  getАnalystsSeacrch = (companyId) => {
    const { valueSetsAll } = this.props;
    const company = this.getCompanyById(companyId);
    const arrayAuthors = [];
    if (valueSetsAll && company) {
      if (
        valueSetsAll?.author?.find((author) =>
          company?.authors.includes(author.id),
        )
      ) {
        const ao = valueSetsAll?.author?.find((author) =>
          company?.authors.includes(author.id),
        );
 //       console.log('getАnalystsSeacrch ao=', ao)
        if( !ao.blocked)
          arrayAuthors.push({
            fullName: language(ao.name),
            photo: ao.photo,
            urlPage: `/analyst-profile/${ao.id}`,
          });
      }
    }

    return arrayAuthors;
  };

  getAuthorSearch = (authorId) => {
    const { valueSetsAll } = this.props;
    if (valueSetsAll && authorId) {
      if (valueSetsAll?.author?.find((author) => authorId === author.id)) {
        const ao = valueSetsAll?.author?.find(
          (author) => authorId === author.id,
        );
        return {
          fullName: language(ao.name),
          photo: ao.photo,
          id: ao.id,
          urlPage: `/analyst-profile/${ao.id}`,
        };
      }
    }
  };

  render() {
    const {
// 2022-02-15
//      dataSearch = {},
      shouldRenderResults = true,
      shouldRenderHeader = true,
    } = this.props;
    let countResults = 0;
// 2022-02-15 изменено
//     console.log('this.props=', this.props);
    let dataSearch = (this.props.dataSearch || this.props.dataSearch == null) ? this.props.dataSearch: {} ;
    if( dataSearch && dataSearch.events == null)  {
      dataSearch.events = [];
      dataSearch.companies = [];
    }
    if( dataSearch && dataSearch.ourTeam == null)  {
      dataSearch.ourTeam = [];
    }
// изменено конец


    dataSearch &&
      Object.keys(dataSearch).forEach((key) => {
        if (
          Array.isArray(dataSearch[key]) &&
          ['companies', 'ourTeam', 'products'].includes(key)
        ) {
          countResults += dataSearch[key].length;
        }
      });

    return (
      <div className={searchResult()}>
        <SearchInput />
        {shouldRenderHeader && (
          <span className={searchFrom('count-result')}>
            {`Number of results: ${countResults} `}
          </span>
        )}
        {shouldRenderResults &&
          dataSearch?.companies.map(({ itemId }) => (
            <CompanyResultSearch
              recommendation={this.getRecommendation(itemId)}
              company={this.getCompanyById(itemId)}
              analystsSeacrch={this.getАnalystsSeacrch(itemId)}
            />
          ))}
        {shouldRenderResults &&
          dataSearch?.ourTeam.map(({ itemId }) => (
            <AuthorResultSearch user={this.getAuthorSearch(itemId)} />
          ))}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  valueSet: selectValueSet(),
  dataSearch: selectSearchData,
  valueSetsAll: selectAllValueSets,
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, withRouter)(SearchResult);
