import { fromJS } from 'immutable';
import { SET_INTERFACE_SET } from './constants';

const initialState = fromJS({});

const interfaceSetReducer = (state = initialState, { type, payload }: any) => {
  switch (type) {
    case SET_INTERFACE_SET: {
      const data = payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state.set('setting', data);
    }
    default:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      return state;
  }
};

export default interfaceSetReducer;
